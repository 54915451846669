import React from 'react'

class VisitGoal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false
    }

    this.toggleEditing = this.toggleEditing.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpdateFormSubmit = this.handleUpdateFormSubmit.bind(this)
  }

  toggleEditing(evt) {
    this.setState({ editing: !this.state.editing });
  }

  handleDelete(evt) {
    evt.preventDefault();
    this.props.onVisitGoalDelete(this.props.id, this.props.deleteURL)
  }

  handleUpdateFormSubmit(evt) {
    evt.preventDefault();

    var count = this.refs.visitCountEdit.value.trim();
    if(!count){
      return alert('Please insert a valid value in expected visit count');
    }
    var formData = $( this.refs.updateForm ).serialize();
    var formData = {}
    formData.visit_goal = {}
    formData.visit_goal.expected_visits = this.refs.visitCountEdit.value
  
    this.props.onVisitGoalUpdate(formData, this.props.updateURL);
    this.toggleEditing(evt);
  }

  render() {
    var visitGoalCounter
    var availableActions

    if (this.state.editing) {
      availableActions = (
        <div className="btn-group">
          <button className="btn btn-primary btn-sm mr-1" onClick={ this.handleUpdateFormSubmit }>Save</button>
          <button className="btn btn-light btn-sm" onClick={ this.handleDelete }>Delete</button>
        </div>
      )

      visitGoalCounter = (
        <input ref="visitCountInput" className="form-control" name={ this.props.updateForm.visits_input_name } ref="visitCountEdit" autoFocus={true} defaultValue={ this.props.visitCount }/>
      )
    }
    else {
      availableActions = (
        <div className="btn-group">
          <button className="btn btn-link btn-sm" onClick={ this.handleDelete }>Delete</button>
        </div>
      )

      visitGoalCounter = (
        <div className='form-group'>
          <a className='a-link' onClick={ this.toggleEditing }>
            <i className="fa fa-star"></i>
            { this.props.visitCount }
          </a>
        </div>
      );
    }

    return(
      <tr>
        <td>
          { this.props.locationName }
        </td>
        <td style={{ width: 20 + "%" }}>
          <form className="form-inline" ref="updateForm" action={ this.props.updateURL } acceptCharset="UTF-8" method="put" onSubmit={ this.handleUpdateFormSubmit } role="form">
            <p style={{ position: 'relative',margin: 0, padding: 0, width: 0 + "px", display: 'none' }}><input type="hidden" name={ this.props.updateForm.csrf_param } value={ this.props.updateForm.csrf_token } /></p>
            <div className="form-group">
              { visitGoalCounter }
            </div>
            <button type="submit" className="d-none"></button>
          </form>
        </td>
        <td style={{ width: 30 + '%' }}>
          { availableActions }
        </td>
      </tr>
    )
  }
}

class VisitGoalList extends React.Component {
  render() {
    var deleteHandler = this.props.onVisitGoalDelete;
    var updateHandler = this.props.onVisitGoalUpdate;
    var updateForm = this.props.updateForm;

    var visitGoalNodes = this.props.visitGoals.map(function(visitGoal, index){
      return <VisitGoal onVisitGoalUpdate={ updateHandler.bind(null, index) } onVisitGoalDelete={ deleteHandler.bind(null, index) } locationName={ visitGoal.location_name } visitCount={ visitGoal.expected_visits } key={ visitGoal.id } id={ visitGoal.id } updateURL={ visitGoal.update_url } deleteURL={ visitGoal.delete_url } updateForm={ updateForm } />
    })

    return(
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th> { i18n.t('visit_goal.location_name') } </th>
              <th> { i18n.t('visit_goal.visit_count') } </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            { visitGoalNodes }
          </tbody>
        </table>
      </div>
    )
  }
}

export default VisitGoalList
