import React, { Component } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'

import { Translate } from "react-localize-redux"
import Select from 'react-select'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 1040
  },
  content : {
    position                   : 'absolute',
    top                        : '10%',
    left                       : '5%',
    right                      : '5%',
    bottom                     : '10%',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '0px'
  }
}

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class GalleryShareModal extends Component {
  constructor(props) {
    super(props)

    this.handleName = this.handleName.bind(this)
    this.handleDescription = this.handleDescription.bind(this)
    this.handleGalleries = this.handleGalleries.bind(this)
  }

  handleName(e) {
    let value = e.target.value
    this.props.handleData(value, 'name')
  }

  handleDescription(e) {
    let value = e.target.value
    this.props.handleData(value, 'description')
  }

  handleGalleries(values) {
    this.props.handleData(values, 'galleries')
  }

  render() {
    let gs = this.props.gallery_share
    let button = this.props.is_editing ? (<Translate id="gallery_shares.form.edit" />) : (<Translate id="gallery_shares.form.save" />)

    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={this.props.closeSearchModal}
        isOpen={this.props.search_modal_open}>
        <div className="">
          <div onClick={this.props.closeSearchModal} className="close-button">
            <i style={{cursor: 'pointer', marginTop: '15px', marginRight: '20px'}} className="fa fa-times fa-2x clickable pull-right"></i>
          </div>

          <div className="p-5">
            <h2 className="text-dark h5 d-block campo_font-weight-semi-bold mb-4">
              {this.props.is_editing ? (
                <Translate id="gallery_shares.modal.edit_title" />
              ) : (
                <Translate id="gallery_shares.modal.new_title" />
              )}
            </h2>

            <div className="row mb-4">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    <Translate id="gallery_shares.form.name" />
                  </label>

                  <input value={gs.name} onChange={this.handleName} className='form-control text-dark'/>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    <Translate id="gallery_shares.form.description" />
                  </label>

                  <input value={gs.description} onChange={this.handleDescription} className='form-control text-dark'/>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    <Translate id="gallery_shares.form.galleries" />
                  </label>

                  <Select
                    closeMenuOnSelect={false}
                    styles={selectStyles}
                    value={gs.galleries}
                    isMulti={true}
                    onChange={this.handleGalleries}
                    options={this.props.galleries}
                    placeholder={''}
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-auto">
                <button 
                  onClick={this.props.handleSubmit}
                  className="text-white text-uppercase form-campo__button border-0 py-2 px-4 rounded">
                  {button}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
