import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function getDatasources() {
  let url = '/scheduled_reports/datasources.json'
  return axios.get(url).then((response) => response.data)
}

function create(params) {
  let url = '/scheduled_reports'
  return axios.post(url, params).then((response) => response.data)
}

function update(id, params) {
  let url = `/scheduled_reports/${id}`
  return axios.put(url, params).then((response) => response.data)
}

module.exports = {
  getDatasources: getDatasources,
  create: create,
  update: update,
}
