$(function() {
  var from, now, nowTemp, to;
  if ($("#attendanceFrom").length > 0) {
    nowTemp = new Date();
    now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
    from = $("#attendanceFrom").datepicker({
      onRender: function(date) {
        return date;
      }
    }).on("changeDate", function(ev) {
      var newDate;
      if (to.date && ev.date && ev.date.valueOf() > to.date.valueOf()) {
        newDate = new Date(ev.date);
        newDate.setDate(newDate.getDate());
        to.setValue(newDate);
      }
      from.hide();
      $("#attendanceTo")[0].focus();
    }).data("datepicker");
    return to = $("#attendanceTo").datepicker({
      onRender: function(date) {
        if (date.valueOf() < from.date.valueOf()) {
          return "disabled";
        } else {
          return "";
        }
      }
    }).on("changeDate", function(ev) {
      to.hide();
    //$('#attendance_form').submit()
    }).data("datepicker");
  }
});
