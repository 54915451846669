import './jquery'
import JQueryComments from './vendor_libraries_files/jquery-comments'
JQueryComments()

//import 'popper.js/dist/umd/popper'
import './vendor_libraries_files/jquery-ui'

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'moment/moment'
import 'fullcalendar/dist/fullcalendar'
import 'toastr/build/toastr.min.js'
//import 'select2/dist/js/select2.full'
import 'daterangepicker/daterangepicker'
import 'jszip/dist/jszip'
import './json_editor'
import '@json-editor/json-editor/dist/jsoneditor'
