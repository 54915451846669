import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

import CalendarFilters from '../components/submission_explorer/CalendarFilters'
import ModalInfo from '../components/submission_explorer/ModalInfo'
import api from '../utils/submission_explorer_api'
import { ToastContainer, toast } from 'react-toastify';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

moment.locale('en', {
  week: {
    dow: 1
  }
})

const localizer = momentLocalizer(moment)

function MyEvent(props) {
  let event = props.event
  let id = event.model == 's' ? `subs${event.id}` : `item${event.id}`

  return(
    <div id={id}>{event.title}</div>
  )
}

export default class SubmissionExplorerCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendar_type: 'month',
      popoverOpen: false,
      events: [],
      loading_datasources: true,
      filtered: false,
      filters: {},
    }

    this.selected = this.selected.bind(this)
    this.onNavigate = this.onNavigate.bind(this)
    this.onFilter = this.onFilter.bind(this)
    this.eventStyleGetter = this.eventStyleGetter.bind(this)

    this.toggle = this.toggle.bind(this)
    this.closePopover = this.closePopover.bind(this)

    this.onView = this.onView.bind(this)
  }

  closePopover() {
    this.setState({popoverOpen: false, selected_id: null})
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  selected(event) {
    let type = event.model == 'ss' ? 'item' : 'subs'
    if(type == 'subs' || event.s_id) {
      let id = event.s_id || event.id
      window.open(`/submissions/${id}/webview`,'_blank');
      this.setState({popoverOpen: false, selected_id: null})
    } else {
      this.setState({popoverOpen: true, selected_id: `${type}${event.id}`})
    }
  }

  onNavigate(date) {
    let moment_date = moment(date)
    let year = moment_date.year()
    let month = moment_date.month() + 1
    let day = moment_date.date()
    let filters = this.state.filters
    filters.month = month
    filters.year = year
    filters.day = day
    this.onFilter(filters)
  }

  eventStyleGetter(event, start, end, isSelected) {
    let style = {
      border: 'none',
      boxShadow: 'none',
      margin: '0',
      padding: '2px 5px',
    
      borderRadius: '3px',
     
      cursor: 'pointer',
      width: '100%',
      textAlign: 'left',
      fontSize: '12px'
    }
    if(event.type == 'e') {
      style = {...style, backgroundColor: '#3EE3BA' }
    } else if (event.type == 'oe') {
      style = {...style, border: '1px solid tomato' }
    } else if (event.type == 'm') {
      style = {...style, color: '#000', border: '1px solid tomato', backgroundColor: '#fff' }
    }
      
    return {
      style: style
    }
  }

  onFilter(data) {
    let set_date = new Date(data.year + '-' + data.month + '-' + data.day)
    this.setState({filters: data, set_date: set_date, loading_explore: true, events: []}, () => {
      api.getCalendarEvents(data).then((response) => {
        let events = response.data.map((ss) => {
          return(Object.assign(ss, {
            start: moment(ss.start).toDate(),
            end: moment(ss.end).toDate()
          }))
        })
        this.setState({events: events, filtered: true, loading_explore: false})
      }).catch(error => {
        toast.error(i18n.t('errors.backend'), {
          position: toast.POSITION.TOP_RIGHT
        })
        this.setState({loading_explore: false, events: []})
      })
    })
  }

  componentWillMount() {
    api.getDatasources().then((response) => {
      this.setState({datasources: response, loading_datasources: false})
    })
  }

  onView(calendar_type) {
    this.setState({calendar_type: calendar_type, popoverOpen: false, selected_id: null})
  }

  render() {
    let style = {height: '800px'}
    if (this.state.loading_explore) {
      style = {
        opacity: '0.4',
        height: '800px'
      }
    }
    return(
      <Fragment>
        { !this.state.loading_datasources &&
        <div className='bg-white p-3' style={{height: '1200px'}}>
          <div className='row'>
            <div className='col-md-12'>
              <CalendarFilters
                datasources={this.state.datasources}
                onFilter={this.onFilter}
              />
            </div>
          </div>

          { this.state.filtered &&
          <div className='row'>
            <div className='col-md-12 campo__calendar' style={style}>
              <Calendar
                popup 
                localizer={localizer}
                events={this.state.events}
                startAccessor="start"
                endAccessor="end"
                date={this.state.set_date}
                onSelectEvent={this.selected}
                onNavigate={this.onNavigate}
                onView={this.onView}
                views={['month', 'week','day','agenda']}
                drilldownView="week"
                timeslots={2}
                step={15}
                eventPropGetter={this.eventStyleGetter}
                components={
                  {event: MyEvent}
                }
              />
            </div>
          </div>
          }

          { this.state.selected_id &&
            <ModalInfo
              isOpen={this.state.popoverOpen}
              selected_id={this.state.selected_id}
              closeModal={this.closePopover}
            />
          }

        </div>
        }

        <ToastContainer autoClose={5000} />
      </Fragment>
    )
  }
}
