var app = window.app || {};
app.calendar = app.calendar || {};

$.extend(app.calendar, new function(){

    var module = {
        copyWeek: copyWeek
    };

    return module;

    function copyWeek(caller, callback) {
        var $target = $(caller.currentTarget);
        var fromWeek = $target.data('fromWeek');
        var toWeek = $target.data('toWeek');

        var sourceEvents = app.calendar.eventsByCalendarWeekId(fromWeek);
        var result = reloadWeek(toWeek, sourceEvents);
        return callback ? callback() : result;
    }

    function reloadWeek(id, events) {
        var selector = '.calendar[data-week-number="{0}"]'.replace('{0}', id);
        $(selector).fullCalendar('removeEvents');
        $(selector).fullCalendar('addEventSource', events);
    }

});


$(function(){
    app.calendar.drawCalendar();
    var elementsToBind = $('a[name="copy-week-btn"]');

    app.bindOnClick(elementsToBind, function(caller){
        app.calendar.copyWeek(caller);
    });
});
