import React, { Component } from 'react';
import BaseInsightComponent from './BaseInsightComponent';

class InsightColumnChart extends BaseInsightComponent {
  chartConfig(){
    let percentage_label = this.props.data.show_percentage ? "%" : ""
    let config = {
      chart: {
        type: 'column'
      },
      title: {
        text: this.props.name
      },
      subtitle: {
        text: this.props.description
      },
      xAxis: {
        categories: this.props.data.labels,
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        min: 0,
        title: {
          text: this.props.data.yText
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: `<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:.1f}${percentage_label}</b></td></tr>`,
        footerFormat: '</table>',
        useHTML: true,
        shared: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: this.props.data.series,
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ["printChart",
              "separator",
              "downloadXLS",
              "downloadCSV",
              "separator",
              "downloadPNG",
              "downloadPDF",
            ]
          }
        }
      }
    };

    if(this.props.data.isStacked) {
      if(this.props.chart_type == 'line_chart') {
        config.chart = {
          type: 'bar'
        }
      }

      config.stackLabels = {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:'gray'
        }
      }
      config.tooltip = {
        formatter: function() {
          return '<b>'+ this.x +'</b><br/>'+
            this.series.name +': '+ this.y +'<br/>'
        }
      }
      config.plotOptions = {
        series: {
          stacking: 'normal'
        }
      }
    }

    return config
  }
}

export default InsightColumnChart;
