import React from 'react'

class LocationSearchForm extends React.Component {
  constructor(props) {
    super(props)

    this.stopSubmit = this.stopSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  stopSubmit(evt) {
    evt.preventDefault();
  }

  handleChange(evt) {
    var query = this.refs.searchInput.value.trim();

    if (query.length >= 2) {
      this.props.onChange(this.props.form.url, query);
    }
  }

  render() {
    return (
      <form onSubmit={ this.stopSubmit } ref="form" className='bootstrap-validator-form' action={ this.props.form.url } acceptCharset="UTF-8" method="get" role="form">
        <div className="form-group">
          <input type="text" className="form-control" ref="searchInput" placeholder={this.props.placeholder} value={this.props.query} onChange={this.handleChange}/>
        </div>
      </form>
    )
  }
}

export default LocationSearchForm
