export default function normalize(object, fieldsToRemove = ['label', 'selected', 'periods_in_past']) {
  if (Object.keys(object).indexOf('dashboard_type') === -1) {
    fieldsToRemove = fieldsToRemove.concat(['name']);
  }
  const keys = Object.keys(object)
              .filter(k => fieldsToRemove.indexOf(k) === -1)
              .filter(k => object[k] !== '');
  if (keys.length === 0) return;
  if (keys.length === 1 && object[keys[0]] !== '') return object[keys[0]];
  const objectKeysIsEmpty = keys.every(k => {
    if (object[k] === '')
      return true;
    if (typeof object[k] === 'object' && Object.keys(object[k]).length === 0)
      return true;
    return false;
  });
  if (objectKeysIsEmpty) return;
  return keys.reduce((obj, key) => {
    if (Array.isArray(object[key])) {
      const arr = object[key]
                .map(o => normalize(o))
                .filter(o => o !== undefined);
      if (arr.length !== 0)
        obj[key] = arr;
      else
        obj[key] = [];
    } else if (typeof object[key] === 'object') {
      const o = normalize(object[key]);
      if (o !== undefined)
        obj[key] = o;
    } else {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};
