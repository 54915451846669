import React, { Component } from 'react';
import resourcesApi from '../../utils/resources_api'

export default class BaseInsightButton extends Component {
  constructor(props) {
    super(props)

    this.showInsightCreationModal = this.showInsightCreationModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleToast = this.handleToast.bind(this)
    this.state = {
      showModal: false
    }
  }

  showInsightCreationModal() {
    resourcesApi.getDataSource(this.props.entity_type, this.props.entity_id).then((datasources) => {
      this.setState(() => {
        return {
          dataSources: datasources,
          showModal: true,
        }
      })
    })
  }

  closeModal() {
    this.setState(() => {
      return {
        showModal: false,
      }
    })
  }

  handleToast(type) {
    this.refs.toastContainer.clear()
    switch(type) {
      case 'create_success':
        this.refs.toastContainer.success(
          'Dashboard successfully created!',
          '', {
            timeOut: 3000
        });
      case 'update_success':
        this.refs.toastContainer.success(
          'Dashboard updated!',
          '', {
            timeOut: 3000
        });
      case 'create_error':
        this.refs.toastContainer.error(
          'Error! Could not create dashboard.',
          '', {
            timeOut: 3000
        });
      case 'update_error':
        this.refs.toastContainer.error(
          'Error! Could not update dashboard.',
          '', {
            timeOut: 3000
        });
    }
  }

  render() {
    return(<div></div>)
  }
}
