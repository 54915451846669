import React, { Component } from 'react'
import { render } from 'react-dom'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "../Images/translations/global.json"

import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment from 'moment'

class GallerySharesShowGalleryNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: {
        search: ''
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    })

    this.selectDateRange = this.selectDateRange.bind(this)
    this.selectSearch = this.selectSearch.bind(this)
    this.clearDates = this.clearDates.bind(this)
    this.handleFilter = this.handleFilter.bind(this)

    this.isOutsideRange = this.isOutsideRange.bind(this)
  }

  isOutsideRange(day) {
    let is_invalid = false
    let start_date = this.props.gallery.filters.start_date
    let end_date = this.props.gallery.filters.end_date
    if(start_date && end_date) {
      is_invalid = day < moment(start_date) || day > moment(end_date)
    } else if (start_date) {
      is_invalid = day < moment(start_date)
    } else if (end_date) {
      is_invalid = day > moment(end_date)
    } else {
      is_invalid = moment().diff(day) < 0
    }

    return is_invalid
  }

  handleFilter() {
    this.props.handleFilter(this.state.filters)
  }

  selectDateRange(data) {
    let filters = this.state.filters
    filters.start_date = data.startDate
    filters.end_date = data.endDate
    this.setState({filters: filters})
  }

  selectSearch(event) {
    let value = event.target.value
    let filters = this.state.filters
    filters.search = value
    this.setState({filters: filters})
  }

  clearDates() {
    let filters = this.state.filters
    filters.start_date = null
    filters.end_date = null
    this.setState({filters: filters})
  }

  render() {
    let { gallery, comesAuth } = this.props

    return(
      <div>
        <section className='images-filters bg-white'>
          <div className={"container-fluid " + (comesAuth ? "" : "border-top")}>
            <div className="container p-0 pl-sm-3 pr-sm-3">
              { !comesAuth &&
              <div className="row no-gutters">
                <nav className="w-100">
                  <div className="nav nav__sub-header py-3 justify-content-end">
                    <a className="gallery-name">
                      {gallery.name}
                    </a>
                  </div>
                </nav>
              </div>
              }
              <div className='row'>
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-group--campo_label text-uppercase m-0">
                      <Translate id="advance_search.form.search.label" />
                    </label>
                    <input value={this.state.filters.search} onChange={this.selectSearch} className='form-control text-dark'/>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-group--campo_label text-uppercase m-0">
                      <Translate id="advance_search.form.date_range.label" />
                      { (this.state.filters.start_date || this.state.filters.end_date) &&
                        <a className='ml-3' onClick={this.clearDates}>
                          <Translate id="advance_search.form.date_range.clear" />
                        </a>
                      }
                    </label>
                    
                    <div>
                      <DateRangePicker
                        orientation="horizontal"
                        verticalHeight={568}
                        hideKeyboardShortcutsPanel
                        startDate={this.state.filters.start_date}
                        startDateId="start_date_id"
                        startDatePlaceholderText={this.props.translate("advance_search.form.date_range.placeholder_start")}
                        endDate={this.state.filters.end_date}
                        endDateId="end_date_id"
                        endDatePlaceholderText={this.props.translate("advance_search.form.date_range.placeholder_end")}
                        onDatesChange={this.selectDateRange}
                        focusedInput={this.state.focusedInput}
                        isOutsideRange={this.isOutsideRange}
                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-1 col-md-2">
                  <div className="form-group">
                    <div className="mobile-margin hidden-xs-down mt-4"></div>
                    <button 
                      onClick={this.handleFilter}
                      className="text-white text-uppercase form-campo__button border-0 py-2 px-4 rounded">
                      <Translate id="advance_search.filter" />
                    </button>
                  </div>
                </div>

                <div className="col-lg-1 col-md-2">
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withLocalize(GallerySharesShowGalleryNav)
