import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import DrillDownTable from './DrillDownTable'
import GeneralBarChart from './GeneralBarChart'
import GeneralTable from './GeneralTable'

export default class DurationReport extends Component {
  render() {
    let children = []
    this.props.aggregations.forEach((aggs) => children = children.concat(aggs.children))

    return(
      <div>
        <div className='row'>
          <div className='col-md-12 my-2'>
            <h4>{this.props.filters.report_type.label}</h4>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 '>
            <GeneralTable
              {...this.props}
            />
          </div>
        </div>
        { children.length > 0 &&
          <Fragment>
            <div className='row'>
              <DrillDownTable
                {...this.props}
              />
            </div>
          </Fragment>
        }
      </div>
    )
  }
}
