import React, { Component } from 'react';
import { render } from 'react-dom'
import acknowledgeApi from '../utils/acknowledge_api'

function HasAcknowledge (props) {
  return (
    <div className='ack-title'>
      {props.label}
    </div>
  )
}

class AcknowledgeList extends Component {
  componentDidMount() {
    this.loadTooltip();
  }

  componentDidUpdate() {
    this.loadTooltip();
  }

  render() {
    var list = ''
    for (var i = 0; i < this.props.acknowledge_list.length; i++) {
      list = `${list}<p>${this.props.acknowledge_list[i].user} <br/><small style="color:lightblue">${this.props.acknowledge_list[i].created_at}</small></p>`
    }


    return(
      <div className='ack-total' data-toggle="tooltip" data-placement="bottom" data-html='true' title={list}>
        {this.props.label}
      </div>
    )
  }

  loadTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
  }
}

function PendingAcknowledge (props) {
  return(
    <div className='mark-acknowledge' onClick={() => props.handleMarkAcknowledge(props.record)}>
      {props.label}
    </div>
  )
}

export default class Acknowledge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      has_acknowledge: props.has_acknowledge,
      acknowledge_list: props.acknowledge_list || [],
      total_acknowledge: props.i18n.total_acknowledge
    }

    this.handleMarkAcknowledge = this.handleMarkAcknowledge.bind(this)
  }

  handleMarkAcknowledge(record) {
    acknowledgeApi.submitAcknowledge(record.id, record.type, this.props.internal_token).
      then((response) =>{
      this.setState({
        has_acknowledge: true,
        acknowledge_list: response.acknowledge_list,
        total_acknowledge: response.total_acknowledge
      })
    })
  }

  render() {
    return(
      <div className='marka'>
      {this.state.has_acknowledge
        ? <HasAcknowledge label={this.props.i18n.marked_acknowledge}/>
        : <PendingAcknowledge label={this.props.i18n.mark_acknowledge}
          handleMarkAcknowledge={this.handleMarkAcknowledge}
          record={this.props.record}/>
      }
      <AcknowledgeList acknowledge_list={this.state.acknowledge_list} label={this.state.total_acknowledge}/>
      </div> 
    )
  }
}
 
