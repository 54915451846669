import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

export default function Legend(props) {
    let {report_type} = props
    if(report_type == 'route_compliance') {
      return(
        <div className='d-flex flex-row-reverse'>
          <span className="small text-muted">Scheduled</span>
          <div className="submission-item future mr-1 ml-2"><div></div></div>
          <span className="small text-muted">Missing</span>
          <div className="submission-item none mr-1 ml-2"><div></div></div>
          <span className="small text-muted">Unexpected</span>
          <div className="submission-item extra mr-1 ml-2"><div></div></div>
          <span className="small text-muted">Completed</span>
          <div className="submission-item good mr-1"><div></div></div>
        </div>
      )
    } else {
      return (
        <div className='d-flex flex-row-reverse'>
          <span className="small text-muted">Submission</span>
          <div className="submission-item good mr-1"><div></div></div>
        </div>
      )
    }
}

