import React from 'react'

class Location extends React.Component {
  componentDidMount(){
    var $el = $(this.refs.location);
    var eventsData = {
      title: this.props.name,
      location: this.props.id
    }

    $el.data('eventObject', eventsData)

    $el.draggable({
      zIndex: 999,
      revert: true,
      revertDuration: 0
    });
  }

  render() {
    return(
      <div
        ref="location"
        className="name ls__location badge badge-info ui-draggable"
        data-location= { this.props.id }
        style={ { position: "relative"} }>
          {this.props.name}
      </div>
    )
  }
}

class LocationsList extends React.Component {
  render() {
    var content

    if(this.props.loading){
      content = ( <div className="loading-md"></div> )
    } else {
      content = this.props.locations.map(function(location){
        return (
          <Location key={ location.id } id={ location.id } name={ location.name }/>
        );
      });
    }

    return(
      <div id="locations" className="list mt-3">
        { content }
      </div>
    )
  }
}

export default LocationsList
