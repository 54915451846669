import React, { Component } from 'react'
import { render } from 'react-dom'
import { AndOrRadio, ConditionLabel } from './common_components'

import SingleFilter from './SingleFilter'

export default class FilterGroup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filter_type: 'and'
    }

    this.handleFilterType = this.handleFilterType.bind(this)
    this.addNewFilter = this.addNewFilter.bind(this)
    this.updateSingleFilter = this.updateSingleFilter.bind(this)
    this.removeSingleFilter = this.removeSingleFilter.bind(this)
  }

  handleFilterType(type) {
    let filter_group = this.props.filter_group
    filter_group.query_type = type
    this.props.updateFilterGroup(
      this.props.uuid, filter_group
    )
  }

  removeSingleFilter(uuid) {
    let filters = this.props.filter_group.filters.filter((item) => item.uuid != uuid)

    this.props.updateFilterGroup(
      this.props.uuid, Object.assign(this.props.filter_group, {filters})
    )
  }

  isSingleFilterValid(single_filter) {
    let valid = false
    let {filter_condition, filter_type, filter_key, filter_value} = single_filter

    if(filter_type.value == 'key') {
      valid = !!(filter_condition.value && filter_type.value && filter_key && filter_key.value)
      if(filter_condition.value != 'presence') {
        if(Array.isArray(filter_value)) {
          valid = !!(valid && filter_value.length > 0)
        } else {
          valid = !!(valid && filter_value)
        }
      }
    } else if(filter_type.value == 'answer') {
      valid = !!(filter_condition.value && filter_type.value)
      if(Array.isArray(filter_value)) {
        valid = !!(valid && filter_value.length > 0)
      } else {
        valid = !!(valid && filter_value)
      }
    } else if(filter_type.value == 'exception') {
      valid = !!(filter_condition.value && filter_type.value)
    } else {
      let valid_value = false
      if(Array.isArray(filter_value)) {
        valid_value = filter_value.length > 0
      } else {
        valid_value = !!filter_value.value
      }
      valid = filter_condition.value && filter_type.value && valid_value
    }
    return valid
  }

  updateSingleFilter(uuid, single_filter) {
    let valid = this.isSingleFilterValid(single_filter)
    let filters = this.props.filter_group.filters
    let index = filters.findIndex((filter) => filter.uuid == uuid)
    single_filter.valid = valid
    filters[index] = single_filter

    this.props.updateFilterGroup(
      this.props.uuid, Object.assign(this.props.filter_group, {filters})
    )
  }

  addNewFilter() {
    let filters = this.props.filter_group.filters
    filters.push({
      uuid: Math.random().toString(36).substring(2),
      valid: false,
    })
    this.props.updateFilterGroup(
      this.props.uuid, Object.assign(this.props.filter_group, {filters})
    )
  }

  render() {
    let {filter_group, show_validations} = this.props
    let show_errors = show_validations && filter_group.filters.length == 0
    let border_class = show_errors ? 'border-danger' : ''

    return(
      <div>
        { this.props.position != 0 &&
          <div className='single-filter-condition'>
            <ConditionLabel type={this.props.query_type}/>
          </div>
        }

        <div className={"my-2 card " + border_class}>
          <div className='filter-group-close'>
            <i onClick={() => this.props.removeFilterGroup(filter_group.uuid)}
              className='fa text-muted fa-times'></i>
          </div>

          <div className='card-body'>
            {filter_group.filters.map((filter, index) => {
              return(
                <SingleFilter
                  show_validations={show_validations}
                  form={this.props.form}
                  update={this.updateSingleFilter}
                  remove={this.removeSingleFilter}
                  datasources={this.props.datasources}
                  single_filter={filter}
                  uuid={filter.uuid}
                  key={filter.uuid}
                  query_type={filter_group.query_type}
                  position={index} />
              )
            })}
            <div className="row">
              <div className="col-sm-9">
                <div className="mt-3 form-campo__condition-action-button">
                  <button type="button" onClick={this.addNewFilter} className="btn btn-light">
                    <i className='fa fa-plus-circle'></i> {i18n.t('analytics.form.add_filter')}
                  </button>
                </div>
              </div>
              { filter_group.filters.length > 1 &&
              <div className="col-sm-3">
                <div className="mt-4">
                  <AndOrRadio
                    selected={filter_group.query_type}
                    onChange={this.handleFilterType}
                    uuid={this.props.filter_group.uuid}
                  />
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
