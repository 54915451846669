import axios from 'axios'

function getChainCategoryCompliances(entity_type, entity_id, form_id) {
  let url = ''

  if (entity_type && entity_id) {
    url = `/forms/${form_id}/compliances.json?entity_type=${entity_type}&entity_id=${entity_id}`;
  } else {
    url = `/forms/${form_id}/compliances.json`;
  }

  return axios.get(url).then(response => response.data);
}

function getRouteData(form_id) {
  let url = `/forms/${form_id}/route_dashboards.json`;
  return axios.get(url).then(response => response.data);
}

module.exports = {
  getChainCategoryCompliances: getChainCategoryCompliances,
  getRouteData: getRouteData,
}
