import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import DrillDownTable from './DrillDownTable'
import GeneralBarChart from './GeneralBarChart'
import GeneralTable from './GeneralTable'
import AnalyticGraph from './AnalyticGraph'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

export default class LocationsReported extends Component {
  render() {
    let children = []
    this.props.aggregations.forEach((aggs) => children = children.concat(aggs.children))

    return(
      <div>
        <div className='row'>
          <div className='col-md-12 my-2'>
            <h4>Locations where reported</h4>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 '>
            <GeneralTable
              {...this.props}
            />
          </div>
        </div>
        { children.length > 0 &&
          <Fragment>
            <div className='row'>
              <DrillDownTable
                {...this.props}
              />

              <div className='col-md-12'>
                <GeneralBarChart
                  {...this.props}
                />
              </div>

            </div>

            <div className='row'>
              <div className='col-md-12 mt-4'>
                <Tabs>
                  <TabList className='react-tabs__tab-list mx-4'>
                    {this.props.aggregations.map((bucket) => {
                      return(<Tab key={bucket.key}>{bucket.name}</Tab>)
                    })}
                  </TabList>

                  {this.props.aggregations.map((bucket) => {
                    return(
                      <TabPanel key={bucket.key}>
                        <AnalyticGraph 
                          key={bucket.key}
                          bucket={bucket}
                          filters={this.props.filters}
                        />
                      </TabPanel>
                    )
                  })}
                </Tabs>
              </div>
            </div>
          </Fragment>
        }
      </div>
    )
  }
}
