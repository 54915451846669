import React, { Component } from 'react'
import { render } from 'react-dom'

import { LocalizeProvider } from "react-localize-redux"
import { BrowserRouter as Router, Route } from "react-router-dom"

import GallerySharesShow from '../components/GalleryShares/GallerySharesShow'
import GallerySharesShowGallery from '../components/GalleryShares/GallerySharesShowGallery'

export default class GallerySharesShowContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <LocalizeProvider>
        <Router>
          <div>
            <Route exact path="/gallery_shares/:galleryShareId" render={(props) => <GallerySharesShow {...this.props} {...props} />} />
            <Route path="/gallery_shares/:galleryShareId/galleries/:galleryId" render={(props) => <GallerySharesShowGallery {...this.props} {...props}/>} />
          </div>
        </Router>
      </LocalizeProvider>
    )
  }
}
