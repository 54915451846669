function statistics(values) {
  let total_submissions = 0
  let only_routes = 0
  let completed = 0
  let extra = 0
  let percentage = '0%'
  let groups = [[],[],[],[],[],[],[]]

  values.forEach((s) => {
    if(s.t != 'm' && s.t != 'f') {
      total_submissions += 1
    }

    if(s.t == 'o' || s.t == 'oe' || s.t == 'oes' || s.t == 'm') {
      only_routes += 1
    }

    if(s.t == 'o' || s.t == 'oe' || s.t == 'oes') {
      completed += 1
    }

    if(s.t == 'e') {
      extra += 1
    }

    groups[s.wd - 1].push(s)
  })

  if(only_routes > 0) {
    percentage = `${Math.round((completed/only_routes) * 100)}%`
  }

  return {
    total_submissions,
    only_routes,
    completed,
    extra,
    percentage,
    groups
  }
}

module.exports = {
  statistics: statistics
}
