import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'
import moment from 'moment'

import { useGenerateQRPdfHook } from './hooks_api'

const TaskListItem = ({taskList, handleSelected, setQrSelectedTaskLists, qrSelectedTaskLists}) => {
  let stops = taskList.tasks.length
  let stop_label = stops == 1 ? 'stop' : 'stops'
  let steps = taskList.tasks.map(e => e.attributes.title).join(", ")
  let [qrPdfUrl, setQrPdfUrl] = useState(undefined) 

  const { mutate: generateQRPdf, isLoading: isGeneratingQRPdf} = useGenerateQRPdfHook()

  const generateQRCode = () => {
    if(isGeneratingQRPdf) {
      return
    }

    generateQRPdf(taskList, {
      onSuccess: (data) => {
        setQrPdfUrl(data.file_url)
        window.open(data.file_url, '_blank').focus()
      }
    })
  }

  const handleCheck = (taskListId) => {
    let isChecked = qrSelectedTaskLists.includes(taskListId)
    let ids = []

    if(isChecked) {
      ids = qrSelectedTaskLists.filter(e => e != taskListId)
    } else {
      ids = [...qrSelectedTaskLists, taskListId]
    }

    setQrSelectedTaskLists(ids)
  }

  let isChecked = qrSelectedTaskLists.includes(taskList.id)

  return(
    <tr>
      <td>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheck(taskList.id)}
        />
      </td>
      <td>
        <span className='cursor-pointer text-primary' onClick={() => handleSelected(taskList)}>
          {taskList.name}
        </span>
        <small className='text-muted ml-2'>
          <i className='fa fa-truck'></i> {stops} {stop_label}
        </small>
      </td>
      <td className='text-capitalize'>
        {taskList.status}
      </td>
      <td>
        {taskList.scheduled_on_label}
      </td>
      <td>
        <small className='text-secondary'>{steps}</small>
      </td>
      <td>
        { isGeneratingQRPdf ? (
          <small className='text-primary'>
            <i className='fa fa-spinner'></i> Processing ...
          </small>
        ) : (
          <Fragment>
            { qrPdfUrl ? (
              <a href={qrPdfUrl} className="btn btn-sm btn-outline-secondary" target="_blank">
                <i className='fa fa-qrcode'></i> QR Code
              </a>
            ) : (
              <button type="button" className="btn btn-sm btn-outline-secondary" onClick={generateQRCode}>
                <i className='fa fa-qrcode'></i> QR Code
              </button>
            )}
          </Fragment>
        )}
      </td>
    </tr>
  )
}

const TaskListsTable = ({taskLists, setSelectedTaskList, setQrSelectedTaskLists, qrSelectedTaskLists}) => {
  if(taskLists.length == 0) {
    return(
      <div>
        <center>No task lists</center>
      </div>
    )
  }

  const handleSelected = (taskList) => {
    let selectedTaskList = {...taskList}
    selectedTaskList.scheduled_on = moment(taskList.scheduled_on)
    selectedTaskList.tasks = taskList.tasks.map(e => e.attributes)

    setSelectedTaskList(selectedTaskList)
  }

  const handleCheck = () => {
    let isChecked = qrSelectedTaskLists.length == taskLists.length
    let ids = []
    if(!isChecked) {
      ids = taskLists.map(e => e.id)
    }

    setQrSelectedTaskLists(ids)
  }

  let isChecked = qrSelectedTaskLists.length == taskLists.length

  return(
    <table className="table mb-0">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheck()}
            />
          </th>
          <th>Name</th>
          <th>Status</th>
          <th>Scheduled on</th>
          <th>Tasks</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        { taskLists.map((taskList) => {
          return(
            <TaskListItem 
              key={taskList.id}
              taskList={taskList}
              handleSelected={handleSelected}
              setQrSelectedTaskLists={setQrSelectedTaskLists}
              qrSelectedTaskLists={qrSelectedTaskLists}
            />
          )
        })}
      </tbody>
    </table>
  )
}

export default TaskListsTable
