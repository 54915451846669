// Enable pusher logging - don't include this in production
import toastr from 'toastr'

if(typeof Pusher !== 'undefined' && typeof gon !== 'undefined' && gon.config!= undefined && gon.config.pusher_channel_id != undefined){
  Pusher.logToConsole = gon.config.pusher_log_to_console;
  var pusher = new Pusher(gon.config.pusher_key, {
    encrypted: true,
    cluster: gon.config.pusher_cluster,
    authEndpoint: '/pusher/auth',
    auth: {
      headers: {
        'X-CSRF-Token': gon.config.form_authenticity_token
      }
    }
  });

  var channel = pusher.subscribe(gon.config.pusher_channel_id);
  channel.bind('notice', function(response) {

    toastr.options = {
      'closeButton': true,
      'debug': false,
      'newestOnTop': false,
      'progressBar': true,
      'positionClass': 'toast-bottom-right',
      'preventDuplicates': true,
      'showDuration': '0',
      'hideDuration': '0',
      'timeOut': '5000',
      'extendedTimeOut': '30000',
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut'
    }

    toastr[response.type](response.message)
  });
    
  var channel = pusher.subscribe(gon.config.pusher_channel_id);
  channel.bind('report-update', function(response) {

    toastr.options = {
      'closeButton': true,
      'debug': false,
      'newestOnTop': false,
      'progressBar': true,
      'positionClass': 'toast-bottom-right',
      'preventDuplicates': true,
      'showDuration': '0',
      'hideDuration': '0',
      'timeOut': '5000',
      'extendedTimeOut': '0',
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut'
    }
    
    var pendingTemplateScript;

    if(response.data.state === 'queued' || response.data.state === 'duplicate_ready_to_download'){
      $('.nav-tabs a#nav-three-tab').tab('show'); 
    }

    if(response.data.state === 'ready_to_download' || response.data.state === 'duplicate_ready_to_download' ){
       toastr[response.type](response.message)
       pendingTemplateScript = $('#download-template').html();

    } else {
        pendingTemplateScript = $('#pending-download-template').html();
    }

    // Compile the template
    var pendingTemplate = Handlebars.compile(pendingTemplateScript);

    // Define our data object
    var dateRange = '';
    if(response.data.start_at)
      dateRange = dateRange + +' - '+response.data.end_at

    var context={
      'download_type': response.data.report_type,
      'report_description': response.data.report_description,
      'date_range_start': response.data.start_at ,
      'date_range_end': response.data.end_at,
      'state': response.data.state_description,
      'created_at': response.data.created_at_description,
      'download_id': response.data.id,
      'download_url': '/form_report_downloads/' + response.data.id,
      'download_button_label': response.data.download_button_label
    };

    // Pass our data to the template
    var pendingCompiledHtml = pendingTemplate(context);
    
    if ( $('#download_'+response.data.id) != undefined ){
      $('#download_'+response.data.id).remove();
    }
   $('#downloadable-reports').prepend(pendingCompiledHtml);
    
   $('#download_'+response.data.id).effect('highlight', {}, 5000);
    
  
   
  });


}
