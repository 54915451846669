import React from 'react'
import LocationSearchForm from './LocationSearchForm'
import LocationsList from './LocationsList'
import axios from 'axios'

class LocationSearchBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: 0,
      locations: []
    }
    this._clickDocument = this._clickDocument.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
    this.onComplete = this.onComplete.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
  }

  _clickDocument(e) {
    var component = this.refs.component;
    if (e.target == component || $(component).has(e.target).length) {
    } else {
      this.setState({ locations: [] })
    }
  }

  onSuccess(data){
    let locations = data.data.map(e => e.locations)
    this.setState({ locations });
  }

  onError(){
    this.setState({ locations: [] })
    swal({
      title: "¡Lo sentimos!",
      text: "No pudimos buscar la información en estos momentos. Por favor verificar conectividad de internet",
      type: "error",
    });
  }

  onComplete(){
    this.setState({ loading: this.state.loading - 1 });
  }

  changeHandler(url, query) {
    var context = this;
    this.setState({ loading: this.state.loading + 1 });

    axios.get(url, { params :{ q: query }}).then(this.onSuccess).catch(this.onError).then(this.onComplete)
  }

  render() {
    return(
      <div ref="document">
        <LocationSearchForm form={this.props.presenter.form} onChange={app.debounce(this.changeHandler, 600)} placeholder={i18n.t('location_filter.placeholder')}/>
        <LocationsList loading={this.state.loading} locations={this.state.locations}/>
      </div>
    )
  }
}

export default LocationSearchBox
