const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

module.exports = {
  selectStyles: selectStyles
}
