import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'
import { SingleDatePicker } from 'react-dates';
import moment from 'moment'
import PredefinedPicker from './PredefinedPicker'
import uuid from 'node-uuid';
import { useDatasourcesHooks, useCreateTaskHook, useCreateTaskListHook, useUpdateTaskListHook } from './hooks_api'
import TaskItem from './TaskItem'
import Modal from 'react-bootstrap/Modal'
import { useQueryClient } from 'react-query'

import {selectStyles} from '../../support/helpers'
import Select from 'react-select'

const TaskListForm = (props) => {
  const queryClient = useQueryClient()
  const initialTaskList = props.selectedTaskList.id > 0 ? props.selectedTaskList : {
    name: "",
    scheduled_on: undefined,
    form: undefined,
    tasks: [],
  }

  const [taskList, setTaskList] = useState(initialTaskList)

  const [focused, setFocused] = useState(false)
  const { isLoading: isLoadingDatasources, data: datasources } = useDatasourcesHooks()
  const { mutate: createTask, isLoading: isCreatingTask } = useCreateTaskHook()
  const { mutate: createTaskList, isLoading: isCreatingTaskList } = useCreateTaskListHook()
  const { mutate: updateTaskList, isLoading: isUpdatingTaskList } = useUpdateTaskListHook()

  const addTask = () => {
    createTask({}, {
      onSuccess: (data) => {
        let tasks = [...taskList.tasks]
        tasks.push(data.data.attributes)
        setTaskList({...taskList, tasks})
      }
    })
  }

  if(isLoadingDatasources) {
    return(<div></div>)
  }

  const canSubmit = () => {
    return !isCreatingTask && taskList.name && taskList.scheduled_on && !isCreatingTaskList && !isUpdatingTaskList &&
      taskList.tasks.length > 0 && !taskList.tasks.find(t => !t.location_id)
  }

  const handleSave = () => {
    let submitTaskList = {...taskList}
    if(datasources.forms.length == 1 && !taskList.id) {
      submitTaskList.form = datasources.forms[0]
    }

    if(submitTaskList.id) {
      updateTaskList(submitTaskList, {
        onSuccess: (data) => {
          queryClient.invalidateQueries('task-lists')
          props.onClose()
        }
      })
    } else {
      createTaskList(submitTaskList, {
        onSuccess: (data) => {
          queryClient.invalidateQueries('task-lists')
          props.onClose()
        }
      })
    }
  }

  const selectForm = (e) => {
    let useTaskList = {...taskList}
    useTaskList.form = e
    setTaskList(useTaskList)
  }

  const onClose = () => {
    queryClient.invalidateQueries('task-lists')
    props.onClose()
  }

  const saveLabel = taskList.id ? "Update Changes" : "Create Task List"
  const title = taskList.id ? "Update Task List" : "New Task List"
  const forms = datasources.forms || {}
  const formsSize = forms.length 

  return(
    <Modal show={true} onHide={onClose} size="xl" animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-5 col-sm-12'>
            <label className='form-group--campo_label text-uppercase'>
              Name
            </label>

            <input
              type="text"
              className="form-control"
              disabled={false}
              value={taskList.name}
              onChange={(e)=>{setTaskList({...taskList, name: e.target.value})}}
            />
          </div>


          { formsSize != 0 &&
            <div className='col-md-4 col-sm-12'>
              <label className='form-group--campo_label text-uppercase'>
                Form
              </label>

              { formsSize == 1 &&
                <input type="text" className="form-control" value={forms[0].label} disabled />
              }

              { formsSize > 1 &&
                <Select
                  styles={selectStyles}
                  value={taskList.form}
                  placeholder={'Select form'}
                  onChange={selectForm}
                  options={datasources.forms}
                />
              }
            </div>
          }

          <div className='col-md-3 col-sm-12'>
            <label className='form-group--campo_label text-uppercase'>
              Date
            </label>

            <div className="form-group">
              <SingleDatePicker
                placeholder="mm/dd/yyyy"
                date={taskList.scheduled_on}
                onDateChange={scheduled_on => setTaskList({...taskList, scheduled_on})}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="date"
                orientation="horizontal"
                verticalHeight={568}
                hideKeyboardShortcutsPanel
                isOutsideRange={day => (moment().diff(day) > 0)}
              />

              <PredefinedPicker 
                handlePredefined={(scheduled_on) => setTaskList({...taskList, scheduled_on})}
              />
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <label className='form-group--campo_label text-uppercase'>
              Tasks
            </label>

            { taskList.tasks.map((task) => {
              return(
                <TaskItem
                  key={task.id}
                  task={task}
                  taskList={taskList}
                  datasources={datasources}
                  setTaskList={setTaskList}
                />
              )
            })}

            { props.selectedTaskList.status != 'completed' &&
              <div className="form-campo__condition-action-button">
                <button type="button" onClick={addTask} className="btn btn-light">
                  <i className='fa fa-plus-circle'></i> Add Task
                </button>
              </div>
            }
          </div>
        </div>


        <Modal.Footer>
          <button disabled={!canSubmit()}
            className="btn btn-primary"
            onClick={handleSave}>{saveLabel}</button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default TaskListForm
