import React, { Component } from 'react'
import { render } from 'react-dom'

import RegisterModal from '../components/EmailSchedule/RegisterModal'
import { LocalizeProvider } from "react-localize-redux"

import EmailScheduleApi from '../utils/email_schedule_api'

export default class EmailSchedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_open: false,
      isFetching: false,
      schedule: {
        form_id: props.form.id,
        report_type: {label: '', value: ''},
        start_at: '',
        interval: {label: '', value: ''},
        period: {label: '', value: ''},
        name: '',
        repeat: 1,
        recipients: [],
        days: ['0','1','2','3','4','5','6'],
        extra_recipients: ''
      }
    }

    this.openSchedule = this.openSchedule.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleSave() {
    this.setState({isFetching: true})
    let url = '/scheduled_reports'
    let form_data = $('#submissions_form').serializeArray()
    let data = {scheduled_report: this.state.schedule, form_data: form_data}
    EmailScheduleApi.create(data).then((response) => {
      this.setState({is_open: false, isFetching: false})
      window.location = "/forms/"+this.props.form.id+"/reports/submissions?scheduled_report=true"
    }).catch((err) => {
      this.setState({other_errors: err.data.errors})
    })
  }

  openSchedule() {
    let url = '/scheduled_reports/datasources.json'
    EmailScheduleApi.getDatasources(url).then((response) => {
      this.setState({is_open: true, datasources: response.datasources})
    })
  }

  closeModal() {
    let schedule = {
      form_id: this.props.form.id,
      report_type: {label: '', value: ''},
      start_at: '',
      interval: {label: '', value: ''},
      period: {label: '', value: ''},
      name: '',
      repeat: 1,
      recipients: [],
      days: ['0','1','2','3','4','5','6'],
      extra_recipients: ''
    }
    this.setState({is_open: false, schedule: schedule})
  }

  handleInputChange(key, value) {
    let schedule = this.state.schedule
    schedule[key] = value
    this.setState({schedule: schedule})
  }

  render() {
    return(
      <LocalizeProvider>
        <div>
          <a onClick={this.openSchedule} className='btn btn-default'>
            Schedule
          </a>

          <RegisterModal
            is_open={this.state.is_open} 
            closeModal={this.closeModal}
            handleInputChange={this.handleInputChange}
            schedule={this.state.schedule}
            datasources={this.state.datasources}
            handleSave={this.handleSave}
            isFetching={this.state.isFetching}
            other_errors={this.state.other_errors}
            language={this.props.language}
            form={this.props.form}
          />
        </div>
      </LocalizeProvider>
    )
  }
}
