import React, { Component, Fragment } from 'react';
import { render } from 'react-dom'
import postsApi from '../utils/posts_api'
import ContentLoader from 'react-content-loader'

const TableRow = props => {
  const random = Math.random() * (1 - 0.7) + 0.7
  return (
    <ContentLoader
    height={40}
    width={1060}
    speed={2}
    primaryColor="#d9d9d9"
    secondaryColor="#ecebeb"
    {...props}
    >
    <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
    <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
    <rect x="633" y="13" rx="6" ry="6" width={23 * random} height="12" />
    <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
    <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
    <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

    <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  )
}

const LoadingTable = () => (
  <Fragment>
  {Array(10)
    .fill('')
    .map((e, i) => (
      <TableRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
    ))}
  </Fragment>
)

class UserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 'viewed'
    }
    this.changeSelected = this.changeSelected.bind(this)
  }

  changeSelected(type) {
    this.setState({selected: type})
  }

  render() {
    let viewed = []
    let not_viewed = []
    let ack = []
    let not_ack = []

    let view_ids = []
    let ack_ids = []
    let current = []

    this.props.pa.forEach((ack) => {
      if(ack.viewed) { view_ids.push(ack.user_id)}
      if(ack.acknowledge) { ack_ids.push(ack.user_id)}
    })
    this.props.users.forEach(user => {
      if(view_ids.includes(user.id)) {
        viewed.push(user)
      } else {
        not_viewed.push(user)
      }

      if(ack_ids.includes(user.id)) {
        ack.push(user)
      } else {
        not_ack.push(user)
      }
    })

    if(this.state.selected == 'viewed') {
      current = viewed
    } else if(this.state.selected == 'not_viewed') {
      current = not_viewed
    } else if(this.state.selected == 'ack') {
      current = ack
    } else if(this.state.selected == 'not_ack') {
      current = not_ack
    }

    return(
      <Fragment>
        <ul className="nav nav-tabs nav-justified user-list">
          <li className="nav-item">
            <a className={"nav-link " + (this.state.selected == 'viewed' ? 'active' : '')} onClick={(e) => this.changeSelected('viewed')}>
              {this.props.iTabs.viewed} ({viewed.length})
            </a>
          </li>
          <li className="nav-item">
            <a className={"nav-link " + (this.state.selected == 'not_viewed' ? 'active' : '')} onClick={(e) => this.changeSelected('not_viewed')}>
              {this.props.iTabs.not_viewed} ({not_viewed.length})
            </a>
          </li>
          { this.props.post.require_acknowledge &&
            <Fragment>
              <li className="nav-item">
                <a className={"nav-link " + (this.state.selected == 'ack' ? 'active' : '')} onClick={(e) => this.changeSelected('ack')}>
                  {this.props.iTabs.ack} ({ack.length})
                </a>
              </li>
              <li className="nav-item">
                <a className={"nav-link " + (this.state.selected == 'not_ack' ? 'active' : '')} onClick={(e) => this.changeSelected('not_ack')}>
                  {this.props.iTabs.not_ack} ({not_ack.length})
                </a>
              </li>
            </Fragment>
          }
        </ul>
        <Fragment>
          { current.length > 0 ? (
            <Fragment>
              {current.map((user) => {
                return(<p className='mt-2 mb-0' key={user.id}>{user.name}</p>)
              })}
            </Fragment>
          ) : (
            <div className='py-3'>
              <center>{this.props.iTabs.no_data}</center>
            </div>
          )}
        </Fragment>
      </Fragment>
    )
  } 
}

export default class PostStats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewed: 0,
      acknowledge: 0,
      viewed_list: null,
      acknowledge_list: null,
      expand_list: false,
      loading_list: false,
      users: [],
      pa: [],
    }

    this.getViewedList = this.getViewedList.bind(this)
    this.getAcknowledgeList = this.getAcknowledgeList.bind(this)
    this.expandList = this.expandList.bind(this)
  }

  expandList() {
    if(this.state.expand_list) {
      this.setState({expand_list: false})
    } else {
      this.setState({expand_list: true, loading_list: true}, () => {
        postsApi.getList(this.props.post_id).then((response) => {
          this.setState({
            users: response.users,
            pa: response.post_acknowledgements,
            loading_list: false,
          })
        })
      })
    }
  }

  componentWillMount() {
    postsApi.getStats(this.props.post_id).then((response) => {
      this.setState({
        viewed: response.viewed,
        acknowledge: response.acknowledge
      })
    })
  }

  getViewedList() {
    postsApi.getList(this.props.post_id, 'viewed').then((response) => {
      this.setState({viewed_list: response.users})
    })
  }

  getAcknowledgeList() {
    postsApi.getList(this.props.post_id, 'acknowledge').then((response) => {
      this.setState({acknowledge_list: response.users})
    })
  }

  render() {
    let expand_label = this.state.expand_list ? this.props.iHideList : this.props.iViewList
    let icon = this.state.expand_list ? 'fa fa-compress mr-1' : 'fa fa-expand mr-1'
    return(
      <Fragment>
        <div className='row mt-2 mb-3'>
          <div className='col-md-3 pl-4'>
            <span className='post-ack'>
              <span className='post-ack-number'>{this.state.viewed}</span>
              <span className='post-ack-text'>{this.props.iViewed}</span>
            </span>
          </div>

          <div className='col-md-3'>
            <span className='post-ack'>
              <span className='post-ack-number'>{this.state.acknowledge}</span>
              <span className='post-ack-text'>{this.props.iAcknowledge}</span>
            </span>
          </div>

          <div className='col-md-6'>
            <span className='post-ack'>
              <span className='post-ack-view-list mt-4' onClick={this.expandList}>
                <i className={icon}></i> {expand_label}
              </span>
            </span>
          </div>
        </div>
        { this.state.expand_list && 
          <div className='row'>
            {this.state.loading_list ? (
              <div className='col-md-12'>
                <LoadingTable />
              </div>
            ): (
              <div className='col-md-12'>
                <UserList 
                  post={this.props.post}
                  users={this.state.users}
                  pa={this.state.pa}
                  {...this.props}
                />
              </div>
            )}
          </div>
        }
      </Fragment>
    )
  }
}
