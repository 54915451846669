import 'react-dates/initialize'

var Highcharts = window.Highcharts = global.Highcharts = require('highcharts');
var Highcharts = window.Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

var Sentry = require("@sentry/browser");
if(document.body.dataset.sentryJsEnabled == 'true') {
  Sentry.init({dsn: document.body.dataset.sentryDsn});
}

global.i18n = require('./components/support/my_i18n.js');
global.Search2 = require('./components/components/Search2.js').default;

global.CascadingOptions = require('./components/components/routes/CascadingOptions').default;
global.VisitGoalBox = require('./components/visit_goal/VisitGoalBox').default;
global.LocationSearchBox = require('./components/location_search/LocationSearchBox').default;

//Project management
global.ConfigurableTable = require('./components/components/projects_management/helpers/ConfigurableTable').default;
global.PivotTable = require('./components/components/projects_management/helpers/PivotTable').default;

//Tasks
global.UserAvatar = require('react-user-avatar');
global.ProfilePageImages = require('./components/components/ProfilePage/ProfilePageImages').default;
global.InsightPieChart = require('./components/components/Chart/InsightPieChart').default;
global.InsightLineChart = require('./components/components/Chart/InsightLineChart').default;
