import React, { Component } from 'react'
import BaseInsightComponent from './BaseInsightComponent'
import PropTypes from 'prop-types'

class InsightPieChart extends BaseInsightComponent {
  constructor(props) {
    super(props);
 
  }
  
  chartConfig(){
    let component = this;
    let config = {
      colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
      chart: {
        plotBackgroundColor: '#fff',
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
         events: {
          drilldown: function(e) {
            var elChart = this;
            this.showLoading('Loading Drilldown ...');

            if(!("drilldown" in e.point))
            {
              return
            } 

            var depth = 0
            if(e.point.d){
              depth = e.point.d
            }

            var group_by = component.props.drilldowns[depth]
            var last = component.props.drilldowns.length - 1 <= depth;
            
            $.get("/projects_management/drilldown.json?last="+last+"&depth="+ depth +"&group_by="+group_by+"&name="+e.point.name+"&query="+ e.point.drilldown, function(data) {
              // data = {
              //       name: Math.random().toString(36).substring(7),
              //       data: [
              //           { name: 'Toyota', id: 'toyo', cursor: "{filters: [{key: 'a', value: '43'}]}", y: 1, drilldown: 'Toyota'},
              //           { name: 'Nissan', id: 'niss',y: 2, drilldown: 'Nissan'},
              //           { name: 'Ford', id: 'ford', y: 3, drilldown: 'Ford'}
              //       ]
              //   }
                
                elChart.hideLoading();
                elChart.addSeriesAsDrilldown(e.point, data);
            });
          
          }
        }
      }, 
     
      drilldown: {
        series: []
      },
      title: {
        text: this.props.name
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      subtitle: {
        text: this.props.description
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            format: '<b>{point.name}</b>: {point.y} ',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          },

          showInLegend: this.props.show_legend
        }
      },
      series: this.props.data.series,
      exporting: {
        showTable: this.props.show_table,
        buttons: {
          contextButton: {
            menuItems: [,
              "downloadXLS",
              "downloadCSV",
              "separator",
              "downloadPNG",
              "downloadPDF",
              "printChart"
            ]
          }
        }
      }
    }

    if(this.props.isMoney) {
      config.tooltip = {
        pointFormat: '{series.name}: <b>${point.y}</b>'
      }

      config.plotOptions = {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          
          dataLabels: {
            enabled: true,
            
            format: '<b>{point.name}</b>: ${point.y} ',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          },

          showInLegend: false
        }
      }
    }

    return config
  }

}

InsightPieChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}
InsightPieChart.defaultProps = {
  title: '',
  subtitle: '',
  series: [],
  drilldowns: []
}

export default InsightPieChart;
