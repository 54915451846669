import React from 'react'
import VisitGoalForm from './VisitGoalForm'
import VisitGoalList from './VisitGoalList'
import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

class VisitGoalBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: this.props.visitGoalPresenter.locations,
      visit_goals: this.props.visitGoalPresenter.visit_goals
    }

    this.handleVisitGoalFormSubmit = this.handleVisitGoalFormSubmit.bind(this)
    this.handleVisitGoalDelete = this.handleVisitGoalDelete.bind(this)
    this.handleVisitGoalUpdate = this.handleVisitGoalUpdate.bind(this)
  }

  handleVisitGoalFormSubmit(formData, action, success, error) {
    axios.post(action,formData).
      then((response) => {
        this.setState({ visit_goals: response.data});
        success.call();
      })
  }

  handleVisitGoalDelete(index, id, action) {
    axios.delete(action, {}).
      then((response) => {
      var goals = this.state.visit_goals;
      if (this.state.visit_goals.length > 1){
        goals.splice( index, 1 );
      }else{
        goals = []
      }
      this.setState({visit_goals: goals });
    }).catch((err) => {
      console.log(err);
    })
  }

  handleVisitGoalUpdate(index, formData, action) {
    axios.put(action, formData).then((response) => {
      var goals = this.state.visit_goals.splice(0);
      goals[index] = response.data;
      this.setState({visit_goals: goals })
    }).catch((err) => {
      //TODO handle failure
      console.log(err);
    })
  }

  render() {
    return(
      <div>
        <div className="row">
          <div className="col-md-12">
            <VisitGoalForm locations={ this.state.locations } form={ this.props.visitGoalPresenter.create_form } onVisitGoalFormSubmit={ this.handleVisitGoalFormSubmit } />
          </div>
        </div>
        { this.state.visit_goals.length > 0  &&
          <div className="row">
            <div className="col-md-12">
              <VisitGoalList onVisitGoalUpdate={ this.handleVisitGoalUpdate } updateForm={ this.props.visitGoalPresenter.update_form } onVisitGoalDelete={ this.handleVisitGoalDelete } visitGoals={ this.state.visit_goals } />
            </div>
        </div>
        }
      </div>
    )
  }
}

export default VisitGoalBox
