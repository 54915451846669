import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import AnalyticsApi from '../../utils/analytics_api'
import QueryForm from './QueryForm'

import AnalyticSaveModal from './AnalyticSaveModal'
import AnalyticType from './AnalyticType'
import { toast } from 'react-toastify'


export default class QueryContainer extends Component {
  constructor(props) {
    super(props)
    let show_query = this.props.comes_from_report ? false : true
    let loading_query = this.props.comes_from_report ? true : false
    this.state = {
      loading: true,
      loading_query: loading_query,
      show_query: show_query,
      aggregations: null,
      single_value: null,
      filters: null,
      datasources: {
        forms: [],
        report_types: [],
      },
      analytic_report: {
        name: '',
        description: '',
        has_mobile_dashboard: {value: false, label: 'No'},
        show_on: {value: 'my_reports', label: 'My reports'},
        clone_title: '',
        clone_description: '',
        clone_order: 1,
      },
      save_modal_open: false,
      query_data: {},
    }

    this.runQuery = this.runQuery.bind(this)
    this.saveQuery = this.saveQuery.bind(this)
    this.closeSaveModal = this.closeSaveModal.bind(this)
    this.handleReportData = this.handleReportData.bind(this)
    this.handleReportCreation = this.handleReportCreation.bind(this)
    this.saveQueryAndRun = this.saveQueryAndRun.bind(this)
  }

  saveQueryAndRun(data) {
    AnalyticsApi.updateQuery(this.props.ar.id, {query: data}).then((response) => {
      this.props.showQuery()
      this.props.ar.query = data
      this.runQuery(data)
    })
  }

  handleReportCreation() {
    let data = {query: this.state.query_data, analytic_report: this.state.analytic_report}
    AnalyticsApi.createAnalyticReport(data).then((response) => {
      this.setState({save_modal_open: false, query_data: {}, analytic_report: {name: ''}}, () => {
        this.props.history.push('/analytics/reports')
      })
    }).catch(() => {
      toast.error("Error!", {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    })

  }

  runQuery(data) {
    this.setState({single_value: null, aggregations: null, filters: data, loading_query: true}, () => {
      AnalyticsApi.analize(data).then((response) => {
        this.setState({
          aggregations: response.analytics,
          single_value: response.value,
          loading_query: false,
        })
      }).catch(() => {
        this.setState({
          loading_query: false,
        })

        toast.error("Error!", {
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
    })
  }

  saveQuery(data) {
    this.setState({save_modal_open: true, query_data: data})
  }

  closeSaveModal() {
    this.setState({
      save_modal_open: false,
      query_data: {},
      analytic_report: {
        name: '',
        description: '',
        has_mobile_dashboard: {value: false, label: 'No'},
        clone_title: '',
        clone_description: '',
        clone_order: 1,
      }
    })
  }

  componentWillMount() {
    AnalyticsApi.getDatasources().then((response) => {
      let forms = response.forms.map((record) => ({value: record.id, label: record.title, ca_label: record.ca_label, cb_label: record.cb_label, cc_label: record.cc_label, cd_label: record.cd_label, capture_location: record.capture_location, process_compliance: record.process_compliance}))
      let chains = response.chains.map((record) => ({value: record.id, label: record.name}))
      let chain_categories = response.chain_categories.map((record) => ({value: record.id, label: record.name}))
      let zones = response.zones.map((record) => ({value: record.id, label: record.name}))
      let location_types = response.location_types.map((record) => ({value: record.id, label: record.name}))

      let users = response.users.map((record) => ({value: record.id, label: record.name}))
      let supervisors = response.supervisors.map((record) => ({value: record.id, label: record.name}))

      let ca = response.ca.map((record) => ({value: record.value, label: record.label, form_id: record.form_id}))
      let cb = response.cb.map((record) => ({value: record.value, label: record.label, form_id: record.form_id}))
      let cc = response.cc.map((record) => ({value: record.value, label: record.label, form_id: record.form_id}))
      let cd = response.cd.map((record) => ({value: record.value, label: record.label, form_id: record.form_id}))

      let datasources = {
        forms: forms,
        chain_categories: chain_categories,
        chains: chains,
        location_types: location_types,
        zones: zones,
        users: users,
        supervisors: supervisors,
        ca: ca,
        cb: cb,
        cc: cc,
        cd: cd,
        report_types: response.report_types,
        keys: response.keys,
        classifiers: response.classifiers,
        location_classifiers: response.location_classifiers,
        user_classifiers: response.user_classifiers,
      }

      this.setState({
        datasources: datasources,
        loading: false
      }, () => {
        if(this.props.comes_from_report) {
          this.runQuery(this.props.query)
        }
      })
    })
  }

  handleReportData(data) {
    this.setState({analytic_report: data})
  }

  render() {
    let show_query = this.props.comes_from_report ? this.props.show_query : true
    let { auth } = this.props

    return(
      <Fragment>
        { show_query && !this.state.loading &&
          <div className='row'>
            <div className='col-md-12'>
              <div className="card">
                <div className="card-header text-uppercase">
                  {i18n.t('analytics.graph.title')}
                </div>
                <div className="card-body">
                  <QueryForm 
                    loading={this.state.loading}
                    datasources={this.state.datasources}
                    runQuery={this.runQuery}
                    saveQuery={this.saveQuery}
                    comes_from_report={this.props.comes_from_report}
                    query={this.props.query}
                    saveQueryAndRun={this.saveQueryAndRun}
                    auth={auth}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        { (this.props.comes_from_report || !this.state.loading) &&
        <div className='row'>
          <div className='col-md-12'>
            <AnalyticType
              filters={this.state.filters}
              aggregations={this.state.aggregations}
              single_value={this.state.single_value}
              loading_query={this.state.loading_query}
              ar={this.props.ar}
              query={this.props.query}
              auth={auth}
            />
          </div>
        </div>
        }

        <AnalyticSaveModal 
          save_modal_open={this.state.save_modal_open}
          closeSaveModal={this.closeSaveModal}
          analytic_report={this.state.analytic_report}
          handleReportData={this.handleReportData}
          handleSubmit={this.handleReportCreation}
        />
      </Fragment>
    )
  }
}
