import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

import ContentLoader from "react-content-loader"

const DayLoader = () => (
  <ContentLoader 
    height={475}
    width={120}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="8" y="13" rx="4" ry="4" width="90" height="12" /> 
    <rect x="10" y="36" rx="4" ry="4" width="50" height="8" /> 
    <rect x="0" y="70" rx="5" ry="5" width="400" height="280" />
  </ContentLoader>
)

export default function WeekLoader(props) {
  return(
    <div className='row'>
      <div className='col'>
        <DayLoader/>
      </div>
      <div className='col'>
        <DayLoader/>
      </div>
      <div className='col'>
        <DayLoader/>
      </div>
      <div className='col'>
        <DayLoader/>
      </div>
      <div className='col'>
        <DayLoader/>
      </div>
      <div className='col'>
        <DayLoader/>
      </div>
      <div className='col'>
        <DayLoader/>
      </div>
    </div>
  )
}
