import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import QueryContainer from './QueryContainer'

export default class QueryIndex extends Component {
  constructor(props) {
    super(props)
  } 

  render() {
    let { history, auth } = this.props  
    return(
      <div className="app p-3">
        <div className="container-fluid py-3">
          <QueryContainer history={history} auth={auth}/>
        </div>
      </div>
    )
  }
}
