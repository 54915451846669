import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'

import Select from 'react-select'
import {selectStyles} from '../../support/helpers'

const options = [
  {value: false, label: 'No'},
  {value: true, label: 'Yes'},
]

const show_on_options = [
  {value: 'my_reports', label: 'My reports (Only visible to you)'},
  {value: 'organization_reports', label: 'Organization reports'},
]

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 1040
  },
  content : {
    position                   : 'absolute',
    top                        : '10%',
    left                       : '5%',
    right                      : '5%',
    bottom                     : '10%',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '0px'
  }
}

import axios from 'axios';

export default class AnalyticSaveModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      folders: [],
    }

    this.handleValue = this.handleValue.bind(this)
    this.selectHasMobileDashboard = this.selectHasMobileDashboard.bind(this)
    this.selectShowOn = this.selectShowOn.bind(this)
    this.selectShowInvertedOnMobile = this.selectShowInvertedOnMobile.bind(this)
    this.selectAnalyticReportFolder = this.selectAnalyticReportFolder.bind(this)
  }

  handleValue(e, type) {
    let value = e.target.value
    let data = this.props.analytic_report
    data[type] = value
    this.props.handleReportData(data)
  }

  selectHasMobileDashboard(value) {
    let data = this.props.analytic_report
    data.has_mobile_dashboard = value
    this.props.handleReportData(data)
  }

  selectShowOn(value) {
    let data = this.props.analytic_report
    data.show_on = value
    this.props.handleReportData(data)
  }

  selectAnalyticReportFolder(value) {
    let data = this.props.analytic_report
    data.analytic_report_folder = value
    this.props.handleReportData(data)
  }

  selectShowInvertedOnMobile(value) {
    let data = this.props.analytic_report
    data.show_inverted_on_mobile = value.value
    this.props.handleReportData(data)
  }

  componentWillMount() {
    axios.get('/web_api/analytic_report_datasources').then((response) => {
      this.setState({folders: response.data.folders})
    })
  }

  render() {
    let ar = this.props.analytic_report
    let show_inverted_on_mobile = {value: false, label: 'No'}
    if(ar.show_inverted_on_mobile) {
      show_inverted_on_mobile = {value: true, label: 'Yes'}
    }

    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={this.props.closeSearchModal}
        isOpen={this.props.save_modal_open}>
        <div className="">
          <div onClick={this.props.closeSaveModal} className="close-button">
            <i style={{cursor: 'pointer', marginTop: '15px', marginRight: '20px'}} className="fa fa-times fa-2x clickable pull-right"></i>
          </div>

          <div className="p-5">
            <h2 className="text-dark h5 d-block campo_font-weight-semi-bold mb-4">
              Analytic Report
            </h2>

            <div className="row mb-2">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    Report name
                  </label>

                  <input value={ar.name} onChange={(e) => this.handleValue(e, 'name')} className='form-control text-dark'/>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    Description
                  </label>

                  <input value={ar.description} onChange={(e) => this.handleValue(e, 'description')} className='form-control text-dark'/>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    Create mobile app dashboard from report
                  </label>

                  <Select
                    styles={selectStyles}
                    value={ar.has_mobile_dashboard}
                    onChange={this.selectHasMobileDashboard}
                    options={options}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    Save to
                  </label>

                  <Select
                    styles={selectStyles}
                    value={ar.show_on}
                    onChange={this.selectShowOn}
                    options={show_on_options}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    Folder
                  </label>

                  <Select
                    styles={selectStyles}
                    value={ar.analytic_report_folder}
                    onChange={this.selectAnalyticReportFolder}
                    options={this.state.folders}
                    isClearable={true}
                  />
                </div>
              </div>
            </div>

            { ar.has_mobile_dashboard && ar.has_mobile_dashboard.value &&
              <Fragment>
                <div className="row mb-2">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        Mobile dashboard title
                      </label>

                      <input value={ar.clone_title} onChange={(e) => this.handleValue(e, 'clone_title')} className='form-control text-dark'/>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        Mobile dashboard description
                      </label>

                      <input value={ar.clone_description} onChange={(e) => this.handleValue(e, 'clone_description')} className='form-control text-dark'/>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        Mobile dashboard position
                      </label>

                      <input pattern="[0-9]*" type='number' value={ar.clone_order} onChange={(e) => this.handleValue(e, 'clone_order')} className='form-control text-dark'/>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        Show recent first on mobile
                      </label>

                      <Select
                        styles={selectStyles}
                        value={show_inverted_on_mobile}
                        onChange={this.selectShowInvertedOnMobile}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            <div className="row justify-content-end">
              <div className="col-auto">
                <button 
                  onClick={this.props.handleSubmit}
                  className="text-white text-uppercase form-campo__button border-0 py-2 px-4 rounded">
                  { this.props.isEdit ? (
                    <Fragment>Update report</Fragment>
                  ) : (
                    <Fragment>Save report</Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
