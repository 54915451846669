import React, { Component } from 'react'
import { render } from 'react-dom'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "../Images/translations/global.json"
import Empty from '../../utils/Empty'

import ImagesApi from '../../utils/images_api'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'

class GalleryShareRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      already_copied: false,
    }

    this.handleRemove = this.handleRemove.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.onCopy = this.onCopy.bind(this)
    this.copyMouseOut = this.copyMouseOut.bind(this)
  }

  copyMouseOut() {
    this.setState({copied: false})
  }

  handleRemove() {
    ImagesApi.removeGalleryShare(this.props.gallery_share.id).then(() => {
      this.props.handleRemove()
    })
  }

  handleEdit() {
    this.props.handleEdit(this.props.gallery_share)
  }

  onCopy() {
    this.setState({copied: true, already_copied: true})
  }

  render() {
    let gs = this.props.gallery_share
    let copy_text = this.state.copied ? "Copied!" : "Click to copy link!"
    let input_show = this.state.already_copied ? {} : {display: 'none'}

    return(
      <div className='row mb-2'>
        <div className='col-md-12'>
          <div className='p-3 bg-white'>
            <div>
              <h6 className="mb-0" style={{fontWeight: '600', display:'inline-flex'}}>{gs.name}</h6>
              <div className="pull-right" style={{fontSize: '14px'}}>
                <a href="#" onClick={this.handleEdit} className='ml-2 text-info'>Edit</a>
                <a href="#" onClick={this.handleRemove} className='ml-2 text-danger'>Remove</a>
              </div>
            </div>

            <small className="mb-1">{gs.description}</small>

            <div className="row">
              <div className="col-md-6">
                <div className="input-group mt-1">
                  <div className="mr-2 text-primary">
                    <CopyToClipboard onCopy={this.onCopy} text={gs.link}>
                      <div className='py-2' onMouseOut={this.copyMouseOut} onClick={this.copyClipboard} style={{cursor: 'pointer', fontSize: '14px'}} data-tip data-for={"copy"+gs.id}>
                        Copy link
                      </div>
                    </CopyToClipboard>

                    <ReactTooltip place="bottom" id={"copy" + gs.id} type="dark" effect="float">
                      <span>{copy_text}</span>
                    </ReactTooltip>
                  </div>

                  <input onChange={() => ''} style={input_show} className="form-control" type="text" value={gs.link} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class GallerySharesIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_modal_open: false,
      name: '',
      gallery_share: {
        name: '',
        description: '',
        galleries: [],
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    })

    this.openSearchModal = this.openSearchModal.bind(this)
    this.closeSearchModal = this.closeSearchModal.bind(this)
    this.handleData = this.handleData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleData(value, type) {
    let gallery_share = this.state.gallery_share
    gallery_share[type] = value
    this.setState({gallery_share: gallery_share})
  }


  handleSubmit() {
    let gallery_share = this.state.gallery_share
    if(!gallery_share.name || !gallery_share.description || gallery_share.galleries.length == 0) {
      return
    }

    ImagesApi.createGalleryShare({
      gallery_share: gallery_share,
    }).then((response) => {
      this.setState({search_modal_open: false, gallery_share: {name: '', description: '', galleries: []}}, () => {
        this.props.handleCreatedGalleryShare()
      })
    })
  }

  openSearchModal() {
    this.setState({search_modal_open: true})
  }

  closeSearchModal() {
    this.setState({search_modal_open: false})
  }

  render() {
    return(
      <div className="app pb-5">
        { this.props.activeLanguage && 
          <div className="container py-3">
            {this.props.gallery_shares.length > 0 ? (
              <div>
                {this.props.gallery_shares.map((gs, index) => {
                  return <GalleryShareRow handleEdit={this.props.handleEdit} handleRemove={this.props.handleRemove} gallery_share={gs} key={gs.id}/>;
                })}
              </div>
            ) : (
              <Empty text={(<Translate id="nav.no_gallery_shares_found" />)} />
            )}
          </div>
        }
      </div>
    )
  }
}

export default withLocalize(GallerySharesIndex)
