import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

export default function GlobalSummary(props) {
  let {summary, report_type} = props

  let {total_submissions, only_routes, completed, extra, percentage} = summary

  return(
    <div className='row pt-4 pb-2 my-4 bg-white border-bottom border-secondary'>
      <div className='col-md-12 mb-4'>
        <h6 className='mb-3 text-uppercase'>{i18n.t('explore.summary')}</h6>
        { report_type == 'route_compliance' ? (
          <div>
            <div className='d-inline pull-left pr-4'>
              <h5 className='mb-0 text-center'>{total_submissions}</h5>
              <span className='text-muted text-uppercase small'>{i18n.t('explore.total_reports')}</span>
            </div>

            <div className='d-inline pull-left pr-4'>
              <h5 className='mb-0 text-center'>{completed} / {only_routes}</h5>
              <span className='text-muted text-uppercase small'>{i18n.t('explore.total_routes')}</span>
            </div>

            <div className='d-inline pull-left pr-4'>
              <h5 className='mb-0 text-center'>{percentage}</h5>
              <span className='text-muted text-uppercase small'>{i18n.t('explore.completed')}</span>
            </div>

            <div className='d-inline pull-left pr-4'>
              <h5 className='mb-0 text-center'>{extra}</h5>
              <span className='text-muted text-uppercase small'>{i18n.t('explore.extra')}</span>
            </div>
          </div>
        ) : (
          <div>
            <div className='d-inline pull-left pr-4'>
              <h5 className='mb-0 text-center'>{total_submissions}</h5>
              <span className='text-muted text-uppercase small'>{i18n.t('explore.total_reports')}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
