import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {HotTable} from '@handsontable/react';

class FormLocationsEditor extends React.Component {
  constructor(props) {
    super(props)

    this.afterChange = this.afterChange.bind(this)
  }

  afterChange(data) {
    let state_data = this.props.state_data

    if(data) {
      data.forEach((element) => {
        let row_position = element[0]
        let column_position = element[1]
        let previous_value = element[2]
        let new_value = element[3]

        let field_id = this.props.row_data.row_map[row_position]
        let assignation_id = this.props.col_data.col_map[column_position]

        if(!state_data[field_id]) {
          state_data[field_id] = {}
        }

        if(new_value == '') {
          if (state_data[field_id][assignation_id])
            delete state_data[field_id][assignation_id]
          
          if (Object.keys(state_data[field_id]).length == 0)
            delete state_data[field_id]  
        }
        else
          state_data[field_id][assignation_id] = new_value
      })

      this.props.saveNewData(state_data)
    }
  }

 
  render() {
    let colData = this.props.col_data
    let rowData = this.props.row_data
    let colHeaders = colData.col_headers
    let rowHeaders = rowData.row_headers

    let maxRows = rowHeaders.length

    let data = this.props.table_data

    return(
      <HotTable 
      root="hot" 
      rowHeaderWidth={500}
      height={700}
      autoRowSize={true}
      colHeaders={colHeaders}
      rowHeaders={rowHeaders} 
      afterChange={this.afterChange}
      data={data} 
      wordWrap= {true}
      maxRows={maxRows}
      stretchH="all"
      />
    );
  }
}

export default FormLocationsEditor
