import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectOperations extends Component {
  constructor(props) {
    super(props);
    this.renderOperations = this.renderOperations.bind(this);
  }

  componentDidMount() {
    if(this.props.operation.value) {
      this.props.handleSelectOperation(this.props.operation.value)
    }
  }

  renderOperations() {
    if (this.props.operations.length > 0) {
      return (
        <Select
          value={this.props.operation.label}
          onChange={this.props.handleSelectOperation}
          placeholder={ this.props.selectOperationText }
          style={{width: '100%'}}
          disabled={this.props.isFetching}
          optionFilterProp="label"
          dropdownStyle={{zIndex: 101}}
          filterOption={filterOptions}>
          {this.props.operations.map((ds) => {
            return (<Option key={ds.value} label={ds.label} value={ds.value}>{ds.label}</Option>)
          })}
        </Select>
      )
    }
  }

  render() {
    return (
      <div>{this.renderOperations()}</div>
    );
  }
}

SelectOperations.defaultProps={
  selectOperationText: i18n.t('dashboard_form.select_operation_header'),
}
