import React, { Component } from 'react'
import { render } from 'react-dom'

export default class Segments extends Component {
  render() {
    return(
      <div>
        Segments
      </div>
    )
  }
}
