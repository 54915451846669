function getDashboardType(dashboard_type) {
  return (dashboard_type.value == 'line_chart' || dashboard_type.value == 'bar_chart') ? 'graph' : dashboard_type.value 
}

function setDataSource(config, data) {
  if(data.datasource.value){
    config.datasource = data.datasource.value
  } 
}

function setFieldGroup(config, data) {
  if(data.fieldGroup.value){
    config.field_group = data.fieldGroup.value
  } 
}

function setFieldSubgroups(config, data) {
  if(data.selectedFieldSubGroups.length > 0){
    config.field_subgroups = data.selectedFieldSubGroups
  } 
}

function setGroupBy(config, data) {
  if(data.group_by.value){
    config.group_by = data.group_by.value
  } 
}

function setDashboardGroup(config, data) {
  if(data.dashboard_group.value){
    config.dashboard_group = data.dashboard_group.value
  } 
}

function setSummarizeBy(config, data) {
  if(data.summarize_by.value){
    config.summarize_by = data.summarize_by.value
  } 
}

function setLocations(config, data) {
  if(data.locations.length > 0){
    config.locations = data.locations
  } 
}

function setSupervisors(config, data) {
  if(data.supervisors.length > 0){
    config.supervisors = data.supervisors
  } 
}

function setUsers(config, data) {
  if(data.users.length > 0){
    config.users = data.users
  } 
}

function setOperation(config, data) {
  if(data.operation.value){
    config.operation = data.operation.value
  } 
}

function setPeriod(config, data) {
  if(data.period.value){
    config.period = data.period.value
  } 
}

function setPeriodInPast(config, data) {
  if(data.period_in_past.value){
    config.periodInPast = data.period_in_past.value
  } 
}

export default function DashboardFormater(object) {
  let config = {}

  let new_object = {
    name: object.name,
    description: object.description,
    id: object.id,
    dashboard_type: getDashboardType(object.dashboard_type),
    chart_type: object.dashboard_type.value,
  }

  Object.keys(new_object).forEach((key) => (new_object[key] == null || new_object[key] == '') && delete new_object[key])

  setDataSource(config, object)
  setFieldGroup(config, object)
  setFieldSubgroups(config, object)
  setGroupBy(config, object)
  setSummarizeBy(config, object)
  setLocations(config, object)
  setSupervisors(config, object)
  setUsers(config, object)
  setOperation(config, object)
  setPeriod(config, object)
  setPeriodInPast(config, object)
  setDashboardGroup(config, object)

  new_object.config = config
  return new_object
};
