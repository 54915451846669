import numeral from 'numeral';

const withSeparator = (number, separator = ',') => {
  if (typeof number == 'string') {
    return number
  } else{
    const format = `0${separator}0.[00]`;
    return numeral(number).format(format);
  }
}

const inPercent = (number, decimalPlaces = 0) => {
  const format = `0.${Array.apply(null, Array(decimalPlaces))
                  .map(Number.prototype.valueOf,0).join('')}%`;
  return numeral(number).format(format);
}

const inCurrency = (number, decimalPlaces = 0, separator = ',') => {
  const format = `$0${separator}0.${Array.apply(null, Array(decimalPlaces))
                  .map(Number.prototype.valueOf,0).join('')}`;
  return numeral(number).format(format);
}

export default {
  withSeparator,
  inPercent,
  inCurrency
}
