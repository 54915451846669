import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

function submitAcknowledge(entity_id, entity_type, internal_token) {
  let url = ''

  if (entity_type == 'submission') {
    url = `/submissions/${entity_id}/acknowledge`
  } else {
    url = `/submission_data/${entity_id}/acknowledge`
  }
  let data = internal_token ? {internal_token: internal_token} : {}

  return axios.post(url, data).then(response => response.data)
}

module.exports = {
  submitAcknowledge: submitAcknowledge
}
