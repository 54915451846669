import React, { Component } from 'react'
import { render } from 'react-dom'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "./translations/global.json"
import ImagesApi from '../../utils/images_api'
import Select from 'react-select'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: 'normal',
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    textOverflow: 'ellipsis',
    maxWidth: "90%",
    whiteSpace: "nowrap",
    fontSize: '16px',
    fontWeight: 'normal',
    overflow: "hidden",
  })
}

const keyMap = {
  search: 'search',
  form: 'form',
  users: 'user',
  supervisors: 'supervisor',
  locations: 'location',
  chain_categories: 'chain_category',
  chains: 'chain',
  location_types: 'location_type',
  zones: 'zone',
  ca: 'form_field_classifier_a',
  cb: 'form_field_classifier_b',
  cc: 'form_field_classifier_c',
  cd: 'form_field_classifier_d',
  start_date: 'start_date',
  end_date: 'end_date',
  keys: 'key'
}


class ProfileImagesFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_modal_open: false,
      filters: {
        form: {},
        keys: [],
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    });

    this.handleSearch = this.handleSearch.bind(this)
    this.handleState = this.handleState.bind(this)
    this.selectForm = this.selectForm.bind(this)
    this.selectKeys = this.selectKeys.bind(this)
    this.parseSubmitData = this.parseSubmitData.bind(this)
  }

  handleSearch(filters) {
    this.closeSearchModal()
    this.props.handleAdvanceFilters(filters)
  }

  handleState(new_state) {
    this.setState(new_state)
  }

  parseSubmitData(filters) {
    let keys = Object.keys(this.state.filters)
    let new_filters = {}

    keys.forEach((key, index) => {
      let data = filters[key]
      if(key == 'form') {
        if(data.value) { new_filters[keyMap[key]] = data.value }
      } else if (key == 'search') {
        new_filters[keyMap[key]] = data
      } else if (key == 'start_date') {
        if(data) { new_filters[keyMap[key]] = data}
      } else if (key == 'end_date') {
        if(data) { new_filters[keyMap[key]] = data}
      } else {
        if(data.length > 0) {
          new_filters[keyMap[key]] = []
          data.forEach((datum) => {
            new_filters[keyMap[key]].push(datum.value)
          })
        }
      }
    })
    return new_filters
  }

  selectForm(data) {
    let filters = this.state.filters
    filters.form = data
    filters.ca = []
    filters.cb = []
    filters.cc = []
    filters.cd = []
    filters.keys = []

    this.setState({filters: filters, keys: []},() => {
      ImagesApi.getFormKeys(data.value, this.props.baseFilters).then((response) => {
        let keys = response.keys.map((record) => ({value: record.slug, label: record.full_name_count, form_id: record.form_id}))
        this.setState({keys: keys}, () => {
          this.props.handleAdvanceFilters(this.parseSubmitData(filters))
        })
      })
    })
  }

  selectKeys(data) {
    let filters = this.state.filters
    filters.keys = data
    this.setState({filters: filters},() => {
      this.props.handleAdvanceFilters(this.parseSubmitData(filters))
    })
  }

  componentWillMount() {
    ImagesApi.getSimpleDatasources(this.props.baseFilters).then((response) => {
      let forms = response.forms.map((record) => ({value: record.id, label: record.full_name_count}))
      this.setState({
        forms: forms,
      })
    })
  }

  render() {
    let form_id = this.state.filters.form.value
    let form_value = form_id ? this.state.filters.form : null
    let keys = this.state.keys

    return(
      <div>
        <section className='images-filters bg-white mb-2'>
          <div className="container-fluid p-0 pl-sm-3 pr-sm-3">
            <div className="row no-gutters">
              { this.state.forms && 
                <div className='mr-2 col-md-4' style={{width: '230px', display: 'inline-block'}}>
                  <Select
                    styles={selectStyles}
                    value={form_value}
                    placeholder={(<Translate id="advance_search.form.form.placeholder" />)}
                    onChange={this.selectForm}
                    options={this.state.forms}
                  />
                </div>
              }
              { this.state.keys && form_id && this.state.keys.length > 0 &&
                <div className='col-md-7'>
                  <Select
                    styles={selectStyles}
                    value={this.state.filters.keys}
                    placeholder={(<Translate id="advance_search.form.keys.placeholder" />)}
                    isMulti={true}
                    onChange={this.selectKeys}
                    options={keys}
                  />
                </div>
              }
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withLocalize(ProfileImagesFilters)
