import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Loading from '../../utils/Loading'
import ImagesApi from '../../utils/images_api'
import GalleriesShowNav from './GalleriesShowNav'
import ImagesIndex from '../Images/ImagesIndex'

import GallerySharesShowGalleryNav from '../GalleryShares/GallerySharesShowGalleryNav'

export default class GalleriesShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gallery: null,
      loading: true,
      images: [],
      total_pages: 0,
      filters: {}
    }

    this.getImages = this.getImages.bind(this)
    this.handlePagination = this.handlePagination.bind(this)
    this.handlePerPage = this.handlePerPage.bind(this)
    this.handleUpdatedGallery = this.handleUpdatedGallery.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }

  handleFilter(filters) {
    let new_filters = {...this.state.filters}
    new_filters.advance_filters = {...new_filters.advance_filters, ...filters}
    new_filters.page = null
    this.setState({filters: new_filters}, () => {
      this.getImages(new_filters)
    })
  }

  handleUpdatedGallery(updated_gallery) {
    let filters = {
      advance_filters: updated_gallery.filters,
      per_page: 25,
    }
    this.setState({gallery: updated_gallery}, () => {
      this.getImages(filters)
    })
  }

  getImages(filters) {
    ImagesApi.getImages(filters).then((response) => {
      let images = response.data.map(image => image.attributes)
      let total_pages = response.meta.total_pages
      this.setState({total_pages: total_pages, loading: false, images:images})
    })
  }

  handlePagination(data) {
    let page = (data.selected + 1)
    let filters = this.state.filters
    filters.page = page
    this.getImages(filters)
  }

  handlePerPage(event) {
    let per_page = event.target.value
    let filters = this.state.filters
    filters.per_page = per_page
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  componentDidMount() {
    let id = this.props.match.params.galleryId
    ImagesApi.getGallery(id).then((response) => {
      let gallery = response.data.attributes
      let filters = {
        advance_filters: gallery.filters,
        per_page: 25,
      }
      this.setState({
        gallery: gallery,
        filters: filters
      }, () => {
        this.getImages(filters)
      })
    })
  }

  render() {
    return(
      <div>
        { this.state.gallery && 
          <Fragment>
            <GalleriesShowNav
              gallery={this.state.gallery}
              handleUpdatedGallery={this.handleUpdatedGallery}
              language={this.props.language}
              auth={this.props.auth}
            />

            <GallerySharesShowGalleryNav
              gallery={this.state.gallery}
              language={this.props.language}
              handleFilter={this.handleFilter}
              comesAuth={true}
            />
          </Fragment>
        }

        { this.state.loading ? (
          <div></div>
        ) : (
          <ImagesIndex 
            image_placeholder_url={this.props.image_placeholder_url}
            loading_url={this.props.loading_url}
            images={this.state.images}
            per_page={this.state.filters.per_page}
            handlePerPage={this.handlePerPage}
            total_pages={this.state.total_pages}
            handlePagination={this.handlePagination}
          />
        )}
      </div>
    )
  }
}
