import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

import ReactHighcharts from 'react-highcharts';

export default class SubmissionComplianceHistory extends Component {
  render() {
    let points = []
    if(this.props.points) {
      points = this.props.points.split(',').map(Number)
    }

    let config = {
      chart: {
        renderTo: 'sparkline-container',
        margin: [5, 5, 2, 5],
        backgroundColor: 'transparent',
        width: 240,
        height: 40
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        startOnTick: false,
        endOnTick: false,
        labels: {
          enabled: false
        }
      },
      yAxis: {
        endOnTick: false,
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          enableMouseTracking: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          dataLabels: {
            enabled: true
          },
          marker: {
            radius: 2,
            states: {
              hover: {
                radius: 2
              }
            }
          }
        }
      },
      exporting: {
        enabled: false
      },
      series: [
        {
          type: 'line',
          data: points
        }
      ]
    }

    return(
      <Fragment>
        <ReactHighcharts ref='chart' config={config}/>
      </Fragment> 
    )
  }
}
