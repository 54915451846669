import axios from 'axios'

const csrf_token = $('meta[name=csrf-token]').attr('content')
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token

function getDatasources() {
  let url = `/web_api/analytics/datasources`
  return axios.get(url).then((response) => response.data)
}

function searchDatasource(data) {
  let url = `/web_api/analytics/search_datasource`
  return axios.get(url, {params: data}).then((response) => response.data)
}

function analize(data) {
  let url = `/web_api/analytics/analize`
  return axios.get(url, {params: {query: data}}).then((response) => response.data)
}

function createAnalyticReport(data) {
  let url = `/web_api/analytic_reports`
  return axios.post(url, data).then((response) => response.data)
}

function getAnalyticReports(type, folder_id) {
  let url = `/web_api/analytic_reports`
  return axios.get(url, {params: {analytic_type: type, folder_id: folder_id}}).then((response) => response.data)
}

function remove(id) {
  let url = `/web_api/analytic_reports/${id}`
  return axios.delete(url).then((response) => response.data)
}

function editAnalyticReport(data) {
  let url = `/web_api/analytic_reports/${data.analytic_report.id}`
  return axios.put(url, data).then((response) => response.data)
}

function updateQuery(id, data) {
  let url = `/web_api/analytic_reports/${id}/update_query`
  return axios.put(url, data).then((response) => response.data)
}

function copy(id, name) {
  let url = `/web_api/analytic_reports/${id}/copy`
  return axios.post(url, {name}).then((response) => response.data)
}

function getSubmissions(id, query, filters) {
  let url = `/web_api/analytic_reports/${id}/submissions`
  return axios.get(url, {params: {id, query, filters}}).then((response) => response.data)
}

module.exports = {
  getDatasources: getDatasources,
  searchDatasource: searchDatasource,
  analize: analize,
  createAnalyticReport: createAnalyticReport,
  getAnalyticReports: getAnalyticReports,
  remove: remove,
  copy: copy,
  editAnalyticReport: editAnalyticReport,
  updateQuery: updateQuery,
  getSubmissions
}
