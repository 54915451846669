import React, { Component, Fragment } from 'react';
import { render } from 'react-dom'
import CreateInsightButton from '../components/Insights/CreateInsightButton'
import CreateDataSetButton from '../components/DataSet/CreateDataSetButton'
import Insights from '../components/Insights/Insights'
import resourcesApi from '../utils/resources_api'
import Select from 'react-select'

export default class InsightsContainer extends Component {
  constructor(props) {
    super(props)
    let type = 'all'
    if(props.dashboard_groups && props.dashboard_groups.length > 0 ) {
      type = 'empty'
    }

    this.state = {
      insights: [],
      type: type,
      loading: true,
      full_select: {value: type, label: this.props.empty_dashboard_group_label || 'Dashboards'},
    }

    this.updateInsightList = this.updateInsightList.bind(this)
    this.updateInsight = this.updateInsight.bind(this)
    this.removeInsightFromList = this.removeInsightFromList.bind(this)
    this.handleDashboardGroupFilter = this.handleDashboardGroupFilter.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  
  handleChange(data) {
    this.handleDashboardGroupFilter(data.value, data)
  }

  handleDashboardGroupFilter(filter, data) {
    this.setState({type: filter, full_select: data, insights: [], loading: true}, () => {
      resourcesApi.getInsights(this.props.entity_type, this.props.entity_id, filter).
        then((insights) => {
          this.setState({insights: insights, loading: false})
        })
      })
  }

  removeInsightFromList(id) {
    let new_insights = this.state.insights.filter((el) => {
      return el.id != id
    }) 

    this.setState(()=> {
      return {
        insights: new_insights
      }
    })
  }

  updateInsight(new_data) {
    if(this.props.dashboard_groups && this.props.dashboard_groups.length > 0) {
      if(new_data.dashboard_group_id) {
        this.handleDashboardGroupFilter(new_data.dashboard_group_id)
      } else {
        this.handleDashboardGroupFilter('empty')
      }

    } else {
      let new_insights = this.state.insights.filter((el) => {
        return el.id != new_data.id
      }) 

      new_data.processing = true

      new_insights.push(new_data)

      this.setState(()=> {
        return {
          insights: new_insights
        }
      })
    }
  }

  updateInsightList(new_insight) {
    //For a create
    if(this.props.dashboard_groups && this.props.dashboard_groups.length > 0) {
      if(new_insight.dashboard_group_id) {
        this.handleDashboardGroupFilter(new_insight.dashboard_group_id)
      } else {
        this.handleDashboardGroupFilter('empty')
      }
    } else {
      this.setState(()=> {
        return {
          insights: this.state.insights.concat(new_insight)
        }
      })
    }
  }

  componentDidMount() {
    resourcesApi.getInsights(this.props.entity_type, this.props.entity_id, this.state.type).
      then((insights) => {
        this.setState(()=> {
          return {
            insights: insights,
            loading: false,
          }
        })
    })
  }

  header() {
    if (this.props.header)
      return this.props.header
    else
      return i18n.t('dashboard_container.dashboard_section_header')
  }

  group_iframe() {
    let db_group = this.props.dashboard_groups.find(element => ( element.value == this.state.type))

    if(db_group && db_group.embedded_page_url) {
      let url = db_group.quicksight_dashboard ? db_group.embedded_page_url.embed_url : db_group.embedded_page_url 

      return ( 
        <Fragment>
          <div className='px-4'>
            <iframe frameBorder='0' allowFullScreen src={url} width="100%" height="600px"></iframe>
          </div>
        </Fragment>
      )
    }
  }

  render() {
    let border_bottom = (!this.props.webview) ? 'border-bottom' : ''
    let minStyle = {}
    let options = [{value: 'empty', label: this.props.empty_dashboard_group_label || 'Dashboards'}]
    if(this.props.dashboard_groups) {
      minStyle = {minHeight: '200px'}
      this.props.dashboard_groups.forEach((dashboard_group) => {
        options.push(dashboard_group)
      })
    }

    return(
      <div>
        { !this.props.entity_id &&
          <section className='mt-2 sub-navbar bg-white'>
            <div className={"container-fluid " + border_bottom}>
              <div className="container p-0 pl-sm-3 pr-sm-3">
                <div className="row no-gutters">
                  { this.props.dashboard_groups && this.props.dashboard_groups.length > 0 ? (
                    <div className='col-md-12'>
                      { this.props.webview ? (
                        <Select
                          value={this.state.full_select}
                          onChange={this.handleChange}
                          isSearchable={false}
                          options={options}
                        />
                      ) : (
                        <nav className="w-100">
                          <div className="nav nav-tabs nav-tabs-campo border-0" id="nav-tab" role="tablist">
                            <a className={"nav-item nav-link py-3 mr-3 " + (this.state.type == 'empty' ? 'active' : '')} onClick={() => this.handleDashboardGroupFilter('empty') } href="#">
                              {this.props.empty_dashboard_group_label || 'Dashboards'}
                            </a>

                            { this.props.dashboard_groups.map((group) => {
                              return(
                                <a key={group.value} className={"nav-item nav-link py-3 mr-3 " + (this.state.type == group.value ? 'active' : '')} onClick={() => this.handleDashboardGroupFilter(group.value) } href="#">
                                  {group.label}
                                </a>
                              )
                            })}
                          </div>
                        </nav>
                      )}
                    </div>
                  ) : (
                    <div>
                      { !this.props.webview && 
                      <nav className="w-100">
                          <div className="nav nav-tabs nav-tabs-campo border-0" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link py-3 mr-3 active" href="/">{this.props.dashboards_label}</a>
                          </div>
                      </nav>
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        }

        <section className='images-filters bg-white'>
          <div className="container-fluid">
            <div className="container p-0 pl-sm-3 pr-sm-3">
              <div className="row no-gutters">
                <nav className="w-100">
                  <div className="nav nav__sub-header py-2 justify-content-end">
                    { !this.props.entity_type && this.props.show_dataset &&
                      <CreateDataSetButton />
                    }

                    { this.props.show_add &&
                      <CreateInsightButton
                        dashboard_groups={this.props.dashboard_groups}
                        entity_type={this.props.entity_type}
                        entity_id={this.props.entity_id}
                        empty_dashboard_group_label={this.props.empty_dashboard_group_label}
                        updateInsightList={this.updateInsightList}/>
                    }
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <div className="app pt-3">
          <div className="container py-3 campo__dashboard">
            <div className='row'>
              <div className="col-md-12">
                <div style={minStyle}>
                  { this.state.loading ? (
                    <div className='loading'>
                      <center>
                        <img src={this.props.loadingImageUrl} alt="Loading" />
                      </center>
                    </div>
                  ) : (
                    <div>
                      { this.group_iframe() }
                      { this.state.insights.length > 0 ? (
                       
                        <Insights
                          dashboard_groups={this.props.dashboard_groups}
                          updateInsight={this.updateInsight}
                          removeInsightFromList={this.removeInsightFromList}
                          empty_dashboard_group_label={this.props.empty_dashboard_group_label}
                          insights={this.state.insights}
                          webview={this.props.webview}
                          entity_id={this.props.entity_id}
                        />
                      ) : (
                        <div className='p-4'>
                          <center>No dashboards</center>
                        </div>
                      
                      )}
                    </div>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) 
  }
}
