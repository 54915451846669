import React, { Component } from 'react'
import { render } from 'react-dom'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "../Images/translations/global.json"

import GalleriesList from '../galleries/GalleriesList'

class GallerySharesShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      galleries: props.galleries,
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    })
  }

  render() {
    let {gallery_share} = this.props
    
    return(
      <div>
        <div className='app mt-2'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='p-3 bg-white'>
                  <h3 className='mb-0'>{gallery_share.name}</h3>
                  <small>{gallery_share.description}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <GalleriesList
          galleries={this.state.galleries}
          gallery_placeholder_url={this.props.gallery_placeholder_url}
          gallery_share_id={gallery_share.id}
          auth={this.props.auth}
        />
      </div>
    )
  }
}

export default withLocalize(GallerySharesShow)
