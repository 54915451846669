import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal'
import api from '../../utils/scheduled_submission_api'

import PopoverLoader from './PopoverLoader'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 1040
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
}


export default class ModalInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
    
    this.setScheduledSubmission = this.setScheduledSubmission.bind(this)
  }

  setScheduledSubmission(id) {
    this.setState({ss: null, loading: true}, () => {
      if(this.props.selected_id.substring(0,4) == 'subs') {
        api.getSubmission(id).then((response) => {
          this.setState({loading: false, ss: response.scheduled_submission})
        })
      } else {
        api.getScheduledSubmission(id).then((response) => {
          this.setState({loading: false, ss: response.scheduled_submission})
        })
      }
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selected_id != this.props.selected_id) {
      let id = this.props.selected_id.slice(4)
      this.setScheduledSubmission(id)
    }
  }

  componentDidMount() {
    let id = this.props.selected_id.slice(4)
    this.setScheduledSubmission(id)
  }

  render() {
    let ss = this.state.ss
    let report_time = ""
    if(ss) {
      if(ss.checkin_at) {
        report_time += ss.checkin_at
      }

      if(ss.checkin_at && ss.checkout_at) {
        report_time += " - "
      }

      if(ss.checkout_at) {
        report_time += ss.checkout_at
      }
    }

    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={this.props.closeSearchModal}
        isOpen={this.props.isOpen}>

        <div style={{width: '483px'}} className="">
          <div className='row mb-2 justify-content-end'>
            <a className='popover-campo__close' onClick={this.props.closeModal}>
              <i className='fa fa-times'></i>
            </a>
          </div>

          { this.state.loading ? (
            <div className='row mb-4'>
              <div className='col-12'>
                <PopoverLoader />
              </div>
            </div>
          ) : (
            <Fragment>
              <div className='row mb-1'>
                <div className='col-8'>
                  <h4>
                    {ss.location_name}
                  </h4>
                  <h5 className='text-primary'>{ss.user_name}</h5>
                  <h4 className='popover-campo__place'>
                    {ss.location_description}
                  </h4>
                 
                </div>
                { ss.compliance &&
                  <div className='col-4 text-center'>
                    <span style={{background: ss.color}} className='popover-campo__status-icon'>
                    </span>
                    <h6 className='popover-campo__status-text'>{ss.compliance}%</h6>
                  </div>
                }
              </div>
              <div className='row'>
                <div className='col-12'>
                    <h6 className='popover-campo__hour'>
                      {ss.form_name} 
                    </h6>
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-12 text-secondary'>
                  { ss.submission_id ? (
                    <h5 className='popover-campo__hour'>
                      {ss.checkin_at} - {ss.checkout_at}
                    </h5>
                  ) : (
                    <h5 className='text-info'>
                      <i className='fa fa-calendar'></i> Scheduled
                    </h5>
                  )}
                </div>
              </div>
              { ss.has_exceptions &&
                <div className='row mb-4'>
                  <div className='col-1 pr-0 pt-1'>
                    <span className='popover-campo__status-icon-small'>
                    </span>
                  </div>
                  <div className='col-11 pl-0'>
                    <h5 className='popover-campo__info__title font-weight-bold'>
                      {i18n.t('explore.popover.exceptions_title')}
                    </h5>

                    { ss.exception_messages.map((message) => {
                      return(
                        <h6 className='popover-campo__info__text'>
                        {message}
                        </h6>
                      )
                    })}
                  </div>
                </div>
              }

              { ss.out_of_area_alert &&
                <div className='row mb-4'>
                  <div className='col-1 pr-0 pt-1'>
                    <span className='popover-campo__status-icon-small'>
                    </span>
                  </div>
                  <div className='col-11 pl-0'>
                    <h5 className='popover-campo__info__title font-weight-bold'>
                      {i18n.t('explore.popover.far_title')}
                    </h5>
                    <h6 className='popover-campo__info__text'>
                      {i18n.t('explore.popover.far_description')}
                    </h6>
                  </div>
                </div>
              }

              { ss.submission_id &&
                <div className='row'>
                  <div className='col-12 col-sm-4 mb-2 mb-sm-0'>
                    <a href={"/submissions/" + ss.submission_id + "/webview"} className='popover-campo__button' target='_blank'>
                      {i18n.t('explore.popover.see_report')}
                    </a>
                  </div>
                  { ss.out_of_area_alert &&
                  <div className='col-12 col-sm-4 mb-2 mb-sm-0'>
                    <a href={"/submissions/" + ss.submission_id + "/comparative_map/webview"} className='popover-campo__button' target='_blank'>
                      {i18n.t('explore.popover.see_map')}
                    </a>
                  </div>
                  }
                </div>
              }
            </Fragment>
          )}

        </div>
      </Modal>
    )
  }
}
