import React from 'react'

class OptionsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: this.getCurrentOption(props)
    }

    this.handleClick = this.handleClick.bind(this)
  }

  getCurrentOption(props) {
    for (var i = 0; i < props.elements.length; i++) {
      if (props.elements[i].id == props.active) {
        return props.elements[i];
      }
    }
  }

  handleClick(a, event) {
    this.setState({active: this.props.elements[a]})
    this.props.clickHandler(this.props.elements[a], a, event)
  }

  render() {
    let input = (
      <input value={this.state.active.id} type='hidden' name={this.props.inputName}/>
    )

    var options = []

    for (var i = 0; i < this.props.elements.length; i++) {
      let className
      if (this.props.elements[i].id == this.state.active.id) {
        className = 'btn btn-outline-dark mx-1'
      }else{
        className = 'btn btn-light text-secondary mx-1'
      }

      let option = (
        <a key={i} className={className} href="#" onClick={this.handleClick.bind(this, i)}>
          {this.props.elements[i].name}
        </a>
      )

      options.push( option );
    }

    return (
      <div className="options btn-group btn-group-sm mb-2">{options} {input}</div>
    )
  }
}

class CascadingOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentOption: props.options[props.selectedOption]
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(option, index, event) {
    this.setState({currentOption: option})
   
  }

  render() {
    return(
      <div className='cascading-options'>
        <OptionsList
          active={ this.props.selectedOption }
          inputName={ this.props.optionsInputName }
          elements={ this.props.options }
          clickHandler={ this.handleClick } />

        <OptionsList
          active={ this.props.selectedNestedOption }
          inputName={ this.props.nestedOptionsInputName }
          elements={ this.props.nestedOptions[this.state.currentOption.id] }
          clickHandler={ (option, index, event) => { event.preventDefault() } } />
      </div>
    )
  }
}

export default CascadingOptions


