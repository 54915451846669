import React from 'react';
import uuid from 'node-uuid';

import UserAvatar from 'react-user-avatar';

export default class Results extends React.Component {
  constructor(props) {
    super(props);

    this.renderAllResults = this.renderAllResults.bind(this);
  }

  renderAllResults() {
    if (this.props.results.results_url) {
      return (
        <div id={uuid.v4()} className="search-results--all">
          <a href={this.props.results.results_url}>
            <div>
              View all results "{this.props.searchTerm}"
            </div>
          </a>
        </div>
      );
    }
  }

  renderGroupResult(result, index) {
    let borderRadius = '20%'
      
    return (
      <div key={index}>
        <div id="result-group" className='result-group'>{result.label}</div>
        {result.results.map((value, index) => {
          return (
            <div key={index} className="result">
            <a href={value.url} data-label={value.label}>
              <div className="d-flex align-items-start pt-2 pl-1">
                <div className="pt-1 pr-3">
                  <UserAvatar size="16" borderRadius={borderRadius} name={value.label} src={value.icon} color='#77D0E5' />
                </div>
                <div className="">
                  {value.label}
                  <div className="result--information--description">
                    {value.description}
                  </div>
                </div>
              </div>
            </a>
          </div>
          )
        })}
      </div>
    );
  }

  renderSingleResult(result, index) {
    return (
      <div key={index} className="result">
        <a href={result.url} data-label={result.label}>
          <div className="result__img--wrapper">
            <UserAvatar size="16" name={result.label} src={result.icon} />
          </div>
          <div className="result--information">
            <p className="result--information__paragraph">
              {result.label}
              <br/>
              <span className="result--information--description">
                {result.description}
              </span>
            </p>
          </div>
        </a>
      </div>
    );
  }

  render() {
    if (this.props.display) {
      if (this.props.results.length > 0) {
        return (
          <div className="search-results--wrapper">
            <div className="search-results" onMouseEnter={this.props.mouseEnter}>
              {this.props.results.map((result, index) => {
                if (result.type === 'result') {
                  return (this.renderSingleResult(result, index));
                }
              })}
              {this.props.results.map((result, index) => {
                if (result.type === 'group') {
                  return (this.renderGroupResult(result, index));
                }
              })}
              {this.renderAllResults()}
            </div>
          </div>
        );
      }

      return (
        <div className="search-results--wrapper">
          <div className="search-results search-no-results">
            <strong>No results found!</strong>
          </div>
        </div>
      );
    }

    return (<div></div>);
  }
}
