var app = window.app || {};

app.yearChanged = function(year){
  if(!year || !year.length || year.length > 4 || year.length < 2){
    return;
  }

  function firstMondayDate(y){
    var d = new Date(y, 0, 1)
    while (d.getDay() !== 1) {
          d.setDate(d.getDate() + 1);
    }
    return d;
  }

  var day = firstMondayDate(year).getUTCDate() + '';
  var month = '01';
  day = day.length > 1 ? '0' + day : day;
  var format = $('#organization-commercial-year-datepicker').data('date-format');
  var startDate =  format.replace('dd', day).replace('mm', month).replace('yyyy', year);

  $('#organization-commercial-year-datepicker').datepicker('update', startDate);
  $('#organization-commercial-year-datepicker').val(startDate);
};

$(function(){
  var nowTemp = new Date();
  var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
  
  $('#organization-commercial-year-datepicker').datepicker().on('changeDate', function(ev){
    $(ev.target).datepicker('hide');

    var year = ev.date.getFullYear();
    var month = ev.date.getUTCMonth() + 1;
    var day = ev.date.getUTCDate();

    $('.selected-year select').val(year);
  });

  var year = $('.selected-year').val();
  app.yearChanged(year);
});
