import { useQuery, useMutation } from 'react-query'
import axios from 'axios'

const fetchDatasources = () => {
  return axios.get('/web_api/task_lists/datasources').then(res => {
    return res.data
  })
}

const createTaskList = (data) => {
  return axios.post('/web_api/task_lists', {data}).then(res => {
    return res.data
  })
}

const updateTaskList = (data) => {
  return axios.put(`/web_api/task_lists/${data.id}`, {data}).then(res => {
    return res.data
  })
}

const createTask = () => {
  return axios.post('/web_api/task_lists/create_task').then(res => {
    return res.data
  })
}

const removeTask = (data) => {
  return axios.delete('/web_api/task_lists/remove_task', {data : {task_id: data.id }}).then(res => {
    return res.data
  })
}

const updateTask = (data) => {
  return axios.put('/web_api/task_lists/update_task', {data}).then(res => {
    return res.data
  })
}

const getTaskLists = (data) => {
  return axios.get('/web_api/task_lists', {params: {filters: data.queryKey[1]}}).then(res => {
    return res.data
  })
}

const generateQRPdf = (data) => {
  return axios.post(`/web_api/task_lists/${data.id}/generate_qr_pdf`).then(res => {
    return res.data
  })
}

export const useDatasourcesHooks = () => {
  return useQuery(
    'tl-datasources',
     fetchDatasources
  )
}

export const useTaskListsHook = (filters) => {
  return useQuery(['task-lists', filters], getTaskLists)
}

export const useCreateTaskListHook = () => {
  return useMutation(createTaskList)
}

export const useUpdateTaskListHook = () => {
  return useMutation(updateTaskList)
}

export const useCreateTaskHook = () => {
  return useMutation(createTask)
}

export const useRemoveTaskHook = () => {
  return useMutation(removeTask)
}

export const useUpdateTaskHook = () => {
  return useMutation(updateTask)
}

export const useGenerateQRPdfHook = () => {
  return useMutation(generateQRPdf)
}
