import React, { Component, Fragment, useEffect, useState } from 'react'
import { render } from 'react-dom'

import TicketModal from './TicketModal'
import TicketsHeader from './TicketsHeader'
import TicketsTable from './TicketsTable'
import ticket_hooks from './ticket_hooks'
import ReactPaginate from 'react-paginate'
import ShowMore from '../../utils/ShowMore'

const TicketsIndex = (props) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get("id");
  const [filters, setFilters] = useState({per_page: 5})
  const [selectedTicket, setSelectedTicket] = useState({})
  const [sort, setSort] = useState(undefined)
  const [ loading, tickets, total_pages ] = ticket_hooks.getTickets(filters, sort)
  const [individualTicket, setIndividualTicket] = useState(false)

  useEffect(() => {
    if(!individualTicket && !loading && id) {
      let ticket = tickets.find(t => t.id == id)
      if(ticket) {
        setSelectedTicket(ticket)
      }
      setIndividualTicket(true)
    }
  }, [loading])

  const handleClose = () => {
    setSelectedTicket({})
    setFilters({...filters, changed: !filters.changed})
  }

  const handlePerPage = (e) => {
    setFilters({...filters, per_page: e.target.value})
  }

  const handlePagination = (e) => {
    let page = (e.selected + 1)
    setFilters({...filters, page: page})
  }

  return(
    <div className="app pb-5">
      <div className="container py-3">
        <div className='row'>
          <div className='col-12 p-4 bg-white'>
            <TicketsHeader
              sort={sort}
              filters={filters}
              datasources={props.datasources}
              setSort={setSort}
              setFilters={setFilters}
              loading={loading}
              setSelectedTicket={setSelectedTicket}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12 p-4 bg-white'>
            <TicketsTable 
              tickets={tickets}
              setSelectedTicket={setSelectedTicket}
            /> 
          </div>
        </div>

        { tickets.length > 0 &&
          <div className="row p-4 justify-content-between bg-white">
            <div className="col-auto">
              <ShowMore
                selected={filters.per_page}
                options={[10,25,50,100]}
                onSelect={handlePerPage}
              />                                                          
            </div>                                                        
                                                                          
            <div className="col-auto">                                    
              <ReactPaginate previousLabel={"‹"}                          
                nextLabel={"›"}                                           
                breakLabel={<a href="">...</a>}                           
                breakClassName={"break-me"}                               
                pageCount={total_pages}                        
                marginPagesDisplayed={2}                                  
                pageRangeDisplayed={5}                                    
                onPageChange={handlePagination}                
                containerClassName={"pagination pagination-sm pagination-campo m-0"}
                pageClassName={"page-item"}                               
                pageLinkClassName={"page-link cursor-pointer"}            
                activeClassName={"active"} />                             
            </div>
          </div>
        }

        { (selectedTicket.id || selectedTicket.id == 0) && 
          <TicketModal 
            datasources={props.datasources}
            ticket={selectedTicket}
            setTicket={setSelectedTicket}
            onClose={handleClose}
          />
        }
        
      </div>
    </div>
  )
}

export default TicketsIndex
