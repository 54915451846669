import './campodata_js_files/new_application/profile'
import './campodata_js_files/attendances'
import './campodata_js_files/dashboard'
import './campodata_js_files/submissions'
import './campodata_js_files/posts'
//import './campodata_js_files/filters'

//CustomJs
import './campodata_js_files/organization_commercial_year/date_picker'
import './campodata_js_files/route_reports/form'
import './campodata_js_files/pusher'
import './campodata_js_files/trix_form'
import './campodata_js_files/mobile_scheduled_submissions'

import './components/support/custom_styles'
import './components/support/helpers'
import './components/support/my_i18n'
import './components/support/support'

import './campodata_js_files/calendar/calendar'
import './campodata_js_files/calendar/calendar_goals'
import './campodata_js_files/calendar/copy_week'
import './campodata_js_files/calendar/notifications'


//import './campodata_js_files/tasks/form_inventory_entries'
//import './campodata_js_files/tasks/sub_task_images'
//import './campodata_js_files/tasks/sub_tasks'
//import './campodata_js_files/tasks/task_change_order_files'
//import './campodata_js_files/tasks/task_comments'
//import './campodata_js_files/tasks/task_files'
//import './campodata_js_files/tasks/task_reports'
//import './campodata_js_files/tasks/tasks'
//import './campodata_js_files/tasks/tasks_extra'
//import './campodata_js_files/tasks/tco_comments'
//import './campodata_js_files/tasks/users'

console.log("FILES TO FIX all tasks/all coffee files")

//Necesary on commonjs
require('datatables.net')()


// Code nrecesary that was on sentir app.js 
$(document).ready(function(){
  if ($('#datatable-example').length > 0){
    $('#datatable-example').DataTable( {
      bPaginate: false,
      bStateSave: true
    } );
  }

  $(function () {
		"use strict";
		var configChosen = {
		  '.chosen-select'           : {},
		  '.chosen-select-deselect'  : {allow_single_deselect:true, width:"100%"},
		  '.chosen-select-no-single' : {disable_search_threshold:10},
		  '.chosen-select-no-results': {no_results_text:'Oops, nothing found!'},
		  '.chosen-select-width'     : {width:"100%"}
		}
		for (var selector in configChosen) {
		  $(selector).chosen(configChosen[selector]);
		}
	});

  $('.collapse').on('show.bs.collapse', function() {
    var id = $(this).attr('id');
    $('a.block-collapse[href="#' + id + '"] span.right-icon').html('<i class="fa fa-minus"></i>');
  });
  $('.collapse').on('hide.bs.collapse', function() {
    var id = $(this).attr('id');
    $('a.block-collapse[href="#' + id + '"] span.right-icon').html('<i class="fa fa-plus"></i>');
  });

})
