import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function update(task_id, tco_id, data) {
  let url = `/tasks/${task_id}/task_change_orders/${tco_id}`
  return axios.put(url, {data: data})
}

function getSchema(params) {
  let url = `/web_api/projects_management/schema_editor`

  return axios.get(url, {params: params}).then((response) => response.data)
}

function setAttribute(params) {
  let url = `/web_api/projects_management/schema_editor`

  return axios.post(url, params).then((response) => response.data)
}

module.exports = {
  getSchema: getSchema,
  setAttribute: setAttribute,
}
