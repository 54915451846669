$(function() {
  var forms, show_capture_location, val;
  $('#submissions_form_select').change(function(event) {
    var base_url, value;
    $('#submissions_transition_wrapper').addClass('d-none');
    $('#submissions_transition_wrapper_loading').removeClass('d-none');
    value = $(this).val();
    base_url = '/forms/' + value + '/reports/submissions?' + $('form#submissions_form').serialize();
    return window.location = base_url;
  });
  $('#form_route_reports_select').change(function(event) {
    var base_url, value;
    value = $(this).val();
    base_url = '/forms/' + value + '/route_reports/new';
    return window.location = base_url;
  });
  $('#submissions_form #submission_search_form_data_set_id').change(function(event) {
    var value;
    value = $(this).val();
    if (value) {
      $('#submission_search_user_id').prop('disabled', true);
      $('#submission_search_chain_category_id').prop('disabled', true);
      $('#submission_search_chain_id').prop('disabled', true);
      $('#submission_search_user_id').val('');
      $('#submission_search_chain_category_id').val('');
      return $('#submission_search_chain_id').val('');
    } else {
      $('#submission_search_user_id').prop('disabled', false);
      $('#submission_search_chain_category_id').prop('disabled', false);
      return $('#submission_search_chain_id').prop('disabled', false);
    }
  });
  $(".report-date-filter").on('click', function() {
    var end_date, start_date;
    start_date = $(this).attr('start');
    end_date = $(this).attr('end');
    $('#submission_search_start_date').val(start_date);
    $('#submission_search_end_date').val(end_date);
    return false;
  });
  $(".latest-for-locations-filter").on('click', function() {
    $('#submission_search_start_date').val('');
    $('#submission_search_end_date').val('');
    return false;
  });
  $("#submission_search_form_id").on('change', function() {
    var forms, show_capture_location, val;
    forms = $('.submissions_webview').data("forms");
    val = this.value;
    if (val) {
      $(".submissions_webview #new_submission_search").attr("action", "/forms/" + val + "/reports/submissions/webview?new_webview=true");
      $('.report-file-button').removeClass('hidden');
      show_capture_location = forms[val].capture_location;
      if (show_capture_location) {
        return $('.report-filter').removeClass('hidden');
      } else {
        $('.report-filter').addClass('hidden');
        return $('#submission_search_report_filter').val('');
      }
    } else {
      $('.report-file-button').addClass('hidden');
      $('.report-filter').addClass('hidden');
      return $('#submission_search_report_filter').val('');
    }
  });
  val = $("#submission_search_form_id").val();
  forms = $('.submissions_webview').data("forms");
  if (val) {
    $(".submissions_webview #new_submission_search").attr("action", "/forms/" + val + "/reports/submissions/webview?new_webview=true");
    $('.report-file-button').removeClass('hidden');
    show_capture_location = forms[val].capture_location;
    if (show_capture_location) {
      return $('.report-filter').removeClass('hidden');
    } else {
      $('.report-filter').addClass('hidden');
      return $('#submission_search_report_filter').val('');
    }
  } else {
    $('.report-file-button').addClass('hidden');
    $('.report-filter').addClass('hidden');
    return $('#submission_search_report_filter').val('');
  }
});
