import React, { Component } from 'react'
import { render } from 'react-dom'

import ImagesApi from '../../utils/images_api'
import FilterModal from '../Images/FilterModal'
import {Translate } from "react-localize-redux"

import { toast } from 'react-toastify'

const base_filters = {
  search: '',
  locations: [],
  chains: [],
  chain_categories: [],
  location_types: [],
  zones: [],
  users: [],
  supervisors: [],
  ca: [],
  cb: [],
  cc: [],
  cd: [],
  form: {},
  start_date: null,
  end_date: null,
  keys: [],
}

const base_disabled = {
  locations: false,
  chain_categories: false,
  chains: false,
  users: false,
  supervisors: false,
  zones: false,
  location_types: false,
}

export default class GalleriesIndexNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_modal_open: false,
      name: '',
      public_show_only_favorites: false,
      public_hide_flagged: false,
      filters: Object.assign({}, base_filters),
      disabled: Object.assign({}, base_disabled)
    }

    this.openSearchModal = this.openSearchModal.bind(this)
    this.closeSearchModal = this.closeSearchModal.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleState = this.handleState.bind(this)
    this.handleName = this.handleName.bind(this)
    this.handlePublicFavorites = this.handlePublicFavorites.bind(this)
    this.handlePublicFlagged = this.handlePublicFlagged.bind(this)
  }

  handleName(event) {
    this.setState({name: event.target.value})
  }

  handlePublicFavorites(event) {
    this.setState({public_show_only_favorites: event.target.checked})
  }

  handlePublicFlagged(event) {
    this.setState({public_hide_flagged: event.target.checked})
  }

  handleSubmit(filters) {
    ImagesApi.createGallery({
      name: this.state.name,
      public_show_only_favorites: this.state.public_show_only_favorites,
      public_hide_flagged: this.state.public_hide_flagged,
      filters: filters
    }).then((response) => {
      this.setState({name: '', disabled: Object.assign({}, base_disabled), filters: Object.assign({}, base_filters)}, () => {
        this.props.handleCreatedGallery()
        this.closeSearchModal()
      })
    })
  }

  handleState(new_state) {
    this.setState(new_state)
  }

  openSearchModal() {
    this.setState({search_modal_open: true})
  }

  closeSearchModal() {
    this.setState({search_modal_open: false})
  }

  render() {
    return(
      <div>
        <section className='images-filters bg-white'>
          <div className="container-fluid border-top">
            <div className="container-fluid p-0 pl-sm-3 pr-sm-3">
              <div className="row no-gutters">
                <nav className="w-100">
                  <div className="nav nav__sub-header py-2 justify-content-end">
                    <a onClick={this.openSearchModal} className="nav-item nav-link py-1">
                      <Translate id="galleries.index.nav.new" />
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <FilterModal 
          comesFromGallery={true}
          name={this.state.name}
          public_show_only_favorites={this.state.public_show_only_favorites}
          public_hide_flagged={this.state.public_hide_flagged}
          closeSearchModal={this.closeSearchModal}
          isOpen={this.state.search_modal_open}
          filters={this.state.filters}
          disabled={this.state.disabled}
          handleName={this.handleName}
          handlePublicFavorites={this.handlePublicFavorites}
          handlePublicFlagged={this.handlePublicFlagged}
          handleSubmit={this.handleSubmit}
          handleState={this.handleState}
          language={this.props.language}
        />
      </div>
    )
  }
}


