import axios from 'axios'

Trix.config.attachments.preview.caption = {
  name: false,
  size: false,
}

function uploadFile(attachment) {
  var file = attachment.file
  var server_data = {
    filename: file.name,
    type: file.type,
    uuid: document.getElementById('post_uuid').value
  }
  axios.get('/posts/get_signed_url.json', {params: {file: server_data}}).then(function(response) {
    let data = response.data
    let options = {
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        attachment.setUploadProgress(percentCompleted)
      }
    }

    axios.put(data.upload_url, file, options).then((response) => {
      attachment.setAttributes({
        url: data.view_url,
        href: data.view_url,
      })
    })
  })
}

$(document).ready(function() {
  document.addEventListener('trix-attachment-add', function(event) {
    var attachment = event.attachment
    if(attachment.file) {
      uploadFile(attachment)
    }
  })

});
