import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment from 'moment'
import Cookies from 'universal-cookie';

import Select from 'react-select'

import PredefinedPicker from './PredefinedPicker'
import { toast } from 'react-toastify';

const cookies = new Cookies();
const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}


export default class SubmissionExplorerFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {
      },
      show_download: false,
    }

    this.handlePredefined = this.handlePredefined.bind(this)
    this.selectDateRange = this.selectDateRange.bind(this)
    this.selectReportType = this.selectReportType.bind(this)
    this.selectForm = this.selectForm.bind(this)
    this.selectUser = this.selectUser.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showSubmit = this.showSubmit.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
  }

  showSubmit() {
    let ss = this.state.filters
    return(ss.form && ss.report_type && ss.start_date && ss.end_date)
  }

  handlePredefined(data) {
    let filters = this.state.filters
    filters.start_date = data.start_date
    filters.end_date = data.end_date
    this.setState({filters: filters, show_download: false})
  }

  selectDateRange(data) {
    let filters = this.state.filters
    let start_date = data.startDate
    let end_date = data.endDate
    if(start_date && end_date) {
      let days = moment.duration(end_date.diff(start_date)).asDays()
      if( days > 92) {
        toast.info(i18n.t('explore.range_filter_warn'), {
          position: toast.POSITION.TOP_RIGHT
        })
        end_date = start_date.clone().add(92, 'days')
      }
    }

    filters.start_date = start_date
    filters.end_date = end_date
    this.setState({filters: filters, show_download: false})
  }

  selectReportType(data) {
    let filters = this.state.filters
    filters.report_type = data
    this.setState({filters: filters, show_download: false})
  }

  selectForm(data) {
    let filters = this.state.filters
    filters.form = data
    if(data.has_route) {
      filters.report_type = null
    } else {
      filters.report_type = this.props.datasources.report_types[0]
    }
    this.setState({filters: filters, show_download: false})
  }

  selectUser(data) {
    let filters = this.state.filters
    filters.user = data
    this.setState({filters: filters, show_download: false})
  }

  handleSubmit() {
    if(this.props.loading) {
      return
    } else {
      cookies.set('se_form', this.state.filters.form.value, { path: '/' });
      cookies.set('se_report_type', this.state.filters.report_type.value, { path: '/' });
      cookies.set('se_start_date', this.state.filters.start_date.toISOString(), { path: '/' });
      cookies.set('se_end_date', this.state.filters.end_date.toISOString(), { path: '/' });
      this.setState({show_download: true}, () => {
        this.props.onFilter(this.state.filters)
      })
    }
  }

  handleDownload() {
    if(this.props.loading) {
      return
    } else {
      cookies.set('se_form', this.state.filters.form.value, { path: '/' });
      cookies.set('se_report_type', this.state.filters.report_type.value, { path: '/' });
      cookies.set('se_start_date', this.state.filters.start_date.toISOString(), { path: '/' });
      cookies.set('se_end_date', this.state.filters.end_date.toISOString(), { path: '/' });
      this.props.onDownload(this.state.filters)
    }
  }

  componentWillMount() {
    let se_form = cookies.get('se_form')
    let se_report_type = cookies.get('se_report_type')
    let default_hour = {hour:12,minute:0,second:0,millisecond:0}

    if(se_form) {
      let form = this.props.datasources.forms.find((form) => {
        return form.value == se_form
      })

      let report_type = this.props.datasources.report_types.find((report_type) => {
        return report_type.value == se_report_type
      })

      let filters = {
        form: form,
        report_type: report_type,
        start_date: moment().startOf('month').set(default_hour),
        end_date: moment().endOf('month').set(default_hour),
      }

      if(cookies.get('se_start_date')) {
        filters.start_date = moment(cookies.get('se_start_date'))
      }

      if(cookies.get('se_end_date')) {
        filters.end_date = moment(cookies.get('se_end_date'))
      }

      this.setState({filters: filters}, () => {
        this.handleSubmit()
      })
    }
  }

  render() {
    let report_types = [this.props.datasources.report_types[0]]
    let form = this.state.filters.form
    if(form && form.has_route) {
      report_types = this.props.datasources.report_types
    }

    return(
      <div className='campo__filters mb-4'>
        <div className='campo__filters-title'>
          <h4>
            {i18n.t('explore.title')}
          </h4>
        </div>
        <div className='campo__filters-inputs'>
          <div className='form-row'>
            <div className="col-lg-4 col-md-4 form-group form-group--campo">
              <label className="text-uppercase m-0">
                {i18n.t('explore.filters.form')}
              </label>

              <Select
                styles={selectStyles}
                value={this.state.filters.form}
                placeholder={i18n.t('explore.filters.form_placeholder')}
                onChange={this.selectForm}
                options={this.props.datasources.forms}
              />
            </div>

            <div className="col-lg-4 col-md-4 form-group form-group--campo">
              <label className="text-uppercase m-0">
                {i18n.t('explore.filters.report_type')}
              </label>

              <Select
                styles={selectStyles}
                value={this.state.filters.report_type}
                placeholder={i18n.t('explore.filters.report_type_placeholder')}
                onChange={this.selectReportType}
                options={report_types}
              />
            </div>
            <div className="col-lg-4 col-md-4 form-group form-group--campo">
              <label className="text-uppercase m-0">
                {i18n.t('explore.filters.user')}
              </label>

              <Select
                styles={selectStyles}
                value={this.state.filters.user}
                placeholder={i18n.t('explore.filters.user_placeholder')}
                onChange={this.selectUser}
                options={this.props.datasources.users}
                isClearable={true}
              />
            </div>
 
          </div>
          <div className='form-row'>
            <div className="form-group col-lg-6 col-md-6">
              <label className="form-group--campo_label text-uppercase m-0">
                {i18n.t('explore.filters.date_range')}
              </label>
              
              <div>
                <DateRangePicker
                  orientation="horizontal"
                  verticalHeight={568}
                  hideKeyboardShortcutsPanel
                  startDate={this.state.filters.start_date}
                  startDateId="start_date_id"
                  startDatePlaceholderText={i18n.t('explore.filters.date_range_start')}
                  endDate={this.state.filters.end_date}
                  endDateId="end_date_id"
                  endDatePlaceholderText={i18n.t('explore.filters.date_range_end')}
                  onDatesChange={this.selectDateRange}
                  focusedInput={this.state.focusedInput}
                  isOutsideRange={day => (false)}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                />
                <PredefinedPicker handlePredefined={this.handlePredefined}/>
              </div>
            </div>

            { this.showSubmit() &&
              <div className="form-group col-lg-6 col-md-6">
                <button 
                  onClick={this.handleSubmit}
                  className="mt-4 pull-right text-white text-uppercase form-campo__button border-0 py-2 px-3 ml-2 rounded">
                  {i18n.t('explore.filters.button')}
                </button>
                { this.state.show_download && !this.props.loading &&
                <button 
                  onClick={this.handleDownload}
                  className="btn btn-link pull-right se_download text-muted">
                  <i className='fa fa-download'></i> {i18n.t('explore.filters.download')}
                </button>
                }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
