import axios from 'axios'

function search(q) {
  let url = `/search`
  return axios.get(url, {params: {q: q}}).then((response) => response.data)
}

module.exports = {
  search: search
}
 
