import React, { Component } from 'react';
import Insight from './Insight'

class Insights extends Component {
  constructor(props) {
    super(props)

    this.removeInsightFromList = this.props.removeInsightFromList
    this.updateInsight = this.props.updateInsight
  }

  render() {
    let content = this.props.insights.map((insight) =>
      (<Insight
        entity_id={this.props.entity_id}
        webview={this.props.webview}
        dashboard_groups={this.props.dashboard_groups}
        data={insight}
        key={insight.id + insight.report_updated_at}
        updateInsight={this.updateInsight}
        empty_dashboard_group_label={this.props.empty_dashboard_group_label}
        removeInsightFromList={this.removeInsightFromList} />)
    )

    return(
      <div>
        <div className='columns'>
          {content}
        </div>
      </div>
    )
  }
}

export default Insights
