import React, { Component } from 'react'
import { render } from 'react-dom'

import Loading from '../../utils/Loading'
import ShowMore from '../../utils/ShowMore'
import GalleriesList from './GalleriesList'
import GalleriesIndexNav from './GalleriesIndexNav'

import ImagesApi from '../../utils/images_api'
import ReactPaginate from 'react-paginate'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "../Images/translations/global.json"

class GalleriesIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      galleries: [],
      total_pages: 0,
      filters: {
        per_page: 25
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    })

    this.handleCreatedGallery = this.handleCreatedGallery.bind(this)
    this.handlePagination = this.handlePagination.bind(this)
    this.handlePerPage = this.handlePerPage.bind(this)
    this.getGalleries = this.getGalleries.bind(this)
    this.handleCreatedGallery = this.handleCreatedGallery.bind(this)
  }

  handleCreatedGallery() {
    this.setState({loading: true})
    this.getGalleries({per_page: this.state.filters.per_page})
  }

  getGalleries(filters) {
    ImagesApi.getGalleries(filters).then((response) => {
      let galleries = response.data.map(gallery => gallery.attributes)
      let total_pages = response.meta.total_pages
      this.setState({total_pages: total_pages, loading: false, galleries: galleries})
    })
  }

  handlePagination(data) {
    let page = (data.selected + 1)
    let filters = this.state.filters
    filters.page = page
    this.getImages(filters)
  }

  handlePerPage(event) {
    let per_page = event.target.value
    let filters = this.state.filters
    filters.per_page = per_page
    this.setState({filters: filters, loading: true} , ()=> {
      this.getGalleries(filters)
    })
  }

  componentWillMount() {
    this.getGalleries(this.state.filters)
  }

  render() {
    return (
      <div>
        {this.props.auth.create && (
          <GalleriesIndexNav
            language={this.props.language}
            handleCreatedGallery={this.handleCreatedGallery}
          />
        )}

        {this.state.loading ? (
          <div></div>
        ) : (
          <div>
            <GalleriesList
              galleries={this.state.galleries}
              gallery_placeholder_url={this.props.gallery_placeholder_url}
            />

            {this.state.galleries.length > 0 && (
              <footer className="fixed-bottom py-3 footer-campo">
                <div className="container-fluid">
                  <div className="container">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <ShowMore
                          selected={this.state.filters.per_page}
                          options={[10, 25, 50, 100]}
                          onSelect={this.handlePerPage}
                        />
                      </div>

                      <div className="col-auto">
                        <ReactPaginate
                          previousLabel={"‹"}
                          nextLabel={"›"}
                          breakLabel={<a href="">...</a>}
                          breakClassName={"break-me"}
                          pageCount={this.state.total_pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePagination}
                          containerClassName={
                            "pagination pagination-sm pagination-campo m-0"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link cursor-pointer"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withLocalize(GalleriesIndex)
