import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'

import {selectStyles} from '../../support/helpers'
import Select from 'react-select'
import Attachments from '../projects_management/helpers/Attachments'

import { useRemoveTaskHook, useUpdateTaskHook } from './hooks_api'

const TaskItem = ({task, taskList, datasources, setTaskList}) => {
  const { mutate: removeTask, isLoading: isRemoving } = useRemoveTaskHook()
  const { mutate: updateTask, isLoading: isUpdatingTask } = useUpdateTaskHook()

  let completed = task.current_status == 'completed'

  const handleRemoveTask = () => {
    if(isRemoving || isUpdatingTask) {
      return
    }

    removeTask({id: task.id}, {
      onSuccess: (data) => {
        let tasks = taskList.tasks.filter(e => e.id != task.id)
        setTaskList({...taskList, tasks})
      }
    })
  }

  const selectLocation = (location) => {
    if(isRemoving || isUpdatingTask) {
      return
    }

    updateTask({...task, location: location}, {
      onSuccess: (data) => {
        let tasks = taskList.tasks.map((map_task) => {
          if(task.id == map_task.id) {
            return data.data.attributes
          } else {
            return map_task
          }
        })

        setTaskList({...taskList, tasks})
      }
    })
  }
  console.log(task)

  return(
    <div className="my-2 card ">
      { !completed &&
        <div className="filter-group-close" onClick={handleRemoveTask}>
          <i className="fa text-muted fa-times"></i>
        </div>
      }

      <div className="card-body">
        <div className='row'>
          <div className='col-md-12 col-sm-12'>
            <div className='d-flex justify-content-between align-items-center'>
              { completed ? (
                <div className="">
                  <h5>{task.location.label}</h5>
                </div>
              ) : (
                <div className="" style={{width: '300px'}}>
                  <Select
                    styles={selectStyles}
                    value={task.location}
                    placeholder={'Select location'}
                    onChange={selectLocation}
                    options={datasources.locations}
                  />
                </div>
              )}
              <div className="">
                { completed &&
                  <div className= 'd-flex flex-column align-items-end' style={{color: "#61caa0", fontWeight: 700}}>
                    <div className=''>Completed</div>
                    <div className='text-muted mb-0'>{task.submitted_at} </div>
                  </div>
                }
              </div>

            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-12'>
            <Attachments
              resource_id={task.id}
              resource_type={'tasks'}
              can_create_attachment={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskItem
