import axios from 'axios'

function getStats(post_id) {
  let url = `/posts/${post_id}/stats`

  return axios.get(url).then((response) => response.data)
}

function getList(post_id) {
  let url = `/posts/${post_id}/user_list`

  return axios.get(url).then((response) => response.data)
}

module.exports = {
  getStats: getStats,
  getList: getList
}
