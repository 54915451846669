import axios from 'axios'

const csrf_token = $('meta[name=csrf-token]').attr('content')
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token

function getGalleries(filters) {
  let url = `/web_api/galleries`
  return axios.get(url, {params: filters}).then((response) => response.data)
}

function getGallery(id) {
  let url = `/web_api/galleries/${id}`
  return axios.get(url).then((response) => response.data)
}

function getGalleryShareGallery(gallery_share_id, gallery_id, auth) {
  let url = `/web_api/gallery_shares/${gallery_share_id}/galleries/${gallery_id}`
  return axios.get(url, {params: {auth: auth}}).then((response) => response.data)
}

function createGallery(params) {
  let url = `/web_api/galleries`
  return axios.post(url, params).then((response) => response.data)
}

function updateGallery(id, params) {
  let url = `/web_api/galleries/${id}`
  return axios.put(url, params).then((response) => response.data)
}

function deleteGallery(id) {
  let url = `/web_api/galleries/${id}`
  return axios.delete(url).then((response) => response.data)
}

function getImages(filters) {
  let url = `/web_api/images`
  return axios.get(url, {params: filters}).then((response) => response.data)
}

function getGalleryShareImages(gallery_share_id, gallery_id, filters) {
  let url = `/web_api/gallery_shares/${gallery_share_id}/galleries/${gallery_id}/images`
  return axios.get(url, {params: filters}).then((response) => response.data)
}

function getImage(id) {
  let url = `/web_api/images/${id}`
  return axios.get(url).then((response) => response.data)
}

function getDatasources() {
  let url = `/web_api/images/datasources`
  return axios.get(url).then((response) => response.data)
}

function getSimpleDatasources(base_filters) {
  let url = `/web_api/images/simple_datasources`
  return axios.get(url, {params: {base_filters: base_filters}}).then((response) => response.data)
}

function getFormKeys(id, base_filters) {
  let url = `/web_api/images/form_keys`
  return axios.get(url, {params: {form_id: id, base_filters: base_filters}}).then((response) => response.data)
}

function getLocationDatasources(key) {
  let url = `/web_api/images/location_datasources`
  return axios.get(url, {params: {key: key}}).then((response) => response.data)
}

function changeBanned(id) {
  let url = `/web_api/images/${id}/change_banned`
  return axios.post(url).then((response) => response.data)
}

function changeFavorite(id) {
  let url = `/web_api/images/${id}/change_favorite`
  return axios.post(url).then((response) => response.data)
}

function createGalleryShare(params) {
  let url = `/web_api/gallery_shares`
  return axios.post(url, params).then((response) => response.data)
}

function updateGalleryShare(params) {
  let url = `/web_api/gallery_shares/${params.gallery_share.id}`
  return axios.put(url, params).then((response) => response.data)
}

function getGalleryShares() {
  let url = `/web_api/gallery_shares`
  return axios.get(url).then((response) => response.data.data.map(data => data.attributes))
}

function removeGalleryShare(id) {
  let url = `/web_api/gallery_shares/${id}`
  return axios.delete(url).then((response) => response.data)
}

function resetGalleryShare(id) {
  let url = `/web_api/gallery_shares/${id}/reset_token`
  return axios.post(url).then((response) => response.data)
}

module.exports = {
  getImages: getImages,
  getImage: getImage,
  getDatasources: getDatasources,
  getSimpleDatasources: getSimpleDatasources,
  getLocationDatasources: getLocationDatasources,
  changeFavorite: changeFavorite,
  changeBanned: changeBanned,
  getGalleries: getGalleries,
  getGallery: getGallery,
  createGallery: createGallery,
  updateGallery: updateGallery,
  deleteGallery: deleteGallery,
  createGalleryShare: createGalleryShare,
  updateGalleryShare: updateGalleryShare,
  getGalleryShares: getGalleryShares,
  removeGalleryShare: removeGalleryShare,
  getGalleryShareGallery: getGalleryShareGallery,
  getGalleryShareImages: getGalleryShareImages,
  GalleryShareImages: getGalleryShareImages,
  resetGalleryShare: resetGalleryShare,
  getFormKeys: getFormKeys,
}
