import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route, NavLink } from "react-router-dom"

import QueryIndex from '../components/analytics/QueryIndex'
import Reports from '../components/analytics/Reports'
import Segments from '../components/analytics/Segments'

import { ToastContainer} from 'react-toastify'

export default class AnalyticsContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { language, auth, google_api_key } = this.props 

    return(
      <Router>
        <div>
          { !auth.client_portal &&
            <section className='sub-navbar bg-white'>
              <div className="container-fluid">
                <div className="container-fluid p-0 pl-sm-3 pr-sm-3">
                  <div className="row no-gutters">
                    <nav className="w-100">
                      <div className="nav nav-tabs nav-tabs-campo border-0" id="nav-tab" role="tablist">
                        <NavLink exact className="nav-item nav-link py-3 mr-3" to={"/analytics"} activeClassName="active">
                          {i18n.t('analytics.navs.graph')}
                        </NavLink>
                        <NavLink className="nav-item nav-link py-3 mr-3" to={"/analytics/reports"} activeClassName="active">
                          {i18n.t('analytics.navs.reports')}
                        </NavLink>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          }
          <div>
            <Route exact path="/analytics" render={(props) => <QueryIndex {...props} language={language} auth={auth}/>} />
            <Route path="/analytics/reports" render={(props) => <Reports {...props} language={language} auth={auth} google_api_key={google_api_key} />}/>
            <Route path="/analytics/segments" render={(props) => <Segments {...props} language={language} />} auth={auth} />
          </div>
          <ToastContainer autoClose={8000}/>
        </div>
      </Router>
    )
  }
}
