import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import moment from 'moment'

let default_hour = {hour:12,minute:0,second:0,millisecond:0}

const ranges = {
  today: {
    start_date: moment().set(default_hour),
  },
  tomorrow: {
    start_date: moment().add(1, 'day').set(default_hour),
  }
}

export default class PredefinedPicker extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(type) {
    this.props.handlePredefined(ranges[type].start_date)
  }

  render() {
    let style= {
      textTransform: 'uppercase',
      cursor: 'pointer',
      color: '#007bff',
      padding: '3px 0px 3px 0px'
    }

    return(
      <div style={{fontSize: '11px', padding: '3px 0px'}}>
        <span style={style} onClick={() => {this.handleClick('today')}}>Today</span>{" "}|{" "}
        <span style={style} onClick={() => {this.handleClick('tomorrow')}}>Tomorrow</span>
      </div>
    )
  }
}


