import React from 'react'

import Select from 'react-select'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

class VisitGoalForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_location: null
    }

    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
    this.handleSubmitError = this.handleSubmitError.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.selectLocation = this.selectLocation.bind(this)
  }

  selectLocation(selected) {
    if(!selected) {
      selected = {}
    }

    this.setState({selected_location: selected})
  }


  handleSubmitSuccess() {
    this.setState({selected_location: null})
    this.refs.visitCount.value = ""
  }

  handleSubmitError() {
    alert('Error Saving Visit Goal');
  }

  handleSubmit(evt) {
    evt.preventDefault();

    var visitCount = this.refs.visitCount.value.trim();

    if (!location || !visitCount) {
      return alert('Please enter location and expected visits');
    }

    var formData = {}

    formData.visit_goal = {}
    formData.visit_goal.expected_visits = this.refs.visitCount.value
    formData.visit_goal.location_id = this.state.selected_location.value
    formData.authenticity_token = this.refs.authenticityToken.value

    this.props.onVisitGoalFormSubmit(formData, this.props.form.url,
      this.handleSubmitSuccess, this.handleSubmitError
    )
  }

  render() {
    let locations = this.props.locations.map((l) => {
      return {value: l.id, label: l.name}
    })

    return(
      <form ref="form" className='bootstrap-validator-form' action={ this.props.form.url } acceptCharset="UTF-8" method="post" onSubmit={ this.handleSubmit } role="form">
        <p><input type="hidden" name={ this.props.form.csrf_param } ref="authenticityToken" value={ this.props.form.csrf_token } /></p>
        <div className="row">
          <div className="col-md-6">
            <Select
              styles={selectStyles}
              value={this.state.selected_location}
              placeholder={'Location'}
              isMulti={false}
              onChange={this.selectLocation}
              options={locations}
            />

            <input type="hidden" ref="locationID" name={ this.props.form.location_input_name } />
          </div>

          <div className="col-md-4">
            <div className="form-group has-feedback no-label left-feedback">
              <input ref="visitCount" className="form-control" placeholder={ i18n.t('visit_goal.visits') } name={ this.props.form.visits_input_name }/>
            </div>
          </div>

          <div className="col-md-2">
            <button type="submit" className="btn btn-outline-primary btn-block">{ i18n.t('visit_goal.add_goal') }</button>
          </div>
        </div>
      </form>
    )
  }
}

export default VisitGoalForm
