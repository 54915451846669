import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';
import uuid from 'node-uuid';

export default class SelectFieldgroup extends Component {
  constructor(props) {
    super(props)
    this.selectFieldgroup = this.selectFieldgroup.bind(this)
  }

  selectFieldgroup(value, label){
    this.props.handleSelectFieldGroup(value, label)
  }

  componentDidMount() {
    if(this.props.fieldGroup.value) {
      this.selectFieldgroup(this.props.fieldGroup.value)
    }
  }

  render() {
    let dashboard_type = this.props.dashboard_type
    let selected_field = this.props.fieldGroup
    let field_groups = this.props.field_groups
    let show_percentage = true

    if(dashboard_type.value == 'line_chart' || dashboard_type.value== 'bar_chart') {
      show_percentage = false
      if(selected_field.value == '_campodata_location_presence_percentage') {
        selected_field = {}
      }
    }

    if(dashboard_type.value == 'drill_down') {
      let raw_data = field_groups[3]
      raw_data.values = [raw_data.values[0]]
      raw_data.values.push(
        {label: "Route Compliance", value: "_campodata_route_compliance"}
      )
      field_groups = [raw_data]
    }

    return (
      <Select
        dropdownClassName='simple-group-dropdown'
        value={selected_field.value}
        optionLabelProp={'text'}
        placeholder={this.props.selectFieldGroupText}
        onChange={this.selectFieldgroup}
        optionFilterProp="label"
        dropdownMenuStyle={{maxHeight: 250, overflow: 'scroll'}}
        style={{width: '100%'}}
        dropdownStyle={{zIndex: 101}}>
        {field_groups.map((option) => {
          return (
          <OptGroup key={uuid.v4()} label={option.label}>
          {option.values.map((data) => {
            if (show_percentage || data.value != "_campodata_location_presence_percentage") {
              return (
                <Option key={data.value} text={data.label} value={data.value}>
                 {data.label}
                </Option>
              )
            } else {
              return (
                <Option key={data.value} text={data.label} value={data.value} disabled>
                 {data.label}
                </Option>
              )
            }
          })}
          </OptGroup>
          )
        })}
      </Select>
    );
  }
}

SelectFieldgroup.defaultProps = {
  selectFieldGroupText: i18n.t('dashboard_form.placeholder_select_field_group'),
}
