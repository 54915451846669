import React, { useState, useEffect, Fragment, useRef } from 'react'
import { render } from 'react-dom'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"


const MapComponent = withScriptjs(withGoogleMap(({submissions, setSelectedSubmission}) => {
  const mapRef = useRef(null)
  let long_key = submissions.map(e => e.id).join("|")

  submissions = submissions.filter(s => s.latitude && s.longitude && s.latitude != 0 && s.longitude != 0)

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    submissions.map(submission => {
      bounds.extend({ lat: submission.latitude, lng: submission.longitude });
    });

    mapRef.current.fitBounds(bounds);
  }

  var pinColor = "FFC300";
  var pinImage = new window.google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
    new google.maps.Size(21, 34),
    new google.maps.Point(0,0),
    new google.maps.Point(10, 34));
  var pinShadow = new window.google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_shadow",
    new google.maps.Size(40, 37),
    new google.maps.Point(0, 0),
    new google.maps.Point(12, 35));

  useEffect(() => {
    fitBounds();
  }, [long_key]);

  const onMarkerClustererClick = (markerClusterer) => {
    const clickedMarkers = markerClusterer.getMarkers()
    //console.log(`Current clicked markers length: ${clickedMarkers.length}`)
    //console.log(clickedMarkers)
  }

  return(
    <GoogleMap
      ref={mapRef}
      defaultZoom={8}
      defaultCenter={{ lat: -34.397, lng: 150.644 }}

    >
      <MarkerClusterer
        onClick={onMarkerClustererClick}
        averageCenter
        enableRetinaIcons
        gridSize={60}
        maxZoom={18}
      >
          { submissions.map((submission) => {
            return(
              <Marker
                onClick={() => setSelectedSubmission(submission)}
                key={submission.id}
                position={{ lat: submission.latitude, lng: submission.longitude }}
              />
            )
          })}
      </MarkerClusterer>
    </GoogleMap>
  )}
))

export default MapComponent
