import React, { Component } from 'react';
import resourcesApi from '../../utils/resources_api'
import DashboardFormater from '../../utils/DashboardFormater';
import InsightForm from './InsightForm.js';
import BaseInsightButton from './BaseInsightButton.js';

import { ToastContainer } from 'react-toastr'
let container

class CreateInsightButton extends BaseInsightButton {
  constructor(props) {
    super(props)

    this.updateInsightList = this.props.updateInsightList
    this.handleSave = this.handleSave.bind(this)
    this.initialForm = this.initialForm.bind(this)
  }

  handleSave(data) {
    resourcesApi.createInsight(DashboardFormater(data), this.props.entity_type, this.props.entity_id).
      then((dashboard) => {
        this.closeModal()
        this.updateInsightList(dashboard)
    }).catch((response) => {
    })
  }

  initialForm() {
    let datasource = {}
    if(this.props.entity_type && this.props.entity_type == 'form_data_sets') {
      datasource = {type: 'form_data_set', value: 'form_data_set_' + this.props.entity_id}
    }
    return({
      display: this.state.showModal,
      datasources: this.state.dataSources,
      data: {
        name: "",
        description: "",
        id: "",
        datasource: datasource,
        dashboard_type: {},
        fieldGroup: {},
        dashboard_group: {value: 'empty-dashboard-group'},
        selectedFieldSubGroups: [],
        operation: {},
        period: {},
        period_in_past: {},
        locations: [],
        users: [],
        supervisors: [],
        group_by: {},
        summarize_by: {},
        fds: {}
      }
    })
  }

  render() {
    let hideDatasourceSelect = false
    if(this.props.entity_type && this.props.entity_type == 'form_data_sets') {
      hideDatasourceSelect = true
    }
    return (
      <div> 
        <a className="nav-item nav-link py-1" onClick={this.showInsightCreationModal}>
          {i18n.t('insight.create')}
        </a>

        <ToastContainer
          ref={ref => container = ref}
          className="toast-top-right"
        />
     
        { this.state.showModal &&
          <InsightForm
            hideDatasourceSelect={hideDatasourceSelect}
            dashboard_groups={this.props.dashboard_groups}
            handleSave={this.handleSave}
            empty_dashboard_group_label={this.props.empty_dashboard_group_label}
            form={this.initialForm()}
            closeModal={this.closeModal}
            entity_id={this.props.entity_id}
          />
        }
      </div>
    )
  }
}

export default CreateInsightButton
