import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 1040
  },
  content : {
    position                   : 'absolute',
    top                        : '10%',
    left                       : '15%',
    right                      : '15%',
    bottom                     : 'auto',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '0px'
  }
}

export default class AnalyticCopyModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: `${props.analytic_report.name} (Copy)`
    }

    this.handleValue = this.handleValue.bind(this)
  }

  handleValue(e) {
    let value = e.target.value
    this.setState({name: value})
  }

  render() {
    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={this.props.closeModal}
        isOpen={true}>
        <div className="">
          <div onClick={this.props.closeModal} className="close-button">
            <i style={{cursor: 'pointer', marginTop: '15px', marginRight: '20px'}} className="fa fa-times fa-2x clickable pull-right"></i>
          </div>

          <div className="p-5">
            <h2 className="text-dark h5 d-block campo_font-weight-semi-bold mb-4">
              Copy Analytic Report
            </h2>

            <div className="row mb-2">
              <div className="col-12 col-lg-12">
                <div className="form-group">
                  <label className="form-group--campo_label text-uppercase m-0">
                    Name
                  </label>

                  <input value={this.state.name} onChange={this.handleValue} className='form-control text-dark'/>
                </div>
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-auto">
                <button 
                  onClick={() => this.props.handleSubmit(this.state.name)}
                  className="text-white text-uppercase form-campo__button border-0 py-2 px-4 rounded">
                  Copy report
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
