import React, { Component } from 'react'
import { render } from 'react-dom'
import { ConditionLabel } from './common_components'
import {selectStyles} from '../../../support/helpers'
import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async'

import AnalyticsApi from '../../../utils/analytics_api'

let simple_comparators = [
  {label: i18n.t('analytics.form.comparators.equals'), value: 'equals'},
  {label: i18n.t('analytics.form.comparators.is_not_equal'), value: 'is_not_equal'}
]

let complex_comparators = [
  {label: i18n.t('analytics.form.comparators.presence'), value: 'presence'},
  {label: i18n.t('analytics.form.comparators.match'), value: 'match'},
  {label: i18n.t('analytics.form.comparators.not_match'), value: 'not_match'},
  {label: i18n.t('analytics.form.comparators.equals_number'), value: 'equals'},
  {label: i18n.t('analytics.form.comparators.is_not_equal_number'), value: 'is_not_equal'},
  {label: i18n.t('analytics.form.comparators.greater_than'), value: 'greater_than'},
  {label: i18n.t('analytics.form.comparators.greater_equal_than'), value: 'greater_equal_than'},
  {label: i18n.t('analytics.form.comparators.less_than'), value: 'less_than'},
  {label: i18n.t('analytics.form.comparators.less_equal_than'), value: 'less_equal_than'},
]

let reporter_comparators = [
  {label: i18n.t('analytics.form.comparators.supervised_by'), value: 'supervised_by'},
  {label: i18n.t('analytics.form.comparators.is'), value: 'equals'},
]

let exception_comparators = [
  {label: i18n.t('analytics.form.comparators.exception_present'), value: 'exception_present'},
  {label: i18n.t('analytics.form.comparators.exception_not_present'), value: 'exception_not_present'},
]

const value_comparators = {
  chain_category: simple_comparators,
  chain: simple_comparators,
  location_type: simple_comparators,
  zone: simple_comparators,
  location: simple_comparators,
  ca: simple_comparators,
  cb: simple_comparators,
  cc: simple_comparators,
  cd: simple_comparators,
  reporter: reporter_comparators,
  key: complex_comparators,
  answer: complex_comparators.slice(1, complex_comparators.length - 1),
  exception: exception_comparators,
  location_ca: simple_comparators,
  location_cb: simple_comparators,
  location_cc: simple_comparators,
  location_cd: simple_comparators,
  user_ca: simple_comparators,
  user_cb: simple_comparators,
  user_cc: simple_comparators,
  user_cd: simple_comparators,
}

const global_filter_type_options = [
  {label: i18n.t('analytics.form.filter_type.chain_category'), value: 'chain_category', type: 'single_select'},
  {label: i18n.t('analytics.form.filter_type.chain'), value: 'chain', type: 'multiple_select'},
  {label: i18n.t('analytics.form.filter_type.location_type'), value: 'location_type', type: 'single_select'},
  {label: i18n.t('analytics.form.filter_type.zone'), value: 'zone', type: 'single_select'},
  {label: i18n.t('analytics.form.filter_type.location'), value: 'location', type: 'single_async_select'},
  {label: i18n.t('analytics.form.filter_type.reporter'), value: 'reporter', type: 'single_select'},
  {label: i18n.t('analytics.form.filter_type.key'), value: 'key', type: 'input'},
  {label: i18n.t('analytics.form.filter_type.answer'), value: 'answer', type: 'input'},
  {label: i18n.t('analytics.form.filter_type.exception'), value: 'exception', type: 'single_select'},
]

class ValueFilter extends Component {
  constructor(props) {
    super(props)
    this.loadOptions = this.loadOptions.bind(this)
  }

  loadOptions(filter) {
    return AnalyticsApi.searchDatasource({search: filter, type: this.props.filter_type.value}).then((response) => {
      let records =  response.records.map((record) => ({value: record.id, label: record.name}))
      return records
    })
  }

  render() {
    let {filter_type, datasources, filter_condition} = this.props
    let isMulti = false
    if(filter_type.type == 'multiple_select' || filter_type.type == 'multiple_async_select') {
      isMulti = true
    }
    let options = []
    switch (filter_type.value) {
      case 'chain_category':
        options = datasources.chain_categories
        break;
      case 'chain':
        options = datasources.chains
        break;
      case 'location_type':
        options = datasources.location_types
        break;
      case 'zone':
        options = datasources.zones
        break;
      case 'ca':
        options = datasources.ca
        break;
      case 'cb':
        options = datasources.cb
        break;
      case 'cc':
        options = datasources.cc
        break;
      case 'cd':
        options = datasources.cd
        break;
      case 'location_ca':
        options = datasources.location_classifiers.ca
        break;
      case 'location_cb':
        options = datasources.location_classifiers.cb
        break;
      case 'location_cc':
        options = datasources.location_classifiers.cc
        break;
      case 'location_cd':
        options = datasources.location_classifiers.cd
        break;
      case 'user_ca':
        options = datasources.user_classifiers.ca
        break;
      case 'user_cb':
        options = datasources.user_classifiers.cb
        break;
      case 'user_cc':
        options = datasources.user_classifiers.cc
        break;
      case 'user_cd':
        options = datasources.user_classifiers.cd
        break;
      case 'reporter':
        if(filter_condition.value == 'supervised_by') {
          options = datasources.supervisors
        } else {
          options = datasources.users
        }
        break;
    }

    if(filter_type.type == 'multiple_select' || filter_type.type == 'single_select') {
      return(
        <Select
          styles={selectStyles}
          isMulti={isMulti}
          value={this.props.value}
          placeholder={i18n.t('analytics.form.ph.select_values')}
          onChange={this.props.onChange}
          options={options}
        />
      )
    } else if (filter_type.type == 'input') {
      if(filter_condition.value != 'presence') {
        return(
          <input value={this.props.value} onChange={this.props.onChange} className='form-control' />
        )
      } else {
        return(<div></div>)
      }
    }else {
      return(
        <AsyncSelect
          cacheOptions
          defaultOptions
          isMulti={isMulti}
          placeholder={i18n.t('analytics.form.ph.search_values')}
          value={this.props.value}
          onChange={this.props.onChange} 
          loadOptions={this.loadOptions} />
      )
    }
  }
}

export default class SingleFilter extends Component {
  constructor(props) {
    super(props)

    this.selectFilterType = this.selectFilterType.bind(this)
    this.selectFilterCondition = this.selectFilterCondition.bind(this)
    this.selectFilterValue = this.selectFilterValue.bind(this)
    this.selectFilterKey = this.selectFilterKey.bind(this)
  }

  selectFilterValue(ele) {
    let single_filter = this.props.single_filter
    let value = ele
    if(single_filter.filter_type.type == 'input') {
      value = ele.target.value
    }
    single_filter.filter_value = value
    this.props.update(
      this.props.uuid, single_filter
    )
  }

  selectFilterKey(ele) {
    let single_filter = this.props.single_filter
    single_filter.filter_key = ele
    this.props.update(
      this.props.uuid, single_filter
    )
  }

  selectFilterCondition(ele) {
    let single_filter = this.props.single_filter
    single_filter.filter_condition = ele
    if(single_filter.filter_type.value == 'reporter') {
      single_filter.filter_value = []
    }
    this.props.update(
      this.props.uuid, single_filter
    )
  }

  selectFilterType(ele) {
    let new_data = {
      filter_type: ele,
      filter_condition: value_comparators[ele.value][0],
      filter_value: [],
      filter_key: null,
    }
    this.props.update(
      this.props.uuid, Object.assign(this.props.single_filter, new_data)
    )
  }

  render() {
    let {single_filter, position, datasources, form} = this.props
    let conditions = []
    if(single_filter.filter_type) {
      conditions = value_comparators[single_filter.filter_type.value]
    }
    let filter_type_options = global_filter_type_options.slice(0)
    let filter_key_options = datasources.keys

    if(form) {
      if(datasources.ca.length > 0) {filter_type_options.push({label: `Question - ${form.ca_label}`, value: 'ca', type: 'single_select'})}
      if(datasources.cb.length > 0) {filter_type_options.push({label: `Question - ${form.cb_label}`, value: 'cb', type: 'single_select'})}
      if(datasources.cc.length > 0) {filter_type_options.push({label: `Question - ${form.cc_label}`, value: 'cc', type: 'single_select'})}
      if(datasources.cd.length > 0) {filter_type_options.push({label: `Question - ${form.cd_label}`, value: 'cd', type: 'single_select'})}
    }
    filter_type_options = filter_type_options.concat(datasources.classifiers)

    let show_conditions = false
    let show_keys = false

    if(single_filter.filter_type && single_filter.filter_type.value == 'key') {
      show_keys = true
      show_conditions = single_filter.filter_key ? true : false
    } else if(single_filter.filter_type) {
      show_conditions = true
    }

    let show_errors = this.props.show_validations && !single_filter.valid
    let border_class = show_errors ? 'border-danger' : 'border-light'


    return(
      <div className='row'>
        <div className='col-md-12'>
          { position != 0 &&
            <div className='ml-3 single-filter-condition'>
              <ConditionLabel type={this.props.query_type}/>
            </div>
          }

          <div className={"py-3 px-2 card " + border_class}>
            <div className="form-row">
              <div className="col-auto" style={{width: '200px'}}>
                <Select
                  styles={selectStyles}
                  value={single_filter.filter_type}
                  placeholder={i18n.t('analytics.form.ph.select_filter_type')}
                  onChange={this.selectFilterType}
                  options={filter_type_options}
                />
              </div>
              
              { show_keys &&
                <div className="col-auto" style={{width: '260px'}}>
                  <Select
                    styles={selectStyles}
                    value={single_filter.filter_key}
                    placeholder={i18n.t('analytics.form.ph.select_key')}
                    onChange={this.selectFilterKey}
                    options={filter_key_options}
                  />
                </div>
              }

              { show_conditions &&
                <div className="col-auto" style={{width: '240px'}}>
                  <Select
                    styles={selectStyles}
                    value={single_filter.filter_condition}
                    placeholder={i18n.t('analytics.form.ph.select_condition')}
                    onChange={this.selectFilterCondition}
                    options={conditions}
                  />
                </div>
              }

              { show_conditions && (single_filter.filter_type.value != 'exception') &&
                <div className="col-auto" style={{width: '280px'}}>
                  <ValueFilter
                    value={single_filter.filter_value}
                    filter_type={single_filter.filter_type}
                    filter_condition={single_filter.filter_condition}
                    onChange={this.selectFilterValue}
                    datasources={datasources}
                  />
                </div>
              }

              <div className='filter-group pl-2'>
                <i onClick={() => this.props.remove(single_filter.uuid)}
                  className='fa text-muted fa-times'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
