import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

import PopoverLoader from './PopoverLoader'

import api from '../../utils/scheduled_submission_api'
import { ArrowContainer } from 'react-tiny-popover'

class SubmissionPopover extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }

  }
  componentDidMount() {
    let selected_id = this.props.selected_id
    let id = selected_id.slice(4)
    if(selected_id.substring(0,4) == 'subs') {
      api.getSubmission(id).then((response) => {
        this.setState({loading: false, ss: response.scheduled_submission})
      })
    } else {
      api.getScheduledSubmission(id).then((response) => {
        this.setState({loading: false, ss: response.scheduled_submission})
      })
    }
  }

  render() {
    let ss = this.state.ss
    let report_time = ""
    let { position, targetRect, popoverRect } = this.props

    if(ss) {
      if(ss.checkin_at) {
        report_time += ss.checkin_at
      }

      if(ss.checkin_at && ss.checkout_at) {
        report_time += " - "
      }

      if(ss.checkout_at) {
        report_time += ss.checkout_at
      }
    }

    let style = {
      minWidth: '380px',
      minHeight: '240px',
      maxWidth: '400px',
      border: '1px solid #ccc',
      borderRadius: '10px'
    }
    
    return(
      <ArrowContainer
        position={position}
        targetRect={targetRect}
        popoverRect={popoverRect}
        arrowColor={'#666'}
        arrowSize={10}
      >
        <div className='bg-white p-3' style={style}>
         
          { this.state.loading ? (
            <div className='row'>
              <div className='col-md-12'>
                <PopoverLoader />
              </div>
            </div>
          ) : (
            <Fragment>
              <div className='d-flex flex-row'>
                <div className='flex-grow-1'>
                  <h5>
                    {ss.location_name}
                  </h5>
                </div>
                { ss.compliance &&
                  <div className='text-right' style={{minWidth: '100px'}}>
                    <span style={{background: ss.color}} className='popover-campo__status-icon'>
                    </span>
                    <h6 className='popover-campo__status-text'>{ss.compliance}%</h6>
                  </div>
                }
              </div> 
              <div className='d-flex flex-row mb-3'>
                  { ss.submission_id ? (
                    <Fragment>
                      <div className="flex-fill font-weight-light small">
                        
                        <i className="fa fa-clock-o text-muted pr-1" aria-hidden="true"></i>
                          {ss.checkin_at} - {ss.checkout_at} 
                        <i className="fa fa-calendar text-muted pr-1 ml-4" aria-hidden="true"></i>  
                        {ss.scheduled_at}
                      </div>
                    </Fragment>
                  ) : (
                    <h5 className='text-info'>
                      <i className='fa fa-calendar'></i> Scheduled
                    </h5>
                  )}
              </div>

              <div className='d-flex flex-row'>
                <div className='flex-grow-1'>
                  <h6 className='text-primary'>{ss.user_name}</h6>
                </div>
              </div> 
            
              <div className='row '>
                <div className='col-12 pt-1'>
                  <h6 className='popover-campo__hour'>
                    {ss.form_name} 
                  </h6>
                  <div className=''>
                  <p className='small text-muted'>
                  {ss.location_description}
                  </p>
                </div>
                    
                </div>
              </div>

              { ss.original_date &&
                <div className='row mb-4'>
                  <div className='col-12 text-secondary'>
                    <h5 className='popover-campo__hour text-info'>
                      <i className='fa fa-calendar'></i> Originally scheduled at: {ss.original_date}
                    </h5>
                  </div>
                </div>
              }

              { ss.has_exceptions &&
                <div className='row mb-4'>
                  <div className='col-1 pr-0 pt-1'>
                    <span className='popover-campo__status-icon-small'>
                    </span>
                  </div>
                  <div className='col-11 pl-0'>
                    <h5 className='popover-campo__info__title font-weight-bold'>
                      {i18n.t('explore.popover.exceptions_title')}
                    </h5>

                    { ss.exception_messages.map((message) => {
                      return(
                        <h6 className='popover-campo__info__text'>
                        {message}
                        </h6>
                      )
                    })}
                  </div>
                </div>
              }

              { ss.out_of_area_alert &&
                <div className='row mb-4'>
                  <div className='col-1 pr-0 pt-1'>
                    <span className='popover-campo__status-icon-small'>
                    </span>
                  </div>
                  <div className='col-11 pl-0'>
                    <h5 className='popover-campo__info__title font-weight-bold'>
                      {i18n.t('explore.popover.far_title')}
                    </h5>
                    <h6 className='popover-campo__info__text'>
                      {i18n.t('explore.popover.far_description')}
                    </h6>
                  </div>
                </div>
              }

              { ss.submission_id &&
                <div className='row mt-2'>
                  <div className='col-12 col-sm-6 mb-2 mb-sm-0'>
                    <a href={"/submissions/" + ss.submission_id + "/webview"} className='popover-campo__button' target='_blank'>
                      {i18n.t('explore.popover.see_report')}
                    </a>
                  </div>
                  { ss.out_of_area_alert &&
                  <div className='col-12 col-sm-6 mb-2 mb-sm-0'>
                    <a href={"/submissions/" + ss.submission_id + "/comparative_map/webview"} className='popover-campo__button' target='_blank'>
                      {i18n.t('explore.popover.see_map')}
                    </a>
                  </div>
                  }
                </div>
              }
            </Fragment>
          )}
        </div>
      </ArrowContainer>
    )
  }
}

export default SubmissionPopover
