import React, { Component } from 'react';
import { NumberFormatter as NF } from '../../utils/index';
import BaseInsightComponent from './BaseInsightComponent';

export default class InsightCounter extends BaseInsightComponent {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    if (this.isLoading() && !this.props.showReload) {
      return (
        <div className='spinner-wrap'>
          Loading ...
        </div>
      );
    } else if (this.props.showReload) {
      return(this.renderReload())
    } else {
      return <p className='counter-value'>{NF.withSeparator(this.props.data.value)}</p>
    }
  }

  render() {
    return (
      <div className='counter-insight'>
        <p className='dashboard-label'>{this.props.name}</p>
        <p className='dashboard-description'>{this.props.description}</p>
        {this.renderContent()}
      </div>
    );
  }
}
