import React, { Component } from 'react';
import DashboardCompliance from '../../containers/DashboardCompliance'
import DashboardRouteCompliance from '../../containers/DashboardRouteCompliance'

export default class InsightDrillDown extends Component {
  render() {
    let type = this.props.config.field_group
    return(
      <div>
        <div>
          <p className='dashboard-label'>{this.props.name}</p>
          <p className='dashboard-description'>{this.props.description}</p>
        </div>
        <div>
          { type == '_campodata_compliance' &&
            <DashboardCompliance 
              entityType={'chain_category'} 
              formId={this.props.form_id}
            />
          }
          { type == '_campodata_route_compliance' &&
            <DashboardRouteCompliance 
              form={{id: this.props.form_id}}
            />
          }

        </div>
      </div>
    )
  }
}
