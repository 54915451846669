import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import LocationsReported from './reports/LocationsReported'
import SubmissionsCount from './reports/SubmissionsCount'
import DurationReport from './reports/DurationReport'
import AllReport from './reports/AllReport'
import ComplianceReport from './reports/ComplianceReport'

import TableLoader from '../../utils/TableLoader'

export default function AnalyticType(props) {
  let {filters, aggregations, loading_query, single_value} = props
  let report = (<div></div>)
  if(filters && filters.report_type) {
    let report_type = filters.report_type.value
    if(report_type == 'locations_reported') {
      report = (<LocationsReported {...props} />)
    } else if(report_type == 'submissions_count') {
      report = (<SubmissionsCount {...props} />)
    } else if(['sum_duration', 'avg_duration', 'min_duration', 'max_duration'].includes(report_type)) {
      report = (<DurationReport {...props} />)
    } else if(['sum_reported', 'avg_reported'].includes(report_type)) {
      report = (<AllReport {...props} />)
    } else if(['compliance', 'compliance_deficit', 'compliance_deficit_location'].includes(report_type)) {
      report = (<ComplianceReport {...props} />)
    }
  }

  return(
    <div className='card mt-2'>
      <div className='card-body'>
        { loading_query ? (
          <TableLoader />
        ) : (
          <Fragment>
            { aggregations ? (
              <Fragment>
                {report}
              </Fragment>
            ): (
              <Fragment>
                { single_value ? (
                  <div className="d-flex justify-content-center">
                    <div className="card text-center">
                      <div className="card-header">
                        {filters.report_type.label}
                      </div>
                      <div className="card-body">
                        <h5 className="card-title my-1">{single_value}</h5>
                      </div>
                    </div>
                  </div>
                ) : ( 
                  <div>
                    {i18n.t('analytics.no_data_found')}
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  )
}
