import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

export default class GenerateMultiplePdf extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      timeFetching: 0,
      attachment: {},
    }

    this.exportPdf = this.exportPdf.bind(this)

    this.getPollingInteral = this.getPollingInterval(this);
    this.reloadButton = this.reloadButton.bind(this);
  }

  exportPdf() {
    this.setState({loading: true}, () => {
      axios.post('/web_api/task_lists/generate_multiple_qr_pdf', {task_lists: this.props.qrSelectedTaskLists}).
        then((response) => {
          this.setState({attachment: response.data.data.attributes}, () => {
            this.reloadButton()
          })
        })
    })
  }

  getPollingInterval() {
    let timeFetching = this.state.timeFetching
    if(timeFetching >= 0 && timeFetching < 20000) {
      return 5000
    } else if(timeFetching >= 20000 && timeFetching < 60000) {
      return 10000
    } else if(timeFetching >= 60000 && timeFetching < 120000) {
      return 20000
    } else if(timeFetching >= 120000 && timeFetching < 300000) {
      return 30000
    } else {
      return 30000
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.qrSelectedTaskLists.length != this.props.qrSelectedTaskLists.length) {
      this.setState({ loading: false, timeFetching: 0, attachment: {}});
    }
  }

  reloadButton() {
    if(!this.state.attachment.id) {
      return
    }

    axios.get(`/web_api/task_lists/generated_multiple_qr_pdf/${this.state.attachment.id}`).
      then((response) => {
        let attachment = response.data.data.attributes
        clearInterval(this.state.reloadInterval)
        if (!attachment.file_url) {
          let interval = this.getPollingInterval()
          let timeFetching = this.state.timeFetching + interval

          this.setState(() => {
            return {
              timeFetching: timeFetching,
              reloadInterval: setInterval(this.reloadButton, interval)
            }
          })
        } else {
          this.setState({attachment: attachment, loading: false}, () => {
            window.open(attachment.file_url, '_blank');
          })
        }
      })
  }

  render() {
    let { loading, attachment } = this.state
    let text = loading ? 'Processing ...' : `Export ${this.props.qrSelectedTaskLists.length } QR Codes`

    return(
      <div className='d-inline-block pull-right mr-2'>
        { attachment.file_url ? (
          <a className='btn btn-sm btn-outline-secondary' target="_blank" href={attachment.file_url} download>
            Download generated PDF
          </a>
        ) : (
          <button disabled={loading} className='btn btn-sm btn-outline-secondary' onClick={this.exportPdf}>
            <i className='fa fa-qrcode'></i> {text}
          </button>
        )}

      </div>
    )
  }
}
