import React, { Component } from 'react';
import { render } from 'react-dom'
import complianceApi from '../utils/compliance_api'
import ContentLoader from "react-content-loader"

const MyLoader = () => (
  <ContentLoader 
    height={216}
    width={147}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="2" y="3" rx="5" ry="5" width="400" height="400" />
  </ContentLoader>
)

function ComplianceGrid (props) {
  return (
    <div>
      {props.loading ? (
        <div className='row px-3'>
          <div className="col-lg-2 col-md-3 col-sm-4" style={{marginBottom: '29px'}}>
            <MyLoader/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-4" style={{marginBottom: '29px'}}>
            <MyLoader/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-4" style={{marginBottom: '29px'}}>
            <MyLoader/>
          </div>
        </div>
      ) : (
      <div className='row px-3'>
        {props.records.map(function (record, index) {
          var formated_percentage = record.result ? record.result.toFixed(1) : null
          let color = "#000000"
          let result = record.result
          if(result) {
            if(result >= 30 && result < 70) {
              color = "#EF6B42"
            } else if (result >=70 && result < 80) {
              color = "#5882F0"
            } else if (result >=80 && result < 90) {
              color = "#37BC9B"
            } else if (result >=90) {
              color = "#74AD3B"
            }
          }

          let style = {backgroundColor: color, height: '216px'}

          if(record.result) {
            style.cursor = 'pointer'
          }
          
          let show_percentage = '-'
          if(formated_percentage) {
            show_percentage = `${formated_percentage}%`
          }
          
          return (
            <div key={record.name + index}  onClick={() => props.handleNewList(record)} className="col-lg-2 col-md-3 col-sm-4">
              <div style={style} className="card no-border campo__tile">
                <div style={{zIndex: 3}} className="mt-2 tiles-inner text-center">
                  <p className='tile-title' style={{fontSize: '16px'}}>{record.name}</p>
                  <h2 style={{margin: '40px 0'}} className="bolded">{show_percentage}</h2>

                  {(record.total_locations && record.total_locations != 1) &&
                    <small>
                      {record.total_locations} {i18n.t('insight.locations')}
                    </small>
                  }
                </div>
              </div>
            </div>
          )
        })}
      </div>
      )}
    </div>
  )
}

export default class DashboardCompliance extends Component {
  constructor(props) {
    super(props)

    this.state = {
      entityType: props.entityType || 'chain_category',
      entityId: null,
      records: null,
      backEntity: null,
      parentRecords: [],
      loading: true,
      already_loaded: false,
    }

    this.updateComplianceList = this.updateComplianceList.bind(this)
    this.handleNewList = this.handleNewList.bind(this)
    this.updateStateWithNewInformation = this.updateStateWithNewInformation.bind(this)
    this.handleBackButton = this.handleBackButton.bind(this)
  }

  componentDidMount() {
    this.updateComplianceList(this.state.entityType, this.state.entityId)
  }

  handleNewList(record) {
    if(record.result) {
      this.updateStateWithNewInformation(record)
    }
  }

  handleBackButton() {
    if(!this.state.backEntity) {
      return
    }
    var newEntityType = this.state.backEntity.entityType
    var newEntityId = this.state.backEntity.entityId
    var newBackEntity = null

    let parent_records = this.state.parentRecords
    parent_records.pop()

    if (newEntityType == 'chain') {
      newBackEntity = { entityType: 'chain_category', entityId: null}
    }

    this.setState({
        entityId: newEntityId,
        entityType: newEntityType,
        backEntity: newBackEntity,
        loading: true,
        parentRecords: parent_records,
      }, () => {
        this.updateComplianceList(this.state.entityType, this.state.entityId)
    })

  }

  updateStateWithNewInformation(record) {
    var newEntityType = this.state.entityType
    var newEntityId = this.state.entityId
    var newBackEntity = {entityType: this.state.entityType, entityId: this.state.entityId}

    if (record.chain_category_id) {
      newEntityType = 'chain'
      newEntityId = record.chain_category_id
    } else if (record.chain_id) {
      newEntityType = 'location'
      newEntityId = record.chain_id
    } else if (record.location_id) {
      newEntityType = 'submission'
      newEntityId = record.location_id
    }

    if(record.location_id) {
      window.open(`/forms/${this.props.formId}/compliances.json?entity_type=${newEntityType}&entity_id=${newEntityId}`, '_blank')
    } else {
      let parent_records = this.state.parentRecords
      parent_records.push(record)

      this.setState({
          entityId: newEntityId,
          entityType: newEntityType,
          backEntity: newBackEntity,
          loading: true,
          parentRecords: parent_records
        }, () => {
          this.updateComplianceList(this.state.entityType, this.state.entityId)
      })
    }
  }

  updateComplianceList(entityType, entityId) {
    complianceApi.getChainCategoryCompliances(entityType, entityId, this.props.formId).
      then((response) => {
        if (response.submission_path) {
          window.location.href = response.submission_path
        } else {
          let global_compliance = this.state.global_compliance
          if(response.global_value) {
            global_compliance = response.global_value
          }
          this.setState(() => {
            return {
              loading: false,
              records: response.records,
              already_loaded: true,
              global_compliance: global_compliance,
            }
          })
        }
      })
  }

  render() {
    let parent_record = this.state.parentRecords.slice(-1)[0] || {result: this.state.global_compliance, name: 'Global'}
    let formated_percentage = parent_record.result ? parent_record.result.toFixed(1) : null
    let disabled = this.state.backEntity ? '' : ' d-none'

    return (
      <div>
        {!this.state.records 
          ? (
            <div className='row campo__dashboard'>
              <div className='col-md-12'>
                <center>
                  Loading spinnerrr ....
                </center> 
              </div>
            </div>
          ): (
            <div>
              {this.state.records.length > 0 && 
                <div className='row campo__dashboard'>
                  <div className='col-md-12 mb-4'>
                    { (!this.state.loading || this.state.already_loaded)? (
                      <div className="row px-3">
                        <div className='mb-2 col-md-12 back-data'>
                          <div className='back-button'>
                            <button type="button" className={"mr-2 p-4 btn btn-light " + disabled} onClick={this.handleBackButton}>
                              <i className='fa fa-chevron-left'></i> 
                            </button>
                          </div>

                          <div className='parent-data'>
                            <h5 className='mb-0'>{parent_record.name}</h5>
                            <p className='mb-0 text-muted text-uppercase'>
                              {i18n.t('compliance')}
                            </p>
                            <h4 className='mb-0'>{formated_percentage}%</h4>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                      </div>
                    )}
                    <ComplianceGrid records={this.state.records} back-compliance-button 
                      loading={this.state.loading}
                      entityType={this.state.entityType}
                      handleNewList={this.handleNewList}/>
                  </div>
                </div>
              }
            </div>
          )
        }
      </div>
    );
  }
}
