var i18n = new function (getDictionariesFunc) {
  var instance;

  return function () {
    if (!instance) {
      instance = init();
    }
    return instance;
  }();

  function init() {
    var dictionaries = [];
    var currentLan;

    const ERROR_MESSAGES = {
      TRANSLATION_NOT_FOUND: 'Missing translation for {{0}}',
      DICTIONARY_NOT_FOUND: 'There is not dictionary for lan `{0}`'
    };

    const DEFAULT_LANGUAGE = 'en';

    var module = {
      t: function (word, lan) {
        return translate(word, lan);
      },
      loadDictionaries: function (dictionaries) {
        setDictionaries(dictionaries);
      },
      getDictionary: getDictionary,
      setLanguage: setLanguage
    };

    return module;

    function setLanguage(lan) {
      currentLan = lan;
    }

    function setDictionaries(dicts) {
      if (dicts) {
        for (var lan in dicts) {
          var flatDictionary = dicts[lan];

          if (dictionaries[lan] === undefined) {
            dictionaries[lan] = flatDictionary;
          }

          else {
            mergeDictionary(lan, flatDictionary);
          }

        }
      }
    }

    function mergeDictionary(lan, dict) {
      //Jquery or underscorejs?
      $.extend(dictionaries[lan], dict);
    }

    function translationNotFoundMessage(word) {
      var message = 'i18n: ' + constructMessage(ERROR_MESSAGES.TRANSLATION_NOT_FOUND, word);
      console.warn(message);
      return message;
    }

    function translate(word, lan) {
      lan = lan || getCurrentLan();
      var dictionary = getDictionary(lan);
      if (!dictionary) {
        return translationNotFoundMessage(word);
      }

      var translation = searchTranslation(word, dictionary);

      return translation || translationNotFoundMessage(word);
    }

    function getCurrentLan() {
      return currentLan || DEFAULT_LANGUAGE;
    }

    function searchTranslation(key, dictionary) {
      var nodes = key.split('.');

      var trans = nodes.reduce(function (result, element) {
        result.translation = dictionary[element];
        if (!result.translation) {
          result.translation = result.node[element];
        }
        result.node = result.translation;
        return result;
      }, { translation: '', node: {} });

      return trans.translation;
    }

    function getDictionary(lan) {
      var dict = dictionaries[lan];
      dict || console.warn('i18n:' + constructMessage(ERROR_MESSAGES.DICTIONARY_NOT_FOUND, lan));
      return dict;
    }

    function constructMessage(message) {
      var result = message;
      for (var i = 1; i <= arguments.length; i++) {
        var placeHolder = '{0}'.replace(i);
        result = result.replace(placeHolder, arguments[i]);
      }
      return result;
    }
  }
};

var html_element = document.getElementsByTagName('HTML').item(0);
var html_lang_attr = html_element.attributes.lang;

html_lang_attr && i18n.setLanguage(html_lang_attr.value);

i18n.loadDictionaries({
  en: {
    analytics: {
      navs: {
        graph: 'Data Analysis',
        reports: 'Saved Reports'
      },
      graph: {
        title: 'Query Definition'
      },
      no_data_found: 'No data found',
      form: {
        intervals: {
          hourly: 'Hourly',
          daily: 'Daily',
          weekly: 'Weekly',
          monthly: 'Monthly',
          quarter: 'Quarter',
        },
        report_types: {
          locations_where_reported: 'Locations where reported',
        },
        datasource: 'Datasource',
        datasource_placeholder: 'Forms',
        graph: 'Graph',
        graph_placeholder: 'Report types',
        filters: 'Filters',
        and: 'And',
        or: 'Or',
        add_filter: 'Add filter',
        add_group: 'Add group',
        add_filter_group: 'Add filter group',
        interval: 'Interval',
        date_filter_type: 'Date range filter',
        date_range: 'Date range',
        comparators: {
          equals: 'Equals',
          is_not_equal: 'Not Equals',
          equals_number: 'Equals Numerically',
          is_not_equal_number: 'Not Equals Numerically',
          presence: 'Presence',
          match: 'Matches Text',
          not_match: 'Does not match Text',
          greater_than: 'Greater than',
          greater_equal_than: 'Greater or equal than',
          less_than: 'Less than',
          less_equal_than: 'Less or equal than',
          supervised_by: 'Supervised by',
          is: 'Is',
          exception_present: 'Present',
          exception_not_present: 'Not present',
        },
        filter_type: {
          chain_category: 'Chain category',
          chain: 'Chain',
          chains: 'Chains',
          supervisor: 'Supervisor',
          user: 'User',
          location_type: 'Location type',
          zone: 'Zone',
          location: 'Location',
          reporter: 'Reporter',
          key: 'Question',
          city: 'City',
          question: 'Question',
          country: 'Country',
          answer: 'Answer',
          exception_message: 'Exception message',
          exception: 'Exception',
        },
        ph: {
          select_values: 'Select values',
          search_values: 'Search values',
          select_filter_type: 'Select filter type',
          select_key: 'Select key',
          select_condition: 'Select condition',
          select_group: 'Select group',
          select_interval: 'Select interval',
          select_start_date: 'Start date',
          select_end_date: 'End date',
        },
        group_by: 'Group by',
        run_query: 'Run Query',
        save_query: 'Save Report',
        save_run_query: 'Save and run query'
      },
      errors: {
        backend: 'Server error',
      },
   
    },
    users: {
      table: {
        name: 'Name',
        email: 'Email'
      }
    },
    pm: {
      table: {
        case: 'Case #',
        name: 'Name',
        sub_organization: 'Assigned to',
        status: 'Status',
        project: 'Project',
        project_group: 'Group name',
        completed: 'Completed',
        task_name: 'Task',
        project_name: 'Project',
        user: 'Assigned user',
        contact_name: 'Contact name',
        contact_phone_number: 'Contact phone number',
        contact_email: 'Contact email',
        state: "State",
        email: 'Email',
        phone: 'Phone number',
        sub_organization_role: 'Role',
        role: 'Role',
      }
    },
    errors: {
      backend: 'Server error',
    },
    explore: {
      title: 'Submission Explorer',
      summary: 'Summary for date range',
      extra: 'unexpected',
      total_reports: 'submissions',
      total_routes: 'routes completed',
      completed: 'route compliance',
      range_filter_warn: 'You can explore maximun 3 months',
      filters: {
        button: 'Explore',
        download: 'Download',
        form: 'Form',
        form_placeholder: 'Select a form',
        report_type: 'Report type',
        report_type_placeholder: 'Select a report type',
        user: 'User',
        user_placeholder: 'All users',
        date_range: 'Date range',
        date_range_start: 'Start date',
        date_range_end: 'End date',
        calendar_user_placeholder: 'Select a user',
        month_picker: 'Select a month',
        today: 'Today',
        yesterday: 'Yesterday',
        this_week: 'This week',
        past_week: 'Past week',
        this_month: 'This month',
        past_month: 'Past month'
      },
      popover: {
        exceptions_title: 'Has exceptions',
        exceptions_description: 'See report to view exceptions',
        far_title: 'Far from store',
        far_description: 'See report and map',
        see_report: 'View report',
        see_map: 'View Map'
      },
      sday: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        sat: 'Sat',
        sun: 'Sun'
      }
    },
    confirm_delete_message: 'Are you sure you wish to delete this item?',
    scheduled_report: {
      report_types: {
        aging_report: 'Aging report',
        full_report: 'Full Report',
        activity_report: 'Activity Report',
        custom_report: 'Custom Report',
        compliance_answer_report: 'Compliance Answer Report',
        exception_answer_report: 'Alert Report',
        gains_losses_report: 'Gains & Losses Report',
        inventory_report: 'Inventory Report',
        answer_report: 'Answer Report'
      }
    },
    deleted: 'deleted',
    compliance: 'Compliance',
    route_compliance: 'Route Compliance',
    data_set: {
      create: 'Create Data Set',
      form: {
        new_title: 'New data set',
        submit: 'Add filters',
        step1: {
          title: 'Describe your dataset',
          description: 'Give your dataset a name and a description',
          input_name: 'Name',
          input_description: 'Description'
        },
        step2: {
          title: 'Choose a datasource',
          description: 'Select a form',
          input_select_datasource: 'Datasource',
        }
      }
    },
    insight: {
      edit: 'Edit',
      locations: 'locations',
      view: 'View',
      back: ' Back',
      compliance: 'Compliance',
      route_compliance: 'Route Compliance',
      destroy: 'Delete',
      create: 'Add dashboard',
      processing: 'Processing data ...',
      form_data_set: {
        step3: {
          title: 'Choose a chart type for this data set',
          description: 'Select how you want to display data'
        },
        step4: {
          title: 'Configure options',
          description: 'Select best options to match data',
          start_date: 'FDS Start Date Filter',
          end_date: 'FDS End Date Filter',
        },
      },
      reload: {
        message: 'Error loading dashboad data',
        button: 'Reload'
      },
      form: {
        new_title: 'Add dashboard',
        edit_title: 'Edit dashboard',
        submit: 'Save dashboard',
        multiple_select: 'Multiple select',
        one_select: 'Select',
        step1: {
          input_name: 'Name',
          input_description: 'Description',
          title: 'Describe your dashboard',
          description: 'Give your dashboard a name and a description to explain'
        },
        step2: {
          input_select_datasource: 'Datasource',
          title: 'Choose a data source',
          description: 'Select a form or project'
        },
        step3: {
          title: 'Choose a chart type',
          description: 'Select how you want to display data'
        },
        step4: {
          title: 'Configure display',
          description: 'Configure how data is displayed, filtered and grouped',
          input_dashboard_group: 'Dashboard group',
          input_field_group: 'Data to display',
          input_location_type: 'Select location types to include',
          input_zone: 'Select zones to include',
          input_chain: 'Select chains to include',
          input_location: 'Location',
          input_grouping: 'Group by',
          input_summarize: 'Summarize by',
          input_operation: 'Operation',
          input_period: 'Period',
          input_range: 'Range',
          input_field_subgroups: 'Values to include',
          input_supervisors: 'Supervisors',
          input_users: 'Users',
          period_placeholder: 'Select a period',
          location_title: 'Location filters',
          report_title: 'Reporting data',
          date_title: 'Date range filters',
          group_title: 'Group data by',
          users_title: 'User filters',
        },
      }
    },

    location_filter: {
      placeholder: "search locations",
      filter: 'filter',
      header: 'Locations Search Filter'
    },
    search: {
      placeholder: "Search users, locations, chains, etc."
    },

    visit_goal: {
      add_goal: 'Add Goal',
      visits: 'Visits',
      location_name: 'Location Name',
      visit_count: 'Visit Count',
      actions: 'Actions',
      Location: 'Location'
    },

    dashboard_container: {
      dashboard_create_button: 'Create dashboard',
      dashboard_section_header: 'Dashboards'
    },

    table_component: {
      table_result_total: 'Total'
    },

    client_location: {
      dashboards_header: 'Dashboards',
      create_dashboard_button: 'Create dashboard',
      photos_link: 'Photos',
      submissions_link: 'Recent Submissions',
      configure: 'Configure'
    },

    dashboards: {
      dashboard_actions_edit: 'Edit',
      dashboard_actions_delete: 'Delete',
      dashboard_no_dashboards_created: 'No dashboards have been created.'
    },

    dashboard_form: {
      description_button: 'Add description',
      submit_changes_button: 'Save Changes',
      submit_form_button: 'Add Dashboard',
      select_form_header: 'Select dashboard type',
      select_chart_type_header: 'Select your chart type',
      select_field_groups_header: 'Information to display',
      select_field_subgroups_header: 'Values to include',
      select_operation_header: 'Operation',
      tabs_select_period: 'Period',
      tabs_select_location: 'Locations',
      tabs_select_conditions: 'Conditions',
      tabs_select_grouping: 'Grouping',
      placeholder_select_datasource: 'Select datasource',
      placeholder_dashboard_name_input: 'Enter dashboard name',
      placeholder_select_field_group: 'Select',
      placeholder_tabs_select_location: 'Select locations:',
      placeholder_tabs_select_period: 'Select a period',
      placeholder_tabs_condition_select_condition: 'Select field for condition',
      placeholder_tabs_condition_select_comparator: 'Select comparator',
      placeholder_tabs_condition_input_condition: 'Enter value for condition',
      placeholder_tabs_grouping_select_grouping: 'Select group',
      periods_in_the_past: 'in the past',
      placeholder_summarize: 'Select classifier',
      placeholder_dashboard_group: 'Select'
    },
    form_processing: {
      processing: 'Processing data...',
      processing_success: 'Dashboard created successfully',
      processing_failed: 'Error. Could not create dashboard.'
    }
  },
  es: {
    analytics: {
      navs: {
        graph: 'Análisis Datos',
        reports: 'Reportes guardados'
      },
      graph: {
        title: 'Consulta'
      },
      no_data_found: 'Sin data',
      form: {
        intervals: {
          hourly: 'Por hora',
          daily: 'Diario',
          weekly: 'Semanal',
          monthly: 'Mensual',
          quarter: 'Trimestre',
        },
        report_types: {
          locations_where_reported: 'Localidades reportadas',
        },
        datasource: 'Fiente',
        datasource_placeholder: 'Formularios',
        graph: 'Graph',
        graph_placeholder: 'Typos de reporte',
        filters: 'Filtros',
        and: 'Y',
        or: 'Ó',
        add_filter: 'Agregar filtro',
        add_group: 'Agregar grupo',
        add_filter_group: 'Agregar filtro de grupo',
        interval: 'Intervalo',
        date_filter_type: 'Filtro de rango de fechas',
        date_range: 'Rango de fechas',
        comparators: {
          equals: 'Igual',
          is_not_equal: 'No es igual',
          equals_number: 'Igual número',
          is_not_equal_number: 'No es igual a número',
          presence: 'Está presente',
          match: 'Match',
          not_match: 'Does not match',
          greater_than: 'Mayor que',
          greater_equal_than: 'Mayor e igual que',
          less_than: 'Menor que',
          less_equal_than: 'Menor e igual que',
          supervised_by: 'Supervisado por',
          is: 'Es',
          exception_present: 'Tiene excepciones',
          exception_not_present: 'No tiene excepciones',
        },
        filter_type: {
          chain_category: 'Canales',
          chain: 'Cadena',
          chains: 'Cadenas',
          supervisor: 'Supervisor',
          user: 'Usuario',
          location_type: 'Tipo de localidad',
          zone: 'Zona',
          reporter: 'Reportado por',
          key: 'Pregunta',
          city: 'Ciudad',
          question: 'Pregunta',
          country: 'País',
          answer: 'Por respuesta',
          location: 'Localidades',
          exception_message: 'Mensaje de excepción',
          exception: 'Excepción',
        },
        ph: {
          select_values: 'Selecciona valores',
          search_values: 'Busca valores',
          select_filter_type: 'Selecciona typo de filtro',
          select_key: 'Selecciona pregunta',
          select_condition: 'Selecciona condición',
          select_group: 'Selecciona grupo',
          select_interval: 'Selecciona intervalo',
          select_start_date: 'Fecha inicial',
          select_end_date: 'Fecha fin',
        },
        group_by: 'Agrupar por',
        run_query: 'Consultar',
        save_query: 'Guardar consulta',
        save_run_query: 'Guardar y consultar'
      },
      errors: {
        backend: 'Server error',
      }
    },
    explore: {
      title: 'Explorar reportes',
      summary: 'Resumen del rango',
      total_reports: 'reportes',
      total_routes: 'rutas completadas',
      completed: 'cumplimiento de ruta',
      range_filter_warn: 'Puedes seleccionar máximo 3 meses',
      filters: {
        button: 'Explorar',
        download: 'Descargar',
        form: 'Formulario',
        form_placeholder: 'Selecciona formulario',
        report_type: 'Tipo de reporte',
        report_type_placeholder: 'Selecciona reporte',
        user: 'Usuario',
        user_placeholder: 'Todos los usuarios',
        date_range: 'Rango de fechas',
        date_range_start: 'Fecha inicial',
        date_range_end: 'Fecha final',
        calendar_user_placeholder: 'Selecciona un usuario',
        month_picker: 'Selecciona un mes',
        today: 'Hoy',
        yesterday: 'Ayer',
        this_week: 'Esta semana',
        past_week: 'Semana anterior',
        this_month: 'Este mes',
        past_month: 'Mes anterior'
      },
      popover: {
        exceptions_title: 'Tiene excepciones',
        exceptions_description: 'Mira el reporte para ver las excepciones',
        far_title: 'Lejos de la localidad',
        far_description: 'See report and map',
        see_report: 'Ver reporte',
        see_map: 'Ver mapa'
      },
      sday: {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        sat: 'Sáb',
        sun: 'Dom'
      }
    },
    confirm_delete_message: '¿Deseas eliminar este elemento?',
    scheduled_report: {
      report_types: {
        aging_report: 'Informe de últimas visitas',
        full_report: 'Informe completo',
        activity_report: 'Informe de actividad',
        custom_report: 'Reporte personalizado',
        compliance_answer_report: 'Informe de contestaciones de cumplimiento',
        exception_answer_report: 'Informe de alertas',
        gains_losses_report: 'Informe de Ganacias & Pérdidas',
        inventory_report: 'Informe de inventario',
        answer_report: 'Informe de contestaciones'
      }
    },
    deleted: 'borrado',
    compliance: 'Cumplimiento',
    route_compliance: 'Cumplimiento de ruta',
    data_set: {
      create: 'Create Data Set',
      form: {
        new_title: 'New data set',
        submit: 'Add filters',
        step1: {
          title: 'Describe your dataset',
          description: 'Give your dataset a name and a description',
          input_name: 'Name',
          input_description: 'Description'
        },
        step2: {
          title: 'Choose a datasource',
          description: 'Select a form',
          input_select_datasource: 'Datasource',
        }
      }
    },

    insight: {
      locations: 'localidades',
      view: 'Ver',
      back: ' Atras',
      compliance: 'Cumplimiento',
      route_compliance: 'Cumplimiento de Ruta',
      create: 'Crear tablero',
      processing: 'Procesando datos ...',
      form_data_set: {
        step3: {
          title: 'Selecciona tipo de gráfico para set de datos',
          description: 'Seleciona cómo quieres ver los datos'
        },
        step4: {
          title: 'Configura opciones',
          description: 'Seleciona las opciones que mejor se ajustan a lo que quieres ver',
          start_date: 'Filtro fecha de comienzo',
          end_date: 'Filtro fecha de final',
        },
      },
      edit: 'Editar',
      destroy: 'Eliminar',
      reload: {
        message: 'Error cargando el tablero',
        button: 'Cargar'
      },
      form: {
        new_title: 'Nuevo tablero',
        edit_title: 'Editar tablero',
        submit: 'Guardar tablero',
        multiple_select: 'Selección múltiple',
        one_select: 'Selecciona',
        step1: {
          input_name: 'Nombre',
          input_description: 'Descripción',
          title: 'Describe tu tablero',
          description: 'Pon un nombre y una descripción al tablero'
        },
        step2: {
          input_select_datasource: 'Selecciona una fuente',
          title: 'Elige una fuente de datos',
          description: 'Selecciona un formulario o proyecto y la data que se mostrará'
        },
        step3: {
          title: 'Elige un tipo de gráfica',
          description: 'Selecciona como quieres que se muestre los datos'
        },
        step4: {
          title: 'Configura las opciones',
          description: 'Elige la mejor forma de filtrar tus datos',
          input_dashboard_group: 'Pertenece al grupo',
          input_field_group: 'Información a mostrar',
          input_location_type: 'Selecciona tipos de localidades',
          input_zone: 'Selecciona zonas',
          input_chain: 'Selecciona cadenas',
          input_location: 'Lugares',
          input_grouping: 'Agrupar por',
          input_summarize: 'Resumen por',
          input_operation: 'Operación',
          input_period: 'Periodo',
          input_range: 'Rango',
          input_field_subgroups: 'Valores a incluir',
          input_supervisors: 'Supervisores',
          input_users: 'Usuarios',
          period_placeholder: 'Selecciona un periodo',
          location_title: 'Filtros de localidades',
          report_title: 'Reporting data',
          date_title: 'Filtros rango de fechas',
          group_title: 'Agrupar datos por',
          users_title: 'Filtros de usuario',
        },
      }
    },
    location_filter: {
      placeholder: "escribe aquí para buscar",
      filter: 'filtrar',
      header: 'Busqueda Localidades'
    },
    search: {
      placeholder: "Buscar usuarios, localidades, cadenas..."
    },

    visit_goal: {
      add_goal: 'Agregar Meta',
      visits: 'Visitas',
      location_name: 'Comercio',
      visit_count: '# de visitas',
      actions: 'Acciones',
      Location: 'Localidad'
    },

    dashboard_container: {
      dashboard_create_button: 'Crear tablero',
      dashboard_section_header: 'Tableros'
    },

    table_component: {
      table_result_total: 'Total'
    },

    dashboards: {
      dashboard_actions_edit: 'Editar',
      dashboard_actions_delete: 'Borrar',
      dashboard_no_dashboards_created: 'No se ha creado ninugún tablero.'
    },

    client_location: {
      dashboards_header: 'Tableros',
      create_dashboard_button: 'Crear tablero',
      photos_link: 'Fotos',
      submissions_link: 'Reportes Recientes',
      configure: 'Configurar'
    },

    dashboard_form: {
      description_button: 'Añadir descripción',
      submit_changes_button: 'Guardar Cambios',
      submit_form_button: 'Crear Tablero',
      select_form_header: 'Seleccionar tipo de tablero',
      select_chart_type_header: 'Seleccionar tipo de gráfica',
      select_field_groups_header: 'Información a mostrar',
      select_field_subgroups_header: 'Valores a incluir',
      select_operation_header: 'Operador',
      tabs_select_period: 'Periodo de Tiempo',
      tabs_select_location: 'Localidades',
      tabs_select_conditions: 'Condiciones',
      tabs_select_grouping: 'Agrupación de datos',
      placeholder_select_datasource: 'Seleccionar formulario',
      placeholder_dashboard_name_input: 'Entrar nombre para tablero',
      placeholder_select_field_group: 'Seleccionar',
      placeholder_tabs_select_location: 'Seleccionar localidades:',
      placeholder_tabs_select_period: 'Seleccionar periodo de tiempo',
      placeholder_tabs_condition_select_condition: 'Seleccionar campo para condición.',
      placeholder_tabs_condition_select_comparator: 'Seleccionar comparador',
      placeholder_tabs_condition_input_condition: 'Entrar una valor para la condición',
      placeholder_tabs_grouping_select_grouping: 'Seleccionar cómo agrupar datos',
      periods_in_the_past: 'en el pasado',
      placeholder_summarize: 'Seleccionar clasificador',
      placeholder_dashboard_group: 'Selecciona'
    },
    form_processing: {
      processing: 'Procesando data...',
      processing_success: 'Tablero  creado',
      processing_failed: 'Error. No se pudo crear tablero.'
    }
  }

});

if (module) {
  module.exports = i18n
}
