import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_dashboards_api'
import _ from 'lodash'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_children: props.expand_all || true,
      son_aggregations: [],
    }

    this.getNewMap = this.getNewMap.bind(this)
  }

  getNewMap(type) {
    if(this.state.open_children) {
      this.setState({
        open_children: false,
      })
    } else {
      this.setState({
        open_children: true,
      })
    }
  }

  render() {
    let { bucket, headers } = this.props
    let has_son = bucket.buckets.length > 0
    let chevron = this.state.open_children ? "fa fa-chevron-up" : "fa fa-chevron-down"

    let tr_style = {fontSize: `${this.props.font_size}px`, lineHeight: this.props.line_height}
    let td_style = {paddingLeft: `${this.props.padding_left}px`}
    let icon_style = {paddingRight: `${this.props.padding_right}px`}

    let parent_key = bucket.key
    if(this.props.parent_key) {
      parent_key = `${this.props.parent_key}-${bucket.key}`
    }

    return(
      <Fragment>
        <tr style={tr_style}>
          <th style={td_style}>
            { has_son &&
              <a style={{cursor: 'pointer'}} onClick={(e) => this.getNewMap(bucket.key)}>{bucket.label}</a>
            }

            { !has_son &&
              <Fragment>{bucket.label}</Fragment>
            }

            { has_son &&
              <span style={icon_style} onClick={() => this.getNewMap(bucket.key)} className='pull-right text-primary'>
                <i className={chevron}></i>
              </span>
            }
          </th>
          { headers.map((header) => {
            let full_key = `${header.key}-${bucket.key}`
            if(this.props.parent_key) {
              full_key = `${header.key}-${this.props.parent_key}-${bucket.key}`
            }
            let label = bucket.values[full_key] || "-"
            return(
              <td key={full_key} className='text-center'>{label}</td>
            )
          })}
          <th className='text-center bg-light'>
            {bucket.total}
          </th>
        </tr>

        { this.state.open_children && has_son > 0 &&
          <TableRows
            buckets={bucket.buckets}
            headers={headers}
            parent_key={parent_key}
            padding_left={this.props.padding_left + 30}
            padding_right={this.props.padding_right + 20}
            font_size={this.props.font_size - 1}
            line_height={this.props.line_height - 0.2}
          />
        }
      </Fragment>
    )
  }
}

class TableRows extends Component {
  render() {
    let { buckets, headers } = this.props

    let padding_left = this.props.padding_left || 12
    let font_size = this.props.font_size || 16
    let padding_right = this.props.padding_right || 12
    let line_height = this.props.line_height || 1.5
    let parent_key = this.props.parent_key || ""

    return(
      <Fragment>
        { buckets.map((bucket) => {
          return(
            <Row
              key={bucket.key}
              padding_left={padding_left}
              padding_right={padding_right}
              font_size={font_size}
              line_height={line_height}
              bucket={bucket}
              headers={headers}
              parent_key={parent_key}
              expand_all={this.props.expand_all}
            />
          )
        })}
      </Fragment>
    )
  }
}



export default class PivotTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      table: {}
    }

    this.fetchData = this.fetchData.bind(this)
  }

  fetchData() {
    let { resource_id, resource_type } = this.props
    api.getDashboardData(resource_id).then((data) => {
      this.setState({loading: false, table: data.data})
    })
  }

  componentWillMount() {
    this.fetchData()
  }

  render() {
    let { table, loading } = this.state
    let { config } = this.props
    let { headers, buckets } = table
    let total = 0
    if(!loading) {
      buckets.forEach((b) => total = total + b.total )
    }

    let expand_all = this.props.expand_all || true
    let tr_style = {fontSize: `${this.props.font_size}px`, lineHeight: this.props.line_height}

    return(
      <div>
        <div className='row align-items-end mb-2'>
          <div className='col'>
          </div>
          <div className='col-6'>
            <div className='text-center'>
              <h5 className='mb-0'>
                {config.name}
              </h5>
              <small className='text-muted'>
                {config.description}
              </small>
            </div>
          </div>
          <div className='col'>
          </div>
        </div>

        { !loading && 
          <div className='row'>
            <div className='col-md-12'>
              <div className='table-responsive mb-4'>
                <table className="table mb-0 table-bordered">
                  <thead>
                    <tr style={tr_style}>
                      <th className='text-center'></th>
                      { headers.map((header) => {
                        return(<th className='text-center' key={header.key}>{header.label}</th>)
                      })}
                      <th className='text-center bg-light'>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    <TableRows
                      headers={headers}
                      buckets={buckets}
                      font_size={this.props.font_size}
                      expand_all={expand_all}
                    />
                  </tbody>

                  <tfoot>
                    <tr className='bg-light' style={tr_style}>
                      <th className='text-left'>Total</th>
                      { headers.map((header) => {
                        return(<th className='text-center' key={header.key}>{header.total}</th>)
                      })}
                      <th className='text-center'>{total}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
