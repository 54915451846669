import React, { Component, Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import TaskListForm from './TaskListForm'

import ReactPaginate from 'react-paginate'
import ShowMore from '../../utils/ShowMore'
import TaskListsTable from './TaskListsTable'
import GenerateMultiplePdf from './GenerateMultiplePdf'

import { useTaskListsHook } from './hooks_api'

const TaskListsIndex = (props) => {
  const [selectedTaskList, setSelectedTaskList] = useState({})
  const [filters, setFilters] = useState({per_page: 5, page: 1, status: 'all'})
  const [qrSelectedTaskLists, setQrSelectedTaskLists] = useState([])
  const { isLoading: isLoadingTaskLists, data: taskListsData } = useTaskListsHook(filters)

  useEffect(() => {
    setQrSelectedTaskLists([])
  }, [filters])

  
  const handlePerPage = (e) => {
    setFilters({...filters, per_page: e.target.value, page: 1})
  }

  const handlePagination = (e) => {
    let page = (e.selected + 1)
    setFilters({...filters, page: page})
  }

  let taskLists = taskListsData ? taskListsData.data.map(e => e.attributes) : []
  let total_pages = taskListsData ? taskListsData.meta.total_pages : 0

  return(
    <div className="app pb-5">
      <div className="container-fluid py-3">
        <div className='row'>
          <div className='col-12 p-4 bg-white'>
            <div className='d-inline-block mt-2'>
              <div
                className='d-inline-block mr-3 text-muted cursor-pointer'
                style={{fontWeight: filters.status == 'all' ? 700 : 400}}
                onClick={() => setFilters({...filters, status: 'all'})}>
                All
              </div>
              <div
                className='d-inline-block mr-3 text-muted cursor-pointer'
                style={{fontWeight: filters.status == 'pending' ? 700 : 400}}
                onClick={() => setFilters({...filters, status: 'pending'})}>
                Pending
              </div>

              <div
                className='d-inline-block mr-3 text-muted cursor-pointer'
                style={{fontWeight: filters.status == 'completed' ? 700 : 400}}
                onClick={() => setFilters({...filters, status: 'completed'})}>
                Completed
              </div>
            </div>

            <button
              className='btn btn-primary pull-right btn-sm'
              onClick={() => setSelectedTaskList({id: 0})}>
              New Task List
            </button>

            { qrSelectedTaskLists.length > 1 &&
              <GenerateMultiplePdf
                qrSelectedTaskLists={qrSelectedTaskLists}
              />
            }
          </div>
        </div>

        <div className='row'>
          <div className='col-12 px-4 bg-white'>
            <TaskListsTable 
              taskLists={taskLists}
              setSelectedTaskList={setSelectedTaskList}
              setQrSelectedTaskLists={setQrSelectedTaskLists}
              qrSelectedTaskLists={qrSelectedTaskLists}
            /> 
          </div>
        </div>
        <div className="row px-4 pb-4 justify-content-between bg-white">
          <div className="col-auto">
            <ShowMore
              selected={filters.per_page}
              options={[5,10,25,50,100]}
              onSelect={handlePerPage}
            />                                                          
          </div>                                                        
                                                                        
          <div className="col-auto">                                    
            <ReactPaginate previousLabel={"‹"}                          
              nextLabel={"›"}                                           
              breakLabel={<a href="">...</a>}                           
              breakClassName={"break-me"}                               
              pageCount={total_pages}                        
              marginPagesDisplayed={2}                                  
              pageRangeDisplayed={5}                                    
              onPageChange={handlePagination}                
              containerClassName={"pagination pagination-sm pagination-campo m-0"}
              pageClassName={"page-item"}                               
              pageLinkClassName={"page-link cursor-pointer"}            
              activeClassName={"active"} />                             
          </div>
        </div>

        { (selectedTaskList.id || selectedTaskList.id == 0) && 
          <div className='row'>
            <div className='col-12 p-4 bg-white'>
              <TaskListForm 
                selectedTaskList={selectedTaskList}
                onClose={() => setSelectedTaskList({})}
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default TaskListsIndex

