import React, { Component } from 'react'
import { render } from 'react-dom'

import RegisterModal from '../components/EmailSchedule/RegisterModal'
import { LocalizeProvider } from "react-localize-redux"
import EmailScheduleApi from '../utils/email_schedule_api'

export default class EditEmailSchedule extends Component {
  constructor(props) {
    super(props)
    let schedule = Object.assign({}, props.scheduled_report)
    schedule.start_at = new Date(schedule.start_at)
    this.state = {
      is_open: false,
      isFetching: false,
      schedule: schedule,
    }

    this.openSchedule = this.openSchedule.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleSave() {
    this.setState({isFetching: true})
    let data = {scheduled_report: this.state.schedule}
    EmailScheduleApi.update(this.state.schedule.id, data).then((response) => {
      this.setState({is_open: false, isFetching: false})
      window.location = "/forms/"+this.props.form.id+"/reports/submissions?scheduled_report=true"
    }).catch((err) => {
      this.setState({other_errors: err.data.errors})
    })
  }

  openSchedule() {
    let url = '/scheduled_reports/datasources.json'
    EmailScheduleApi.getDatasources(url).then((response) => {
      this.setState({is_open: true, datasources: response.datasources})
    });
  }

  closeModal() {
    let schedule = Object.assign({}, this.props.scheduled_report)
    schedule.start_at = new Date(schedule.start_at)
    this.setState({schedule: schedule, is_open: false})
  }

  handleInputChange(key, value) {
    let schedule = this.state.schedule
    schedule[key] = value
    this.setState({schedule: schedule})
  }

  render() {
    return(
      <LocalizeProvider>
        <div className='edit-scheduled-report'>
          <a onClick={this.openSchedule} className='edit-sr btn btn-info btn-sm'>
            <i className='fa fa-pencil'></i>
          </a>

          <RegisterModal
            is_open={this.state.is_open} 
            closeModal={this.closeModal}
            handleInputChange={this.handleInputChange}
            schedule={this.state.schedule}
            datasources={this.state.datasources}
            handleSave={this.handleSave}
            isFetching={this.state.isFetching}
            other_errors={this.state.other_errors}
            language={this.props.language}
            form={this.props.form}
          />
        </div>
      </LocalizeProvider>
    )
  }
}
