import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

export default class GeneralTable extends Component {
  render() {
    let {aggregations, filters} = this.props
    let headers = []
    let values = []
    let keys = []
    aggregations.forEach((bucket) => {
      headers.push(bucket.name)
      values.push(bucket.value)
      keys.push(bucket.key)
    })
    
    let show_table = true

    //filters.groups.forEach((group) => {
    //  if(['ca', 'cb', 'cc', 'cd', 'key'].includes(group.group.value) && filters.report_type.value != 'locations_reported') {
    //    show_table = true
    //  }
    //})

    return(
      <Fragment>
        { show_table ? (
          <div className='table-responsive mb-4'>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  { headers.map((header) => {
                    return(<th key={header} className='text-center'>{header}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  { values.map((value, index) => {
                    return(<td key={keys[index]} className='text-center'>{value}</td>)
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Fragment></Fragment>
        )}
      </Fragment>
    )
  }
}

 
