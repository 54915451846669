import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

const getTickets = (filters, sort) => {
  let url = `/web_api/tickets`

  return axios.get(url, { params: { filters, sort} }).then((response) => response.data)
}

const createTicket = (ticket) => {
  let url = `/web_api/tickets`

  return axios.post(url, { ticket }).then((response) => response.data)
}

const updateTicket = (ticket) => {
  let url = `/web_api/tickets/${ticket.id}`

  return axios.put(url, { ticket }).then((response) => response.data)
}

module.exports = {
  getTickets,
  createTicket,
  updateTicket,
}
