import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import FilterGroup from './query_form/FilterGroup'
import Group from './query_form/Group'
import {selectStyles} from '../../support/helpers'

import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment from 'moment'
import { toast } from 'react-toastify';

import { AndOrRadio } from './query_form/common_components'

const intervals = [
  {value: 'hour', label: i18n.t('analytics.form.intervals.hourly')},
  {value: 'day', label: i18n.t('analytics.form.intervals.daily')},
  {value: 'week', label: i18n.t('analytics.form.intervals.weekly')},
  {value: 'month', label: i18n.t('analytics.form.intervals.monthly')},
  {value: 'quarter', label: i18n.t('analytics.form.intervals.quarter')}
]

export default class QueryForm extends Component {
  constructor(props) {
    super(props)
    if(props.comes_from_report) {
      let query = props.query
      let start_date = query.start_date ? moment(query.start_date) : null
      let end_date = query.end_date ? moment(query.end_date) : null
      this.state = {
        form: query.form || null,
        report_type: query.report_type || null,
        filter_groups: query.filter_groups || [],
        filter_groups_query_type: query.filter_groups_query_type || 'and',
        groups: query.groups || [],
        start_date: start_date,
        end_date: end_date,
        interval: query.interval || null,
        focusedInput: null,
        show_validations: false,
        date_filter_type: query.date_filter_type || 'fixed',
        relative_filter: query.relative_filter || 7,
      }

    } else {
      this.state = {
        form: null,
        report_type: null,
        filter_groups: [],
        filter_groups_query_type: 'and',
        groups: [],
        start_date: null,
        end_date: null,
        interval: {value: 'month', label: 'Monthly'},
        focusedInput: null,
        show_validations: false,
        date_filter_type: 'none',
        relative_filter: 7,
      }
    }


    this.selectForm = this.selectForm.bind(this)
    this.selectInterval = this.selectInterval.bind(this)
    this.selectReportType = this.selectReportType.bind(this)

    this.addNewGroup = this.addNewGroup.bind(this)
    this.updateGroup = this.updateGroup.bind(this)
    this.removeGroup = this.removeGroup.bind(this)

    this.addNewFilterGroup = this.addNewFilterGroup.bind(this)
    this.updateFilterGroup = this.updateFilterGroup.bind(this)
    this.removeFilterGroup = this.removeFilterGroup.bind(this)

    this.selectDateRange = this.selectDateRange.bind(this)
    this.handleFilterGroupQueryType = this.handleFilterGroupQueryType.bind(this)

    this.runQuery = this.runQuery.bind(this)
    this.saveQuery = this.saveQuery.bind(this)
    this.saveQueryAndRun = this.saveQueryAndRun.bind(this)

    this.isValid = this.isValid.bind(this)

    this.selectDateFilterType = this.selectDateFilterType.bind(this)
    this.selectRelativeFilter = this.selectRelativeFilter.bind(this)
  }

  handleFilterGroupQueryType(type) {
    this.setState({filter_groups_query_type: type})
  }

  isValid() {
    let valid = true
    this.state.filter_groups.forEach((filter_group) => {
      valid = valid && filter_group.valid
    })
    this.state.groups.forEach((group) => {
      valid = valid && group.valid
    })

    return valid
  }

  runQuery() {
    let valid = this.isValid()
    if(valid) {
      this.setState({show_validations: false}, () => {
        this.props.runQuery({...this.state})
      })
    } else {
      toast.error("Please fill in all missing or invalid fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 7000
      })
      this.setState({show_validations: true})
    }
  }

  saveQuery() {
    let valid = this.isValid()
    this.props.saveQuery({...this.state})
  }

  saveQueryAndRun() {
    let valid = this.isValid()
    this.props.saveQueryAndRun({...this.state})
  }

  selectDateFilterType(e) {
    this.setState({date_filter_type: e.target.value})
  }

  selectRelativeFilter(e) {
    this.setState({relative_filter: e.value})
  }

  selectForm(ele) {
    this.setState({form: ele, report_type: null})
  }

  selectInterval(ele) {
    this.setState({interval: ele})
  }

  selectReportType(ele) {
    this.setState({report_type: ele})
  }

  addNewGroup() {
    let groups = this.state.groups
    groups.push({
      uuid: Math.random().toString(36).substring(2),
      group: null,
      valid: false
    })
    this.setState({groups: groups})
  }

  updateGroup(uuid, group) {
    let groups = this.state.groups
    let index = groups.findIndex((group) => group.uuid == uuid)
    let valid = !!group.group
    group.valid = valid
    groups[index] = group
    this.setState({groups})
  }

  removeGroup(uuid) {
    let groups = this.state.groups.filter((item) => item.uuid != uuid)
    this.setState({groups})
  }

  addNewFilterGroup() {
    let filter_groups = this.state.filter_groups
    filter_groups.push({
      query_type: 'and',
      uuid: Math.random().toString(36).substring(2),
      valid: false,
      filters: [
        {uuid: Math.random().toString(36).substring(2), valid: false}
      ],
    })
    this.setState({filter_groups: filter_groups})
  }

  updateFilterGroup(uuid, filter_group) {
    if(filter_group.filters.length == 0) {
      this.removeFilterGroup(filter_group.uuid)
    } else {
      let filter_groups = this.state.filter_groups
      let index = filter_groups.findIndex((filter_group) => filter_group.uuid == uuid)
      let valid = filter_group.filters.length > 0
      filter_group.filters.forEach((filter) => {
        valid = valid && filter.valid
      })
      filter_group.valid = valid
      filter_groups[index] = filter_group
      this.setState({filter_groups})
    }
  }

  removeFilterGroup(uuid) {
    let filter_groups = this.state.filter_groups.filter((item) => item.uuid != uuid)
    this.setState({filter_groups})
  }

  selectDateRange(data) {
    let start_date = data.startDate
    let end_date = data.endDate
    this.setState({start_date, end_date})
  }

  filteredDatasources(form, datasources) {
    if(form) {
      let new_datasources = Object.assign({}, datasources)
      let form_id = form.value
      let ca = new_datasources.ca.filter((item) => item.form_id == form_id )
      let cb = new_datasources.cb.filter((item) => item.form_id == form_id )
      let cc = new_datasources.cc.filter((item) => item.form_id == form_id )
      let cd = new_datasources.cd.filter((item) => item.form_id == form_id )
      let keys = new_datasources.keys.filter((item) => item.form_id == form_id )
      let report_types = []
      new_datasources.ca = ca
      new_datasources.cb = cb
      new_datasources.cc = cc
      new_datasources.cd = cd
      new_datasources.keys = keys
      datasources.report_types.forEach((report_type) => {
        if(report_type.value == 'locations_reported' && form.capture_location) {
          report_types.push(report_type)
        }

        if(['compliance', 'compliance_deficit', 'compliance_location_deficit'].includes(report_type.value) && form.process_compliance) {
          report_types.push(report_type)
        } 
        
        if(!['compliance', 'compliance_deficit', 'compliance_location_deficit', 'locations_reported'].includes(report_type.value)){
          report_types.push(report_type)
        }
      })

      new_datasources.report_types = report_types
      return new_datasources
    } else {
      return datasources
    }
  }

  addFilterButtonName() {
    if (this.state.filter_groups.length > 0) {
      return i18n.t('analytics.form.add_filter_group')
    } else {
      return i18n.t('analytics.form.add_filter')
    }
  }

  render() {
    let datasources = this.filteredDatasources(this.state.form, this.props.datasources)
    let { auth } = this.props

    let relative_filters = []
    let relative_filter = null
    if(this.state.interval) {
      let value = this.state.interval.value
      for (var i = 1; i < 13; i++) {
        let input = {value: i, label: `Last ${i} ${value}s`}
        if(i == 1) {
          input = {value: i, label: `Last ${i} ${value}`}
        }
        relative_filters.push(input)
        if(this.state.relative_filter == i) {
          relative_filter = input
        }
      }
    }

    return(
      <div>
        { !this.props.loading &&
          <Fragment>
            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  <label className="form-group--campo_label col-form-label text-uppercase">
                    {i18n.t('analytics.form.datasource')}
                  </label>
                  <Select
                    styles={selectStyles}
                    value={this.state.form}
                    placeholder={i18n.t('analytics.form.datasource_placeholder')}
                    onChange={this.selectForm}
                    options={datasources.forms}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className="form-group">
                  <label className="form-group--campo_label col-form-label text-uppercase">
                    {i18n.t('analytics.form.graph')}
                  </label>
                  <Select
                    styles={selectStyles}
                    value={this.state.report_type}
                    placeholder={i18n.t('analytics.form.graph_placeholder')}
                    onChange={this.selectReportType}
                    options={datasources.report_types}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className="form-group">
                  <label className="form-group--campo_label col-form-label text-uppercase">
                    {i18n.t('analytics.form.filters')}
                  </label>

                  <div className='filter-groups'>
                    { this.state.filter_groups.map((filter_group, index) => {
                      return(
                        <FilterGroup 
                          query_type={this.state.filter_groups_query_type}
                          uuid={filter_group.uuid}
                          form={this.state.form}
                          filter_group={filter_group}
                          updateFilterGroup={this.updateFilterGroup}
                          removeFilterGroup={this.removeFilterGroup}
                          key={filter_group.uuid}
                          datasources={datasources}
                          position={index}
                          show_validations={this.state.show_validations}
                        />)
                     })}
                   </div>

                   <div className="row">
                     <div className="col-sm-9">
                       <div className="ml-3 form-campo__condition-action-button">
                         <button type="button" onClick={this.addNewFilterGroup} className="btn btn-light">
                           <i className='fa fa-plus-circle'></i> {this.addFilterButtonName()}
                         </button>
                       </div>
                     </div>

                     { this.state.filter_groups.length > 1 &&
                       <div className="col-sm-3">
                         <div className="mt-2">
                           <AndOrRadio
                             selected={this.state.filter_groups_query_type}
                             onChange={this.handleFilterGroupQueryType}
                             uuid={'query-uuid'}
                           />
                         </div>
                       </div>
                     }
                   </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className="form-group">
                  <label className="form-group--campo_label col-form-label text-uppercase">
                    {i18n.t('analytics.form.group_by')}
                  </label>

                  <div className='filter-groups'>
                    <div className="form-row ml-2">
                      { this.state.groups.map((group, index) => {
                        return(
                          <Group 
                            form={this.state.form}
                            group={group}
                            updateGroup={this.updateGroup}
                            removeGroup={this.removeGroup}
                            key={group.uuid}
                            datasources={datasources}
                            position={index}
                            show_validations={this.state.show_validations}
                          />)
                        })
                      }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-9">
                      <div className="form-row">
                        <div className="ml-3 form-campo__condition-action-button">
                          <button type="button" onClick={this.addNewGroup} className="btn btn-light">
                            <i className='fa fa-plus-circle'></i> {i18n.t('analytics.form.add_group')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            
            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  <label className="form-group--campo_label col-form-label text-uppercase">
                    {i18n.t('analytics.form.date_filter_type')}
                  </label>

                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dft"
                        value="none"
                        checked={this.state.date_filter_type === 'none'} 
                        onChange={this.selectDateFilterType}
                      />
                      <label className="form-check-label">None</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dft"
                        value="relative"
                        checked={this.state.date_filter_type === 'relative'} 
                        onChange={this.selectDateFilterType}
                      />
                      <label className="form-check-label">Relative</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dft"
                        value="fixed"
                        checked={this.state.date_filter_type === 'fixed'} 
                        onChange={this.selectDateFilterType}
                      />
                      <label className="form-check-label">Fixed</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            { this.state.date_filter_type != 'none' &&
              <Fragment>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className="form-group">
                      <label className="form-group--campo_label col-form-label text-uppercase">
                        {i18n.t('analytics.form.interval')}
                      </label>
                      <Select
                        styles={selectStyles}
                        value={this.state.interval}
                        placeholder={i18n.t('analytics.form.ph.select_interval')}
                        onChange={this.selectInterval}
                        options={intervals}
                        isClearable={true}
                      />
                    </div>
                  </div>

                  { this.state.date_filter_type == 'relative' && this.state.interval &&
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="form-group--campo_label col-form-label text-uppercase">
                          {i18n.t('analytics.form.date_range')}
                        </label>
                        <Select
                          styles={selectStyles}
                          value={relative_filter}
                          onChange={this.selectRelativeFilter}
                          options={relative_filters}
                        />
                      </div>
                    </div>
                  }

                  { this.state.date_filter_type == 'fixed' &&
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="form-group--campo_label col-form-label text-uppercase">
                          {i18n.t('analytics.form.date_range')}
                        </label>

                        <div className='filter-groups'>
                          <div className="form-row pl-1">
                            <DateRangePicker
                              verticalHeight={568}
                              hideKeyboardShortcutsPanel
                              startDate={this.state.start_date}
                              startDateId="start_date_id"
                              startDatePlaceholderText={i18n.t('analytics.form.ph.select_start_date')}
                              endDate={this.state.end_date}
                              endDateId="end_date_id"
                              endDatePlaceholderText={i18n.t('analytics.form.ph.select_end_date')}
                              onDatesChange={this.selectDateRange}
                              focusedInput={this.state.focusedInput}
                              isOutsideRange={day => (moment().diff(day) < 0)}
                              onFocusChange={focusedInput => this.setState({ focusedInput })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </Fragment>
            }

            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  { this.props.comes_from_report ? (
                    <Fragment>
                      { this.state.form && this.state.report_type &&
                        <button href="#" onClick={this.saveQueryAndRun} className="text-white ml-2 text-uppercase form-campo__button border-0 py-2 px-4 rounded">
                          {i18n.t('analytics.form.save_run_query')}
                        </button>
                      }
                    </Fragment>
                  ) : (
                    <Fragment>
                      { this.state.form && this.state.report_type &&
                        <Fragment>
                          <button onClick={this.runQuery} className="text-white text-uppercase form-campo__button border-0 py-2 px-4 rounded">
                            {i18n.t('analytics.form.run_query')}
                          </button>

                          { auth.can_create &&
                            <button href="#" onClick={this.saveQuery} className="ml-2 text-uppercase btn btn-link border-0 py-2 px-4 rounded">
                              {i18n.t('analytics.form.save_query')}
                            </button>
                          }
                        </Fragment>
                      }
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        }
      </div>
    )
  }
}
