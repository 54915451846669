import React, { Component } from 'react';
import HighchartsDrilldown from 'highcharts/modules/drilldown'
import ReactHighcharts from 'react-highcharts';
import PropTypes from 'prop-types'

class BaseInsightComponent extends Component {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.renderReload = this.renderReload.bind(this);
    this.renderReloadWithTitle = this.renderReloadWithTitle.bind(this);
  
    if(this.props.hasDrilldown && !ReactHighcharts.Highcharts.Chart.prototype.addSeriesAsDrilldown) {
      HighchartsDrilldown(ReactHighcharts.Highcharts)
    }
  }

  componentDidMount() {
    if ('chart' in this.refs) {
      this.refs.chart.getChart().reflow();
    }
  }

  renderReloadWithTitle() {
    return(
      <div>
        <p className='dashboard-label'>{this.props.name}</p>
        <p className='dashboard-description'>{this.props.description}</p>
        {this.renderReload()}
      </div>
    )
  }

  renderReload() {
    return(
      <div className='reload-container'>
        <div className='reload-message'>
          {i18n.t('insight.reload.message')}
        </div>

        <button onClick={this.props.handleReload} className="btn btn-default btn-large">
          {i18n.t('insight.reload.button')}
        </button>
      </div>
    )
  }

  renderContent() {
    if (this.isLoading() && !this.props.showReload) {
      return (
        <div>
          <p className='dashboard-label'>{this.props.name}</p>
          <p className='dashboard-description'>{this.props.description}</p>
          <div className='spinner-wrap'>
            Loading ...
          </div>
        </div>
        );
    } else if (this.props.showReload) {
      return(this.renderReloadWithTitle())
    } else{
      return <ReactHighcharts ref='chart' config={this.chartConfig()}/>;
    }
  }

  isLoading(){
    return !(this.props.data) || this.props.isProcessing;
  }

  chartConfig(){
    return {
      chart: {
        type: 'line'
      },
      title: {
        text: this.props.name
      },
      subtitle: {
        text: this.props.description
      },
      xAxis: {
        categories: this.props.data.labels,
      },
      yAxis: {
        min: 0,
        title: {
          text: this.props.data.yText
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        useHTML: true,
        shared: true
      },
      series: this.props.data.series,
    };
  }
  render() {
    return (
      <div className='the-box2 border border-gray mb-4 dashboard2 chart-insight'>
        {this.renderContent()}
      </div>
    );
  }
}

BaseInsightComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  yText: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number)
  }))
}

BaseInsightComponent.defaultProps = {
  title: '',
  subtitle: '',
  labels: [],
  yText: '',
  series: []
}

export default BaseInsightComponent;
