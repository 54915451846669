import React, { Component } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'

import FilterModal from './FilterModal'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "./translations/global.json"
import ImagesApi from '../../utils/images_api'
import Select from 'react-select'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: 'normal',
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    textOverflow: 'ellipsis',
    maxWidth: "90%",
    whiteSpace: "nowrap",
    fontSize: '16px',
    fontWeight: 'normal',
    overflow: "hidden",
  }),
  container: (styles, state) => ({
    ...styles,
    zIndex: "2"
  })
}

const keyMap = {
  search: 'search',
  form: 'form',
  users: 'user',
  supervisors: 'supervisor',
  locations: 'location',
  chain_categories: 'chain_category',
  chains: 'chain',
  location_types: 'location_type',
  zones: 'zone',
  ca: 'form_field_classifier_a',
  cb: 'form_field_classifier_b',
  cc: 'form_field_classifier_c',
  cd: 'form_field_classifier_d',
  start_date: 'start_date',
  end_date: 'end_date',
  keys: 'key',
  countries: 'country'
}

class ImagesFilters extends Component {
  constructor(props) {
    super(props)
    let fast_filter_type = props.search ? 'search' : 'predefined'
    this.state = {
      search_modal_open: false,
      fast_filter_type: fast_filter_type,
      filters: {
        search: props.search || '',
        locations: [],
        chains: [],
        chain_categories: [],
        location_types: [],
        zones: [],
        users: [],
        supervisors: [],
        countries: [],
        ca: [],
        cb: [],
        cc: [],
        cd: [],
        form: {},
        keys: [],
        start_date: null,
        end_date: null,
      },
      disabled: {
        locations: false,
        countries: false,
        chain_categories: false,
        chains: false,
        users: false,
        supervisors: false,
        zones: false,
        location_types: false,
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    });

    this.openSearchModal = this.openSearchModal.bind(this)
    this.closeSearchModal = this.closeSearchModal.bind(this)

    this.handleSearch = this.handleSearch.bind(this)
    this.handleState = this.handleState.bind(this)
    this.selectForm = this.selectForm.bind(this)
    this.selectKeys = this.selectKeys.bind(this)
    this.parseSubmitData = this.parseSubmitData.bind(this)
    this.handleFastFilterType = this.handleFastFilterType.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
  }

  handleSearchInput(e) {
    let filters = this.state.filters
    filters.search = e.target.value
    this.setState({filters})
  }

  handleFastFilterType(e) {
    let filters = this.state.filters
    filters.keys = []
    filters.search = ''
    this.setState({
      fast_filter_type: e.target.value,
      filters
    }, () => {
      this.props.handleAdvanceFilters(this.parseSubmitData(filters))
    })
  }

  handleSearch(filters) {
    this.closeSearchModal()
    this.props.handleAdvanceFilters(filters)
  }

  handleState(new_state) {
    this.setState(new_state)
  }

  openSearchModal() {
    this.setState({search_modal_open: true})
  }

  closeSearchModal() {
    this.setState({search_modal_open: false})
  }

  parseSubmitData(filters) {
    let keys = Object.keys(this.state.filters)
    let new_filters = {}

    keys.forEach((key, index) => {
      let data = filters[key]
      if(key == 'form') {
        if(data.value) { new_filters[keyMap[key]] = data.value }
      } else if (key == 'search') {
        new_filters[keyMap[key]] = data
      } else if (key == 'start_date') {
        if(data) { new_filters[keyMap[key]] = data}
      } else if (key == 'end_date') {
        if(data) { new_filters[keyMap[key]] = data}
      } else {
        if(data.length > 0) {
          new_filters[keyMap[key]] = []
          data.forEach((datum) => {
            new_filters[keyMap[key]].push(datum.value)
          })
        }
      }
    })
    return new_filters
  }

  selectForm(selected) {
    let data = selected ? selected : {}
    let filters = this.state.filters
    filters.form = data
    filters.ca = []
    filters.cb = []
    filters.cc = []
    filters.cd = []
    filters.keys = []
    this.setState({filters: filters, keys: []},() => {
      if(!data.value) {
        this.props.handleAdvanceFilters(this.parseSubmitData(filters))
      } else {
        ImagesApi.getFormKeys(data.value).then((response) => {
          let keys = response.keys.map((record) => ({value: record.slug, label: record.full_name_count, form_id: record.form_id}))
          this.setState({keys: keys}, () => {
            this.props.handleAdvanceFilters(this.parseSubmitData(filters))
          })
        })
      }
    })
  }

  selectKeys(data) {
    let filters = this.state.filters
    filters.keys = data
    this.setState({filters: filters},() => {
      this.props.handleAdvanceFilters(this.parseSubmitData(filters))
    })
  }

  componentWillMount() {
    ImagesApi.getSimpleDatasources().then((response) => {
      let forms = response.forms.map((record) => ({value: record.id, label: record.full_name_count}))
      this.setState({
        forms: forms,
      })
    })
  }

  render() {
    let active_banned_class = this.props.hide_banned ? 'text-primary' : ''
    let active_favorite_class = this.props.only_favorites ? 'text-primary' : ''

    let form_id = this.state.filters.form.value
    let form_value = form_id ? this.state.filters.form : null
    let keys = this.state.keys

    return(
      <div>
        { this.props.activeLanguage &&
        <div>
          <section className='images-filters bg-white'>
            <div className="container-fluid border-top">
              <div className="container-fluid p-0 pl-sm-3 pr-sm-3">
                { this.state.fast_filter_type == 'search' ? (
                  <div className="row pt-3">
                    <div className='col-sm-4'>
                      <div className='fast-filter'>
                        {
                          <div style={{width: '100%', display: 'inline-block'}}>
                            <Select
                              styles={selectStyles}
                              value={form_value}
                              placeholder={(<Translate id="advance_search.form.form.placeholder" />)}
                              onChange={this.selectForm}
                              options={this.state.forms}
                              isDisabled={!(this.state.forms)}
                              isClearable={true}
                            />
                          </div>
                        } 
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <div className="d-flex flex-row">
                        <div className="fast-filter flex-fill">
                          <input autoFocus className='form-control' value={this.state.filters.search} onChange={this.handleSearchInput}
                            onKeyPress={(event) => {
                                          if (event.key === 'Enter') {
                                            this.handleSearch(this.parseSubmitData(this.state.filters))
                                          }
                                        }}
                          />
                        </div>
                        <button className='align-self-start btn btn-primary ml-2' onClick={(e) => this.handleSearch(this.parseSubmitData(this.state.filters))}>
                          <Translate id="advance_search.search" />
                        </button>
                      </div>
                    </div>
                  </div>
                ): (
                  <div className="row pt-3">
                    <div className='col-sm-4'>
                      <div className='fast-filter'>
                        {
                          <div style={{width: '100%', display: 'inline-block'}}>
                            <Select
                              styles={selectStyles}
                              value={form_value}
                              placeholder={(<Translate id="advance_search.form.form.placeholder" />)}
                              onChange={this.selectForm}
                              options={this.state.forms}
                              isDisabled={!(this.state.forms)}
                              isClearable={true}
                            />
                          </div>
                        } 
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <div className="d-flex flex-row">
                        <div className="fast-filter flex-fill">
                          {
                            <div style={{width: '100%', display: 'inline-block'}}>
                              <Select
                                styles={selectStyles}
                                value={this.state.filters.keys}
                                placeholder={(<Translate id="advance_search.form.keys.placeholder" />)}
                                isMulti={true}
                                isDisabled={!(this.state.keys && form_id && this.state.keys.length > 0)}
                                onChange={this.selectKeys}
                                options={keys}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className='col-sm-4'>
                    <div className='d-flex flex-row justify-content-start'>
                      <div className='btn-group btn-sm btn-group-toggle py-2'>
                        <label className={'btn btn-outline-secondary btn-sm ' + (this.state.fast_filter_type == 'predefined' ? 'active' : '')}>
                          <input type='radio'
                            className='options'
                            value='predefined'
                            onChange={this.handleFastFilterType}
                            checked={this.state.fast_filter_type == 'predefined'}/>
                            <Translate id="advance_search.predefined" />
                        </label>
                        <label className={'btn btn-outline-secondary btn-sm ' + (this.state.fast_filter_type == 'search' ? 'active' : '')}>
                          <input
                            type='radio'
                            className='options'
                            value='search'
                            onChange={this.handleFastFilterType}
                            checked={this.state.fast_filter_type == 'search'}/>
                            <Translate id="advance_search.search" />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-8'>
                    <div className="nav nav__sub-header py-2">
                      <a onClick={this.props.handleOnlyFavorites} className={"nav-item nav-link py-1 " + active_favorite_class}>
                        { this.props.only_favorites && 
                          <span><i className='fa fa-check'></i> &nbsp;</span>
                        }
                        <Translate id="nav.show_favorites" /> <i className="flag-clicker-icon fa fa-star-o"></i>
                      </a>
                      <a onClick={this.props.handleHideBanned} className={"nav-item nav-link py-1 " + active_banned_class}>
                        { this.props.hide_banned && 
                          <span><i className='fa fa-check'></i> &nbsp;</span>
                        }
                        <Translate id="nav.hide_banned" /> <i className="flag-clicker-icon fa fa-flag-o"></i>
                      </a>
                      <a onClick={this.openSearchModal} className="nav-item nav-link py-1">
                        <Translate id="nav.advance_search" />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <FilterModal 
            closeSearchModal={this.closeSearchModal}
            isOpen={this.state.search_modal_open}
            filters={this.state.filters}
            disabled={this.state.disabled}
            handleSubmit={this.handleSearch}
            handleState={this.handleState}
            keys={this.state.keys}
            language={this.props.language}
            fast_filter_type={this.state.fast_filter_type}
          />
        </div>
      }
      </div>
    )
  }
}

export default withLocalize(ImagesFilters)
