import React, { Component } from 'react';
import { render } from 'react-dom';

import FormLocationsEditor from '../components/FormLocations/FormLocationsEditor'
import formEditorApi from '../utils/form_editor_api'

class TypeSelector extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    if(this.props.type) {
      this.props.handleTypeSelect({currentTarget: {value: this.props.type}})
    }
  }

  render() {
    let props = this.props
    return(
      <div className='type-selector'>
        <fieldset className="form-group">
          <legend>Limit Question Visibility by</legend>
          <div className="form-check">
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="chain_category" onChange={props.handleTypeSelect} checked={props.type == 'chain_category'}/>
              Channel
            </label>

            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="chain" onChange={props.handleTypeSelect} checked={props.type == 'chain'}/>
              Chain
            </label>

            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="location_type" onChange={props.handleTypeSelect} checked={props.type == 'location_type'}/>
              Location type
            </label>
          </div>
        </fieldset>
      </div>
    )
  }
}

class FormLocations extends Component {
  constructor(props) {
    super(props)
    this.handleTypeSelect = this.handleTypeSelect.bind(this)
    this.colData = this.colData.bind( this)
    this.rowData = this.rowData.bind( this)
    this.handleSave = this.handleSave.bind( this)
    this.handleClear = this.handleClear.bind( this)
    this.saveNewData = this.saveNewData.bind( this)

    this.state = {
      save_type: props.assignation_type,
      type: props.assignation_type,
      assignation_data: props.assignation_data || {},
      form_id: props.form_id,
      chain_data: {},
      chain_category_data: {},
      location_type_data: {},
      show_save_message: false,
    }
  }

  handleClear() {
    let new_state = {}
    let data = {}
    switch(this.state.save_type) {
      case 'chain':
        data = this.state.assignation_data
        new_state = {type: this.state.save_type, chain_category_data: {}, location_type_data: {}, chain_data: data}
        break;
      case 'chain_category':
        data = this.state.chain_category_data
        new_state = {type: this.state.save_type, chain_data: {}, location_type_data: {}, chain_category_data: data}
        break;
      case 'location_type':
        data = this.state.location_type_data
        new_state = {type: this.state.save_type, chain_data: {}, chain_category_data: {}, location_type_data: data}
        break;
      default:
        new_state['chain_data'] = {}
        new_state['chain_category_data'] = {}
        new_state['location_type_data'] = {}
        new_state['type'] = this.state.save_type
        break;
    }

    this.setState(new_state, () => {
      this.handleTypeSelect({currentTarget: {value: this.state.type}})
    })
  }

  handleSave() {
    let data = {}
    let completed_data = {}

    switch(this.state.type) {
      case 'chain':
        data = this.state.chain_data
        completed_data = {save_type: this.state.type, chain_category_data: {}, location_type_data: {}, assignation_data: data, show_save_message: true }
        break;
      case 'chain_category':
        data = this.state.chain_category_data
        completed_data = {save_type: this.state.type, chain_data: {}, location_type_data: {}, assignation_data: data, show_save_message: true}
        break;
      case 'location_type':
        data = this.state.location_type_data
        completed_data = {save_type: this.state.type, chain_data: {}, chain_category_data: {}, assignation_data: data, show_save_message: true}
        break;
    }

    formEditorApi.updateAssignation(this.state.form_id, this.state.type, data).then((response) => {
      this.setState(completed_data)
      setTimeout( () => {
        this.setState({show_save_message: false})
      }, 3000);
    })
  }

  saveNewData(data) {
    let new_data = {}
    switch(this.state.type) {
      case 'chain':
        new_data = {chain_data: data}
        break;
      case 'chain_category':
        new_data = {chain_category_data: data}
        break;
      case 'location_type':
        new_data = {location_type_data: data}
        break;
    }
    this.setState(new_data)
  }

  handleTypeSelect(e) {
    let type = e.currentTarget.value

    formEditorApi.getSelectors(type).then ((response)=> {
      this.setState(
        {
          type: type,
          selectors: response,
        }
      )
    })
  }

  colData() {
    let selectors = this.state.selectors

    let col_headers = []
    let col_map = {}
    selectors.forEach((selector, index) =>{
      col_headers.push(selector.name)
      col_map[index] = selector.id
    })

    return { col_headers: col_headers, col_map: col_map}
  }

  rowData() {
    let fields = this.props.form_fields
    let row_headers = []
    let row_map = {}
    let index_counter = 0
    fields.forEach((field) =>{
      if (field.type == 'section' ||  field.type == 'hotspot')
        return

      row_headers.push(`${field.label} (${field.type})`)
      row_map[index_counter] = field.key 
      index_counter += 1
    })

    return { row_headers: row_headers, row_map: row_map}
  }

  handleData(colData, rowData) {
    let data = []
    let colHeaders = colData.col_headers
    let rowHeaders = rowData.row_headers
    let state_data = this.getData()

    rowHeaders.forEach((row, row_index) =>{
      let row_data = []
      let field_id = rowData.row_map[row_index]
      let field = state_data[field_id]
      let value = ''

      colHeaders.forEach((col, col_index) =>{
        let assignation_id = colData.col_map[col_index]
        //Check if already is marked
        if(field) {
          value = field[assignation_id] || ''
        }
       
        row_data.push(value)
      })
      data.push(row_data)
    })
    return data
  }

  getData() {
    let data = {}
    switch(this.state.type) {
      case 'chain':
        data = this.state.chain_data
        break;
      case 'chain_category':
        data = this.state.chain_category_data
        break;
      case 'location_type':
        data = this.state.location_type_data
        break;
    }

    //When already has assignation_data
    if(Object.keys(data).length === 0 && data.constructor === Object) {
      data = this.state.assignation_data
    } 

    return data
  }

  stateData() {
    let data = {}
    switch(this.state.type) {
      case 'chain':
        data = this.state.chain_data
        break;
      case 'chain_category':
        data = this.state.chain_category_data
        break;
      case 'location_type':
        data = this.state.location_type_data
        break;
    }

    return data
  }

  componentDidMount() {
    if(this.state.type) {
      switch(this.state.type) {
        case 'chain':
          this.setState({chain_data: this.state.assignation_data})
          break;
        case 'chain_category':
          this.setState({chain_category_data: this.state.assignation_data})
          break;
        case 'location_type':
          this.setState({location_type_data: this.state.assignation_data})
          break;
      }
    }
  }

  render() {
    let col_data = {}
    let row_data = {}
    let table_data = []
    let state_data = {}

    if(this.state.type && this.state.selectors) {
      row_data = this.rowData()
      col_data = this.colData()
      table_data = this.handleData(col_data, row_data)
      state_data = this.stateData()
    }

    return (
      <div className='row form-locations-editor'>
        <div className='col-md-12'>
          <div className='the-box'>
            {this.state.show_save_message && 
              <div className='row'>
                <div className='col-md-12'>
                  <div className="alert alert-success alert-block fade in alert-dismissable">
                     Saved correctly!
                  </div>
                </div>
              </div>
            }
            <div className='row'>
              <div className='col-md-6'>
                <TypeSelector type={this.state.type} handleTypeSelect={this.handleTypeSelect} />
              </div>
              { this.state.type && this.state.selectors &&
                <div className='col-md-6'>
                  <button
                    onClick={this.handleSave}
                    className="pull-right btn-fb btn btn-square">
                    Save
                  </button>

                  <button
                    onClick={this.handleClear}
                    className="pull-right btn btn-default btn-square">
                    Clear changes
                  </button>
                </div>
              }
            </div>
            { this.state.type && this.state.selectors &&
              <FormLocationsEditor
              saveNewData={this.saveNewData}
              type={this.state.type}
              row_data={row_data}
              col_data={col_data}
              table_data = {table_data}
              state_data = {state_data}
              assignation_data={this.state.assignation_data} />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default FormLocations
