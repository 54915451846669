import React, { useState, useEffect, Fragment } from 'react'
import { render } from 'react-dom'

import AnalyticsApi from '../../utils/analytics_api'
import Empty from '../../utils/Empty'

import axios from 'axios';
import Select from 'react-select'
import {selectStyles} from '../../support/helpers'
import ReportBox from './ReportBox'
import ReportView from './ReportView'

const Reports = (props) => {
  const [loading, setLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [folders, setFolders] = useState([])
  const [reports, setReports] = useState([])
  const [total_pages, setTotalPages] = useState(0)
  const [active_filter, setActiveFilter] = useState('organization_reports')
  const [selected_folder, setSelectedFolder] = useState(undefined)
  const [active_report, setActiveReport] = useState(undefined)
  let { auth } = props

  const loadIndex = (type) => {
    AnalyticsApi.getAnalyticReports(active_filter, selected_folder).then((response) => {
      let reports = response.data.map(analytic_report => analytic_report.attributes)
      let total_pages = response.meta.total_pages
      setLoading(false)
      setTotalPages(total_pages)
      setReports(reports)
      if(type == 'initial' && reports.length > 0) {
        setActiveReport(reports[0])
      }
    })
  }

  const filterReports = (type) => {
    setActiveFilter(type)
  }

  const filterAnalyticReportFolder = (e) => {
    let value = e ? e.value : null
    setSelectedFolder(value)
  } 

  //Load index each time a filter is changed
  useEffect(() => {
    setActiveReport(undefined)
    loadIndex("initial")
  }, [active_filter, selected_folder, changed])

  // ComponentDidMount
  useEffect(() => {
    axios.get('/web_api/analytic_report_datasources?only_active=true').then((response) => {
      setFolders(response.data.folders)
      loadIndex("initial")
    })
  }, []) 

  return (
    <div className="app p-3">
      <div className="container-fluid py-3">
        {loading ? (
          <Fragment></Fragment>
        ) : (
          <Fragment>
            {!auth.client_portal && (
              <div className="row pb-3 d-print-none">
                <div className="col-md-12 ">
                  <div>
                    <a
                      className={
                        "cursor-pointer btn btn-link text-secondary text-uppercase " +
                        (active_filter == "organization_reports"
                          ? "font-weight-bold"
                          : "text-secondary")
                      }
                      onClick={() => filterReports("organization_reports")}
                    >
                      Organization reports
                    </a>
                    <a
                      className={
                        "cursor-pointer btn btn-link text-secondary text-uppercase " +
                        (active_filter == "my_reports"
                          ? "font-weight-bold"
                          : "text-secondary")
                      }
                      onClick={() => filterReports("my_reports")}
                    >
                      My reports
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-4 col-lg-3 d-print-none pr-0">
                {folders.length > 0 && (
                  <div className="mb-3">
                    <Select
                      isClearable={true}
                      placeholder={"Filter by analytic folder..."}
                      styles={selectStyles}
                      onChange={filterAnalyticReportFolder}
                      options={folders}
                    />
                  </div>
                )}

                {reports.length > 0 ? (
                  <div
                    className="list-group"
                    style={{ overflowY: "auto", maxHeight: "650px" }}
                  >
                    {reports.map((analytic_report, index) => {
                      let show = active_report
                        ? active_report.id == analytic_report.id
                        : true;

                      let is_active = active_report
                        ? active_report.id == analytic_report.id
                        : false;

                      return (
                        <ReportBox
                          key={analytic_report.id}
                          analytic_report={analytic_report}
                          setActiveReport={setActiveReport}
                          setChanged={setChanged}
                          changed={changed}
                          is_active={is_active}
                          loadIndex={loadIndex}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <Empty text={"No reports"} />
                )}
              </div>

              <div className="col-md-8 col-lg-9 ">
                {active_report ? (
                  <ReportView
                    analytic_report={active_report}
                    google_api_key={props.google_api_key}
                    auth={auth}
                  />
                ) : (
                  <Empty text={"Select a report"} />
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default Reports
