import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions'
import _ from 'lodash'

export default class SelectPeriod extends Component {
  constructor(props) {
    super(props);
    this.renderPeriods = this.renderPeriods.bind(this);
    this.renderPeriodsRange = this.renderPeriodsRange.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectRange = this.selectRange.bind(this);
  }

  selectPeriod(value) {
    this.props.handleSelectPeriod(value)
  }

  selectRange(value) {
    this.props.handleSelectPeriodInPast(value)
  }

  componentDidMount() {
    if(this.props.period.value) {
      this.selectPeriod(this.props.period.value)
    }
  }

  renderPeriods() {
    let periods = this.props.periods
    let selected_period = this.props.period
    if(!this.props.datasource.capture_location) {
      periods = periods.filter((period) => {
        return !["daily_snapshot", "weekly_snapshot", "monthly_snapshot"].includes(period.value)
      })
    }

    let field_group_value = this.props.fieldGroup.value

    if(field_group_value == '_campodata_location_presence_percentage' || 
      field_group_value == '_campodata_location_presence') {
      let filtered = this.props.dashboardTypes.filter(function(obj) { return obj.value == 'single_value' })
      if(filtered[0]) {
        periods = filtered[0].periods.filter((obj) => {
          return obj.value != 'latest'
        })
        if(['daily', 'weekly', 'monthly', 'latest',"daily_snapshot", "weekly_snapshot", "monthly_snapshot"].includes(selected_period.value)){
          selected_period = {}
        }
      }
    }

    if (periods.length > 0) {
      return (
        <Select
          value={selected_period.value}
          optionLabelProp={'text'}
          placeholder={this.props.periodPlaceholderText}
          onChange={this.selectPeriod}
          dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
          style={{width: '100%'}}
          dropdownStyle={{zIndex: 101}}
          >
          {periods.map((data) => {
            return (
            <Option key={data.value} text={data.label} value={data.value}>
            {data.label}
            </Option>
            )
          })}
        </Select>
      )
    }
  }

  renderPeriodsRange() {
    let periodsInPast = this.props.period.periods_in_past
    let selectedPeriodInPast = this.props.periodsInPast;
    let dashboardType = this.props.dashboard_type;
    let field_group_value = this.props.fieldGroup.value

    if (periodsInPast &&
        dashboardType.value !== 'single_value' &&
        dashboardType.chart_type !== 'pie_chart' &&
        field_group_value != '_campodata_location_presence_percentage' &&
        field_group_value != '_campodata_location_presence') {
      const options = _.range(1, periodsInPast.max + 1).map((value) => {
        const { unit, units } = periodsInPast;
        const label = (value > 1)? `${value} ${units}` : `${value} ${unit}`;
        return { label, value };
      });

      return(
        <div className='form-part'>
          <label>{this.props.rangeSelect}</label>
          <Select
            value={this.props.period_in_past.value}
            optionLabelProp={'text'}
            onChange={this.selectRange}
            dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 101}}
            >
            {options.map((data) => {
              return (
              <Option text={data.label} key={data.value} value={data.value}>
              {data.label}
              </Option>
              )
            })}
          </Select>
        </div>
      )
    }
  }

  render() {
    return (
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-part'>
            <label>{this.props.periodSelect}</label>
            {this.renderPeriods()}
          </div>
        </div>
        <div className='col-md-6'>
          {this.renderPeriodsRange()}
        </div>
      </div>
    );
  }
}

SelectPeriod.defaultProps={
  periodPlaceholderText: i18n.t('insight.form.step4.period_placeholder'),
  periodSelect: i18n.t('insight.form.step4.input_period'),
  rangeSelect: i18n.t('insight.form.step4.input_range'),
}
