var app = window.app || {};
app.debounce = app.debounce || {};

//TODO: Check
app.debounce = function(func, wait, immediate) {
  var timeout;
  timeout = void 0;
  return function() {
    var args, callNow, context, later;
    context = this;
    args = arguments;
    later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

app.bindOnClick = function(selector, action){
  $(selector).on('click', function(caller){
    caller.preventDefault();
    action(caller);
  });
};
