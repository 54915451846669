import axios from 'axios'

function getScheduledSubmission(id) {
  let url = `/web_api/scheduled_submissions/` + id
  return axios.get(url).then((response) => response.data)
}

function getSubmission(id) {
  let url = `/web_api/submissions/` + id + '/explore'
  return axios.get(url).then((response) => response.data)
}

module.exports = {
  getScheduledSubmission: getScheduledSubmission,
  getSubmission: getSubmission,
}
