import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import ColumnChart from '../../Chart/InsightColumnChart'
import Chart from '../../Chart/DrilldownPieChart'

class AnalyticChart extends Component {

  addDrilldown(ds, son, key) {
    let new_key = key || son.key
    let drilldown_data = {
      name: son.name,
      id: new_key,
      data: []
    }

    son.children.forEach((ss) => {
      let son_data = {name: ss.name, y: ss.raw_value}
      if(ss.children.length > 0) {
        son_data.drilldown = `${new_key}_${ss.key}`
        this.addDrilldown(ds, ss, `${new_key}_${ss.key}`)
      }
      drilldown_data.data.push(son_data)
    })
    ds.push(drilldown_data)
  }

  render() {
    let {bucket, use_percentage, float_point} = this.props
    let chart_props =  {}
    let data = {}
    chart_props.name = bucket.name
    chart_props.hasDrilldown = true
    if(this.props.chart_type == 'pie') {
      chart_props.description = `Total: ${bucket.value}`
    } else {
      chart_props.description = ''
    }
    chart_props.hide_labels = true

    let series_data = []
    let drilldown_series = []
    let labels = []
    let series_label = ''

    if(this.props.filters.groups.length > 1) {
      series_label = this.props.filters.groups[1].group.label
    }


    bucket.children.forEach((son) => {
      let has_children = son.children.length > 0 ? true : false
      labels.push(son.name)
      if(has_children) {
        series_data.push({name: son.name, y: son.raw_value, drilldown: son.key})
      
        this.addDrilldown(drilldown_series, son)
      } else {
        series_data.push({name: son.name, y: son.raw_value})
      }
    })

    chart_props.data = {
      show_percentage: use_percentage,
      float_point: float_point,
      chart_type: this.props.chart_type,
      labels: labels,
      series: [
        {
          name: series_label,
          colorByPoint: true,
          data: series_data,
        }
      ],
      drilldown: {
        series: drilldown_series,
      }
    }

    return(
      <Fragment>
        { bucket.children.length > 0 &&
          <div className='col-md-6'>
            <Chart 
              {...chart_props}
            />
          </div>
        }
      </Fragment>
    )
  }
}

export default class AnalyticGraph extends Component {
  renderBarChart(bucket, show_percentage, filters) {
    let chart_props =  {}
    let data = {}
    chart_props.name = bucket.name
    chart_props.description = `Total: ${bucket.value}`
    data.show_percentage = show_percentage
    data.labels = bucket.children.map((son) => son.name)
    data.yText = filters.report_type.label
    data.series = [
      {name: filters.report_type.label, data: bucket.children.map((son) => son.raw_value)}
    ]
    chart_props.data = data

    return(
      <ColumnChart 
        {...chart_props}
      />
    )
  }

  render() {
    let {bucket, filters} = this.props 
    let groups = filters.groups
    let chart_type = 'pie'
    let use_percentage = false
    let float_point = '1f'
    let report_type = filters.report_type.value
    if(report_type == 'locations_reported' || report_type == 'submissions_count') {
      float_point = '0f'
    }

    groups.forEach((group) => {
      if (['answer', 'exception_message', 'key', 'ca', 'cb', 'cc', 'cd'].includes(group.group.value)) {
        chart_type = 'column'
      }
    })

    if(report_type == "compliance") {
      chart_type = 'column'
      use_percentage = true
    }

    if(['compliance_deficit','avg_reported', 'avg_duration', 'min_duration', 'max_duration'].includes(report_type)) {
      chart_type = 'column'
    }

    return(
      <div className='col-md-12 mt-4'>
        <div className='row'>
          <div className='col-md-12 mb-4'>
            {this.renderBarChart(bucket, use_percentage, filters)}
          </div>
        </div>

        <div className='row'>
          { bucket.children.map((son) => {
            return(
              <AnalyticChart 
                key={son.key}
                bucket={son}
                chart_type={chart_type}
                use_percentage={use_percentage}
                float_point={float_point}
                filters={filters}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
 
