import React, { Component } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom"
import TaskListsIndex from '../components/task_lists/TaskListsIndex'
import { QueryClientProvider, QueryClient } from 'react-query'

const queryClient = new QueryClient()

const TaskListsContainer = (props) => {
  let { auth } = props 
  return(
    <QueryClientProvider client={queryClient} >
      <Router>
        <div>
          <section className='sub-navbar bg-white'>
            <div className="container-fluid">
              <div className="container-fluid p-0 pl-sm-3 pr-sm-3">
                <div className="row no-gutters">
                  <nav className="w-100">
                    <div className="nav nav-tabs nav-tabs-campo border-0" id="nav-tab" role="tablist">
                      <NavLink exact className="nav-item nav-link py-3 mr-3" to={"/task_lists"} activeClassName="active">
                        Task Lists
                      </NavLink>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </section>
          <div>
            <Route exact path="/task_lists" render={(props) => <TaskListsIndex {...props} auth={auth}/>} />
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  )
}

export default TaskListsContainer
