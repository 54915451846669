var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext({
  Acknowledge: require('./components/containers/Acknowledge'),
  AnalyticsContainer: require('./components/containers/AnalyticsContainer'),
  EditEmailSchedule: require('./components/containers/EditEmailSchedule'),
  EmailSchedule: require('./components/containers/EmailSchedule'),
  TaskListsContainer: require('./components/containers/TaskListsContainer'),
  GalleriesContainer: require('./components/containers/GalleriesContainer'),
  GallerySharesContainer: require('./components/containers/GallerySharesContainer'),
  GallerySharesShowContainer: require('./components/containers/GallerySharesShowContainer'),
  ImagesContainer: require('./components/containers/ImagesContainer'),
  InsightsContainer: require('./components/containers/InsightsContainer'),

  PostStats: require('./components/containers/PostStats'),
  ProfileImagesContainer: require('./components/containers/ProfileImagesContainer'),

  SchemaEditor: require('./components/containers/SchemaEditor'),
  SubmissionComplianceHistory: require('./components/containers/SubmissionComplianceHistory'),
  SubmissionExplorer: require('./components/containers/SubmissionExplorer'),
  SubmissionExplorerCalendar: require('./components/containers/SubmissionExplorerCalendar'),
  SubmissionShare: require('./components/containers/SubmissionShare'),
  TicketsContainer: require('./components/containers/TicketsContainer'),
  FormLocations: require('./components/containers/FormLocations'),
  FormDesignDocumentFolders: require('./components/containers/FormDesignDocumentFolders'),
});
