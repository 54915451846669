import React, { useState, useEffect, Fragment } from 'react'
import { render } from 'react-dom'
import AnalyticsApi from '../../utils/analytics_api'
import Empty from '../../utils/Empty'
import MapComponent from './MapComponent'

const colors = [
  'CD5C5C',
  'F08080',
  'FA8072',
  'E9967A',
  'FFA07A',
  'AB47BC',
  '9C27B0',
  '8E24AA',
  '7B1FA2'
]

const correctLocList = function (loclist) {
  let lng_radius = 0.0003,         // degrees of longitude separation
    lat_to_lng = 111.23 / 71.7,  // lat to long proportion in Warsaw
    angle = 0.5,                 // starting angle, in radians
    loclen = loclist.length,
    step = 2 * Math.PI / loclen,
    i,
    loc,
    lat_radius = lng_radius / lat_to_lng;
  for (i = 0; i < loclen; ++i) {
    loc = loclist[i];
    loc.longitude = loc.longitude + (Math.cos(angle) * lng_radius);
    loc.latitude = loc.latitude + (Math.sin(angle) * lat_radius);
    angle += step;
  }
}

const SubmissionSidebar = ({selected_submission, setSelectedSubmission}) => {
  const [loading_iframe, setLoadingIframe] = useState(false)
  useEffect(() => {
    setLoadingIframe(true)
  }, [selected_submission])

  return(
     <div>
       { loading_iframe ? (
         <div className='text-center'>
           <i className="fa fa-spinner fa-spin fa-1x fa-fw" aria-hidden="true"></i>
         </div>
       ) : (
         <div className='text-muted'>
           <i className='fa fa-times cursor-pointer pull-right' onClick={() => setSelectedSubmission({})} style={{marginTop: '-20px'}}></i>
         </div>
       )}

       <iframe onLoad={() => setLoadingIframe(false)} style={{borderWidth: '0px', height:'600px'}} loading="lazy" width="100%" src={"/submissions/" + selected_submission.id + "/webview?drilldown=true"}>
       </iframe>
     </div>
    
  )
}

const DrilldownCheckbox = ({aggregation, margin, parents, selected_filters, setSelectedFilters }) => {
  let new_parents = [...parents, aggregation.key]
  let checked = new_parents.join(',') == selected_filters.join(',')

  const handleInputChange = (event) => {
    const value = event.target.checked ? new_parents : []
    setSelectedFilters(value)
  }

  let disabled = aggregation.value == 0 ? true : false
  let cursor_style = {cursor: 'default'}

  useEffect(() => {
    if(aggregation.value > 0 && selected_filters != []) {
      setSelectedFilters([aggregation.key])
    }
  }, [])

  return(
    <div className='text-muted d-inline-block mr-4'>
      <input
        className="mr-2"
        type="radio"
        checked={checked}
        onChange={handleInputChange}
        disabled={disabled}
      />

        <span style={cursor_style} onClick={() => setSelectedFilters(new_parents)}>
        {aggregation.name} <small>({aggregation.value})</small>
      </span>
    </div>
  )
}
const Filters = ({analytic_report, aggregations, setSubmissions, setSelectedSubmission, single_value}) => {
  const [selected_filters, setSelectedFilters] = useState([])
  const [selected_color, setSelectedColor] = useState([])

  const getSubmissions = () => {
    setSelectedSubmission({})

    if(selected_filters.length == 0 && !single_value) {
      setSubmissions([])
    } else {
      AnalyticsApi.getSubmissions(analytic_report.id, analytic_report.query, selected_filters).then((response) => {
        let submissions = response.submissions
        correctLocList(submissions)
        setSubmissions(submissions)
      })
    }
  }

  useEffect(() => {
    getSubmissions()
  }, [selected_filters])

  useEffect(() => {
    if(single_value && aggregations.length == 0) {
      getSubmissions()
    }
  }, [single_value])

  return(
    <div>
      { aggregations.map((aggregation, index) => {
        return(
          <DrilldownCheckbox
            key={index}
            aggregation={aggregation}
            margin={0}
            parents={[]}
            selected_filters={selected_filters}
            setSelectedFilters={setSelectedFilters}
          />
        )
      })}
    </div>

  )
}

const MapContainer = ({
  analytic_report,
  google_api_key,
}) => {

  const [loading_query, setLoadingQuery] = useState(false)
  const [single_value, setSingleValue] = useState(undefined)
  const [aggregations, setAggregations] = useState([])
  const [submissions, setSubmissions] = useState([])
  const [selected_submission, setSelectedSubmission] = useState({})

  const runQuery = () => {
    AnalyticsApi.analize(analytic_report.query).then((response) => {
      let aggregations = (response.analytics || []).map((e, index) => {
        e.color = colors[index%9]
        return e
      })
      setAggregations(aggregations)
      setSingleValue(response.value)
      setLoadingQuery(false)
    }).catch((e) => {
      setLoadingQuery(false)
    })
  }

  useEffect(() => {
    runQuery()
  }, [])

  let map_col = 'col-md-12'

  if(selected_submission.id) {
    map_col = 'col-md-8'
  }

  let submissions_without_points = 0

  submissions.forEach((s) => {
    if(s.latitude == 0 || s.longitude == 0 || !s.latitude || !s.longitude) {
      submissions_without_points += 1
    }
  })

  return(
    <div>
      <div className='row'>
        <div className='col-12'>
          <Filters 
            analytic_report={analytic_report}
            aggregations={aggregations}
            setSubmissions={setSubmissions}
            setSelectedSubmission={setSelectedSubmission}
            single_value={single_value}
          />
        </div>
      </div>

      { submissions.length > 0 ? (
        <div className='row mt-4'>
          { submissions_without_points > 0 &&
            <div className='text-muted small col-md-12'>
              There are {submissions_without_points} submissions without specific location.
            </div>
          }

          <div className={map_col}>
            <MapComponent
              setSelectedSubmission={setSelectedSubmission}
              submissions={submissions}
              googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + google_api_key}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `600px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          
          { selected_submission.id && 
            <div className='col-md-4'>
              <SubmissionSidebar 
                selected_submission={selected_submission}
                setSelectedSubmission={setSelectedSubmission}
              />
            </div>
          }
        </div>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Empty text={'Select a filter to view map'} />
          </div>
        </div>
      )}
    </div>
  )
}

export default MapContainer
