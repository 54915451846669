$(function(){
  $('.datepicker-input').datepicker();


  $('#route_assignation_se_date_evaluation_type').change(function(e) {
    var value = e.target.value
    if(value == 'default') {
      $('#se_user_type').addClass('d-none')
      $('#se_scan_days_past').addClass('d-none')
      $('#se_scan_days_future').addClass('d-none')
    } else if(value == 'same_week') {
      $('#se_user_type').removeClass('d-none')
      $('#se_scan_days_past').addClass('d-none')
      $('#se_scan_days_future').addClass('d-none')
    } else {
      $('#se_user_type').removeClass('d-none')
      $('#se_past_days').removeClass('d-none')
      $('#se_future_days').removeClass('d-none')
    }
  })

})
