import React from 'react';
import DebounceInput from 'react-debounce-input';
import Results from './Results';

import SearchApi from '../utils/search_api'

const initialState = {
  displayResults: false,
  results: '',
  searchTerm: '',
  listCounter: -1 // item in the list being highlighted
}

export default class Search2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleEscape = this.handleEscape.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleFucus = this.handleFocus.bind(this)

    this.removeMobileClass = this.removeMobileClass.bind(this)
    this.addMobileClass = this.addMobileClass.bind(this)
  }

  addMobileClass() {
    $('.navbar-campo').addClass('mobile-search')
    if(this.state.searchTerm != '') {
      this.setState({displayResults: true});
    }
  }

  removeMobileClass() {
    $('.navbar-campo').removeClass('mobile-search')
    this.setState({displayResults: false});
  }

  stopSubmit(event) {
    event.preventDefault();
  }

  handleSearch(event) {
    // Add current search term to state
    if (event.type !== 'blur') {
      this.setState({
        searchTerm: event.target.value
      });

      // detect submit with enter key -> 13
      if (event.keyCode === 13) {
         event.preventDefault();
        if (this.state.listCounter > -1) {
          const link = document.querySelector('.result.is-active a').href;

          this.setState({displayResults: false});
          window.location = link;
          return false;
        }
      } else if (event.target.value) {
          // TODO: Use the link below for production
          // `/search?q=${this.state.searchTerm}`
          SearchApi.search(this.state.searchTerm).then((response) => {
            this.setState({
              displayResults: true,
              results: response,
              listCounter: -1
            });
          });
      }
    }
  }

  handleFocus() {
    if(this.state.searchTerm) {
      this.handleSearch({target: {value: this.state.searchTerm}})
    }
  }

  removeHighlight(position) {
    const regex = /is-active/g;
    const results = document.querySelectorAll('.result');

    results[position].className = results[position].className.replace(regex, '');

  }

  addHighlight(position) {
    const results = document.querySelectorAll('.result');

    results[position].className += ' is-active';
  }

  handleEscape(event) {
    // detect escape key -> 27
    // detect arrow down -> 40
    // detect arrow up -> 38
    if (event.keyCode === 27) {
      this.setState(Object.assign(this.state, {displayResults: false}))
    } else if (event.keyCode === 40) {
      const results = document.querySelectorAll('.result');

      if (this.state.listCounter > -1 && this.state.listCounter != (results.length - 1)) {
        this.removeHighlight(this.state.listCounter);
      }

      if (this.state.listCounter < (results.length - 1)) {
        const counter = (Number(this.state.listCounter) + 1);

        this.setState({
          listCounter: counter
        });

        this.addHighlight(counter);
      }

    } else if (event.keyCode === 38) {
      if (this.state.listCounter >= 0) {
        this.removeHighlight(this.state.listCounter);
        let counter = (Number(this.state.listCounter) - 1);

        this.setState({
          listCounter: counter
        });

        if (counter >= 0) {
          this.addHighlight(counter);
        }
      } else {
        document.querySelector('.search-bar').focus();
      }
    }
  }

  handleBlur(event) {
    return
  }

  handleMouseEnter() {
    if (this.state.listCounter > -1) {
      this.removeHighlight(this.state.listCounter);

      this.setState({
        listCounter: -1
      });
    }
  }

  handleSelected(event){
    event.preventDefault();
    const target = event.relatedTarget;

    if (target && target.href) {
    
      const searchTerm = target.dataset.label;

      if (searchTerm) {
        this.setState({
          searchTerm,
          displayResults: false,
          listCounter: -1,
          results: ''
        });
      }
      window.location = target.href;
    }

    return false;
  }

  render() {
    return (
      <form className="d-inline-block" method='GET' action=''>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.998 12" className="d-inline-block d-lg-none mr-3 img--lupe--search" onClick={this.addMobileClass}>
          <path id="lupa" className="cls-1" d="M11.835,10.375,9.5,8.038a.562.562,0,0,0-.4-.164H8.718a4.872,4.872,0,1,0-.844.844V9.1a.562.562,0,0,0,.164.4l2.336,2.336a.56.56,0,0,0,.794,0l.663-.663A.565.565,0,0,0,11.835,10.375Zm-6.96-2.5a3,3,0,1,1,3-3A3,3,0,0,1,4.875,7.874Z"/>
        </svg>
  
        <div className="search--wrapper">
          <DebounceInput
            name='q'
            className="form-control d-none d-lg-inline-block form-control mr-sm-2 rounded-0"
            autoComplete='off'
            placeholder={i18n.t('search.placeholder')}
            minLength={0}
            debounceTimeout={400}
            onChange={this.handleSearch}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur}
            onKeyDown={this.handleEscape}
            value={this.state.searchTerm}
            onSubmit = {this.stopSubmit}
            aria-describedby="basic-addon2" />
          <Results
            searchTerm={this.state.searchTerm}
            display={this.state.displayResults}
            results={this.state.results}
            mouseEnter={this.handleMouseEnter}
            />
        </div>

        <img 
          onClick={this.removeMobileClass}
          src={this.props.close_image_url}
          className="d-none img--exit--search"/>
      </form>
    );
  }
}
