import React, { Component } from 'react'
import { render } from 'react-dom'

import Modal from 'react-modal'

import {List} from 'react-content-loader'
import ProgressiveImage from 'react-progressive-image'

import ImagesApi from '../../utils/images_api'

import { toast } from 'react-toastify'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(65, 62, 79, 0.85)',
    zIndex            : 1050
  }
}

export default class ImageModal extends Component {
  constructor(props) {
    super(props)

    this.closeModal = this.closeModal.bind(this)
    this.changeBanned = this.changeBanned.bind(this)
    this.changeFavorite = this.changeFavorite.bind(this)
    this.downloadSingleImage = this.downloadSingleImage.bind(this)
  }

  changeBanned() {
    let image = this.props.image
    image.banned = !image.banned

    this.setState({image: image}, () => {
      ImagesApi.changeBanned(this.props.image.id).then((response) => {
        image.banned = response.data.attributes.banned
        this.setState({image: image})
      }).catch((error) => {
        toast.error("Error!");
        image.banned = !image.banned
        this.setState({image: image})
      })
    })
  }

  changeFavorite() {
    let image = this.props.image
    image.favorite = !image.favorite

    this.setState({image: image}, () => {
      ImagesApi.changeFavorite(this.props.image.id).then((response) => {
        image.favorite = response.data.attributes.favorite
        this.setState({image: image})
      }).catch((error) => {
        toast.error("Error!");
        image.favorite = !image.favorite
        this.setState({image: image})
      })
    })
  }

  downloadSingleImage() {
    let image = this.props.image
    window.open(image.image_url, '_blank')
  }

  closeModal() {
    this.props.closeModal()
  }

  render() {
    let {image, full_image, loading_image, index, total_images} = this.props
    let bannedIcon = image.banned ? 'fa-flag' : 'fa-flag-o'
    let favoriteIcon = image.favorite ? 'fa-star' : 'fa-star-o'
 
    let compliance = ''
    if(full_image.id) {
      compliance = full_image.compliance ? `${full_image.compliance}%` : '' 
    }

    return(
      <Modal
        className="modal modal-campo modal-campo--detail-img pt-5"
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={this.closeModal}
        isOpen={this.props.is_open}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <a onClick={this.closeModal} className="modal-close modal-campo__close">
              <i className='fa fa-times'></i>
            </a>

            <div className="modal-img-container">
              <div className="modal-img-wrapper">
                <div className="modal-img-photo-wrapper">
                  <ProgressiveImage src={image.image_url} placeholder={this.props.loading_url}>
                    {(src, loading) => (
                    <div style={{backgroundImage: `url('${src}')`, backgroundSize: loading ? 'initial' : 'contain'}} className="modal-img-photo-container">
                    </div>
                    )}
                  </ProgressiveImage>


                  <ProgressiveImage src={image.image_url} placeholder={this.props.loading_url}>
                    {(src, loading) => (
                      <img className="modal-img-photo-container--mobile" style={{ opacity: loading ? 0.8 : 1, padding: loading ? '45%' : '0px' }} src={src} alt='an image'/>
                    )}
                  </ProgressiveImage>

                  <nav className="modal-img-nav">
                    <a href="#" onClick={() => this.props.handleSlider(index - 1)}>
      { index != 0 && 
        <i className='fa fa-chevron-left'></i>
                      }
                    </a>
                    <a href="#" onClick={() => this.props.handleSlider(index + 1)}>
                      { index != (total_images - 1) && 
                        <i className='fa fa-chevron-right'></i>
                      }
                    </a>
                  </nav>
                </div>
              
                { !this.props.comes_from_gallery_share && 
                  <div className="modal-img-sidebar">
                    { loading_image ? (
                      <List />
                    ) : (
                      <div>
                        <div className="mb-3">
                          <h6 className="text-dark h6 campo_font-weight-semi-bold m-0">
                            {full_image.key}
                          </h6>
                          <small className="m-0 campo_font-weight-semi-bold text-primary">
                            {full_image.form.name}
                          </small>
                        </div>

                        <div className="mb-3">
                          <h5 className="text-dark h5 campo_font-weight-semi-bold m-0">
                            {full_image.location.name} <span style={{fontWeight: 500}}>by {full_image.user.name}</span>
                          </h5>
                          <small className="m-0 campo_font-weight-semi-bold text-secondary">
                            {full_image.submitted_at}
                          </small>
                        </div>
                        <div className="border-bottom w-100 pb-3">
                          <h6 className="text-secondary h6 campo_font-weight-semi-bold m-0 mb-2">
                            {full_image.visit_duration == 'n/a' ? '' : full_image.visit_duration }
                          </h6>

                          <div className="row">
                            <div className="col-8">
                              <h5 className="text-secondary h5 campo_font-weight-semi-bold m-0 d-inline-block">
                                {compliance}
                              </h5>
                            </div>
                            <div className="col-4 text-right">
                              <a href={"/submissions/" + full_image.submission_id + "/webview" } target="_blank">
                                  Full report
                              </a>
                            </div>
                          </div>
                        </div>

                      </div>
                    )}
                    <div className='row align-items-end'>
                      <div className='col-md-12' style={{position: 'absolute', bottom: '0px'}}>
                        <nav className="d-inline-block image-icons">
                          <a style={{cursor: 'pointer'}} className='flag-clicker-link' title='Download Image' onClick={this.downloadSingleImage}>
                            <i className={'flag-clicker-icon fa fa-download'}></i>
                          </a>
                          <a style={{cursor: 'pointer'}} className='flag-clicker-link' title='Toggle Flag Image' onClick={this.changeBanned}>
                            <i className={'flag-clicker-icon fa ' + bannedIcon}></i>
                          </a>
                          <a style={{cursor: 'pointer'}} className='flag-clicker-link' title='Toggle Favorite Image' onClick={this.changeFavorite}>
                            <i className={'flag-clicker-icon fa ' + favoriteIcon}></i>
                          </a>
                        </nav>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

      </Modal>
    )
  }
}
