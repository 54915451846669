import React, { Component } from 'react'
import BaseInsightComponent from './BaseInsightComponent'
import PropTypes from 'prop-types'

class DrilldownPieChart extends BaseInsightComponent {
  chartConfig(){
    let percentage_label = this.props.data.show_percentage ? "%" : ""
    let float_point = this.props.data.float_point ? this.props.data.float_point : '1f'
    let config = {
      chart: {
        type: this.props.data.chart_type || 'pie'
      },
      title: {
        text: this.props.name
      },
      subtitle: {
        text: this.props.description
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.name}: {point.y:.${float_point}}${percentage_label}`
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.${float_point}}${percentage_label}</b><br/>`
      },
      series: this.props.data.series,
      drilldown: this.props.data.drilldown,
      lang: {
        drillUpText: '◁ Back'
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ["printChart",
              "separator",
              "downloadXLS",
              "downloadCSV",
              "separator",
              "downloadPNG",
              "downloadPDF",
            ]
          }
        }
      }
    }

    if(this.props.data.chart_type == 'column') {
      config.xAxis = {
        type: 'category'
      }
      config.plotOptions =  {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.y:.${float_point}}${percentage_label}`
          }
        }
      }
    }

    return config
  }
}

export default DrilldownPieChart;
