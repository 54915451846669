import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

function Loading(props) {
  return(
    <div className='row'>
      <div className='col-md-12'>
        <div className='loading'>
          <center>
            <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
            <span>{props.text}</span>
          </center>
        </div>
      </div>
    </div>
  )
}
export default Loading
