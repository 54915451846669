import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectGraph extends Component {
  constructor(props) {
    super(props);
    this.renderInitialOptions = this.renderInitialOptions.bind(this);
    this.selectChartType = this.selectChartType.bind(this);
  }

  renderInitialOptions() {
    let charts
    let graph_chart
    let new_graph_charts = []

    charts = this.props.dashboardTypes.filter((chart) => {
      if (chart.value == 'graph') {
        graph_chart = chart
        return false
      }
      else {
        return true
      }
    })

    if (graph_chart) {
      charts = graph_chart.chart_types.concat(charts)
    }


    if (charts.length > 0) {
      const dashboardTypes = charts.map(function(data){
        let icon = data.icon;
        let style = `fa ${icon} fa-3x`;
        const checked = this.props.dashboard_type.value === data.value;

        return (
          <div key={data.value} className='col-md-3 col-sm-3 col-xs-6'>
            <div className='chart-select' onClick={this.selectChartType.bind(this, data)}> 
              <div className='chart-content'>
                <label className='icon__label'>
                  <input className='icon__input' type='radio' name='dashboard' value={data.value} defaultChecked={checked}/>
                  <i className={style}></i>
                </label>
                <p>{data.label}</p>
              </div>
            </div>
          </div>
        );
      }.bind(this))

      return (
        <div className="chart-selects row">
          {dashboardTypes}
        </div>
      );
    }
  }

  selectChartType(value){
    this.props.handleChartSelect(value)
  }

  componentDidMount() {
    let value = this.props.dashboard_type.value
    if(value) {
      this.selectChartType({value: value})
    }
  }

  render() {
    return (
      <div>
        {this.renderInitialOptions()}
      </div>
    );
  }
}
