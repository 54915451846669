import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';
import uuid from 'node-uuid';

export default class SelectFieldSubgroups extends Component {
  constructor(props) {
    super(props)
    this.selectFieldSubgroups = this.selectFieldSubgroups.bind(this)
  }

  selectFieldSubgroups(index, value){
    this.props.handleSelectFieldSubgroups(index, value)
  }

  render() {
    const field_groups_with_field_keys =  ['_campodata_reported_values', '_campodata_location_presence', '_campodata_location_presence_percentage']

    let field_subgroups = this.props.selectedFieldSubGroups.map((data) => data.value);
    let show = this.props.fieldGroup.value && field_groups_with_field_keys.includes(this.props.fieldGroup.value)

    let multiple = true
    let placeholder = this.props.selectMultiple

    if(this.props.dashboard_type && this.props.dashboard_type.value == 'single_value') {
      multiple = false
      placeholder = this.props.selectOne
      if(this.props.selectedFieldSubGroups[0]) {
        field_subgroups = this.props.selectedFieldSubGroups[0].value
      } else {
        field_subgroups = null
      }
    }

    return (
      <div>
        { show &&
          <div className='form-part'>
            <label>{this.props.headerSubgroupsText}</label>
            <Select
              dropdownClassName='simple-group-dropdown'
              value={field_subgroups}
              placeholder={ placeholder }
              onChange={this.selectFieldSubgroups}
              multiple={multiple}
              optionFilterProp="label"
              optionLabelProp="label"
              dropdownMenuStyle={{maxHeight: 250, overflow: 'auto'}}
              style={{width: '100%'}}
              dropdownStyle={{zIndex: 101}}
              >
              {this.props.field_subgroups.map(option => {
                return (
                <OptGroup key={option.label + uuid.v4()} label={option.label}>
                {option.values.map(data => {
                  return (
                  <Option key={option.label + data.value + data.label}label={data.label} value={data.value}>
                  {data.label}
                  </Option>
                  )
                })}
                </OptGroup>
                )
              })}
            </Select>
          </div>
        }
      </div>
    );
  }
}

SelectFieldSubgroups.defaultProps = {
  headerSubgroupsText: i18n.t('insight.form.step4.input_field_subgroups'),
  selectMultiple: i18n.t('insight.form.multiple_select'),
  selectOne: i18n.t('insight.form.one_select'),
}
