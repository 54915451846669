import React, { Component } from 'react';
import resourcesApi from '../../utils/resources_api'
import DashboardFormater from '../../utils/DashboardFormater';
import InsightForm from './InsightForm.js';
import BaseInsightButton from './BaseInsightButton.js';

import { ToastContainer } from 'react-toastr'
let container

class EditInsightButton extends BaseInsightButton {
  constructor(props) {
    super(props)
    this.handleSave = this.handleSave.bind(this)
    this.initialForm = this.initialForm.bind(this)
  }

  handleSave(data) {
    resourcesApi.updateInsight(DashboardFormater(data)).then((dashboard) => {
      this.closeModal()
      this.props.handleUpdate(dashboard)
    }).catch((response) => {
    })
  }

  initialForm() {
    let insight = this.props.insight
    let dashboard_type
    let datasource
    let has_complete_value = insight.config.datasource.toString().split("_").length > 1
    let value

    if (insight.form_id) {
      value = has_complete_value ? insight.config.datasource : ('form_' + insight.config.datasource)

      datasource = {type: 'form', value: value}
    } else {
      value = has_complete_value ? insight.config.datasource : ('form_data_set_' + insight.config.datasource)

      datasource = {type: 'form_data_set', value: value}
    }

    if (insight.dashboard_type == 'graph') {
      dashboard_type = insight.chart_type
    } else{
      dashboard_type = insight.dashboard_type
    }

    return({
      display: this.state.showModal,
      datasources: this.state.dataSources,
      dashboardTypes: [],
      data: {
        name: insight.name,
        description: insight.description,
        id: insight.id,
        datasource: datasource,
        dashboard_type: {value: dashboard_type},
        dashboard_group: {value: insight.dashboard_group_id || 'empty-dashboard-group'},
        fieldGroup: {value: insight.config.field_group},
        selectedFieldSubGroups: (insight.config.field_subgroups || []),
        operation: {value: insight.config.operation},
        period: {value: insight.config.period},
        period_in_past: {value: insight.config.periodInPast},
        locations: (insight.config.locations || []),
        group_by: {value: insight.config.group_by},
        summarize_by: {value: insight.config.summarize_by},
        users: (insight.config.users || []),
        supervisors: (insight.config.supervisors || []),
      }
    })
  }

  render() {
    return (
      <div> 
        <button
          className='btn btn-link dashboard--button'
          onClick={this.showInsightCreationModal}>
          { i18n.t('insight.edit') }
        </button>

        <ToastContainer
          ref={ref => container = ref}
          className="toast-top-right"
        />

        { this.state.showModal &&
          <InsightForm
            dashboard_groups={this.props.dashboard_groups}
            handleSave={this.handleSave}
            empty_dashboard_group_label={this.props.empty_dashboard_group_label}
            form={this.initialForm()}
            entity_id={this.props.entity_id}
            closeModal={this.closeModal}
          />
        }
      </div>
    )
  }
}

export default EditInsightButton
