import React, { Component } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'

import Select, {Option, OptGroup} from 'rc-select'
import filterOptions from '../../utils/filterOptions'
import Datetime from 'react-datetime'

import FormValidator from '../../utils/FormValidator'

import {Checkbox, CheckboxGroup} from 'react-checkbox-group'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "./translations/global.json"

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '5%',
    left                       : '15%',
    right                      : '15%',
    bottom                     : '10%',
    border                     : '2px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
}

let report_types = [
  {value: 'aging_report', label: (<Translate id='report_types.aging_report'/>)}, 
  {value: 'inventory_report', label: (<Translate id='report_types.inventory_report'/>)},
  {value: 'exception_answer_report', label: (<Translate id='report_types.exception_answer_report'/>)},
  {value: 'gains_losses_report', label: (<Translate id='report_types.gains_losses_report'/>)},
  {value: 'compliance_answer_report', label: (<Translate id='report_types.compliance_answer_report'/>)},
  {value: 'answer_report', label: (<Translate id='report_types.answer_report'/>)},
  {value: 'activity_report', label: (<Translate id='report_types.activity_report'/>)},
  {value: 'full_report', label: (<Translate id='report_types.full_report'/>)},
]

let intervals = [
  {value: 'daily', label: (<Translate id='intervals.daily'/>)},
  {value: 'weekly', label: (<Translate id='intervals.weekly'/>)},
  {value: 'monthly', label: (<Translate id='intervals.monthly'/>)},
]

let periods = [
  {value: 'today', label: (<Translate id='periods.today'/>)},
  {value: 'yesterday', label: (<Translate id='periods.yesterday'/>)},
  {value: 'this_week', label: (<Translate id='periods.this_week'/>)},
  {value: 'past_week', label: (<Translate id='periods.past_week'/>)},
  {value: 'this_month', label: (<Translate id='periods.this_month'/>)},
  {value: 'past_month', label: (<Translate id='periods.past_month'/>)},
]

const form_validator = new FormValidator([
  { 
    field: 'name',
    method: 'isEmpty',
    validWhen: false,
    message: (<Translate id='errors.blank'/>)
  },
  { 
    field: 'start_at',
    method: 'isEmpty',
    validWhen: false,
    message: (<Translate id='errors.blank'/>)
  }
])



class RegisterModal extends Component {
  constructor(props) {
    super(props)

    this.handleName = this.handleName.bind(this)
    this.handleSelectReportType = this.handleSelectReportType.bind(this)
    this.handleSelectRepeat = this.handleSelectRepeat.bind(this)
    this.handleSelectInterval = this.handleSelectInterval.bind(this)
    this.handleDateSelect = this.handleDateSelect.bind(this)
    this.handleRecipients = this.handleRecipients.bind(this)
    this.handleExtraRecipients = this.handleExtraRecipients.bind(this)
    this.handleSelectPeriod = this.handleSelectPeriod.bind(this)
    this.handleDays = this.handleDays.bind(this)
    this.process = this.process.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      other_errors: null,
      validation: {period: {}, name: {}, report_type: {}, start_at: {}, interval: {}, days: {}}
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    });
  }

  handleClose() {
    this.setState({
      other_errors: null,
      validation: {period: {}, name: {}, report_type: {}, start_at: {}, interval: {}, days: {}}
    })
    this.props.closeModal()
  }

  process() {
    let schedule = this.props.schedule
    let validation = form_validator.validate(schedule)
    let other_errors = null

    if(schedule.report_type.value == '') {
      validation.isValid = false
      validation.report_type = {message: (<Translate id='errors.select'/>), isInvalid: true}
    } else {
      validation.report_type = {isInvalid: false}
    }

    if(schedule.interval.value == '') {
      validation.isValid = false
      validation.interval = {message: (<Translate id='errors.select'/>), isInvalid: true}
    } else {
      validation.interval = {isInvalid: false}
    }

    if(schedule.period.value == '') {
      validation.isValid = false
      validation.period = {message: (<Translate id='errors.select'/>), isInvalid: true}
    } else {
      validation.period = {isInvalid: false}
    }

    if(schedule.interval.value == 'daily' && schedule.days.length == 0) {
      validation.isValid = false
      validation.days = {message: (<Translate id='errors.select_one'/>), isInvalid: true}
    } else {
      validation.days = {isInvalid: false}
    }

    if(schedule.extra_recipients == '' && schedule.recipients.length == 0) {
      validation.isValid = false
      other_errors = (<Translate id='errors.extra_errors'/>)
    }

    if(typeof(schedule.start_at) == 'string') {
      validation.isValid = false
      validation.start_at = {message: (<Translate id='errors.invalid_date_format'/>), isInvalid: true}
    }

    if (validation.isValid) {
      this.props.handleSave()
    } else {
      this.setState({validation: validation, other_errors: other_errors})
    }
  }

  handleDays(values) {
    this.props.handleInputChange('days', values)
  }

  handleSelectReportType(value) {
    let all_report_types = report_types.concat(this.props.datasources.custom_reports)
    let filtered = all_report_types.find((obj) => obj.value == value)
    filtered = {value: filtered.value}

    this.props.handleInputChange('report_type', filtered)
  }

  handleSelectRepeat(e) {
    this.props.handleInputChange('repeat', e.target.value)
  }

  handleSelectInterval(value) {
    let filtered = intervals.find((obj) => obj.value == value)
    filtered = {value: filtered.value}
    this.props.handleInputChange('interval', filtered)
  }

  handleSelectPeriod(value) {
    let filtered = periods.find((obj) => obj.value == value)
    filtered = {value: filtered.value}
    this.props.handleInputChange('period', filtered)
  }

  handleName(e) {
    this.props.handleInputChange('name', e.target.value)
  }

  handleDateSelect(value) {
    this.props.handleInputChange('start_at', value)
  }

  handleExtraRecipients(e) {
    this.props.handleInputChange('extra_recipients', e.target.value)
  }

  handleRecipients(values, labels) {
    let new_recipients = []
    let type

    values.forEach((value, index) => {
      type = value.split("_").slice(0,-1).join("_")
      new_recipients.push({value: value, type: type })
    })

    this.props.handleInputChange('recipients', new_recipients)
  }

  intervalLabel(interval) {
    switch(interval) {
      case 'weekly':
        return (<Translate id='interval_labels.weekly'/>)
      case 'monthly':
        return (<Translate id='interval_labels.monthly'/>)
      default:
        return ''
    }
  }

  render() {
    let schedule = this.props.schedule
    let interval_label = this.intervalLabel(schedule.interval.value)
    let selected_recipients = schedule.recipients.map((data) => data.value)
    let validation = this.state.validation
    let other_errors = this.state.other_errors || this.props.other_errors
    let all_report_types = report_types

    let save_label = schedule.id ? (<Translate id="form.update" />) : (<Translate id="form.save" />)

    if(this.props.datasources && this.props.datasources.custom_reports.length > 0) {
      all_report_types = report_types.concat(this.props.datasources.custom_reports)
    }

    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={this.props.is_open}>

        <div className='container-fluid campo__dashboard email-schedule-modal'> 
          <div className='row modal-header--wrapper'>
            <div className='col-md-10 col-sm-10 col-xs-10 title'>
              <Translate id="title" />
            </div>
            <div className="col-md-2 col-sm-2 col-xs-2 close-button">
              <i className="fa fa-times fa-2x clickable pull-right" onClick={this.handleClose}></i>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <hr className='header-horizontal-rule' />
            </div>
          </div>

          <div className='row modal--content--wrapper'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='modal--content'>
                {other_errors &&
                  <div className="alert alert-danger alert-block">
                    {other_errors}
                  </div>
                }
                <section className='email-schedule-form'>
                  <div className='form-h p-2'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>
                          <Translate id="form.form" />
                        </label>
                      </div>

                      <div className='col-md-9'>
                        {this.props.form.title}
                      </div>
                    </div>
                  </div>

                  <div className='form-h p-2'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>
                          <Translate id="form.name" />
                        </label>
                      </div>

                      <div className='col-md-9'>
                        <input className='form-control' type="text" onChange={this.handleName} value={schedule.name} />
                        
                        <div className="form-control-error"><small>{validation.name.message}</small></div>
                      </div>
                    </div>
                  </div>

                  <div className='form-h p-2'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>
                          <Translate id="form.report_type" />
                        </label>
                      </div>

                      <div className='col-md-9'>
                        <Select
                          onChange={this.handleSelectReportType}
                          value={schedule.report_type.value}
                          style={{width: '100%'}}
                          disabled={this.props.isFetching}
                          dropdownStyle={{zIndex: 101}}
                          optionLabelProp="children">
                          {all_report_types.map((ds) => {
                            return (<Option key={ds.value} value={ds.value}>{ds.label}</Option>)
                          })}
                        </Select>

                        <div className="form-control-error"><small>{validation.report_type.message}</small></div>
                      </div>
                    </div>
                  </div>

                  <div className='form-h p-2'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>
                          <Translate id="form.start_reporting_at" />
                        </label>
                      </div>

                      <div className='col-md-9'>
                        <Datetime 
                          value={schedule.start_at}
                          isValidDate={(current) => current.isAfter(Datetime.moment().subtract( 1, 'day' ))}
                          onChange={this.handleDateSelect}
                        />

                        <div className="form-control-error"><small>{validation.start_at.message}</small></div>
                      </div>
                    </div>
                  </div>

                  <div className='form-h p-2'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>
                          <Translate id="form.interval" />
                        </label>
                      </div>

                      <div className='col-md-9'>
                        <Select
                          onChange={this.handleSelectInterval}
                          value={schedule.interval.value}
                          style={{width: '100%'}}
                          disabled={this.props.isFetching}
                          optionLabelProp="children"
                          dropdownStyle={{zIndex: 101}}>
                          {intervals.map((ds) => {
                            return (
                              <Option key={ds.value} value={ds.value}>
                                {ds.label}
                              </Option>
                            )
                          })}
                        </Select>
                        <div className="form-control-error"><small>{validation.interval.message}</small></div>
                      </div>
                    </div>
                  </div>

                  { schedule.interval.value == 'daily' &&
                    <div className='form-h p-2 days'>
                      <div className='row'>
                        <div className='col-md-3'>
                          <label>
                            <Translate id="form.on" />
                          </label>
                        </div>

                        <div className='col-md-9'>
                          <CheckboxGroup name="days" checkboxDepth={2} value={schedule.days} onChange={this.handleDays}>
                            <label><Checkbox value="0"/>S</label>
                            <label><Checkbox value="1"/>M</label>
                            <label><Checkbox value="2"/>T</label>
                            <label><Checkbox value="3"/>W</label>
                            <label><Checkbox value="4"/>T</label>
                            <label><Checkbox value="5"/>F</label>
                            <label><Checkbox value="6"/>S</label>
                          </CheckboxGroup>

                          <div className="form-control-error"><small>{validation.days.message}</small></div>
                        </div>
                      </div>
                    </div>
                  }

                  { schedule.interval.value != '' && schedule.interval.value != 'daily' &&

                    <div className='form-h p-2'>
                      <div className='row'>
                        <div className='col-md-3'>
                          <label>
                            <Translate id="form.repeat_every" />
                          </label>
                        </div>

                        <div className='col-md-9'>
                          <input className='form-control numeric-input' type="text" pattern="[0-9]*"
                            onChange={this.handleSelectRepeat} value={schedule.repeat} />
                          <span>{interval_label}</span>
                        </div>
                      </div>
                    </div>
                  }

                  <div className='form-h p-2'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>
                          <Translate id="form.period_to_report" />
                        </label>
                      </div>

                      <div className='col-md-9'>
                        <Select
                          onChange={this.handleSelectPeriod}
                          value={schedule.period.value}
                          style={{width: '100%'}}
                          disabled={this.props.isFetching}
                          dropdownStyle={{zIndex: 101}}
                          optionLabelProp="children">
                          {periods.map((ds) => {
                            return (
                              <Option key={ds.value} label={ds.label} value={ds.value}>
                                {ds.label}
                              </Option>
                            )
                          })}
                        </Select>
                        <div className="form-control-error"><small>{validation.period.message}</small></div>
                      </div>
                    </div>
                  </div>


                  { this.props.datasources && 
                    <div>
                      <div className='form-h p-2'>
                        <div className='row'>
                          <div className='col-md-3'>
                            <label>
                              <Translate id="form.recipients" />
                            </label>
                          </div>

                          <div className='col-md-9'>
                            <Select
                              value={selected_recipients}
                              onChange={this.handleRecipients}
                              multiple={true}
                              disabled={this.props.isFetching}
                              optionFilterProp="label"
                              optionLabelProp="label"
                              dropdownMenuStyle={{maxHeight: 250, overflow: 'auto'}}
                              style={{width: '100%'}}
                              dropdownStyle={{zIndex: 101}}
                              >
                              {this.props.datasources.recipients.map(option => {
                                return (
                                <OptGroup key={option.label} label={option.label}>
                                {option.values.map(data => {
                                  return (
                                  <Option key={option.label + data.value + data.label} label={data.label} value={data.value}>
                                  {data.label}
                                  </Option>
                                  )
                                })}
                                </OptGroup>
                                )
                              })}
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div className='form-h p-2'>
                        <div className='row'>
                          <div className='col-md-3'>
                            <label>
                              <Translate id="form.extra_recipients" />
                            </label>
                          </div>

                          <div className='col-md-9'>
                            <textarea className='form-control' type="text" onChange={this.handleExtraRecipients} value={schedule.extra_recipients} >
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='submit-buttons'>
                        <button onClick={this.process} className='btn btn-success btn-sm m-3'>
                          <label className='mb-0'>
                            {save_label}
                          </label>
                        </button>

                        <button onClick={this.handleClose} className='btn btn-default btn-sm m-3 mb-0'>
                          <label className='mb-0'>
                            <Translate id="form.cancel" />
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withLocalize(RegisterModal)
