import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import Comments from '../projects_management/helpers/Comments'
import Modal from 'react-bootstrap/Modal'
import tickets_api from '../../utils/pm_tickets_api'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const priorities = [
  {value: 0, label: 'Low'},
  {value: 1, label: 'Medium'},
  {value: 2, label: 'High'},
]

const TicketModal = (props) => {
  let { ticket, onClose, datasources, setTicket } = props
  let { submission_id } = ticket
  let title = ticket.id == '0' ? 'New Ticket' : 'Edit Ticket'
  const [loading, setLoading] = useState(false)

  const handleSave = () => {
    setLoading(true)
    if(ticket.id == 0) {
      tickets_api.createTicket(ticket).then((response) => {
        setLoading(false)
        onClose()
      })
    } else {
      tickets_api.updateTicket(ticket).then((response) => {
        setLoading(false)
        onClose()
      })
    }
  }

  let priority = typeof ticket.priority === 'object' ? ticket.priority : priorities.find(e => e.value == ticket.priority)

  let assignee = (ticket.assignment_user || {}).value ? ticket.assignment_user : undefined
  let state = (ticket.state || {}).value ? ticket.state : undefined

  let good = ticket.changed && ticket.title

  let col_size = ticket.id == 0 ? 'col-md-12' : 'col-md-6'

  return(
    <Modal show={true} onHide={onClose} size="xl" animation={true}>
      <Modal.Header closeButton>
        <Modal.Title style={{width: '50%'}}>
          <div className='d-inline-block'>
            {title}
          </div>
          { ticket.id != 0 &&
            <button disabled={(!good || loading)} className="pull-right btn btn-primary" onClick={handleSave}>
              Save changes
            </button>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className={col_size}>
            <div className='row'>
              <div className='col-12 mb-3'>
                <label className='text-muted'>
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={loading}
                  value={ticket.title || ""}
                  onChange={(e)=>{setTicket({...ticket, title: e.target.value, changed: true})}}
                />
              </div>
            </div>

            <div className='row'>
            <div className='col-4'>
                <label className='text-muted'>
                  Assignee
                </label>
                <Select
                  styles={selectStyles}
                  value={assignee}
                  placeholder={''}
                  onChange={(e)=>{setTicket({...ticket, assignment_user: e, changed: true})}}
                  isDisabled={loading}
                  options={datasources.users}
                />
              </div>
        
              <div className='col-4'>
                <label className='text-muted'>
                  Status
                </label>
                <Select
                  styles={selectStyles}
                  value={state}
                  placeholder={''}
                  onChange={(e)=>{setTicket({...ticket, state: e, changed: true})}}
                  isDisabled={loading}
                  options={datasources.states}
                />
              </div>
              <div className='col-3'>
                <label className='text-muted'>
                  Priority
                </label>
                <Select
                  styles={selectStyles}
                  value={priority}
                  placeholder={''}
                  onChange={(e)=>{setTicket({...ticket, priority: e, changed: true})}}
                  isDisabled={loading}
                  options={priorities}
                />
              </div>
      
            </div>
            <div className='row'>
              <div className='col-12 mt-3'>
                <label className='text-muted'>
                  Description
                </label>
                <textarea
                  className="form-control"
                  disabled={loading}
                  rows="5"
                  value={ticket.description || ""}
                  onChange={(e)=>{setTicket({...ticket, description: e.target.value, changed: true})}}
                >
                </textarea>
              </div>

              { submission_id &&
                <div className='col-12 mt-4 py-4'>
                  <Comments
                    resource_type="tickets"
                    resource_id={ticket.id}
                    reload_comments={true}
                  />
                </div>
              }
            </div>

              <Modal.Footer>
                { ticket.id == 0 &&
                  <button
                    disabled={(!good || loading)}
                    className="btn btn-primary"
                    onClick={handleSave}>
                    Save changes
                  </button>
                }
              </Modal.Footer>
          </div>

          { ticket.id > 0 && 
            <div className='col-md-6'>
              { submission_id ? (
                <div className='px-4'>
                  <iframe frameBorder='0' allowFullScreen src={`/submissions/${submission_id}/webview`} width="100%" height="600px"></iframe>
                </div>
              ) : (
                <div className='px-4'>
                  <Comments
                    resource_type="tickets"
                    resource_id={ticket.id}
                    reload_comments={true}
                  />
                </div>
              )}
            </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TicketModal

