function checkStrength(password) {
  var strength = 0
  if (password.length >= 8) strength += 2
  if (password.length >= 10) strength += 1
  if (password.length >= 15) strength += 1
  // If password contains both lower and uppercase characters, increase strength value.
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1
  // If it has numbers and characters, increase strength value.
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 2
  // If it has one special character, increase strength value.
  if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1
  // If it has two special characters, increase strength value.
  if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) strength += 2

  lower_case = false
  upper_case = false
  number = false
  symbols = false
  if (password.match(/([a-z])/)) lower_case = true
  if (password.match(/([A-Z])/)) upper_case = true
  if (password.match(/([0-9])/)) number = true
  if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) symbols = true 

  return({
    strength: strength,
    lower_case: lower_case,
    upper_case: upper_case,
    number: number,
    symbols: symbols
  })
}

$(function() {
  $("#user_phone").intlTelInput() 

  $('form.profile').submit( function (e) {
    full_number = $('#user_phone').intlTelInput('getNumber')
    $('#user_phone').val(full_number)
  })

  $('#user_password').keyup(function() {
    value = $('#user_password').val()
    data = checkStrength(value)
    active = 0
    $('ul.form-check-pass__state li').each(function(i) {
      if(active < data.strength) {
        $(this).addClass('active')
        active = active +=1
      } else {
        $(this).removeClass('active')
      }
    });

    if (data.lower_case) {
      $('#lower').addClass('text-success').removeClass('text-black-50')
    } else {
      $('#lower').removeClass('text-success').addClass('text-black-50')
    }
    if (data.upper_case) {
      $('#upper').addClass('text-success').removeClass('text-black-50')
    } else {
      $('#upper').removeClass('text-success').addClass('text-black-50')
    }
    if (data.number) {
      $('#numbers').addClass('text-success').removeClass('text-black-50')
    } else {
      $('#numbers').removeClass('text-success').addClass('text-black-50')
    }
    if (data.symbols) {
      $('#symbols').addClass('text-success').removeClass('text-black-50')
    } else {
      $('#symbols').removeClass('text-success').addClass('text-black-50')
    }

    if (value.length >= 8 && data.lower_case && data.upper_case && data.number && data.symbols) {
      $('input[type="submit"]').removeAttr('disabled');
    } else {
      $('input[type="submit"]').attr('disabled','disabled');
    }
  })
});

