import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectGrouping extends Component {
  constructor(props) {
    super(props);
    this.renderGrouping = this.renderGrouping.bind(this);
  }

  renderGrouping() {
    let grouping = this.props.selected_chart.group_by
    let selected_field = this.props.fieldGroup
    let group_by = this.props.group_by
    let valid_groups_for_presence = ['', 'zone', 'chain', 'chain_category', 'location_type']

    if (selected_field.value == '_campodata_location_presence' || selected_field.value == '_campodata_location_presence_percentage') {
      grouping = grouping.filter((group) => {
        return valid_groups_for_presence.includes(group.value)
      })

      if (!valid_groups_for_presence.includes(group_by.value)) {
        group_by = {}
      }
    }

    if (selected_field.value == '_campodata_exceptions') {
      grouping = grouping.filter((group) => {
        return group.value == 'exception_message'
      })
    } else {
      grouping = grouping.filter((group) => {
        return group.value != 'exception_message'
      })
    }

    if (grouping.length > 0) {
      let groupByOptions = grouping.map((options) => {
        return (
        <Option key={options.value} text={options.label} value={options.value}>
          {options.label}
        </Option>
        );
      })

      return (
        <Select
          placeholder={this.props.groupingPlaceHolderText}
          value={group_by.value}
          optionLabelProp={'text'}
          onChange={this.props.handleSelectGrouping}
          dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
          style={{ width: '100%' }}
          dropdownStyle={{ zIndex: 101 }}
          >
          {groupByOptions}
        </Select>
      )
    }
  }

  render() {
    return (
      <div>{this.renderGrouping()}</div>
    );
  }
}

SelectGrouping.defaultProps={
  groupingPlaceHolderText: i18n.t('dashboard_form.placeholder_tabs_grouping_select_grouping'),
}
