import axios from 'axios'

function getDatasources() {
  let url = `/web_api/submission_explorer/datasources`
  return axios.get(url).then((response) => response.data)
}

function explore(data) {
  let url = `/web_api/submission_explorer/explore`
  return axios.get(url, {params: {filters: data}}).then((response) => response.data)
}

function getCalendarEvents(data) {
  let url = `/web_api/submission_explorer/calendar_events`
  return axios.get(url, {params: {filters: data}}).then((response) => response.data)
}

module.exports = {
  getDatasources: getDatasources,
  explore: explore,
  getCalendarEvents: getCalendarEvents
}
