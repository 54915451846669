import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectDashboardGroup extends Component {
  constructor(props) {
    super(props);
    this.renderGrouping = this.renderGrouping.bind(this);
  }

  renderGrouping() {
    let group_by = this.props.dashboard_group

    let groupByOptions = this.props.dashboard_groups.map((options) => {
      return (
      <Option key={options.value} text={options.label} value={options.value}>
        {options.label}
      </Option>
      );
    })

    let empty_label = this.props.empty_dashboard_group_label || 'Dashboards'
    groupByOptions.unshift((
      <Option key={'no-dashboard-group-value'} text={empty_label} value={'empty-dashboard-group'}>
        {empty_label}
      </Option>
    ))

    return (
      <Select
        placeholder={this.props.groupingPlaceHolderText}
        value={group_by ? group_by.value : null}
        optionLabelProp={'text'}
        onChange={this.props.handleSelectDashboardGroup}
        dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
        style={{ width: '100%' }}
        dropdownStyle={{ zIndex: 101 }}
        >
        {groupByOptions}
      </Select>
    )
  }

  render() {
    return (
      <div>{this.renderGrouping()}</div>
    );
  }
}

SelectDashboardGroup.defaultProps={
  groupingPlaceHolderText: i18n.t('dashboard_form.placeholder_dashboard_group'),
}
