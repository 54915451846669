$(".dashboard_user_timeline").ready(function() {
  if (typeof gon === 'undefined') {
    return
  }
  var container, count, groups, items, options, timeline;
  count = 20;
  groups = new vis.DataSet(gon.users);
  items = new vis.DataSet(gon.submissions);
  function sort_group (a, b) { return b.content < a.content ? 1 : -1; };
  function move (percentage) {
        var range = timeline.getWindow();
        var interval = range.end - range.start;

        timeline.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end:   range.end.valueOf()   - interval * percentage
        });
    };
  options = {
    stack: true,
    start: gon.start,
    end: gon.end,
    min: gon.min,
    max: gon.max,
    groupOrder: sort_group,
    editable: false,
    zoomable: true,
    verticalScroll: true,
    margin: {
      item: 10,
      axis: 5
    },
    zoomMax: 604800000,
    zoomMin: 600000,
    orientation: 'top'
  };
  container = document.getElementById('mytimeline');
  if (container) {
    timeline = new vis.Timeline(container, items, groups, options);
    $("#timeline-loading").hide();
    $('#zoomIn').click(function() {
      return timeline.zoomIn(1.0);
    });
    $('#zoomOut').click(function() {
      return timeline.zoomOut(1.0);
    });
    $('#moveLeft').click(function() {
      return move(0.75);
    });
    return $('#moveRight').click(function() {
      return move(-0.75);
    });
  }
});

  
   

