import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import _ from 'lodash'

import ColumnChart from '../../Chart/InsightColumnChart'

export default class GeneralBarChart extends Component {
  render() {
    let {aggregations, filters} = this.props
    let headers = [""]
    let header_keys = []

    aggregations.forEach((bucket) => {
      headers.push(bucket.name)
    })
    
    let map = {}
    let categories = []
    let series = []

    this.props.aggregations.forEach((aggs) => {
      let parent = aggs.name
      series.push(parent)
      aggs.children.forEach((son) => {
        categories.push(son.name)
        if(!map[son.name]) {
          map[son.name] = {}
          map[son.name][parent] = son.raw_value
        } else {
          map[son.name][parent] = son.raw_value
        }
      })
    })

    categories = Array.from(new Set(categories))
    let graph_series = []

    series.forEach((serie) => {
      let serie_map = {}
      let data = []
      serie_map.name = serie
      categories.forEach((cat) => {
        data.push(map[cat][serie] || null)
      })
      serie_map.data = data
      graph_series.push(serie_map)
    })

    let chart_props = {}
    let data = {}
    if(filters.report_type.value == "compliance") {
      data.show_percentage = true
    }
    data.labels = categories
    data.series = graph_series
    chart_props.data = data

    return(
      <div className='mb-4'>
        <ColumnChart 
          {...chart_props}
        />
      </div>
    )
  }
}
