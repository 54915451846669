import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route, NavLink } from "react-router-dom"

import TicketsIndex from '../components/tickets/TicketsIndex'

const TicketsContainer = (props) => {
  let { datasources, auth } = props 

  return(
    <Router>
      <div>
        <section className='sub-navbar bg-white'>
          <div className="container-fluid">
            <div className="container-fluid p-0 pl-sm-3 pr-sm-3">
              <div className="row no-gutters">
                <nav className="w-100">
                  <div className="nav nav-tabs nav-tabs-campo border-0" id="nav-tab" role="tablist">
                    <NavLink exact className="nav-item nav-link py-3 mr-3" to={"/tickets"} activeClassName="active">
                      Tickets
                    </NavLink>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div>
          <Route exact path="/tickets" render={(props) => <TicketsIndex {...props} datasources={datasources} auth={auth}/>} />
        </div>
      </div>
    </Router>
  )
}

export default TicketsContainer
