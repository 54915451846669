import React, { Component } from 'react'
import { render } from 'react-dom'

import { LocalizeProvider } from "react-localize-redux"
import { BrowserRouter as Router, Route } from "react-router-dom"

import GalleriesIndex from '../components/galleries/GalleriesIndex'
import GalleriesShow from '../components/galleries/GalleriesShow'

import { ToastContainer } from 'react-toastify'

export default class GalleriesContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <LocalizeProvider>
        <Router>
          <div>
            <Route exact path="/galleries" render={(props) => <GalleriesIndex {...props} auth={this.props.auth} gallery_placeholder_url={this.props.gallery_placeholder_url} image_placeholder_url={this.props.image_placeholder_url} language={this.props.language} />} />
            <Route path="/galleries/:galleryId" render={(props) => <GalleriesShow {...props} auth={this.props.auth} loading_url={this.props.loading_url} image_placeholder_url={this.props.image_placeholder_url} language={this.props.language} />} />
            <ToastContainer />
          </div>
        </Router>
      </LocalizeProvider>
    )
  }
}
