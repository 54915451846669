import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'

const priority_map = {
  0: 'Low',
  1: 'Medium',
  2: 'High'
}

const TicketsTable = ({tickets, setSelectedTicket}) => {
  if(tickets.length == 0) {
    return(
      <div>
        <center>No tickets</center>
      </div>
    )
  }

  return(
    <table className="table">
      <thead>
        <tr>
          <th></th>
          <th>Assignee</th>
          <th>Status</th>
          <th>Priority</th>
          <th>Created</th>
          <th>Updated</th>
        </tr>
      </thead>
      <tbody>
        { tickets.map((ticket) => {
          return(
            <tr key={ticket.id}>
              <td>
                <span className='cursor-pointer text-primary' onClick={() => setSelectedTicket(ticket)}>
                  {ticket.title}
                </span>
                { ticket.comments_count > 0 &&
                <small className='text-muted ml-2'>
                  <i className='fa fa-comments'></i> {ticket.comments_count}
                </small>
                }
              </td>
              <td>
                {ticket.assignment_user.label}
              </td>
              <td>
                <i className='fa fa-square mr-1' style={{color: ticket.state.color}}></i> <small className="text-uppercase">{ticket.state.label}</small>
              </td>
              <td>
              <small className="text-uppercase">{priority_map[ticket.priority || 0]}</small>
              </td>
              <td>
                {ticket.created_at_label}
              </td>
              <td>
                {ticket.updated_at_label}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TicketsTable
