import React, { Component } from 'react'
import { render } from 'react-dom'

import Empty from '../../utils/Empty'
import { Link } from "react-router-dom"

import { Translate } from "react-localize-redux"
import ProgressiveImage from 'react-progressive-image'

class GalleryBox extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let gallery = this.props.gallery
    let gallery_link = this.props.gallery_share_id ? `/gallery_shares/${this.props.gallery_share_id}/galleries/${gallery.id}?auth=${this.props.auth}` : `/galleries/${gallery.id}`
    let image_url = gallery.state == 'processing' ? this.props.gallery_placeholder_url : gallery.image_url

    return(
      <div className="galleries-list__item">
        <Link to={gallery_link}>
          <div className="galleries-list__item__images">
            <ProgressiveImage src={image_url} placeholder={this.props.gallery_placeholder_url}>
              {(src, loading) => (
                <img style={{ opacity: loading ? 0.7 : 1 }} src={src} alt='an image'/>
              )}
            </ProgressiveImage>
          </div>
        </Link>
        <div className="galleries-list__item__info">
          <h3 className="galleries-list__item__info__title">
            <Link to={gallery_link}>
              {gallery.name}
            </Link>
          </h3>
        </div>
      </div>
    )
  }

}

export default class GalleriesList extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div className="app pb-5">
        <div className="container-fluid py-3">
          {this.props.galleries.length > 0 ? (
            <section className="galleries-list">
              {this.props.galleries.map((gallery, index) => {
                return(
                  <GalleryBox auth={this.props.auth} gallery_share_id={this.props.gallery_share_id} gallery_placeholder_url={this.props.gallery_placeholder_url} gallery={gallery} key={gallery.id} index={index}/>
                )
              })}
            </section>
          ) : (
            <Empty text={(<Translate id="galleries.no_galleries_found" />)} />
          )}
        </div>
      </div>
    )
  }
}
