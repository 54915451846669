import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import resourcesApi from '../../utils/resources_api'
import SelectDatasource from '../Insights/SelectDatasource';

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '10%',
    left                       : '15%',
    right                      : '15%',
    bottom                     : '25%',
    border                     : '2px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
};

function StepHeader(props) {
  return(
    <div className='form-step-header'>
      <div className='step-number'>
        {props.number}
      </div>
      <div className='step-header'>
        <span className='step-title'>
          {props.title}
        </span>
        <span className='step-description'>
          {props.description}
        </span>
      </div>
    </div>
  )
}

function Step1 (props) {
  return(
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-step'>
          <StepHeader number={1} title={props.title} description={props.description} />
          <div className='form-step-content'>
            <div className='row'>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className='form-part'>
                  <label>{props.dashboardName}</label>
                  <input
                    id='dashboardName'
                    className='form-control'
                    type='text'
                    defaultValue={props.data.name}
                    onChange={props.handleInputName} />
                </div>
              </div>

              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className='form-part'>
                  <label>{props.dashboardDescription}</label>
                  <input
                    id='dashboardDescription'
                    className='form-control'
                    type='text'
                    defaultValue={props.data.description}
                    onChange={props.handleInputDescription} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Step1.defaultProps = {
  dashboardName: i18n.t('data_set.form.step1.input_name'),
  dashboardDescription: i18n.t('data_set.form.step1.input_description'),
  title: i18n.t('data_set.form.step1.title'),
  description: i18n.t('data_set.form.step1.description'),
}

function Step2 (props) {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-step'>
          <StepHeader number={2} title={props.title} description={props.description} />

          <div className='form-step-content'>
            <div className='row'>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className='form-part'>
                  <label>{props.dashboardDatasource}</label>
                  <SelectDatasource 
                    handleSelectDatasource={props.handleSelectDatasource}
                    selectedValue={props.data.datasource}
                    datasources={props.datasources}
                    isFetching={props.isFetching}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Step2.defaultProps = {
  dashboardDatasource: i18n.t('data_set.form.step2.input_select_datasource'),
  title: i18n.t('data_set.form.step2.title'),
  description: i18n.t('data_set.form.step2.description'),
}

export default class DataSetForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      data: props.form.data,
      isFetching: false,
      loadedFormData: false,
    }

    this.handleInputName = this.handleInputName.bind(this)
    this.handleInputDescription = this.handleInputDescription.bind(this)
    this.handleSelectDatasource = this.handleSelectDatasource.bind(this)

    this.renderButton = this.renderButton.bind(this)
  }

  renderButton() {
    let showButton
    showButton = this.state.data.datasource.value && this.state.data.name

    return(
      <div className='row'>
        <div className='col-md-12 form-button-small-section'>
          { showButton &&
            <button className='btn btn-success pull-right btn-lg'
              onClick={this.props.handleSave.bind(null, this.state.data)}>
              {this.props.submitText}
            </button>
          }
        </div>
      </div>
    )
  }


  handleSelectDatasource(value) {
    let selected_datasource
    let values = this.props.form.datasources.map((obj) => {
      return obj.values
    })

    values = [].concat.apply([], values);


    selected_datasource = values.find((data) => data.value == value)
    selected_datasource.model_value = value.split('_').slice(-1)[0]

    this.setState(() =>{                                                        
      return {                                                                  
        isFetching: false,
        loadedFormData: true,
        data: Object.assign(this.state.data, 
          {
            datasource: selected_datasource,
          }
        ),
      }
    })
  }

  handleInputName(event) {
    var value = event.target.value                                              
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {name: value})
      }                                                                         
    })
  }

  handleInputDescription(event) {
    var value = event.target.value
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {description: value})
      }                                                                         
    })
  }

  render(){
    let {props} = this;

    return (
      <section>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={props.form.display}>

          <div className='container-fluid campo__dashboard'> 
            <div className='row modal-header--wrapper'>
              <div className='col-md-10 col-sm-10 col-xs-10 title'>
                {props.newDataSetTitle}
              </div>
              <div className="col-md-2 col-sm-2 col-xs-2 close-button">
                <i className="fa fa-times fa-2x clickable pull-right" onClick={props.closeModal}></i>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <hr className='header-horizontal-rule' />
              </div>
            </div>

            <div className='row modal--content--wrapper'>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                <div className='modal--content'>
                  <Step1 data={this.state.data} 
                  handleInputName={this.handleInputName}
                  handleInputDescription={this.handleInputDescription}/>

                  <Step2 data={this.state.data} 
                  handleSelectDatasource={this.handleSelectDatasource}
                  datasources={this.props.form.datasources}/>

                  {this.renderButton()}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    )
  }
}

DataSetForm.defaultProps = {
  newDataSetTitle: i18n.t('data_set.form.new_title'),
  submitText: i18n.t('data_set.form.submit'),
}
