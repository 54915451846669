var lang = 'en'
var lang_map = {
  es: {
    completed: 'Completado',
    scheduled: 'Calendarizada',
    missed: 'No Visitada',
    unexpected: 'No esperada',
  },
  en: {
    completed: 'Completed',
    scheduled: 'Scheduled',
    missed: 'Missed',
    unexpected: 'Unexpected',
  }
}

var good_s = '<div class="m-good pull-right">{{text}}</div>'
var goode_s = '<div class="m-good pull-right">{{text}</div>'
var future_s = '<div class="m-future pull-right">{{text}}</div>'
var none_s = '<div class="m-none pull-right">{{text}}</div>'
var extra_s = '<div class="m-extra pull-right">{{text}}</div>'

function changeDate(ss) {
  full_data = ''
  if(ss.length > 0) {
    ss.forEach(function(ele) {
      var card = '<div class="card my-2"><div class="card-body"><div class="cart-title">{header}</div><div class="card-content">{body}</div></div></div>'
      var body = '<div class="">{location_description}</div><div style="font-size: 12px" class="text-muted">{form_name} | {user_name} | {scheduled_at}</div>'

      //header
      //
      lang = $('#ss-container').attr("data-lang")
      var square = good_s.replace('{{text}}', lang_map[lang].completed)
      if(ele.type == 'e') {
        square = extra_s.replace('{{text}}', lang_map[lang].unexpected)
      } else if (ele.type == 'm') {
        square = none_s.replace('{{text}}', lang_map[lang].missed)
      } else if (ele.type == 'oe') {
        square = goode_s.replace('{{text}}', lang_map[lang].completed)
      } else if (ele.type == 'f') {
        square = future_s.replace('{{text}}', lang_map[lang].scheduled)
      }
      var header = ele.location_name + square
      var s_card = card 
      s_card = s_card.replace('{header}', header)

      //body
      var new_body = body.replace('{location_description}', ele.location_description)
      new_body = new_body.replace('{form_name}', ele.form_name)
      new_body = new_body.replace('{user_name}', ele.user_name)
      new_body = new_body.replace('{scheduled_at}', ele.scheduled_at)
      s_card = s_card.replace('{body}', new_body)
      //
      full_data = full_data + s_card
    })
    $('#ss-content').html(full_data)
  } else {

    $('#ss-content').html('<div class="text-center mt-2">No Scheduled Submissions</div>')
  }
}


$(document).ready(function() {
  if($('#ss-container').attr("data-url")) {
    var url = $('#ss-container').attr("data-url")
    var base_data = JSON.parse($('#ss-container').attr("data-day"))
    lang = $('#ss-container').attr("data-lang")
    var locale = {
      format: 'MM-DD-YYYY'
    }

    if(lang == 'es' ) {
      locale.daysOfWeek =  [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ]
      locale.monthNames = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Deciembre"
      ]
    }

    changeDate(base_data)
    $('#ss-datepicker').daterangepicker({
      singleDatePicker: true,
      timePicker: false,
      locale: locale
    }, function(start, end) {
      var date = start.toISOString()
      var full_url = url + '&date=' + date
      $.ajax({
        url: full_url,
        dataType: 'json',
      }).done(function(response) {
        var ss = response.scheduled_submissions
        changeDate(ss)
      });
    })
  }
})
