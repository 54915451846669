import React, { Component } from 'react';
import { render } from 'react-dom'
import complianceApi from '../utils/compliance_api'

function ComplianceGrid (props) {
  return (
    <div className='row px-3'>
    {props.records.map(function (record, index) {
      let meta = record.meta
      let header = meta.tile_header
      let subheader = meta.tile_subheader
      let text = meta.tile_text || '-'
      let detail = meta.tile_detail
      
      let color = meta.tile_background_color
      let style = {height: '216px', backgroundColor: `rgba(${color.r},${color.g},${color.b},${color.a})`}

      if(record.clones && record.clones.length > 0) {
        style.cursor = 'pointer'
      }

      return (
        <div onClick={() => props.handleNewList(record)} key={record.name + index} className="col-lg-2 col-md-3 col-sm-4">
	        <div className="card no-border campo__tile" style={style}>
	          <div style={{zIndex: 3}} className="mt-2 tiles-inner text-center">
	            <p className='tile-title' style={{fontWeight: '700', fontSize: '12px', marginBottom: '0px'}}>{header}</p>
	            <small>{subheader}</small>
              <h2 style={{margin: '30px 0'}} className="bolded">{text}</h2>
              <small>
                {detail}
              </small>
            </div>
          </div>
        </div>
      )
    })}
    </div>
  )
}


export default class DashboardRouteCompliance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: props.form,
      current_clones: [],
      parent_clones: [],
      loading: true,
    }

    this.handleNewList = this.handleNewList.bind(this)
    this.handleBackButton = this.handleBackButton.bind(this)
  }

  componentDidMount() {
    complianceApi.getRouteData(this.state.form.id).then((response) => {
      this.setState({loading: false, current_clones: [response]})
    })
  }

  handleNewList(record) {
    if(record.clones && record.clones.length > 0) {
      let parent_clones = this.state.parent_clones
      parent_clones.push(this.state.current_clones)
      this.setState({current_clones: record.clones, parent_clones: parent_clones})
    }
  }

  handleBackButton() {
    let parent_clones = this.state.parent_clones
    let current_clones = parent_clones.slice(-1)[0]
    this.setState({
      current_clones: current_clones,
      parent_clones: this.state.parent_clones.slice(0,-1)
    })
  }


  render() {
    let back_clone = this.state.parent_clones.slice(-1)[0]
    let parent_record = null
    let disabled = ' disabled'
    if(back_clone) {
      parent_record = {
        name: back_clone[0].name || 'Global',
        result: back_clone[0].meta.tile_text
      }
      disabled = ''
    }

    return(
      <div>
        {this.state.loading
          ? ( 
            <div className='row campo__dashboard'>
              <div className='col-md-12'>
                <center>
                  <img src={this.props.loadingImageUrl}/>
                </center> 
              </div>
            </div>
          ) : (
            <div>
              {this.state.current_clones.length > 0 && 
                <div className='row campo__dashboard'>
                  <div className='col-md-12'>
                    <h3>{this.props.form.title}</h3>
                    <h5>{this.props.iCompliance}</h5>

                    {this.state.parent_clones.length > 0 ? (
                      <div className="row px-3">
                        <div className='mb-2 col-md-12 back-data'>
                          <div className='back-button mr-2'>
                            <button type="button" className={"p-4 btn btn-light " + disabled} onClick={this.handleBackButton}>
                              <i className='fa fa-chevron-left'></i> 
                            </button>
                          </div>

                          <div className='parent-data'>
                            <h5 className='mb-0'>{parent_record.name}</h5>
                            <p className='mb-0 text-muted text-uppercase'>
                              {i18n.t('route_compliance')}
                            </p>
                            <h4 className='mb-0'>{parent_record.result}</h4>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                      </div>
                    )}

                    <ComplianceGrid records={this.state.current_clones}
                      handleNewList={this.handleNewList}/>
                  </div>
                </div>
              }
            </div>
          )
        }
      </div>
    )
  }
}
