import React, { useState, useEffect } from 'react'
import tickets_api from '../../utils/pm_tickets_api'

const getTickets = (filters, sort) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [total_pages, setTotalPages] = useState(0)

  useEffect(() => {
    setLoading(true)
    tickets_api.getTickets(filters, sort).then((response) => {
      setRecords(response.data.map((t) => t.attributes))
      setTotalPages(response.meta.total_pages)
      setLoading(false)
    })
  }, [filters, sort])

  return [loading, records, total_pages]
}

export default {
  getTickets
}
