import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'

import Select from 'react-select'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const sort_values = [
  {value: 'priority', label: 'Priority'},
  {value: 'created_at', label: 'Created'},
]

const TicketsHeader = ({sort, filters, datasources, setSort, setFilters, loading, setSelectedTicket}) => {
  let show_closed_style = filters.show_closed ? {fontWeight: 700} : {fontWeight: 500}

  return(
    <div className=''>
      <div className='d-inline-block mr-3' style={{width: '200px'}}>
        <Select
          styles={selectStyles}
          value={filters.state}
          placeholder={'Status'}
          onChange={(e)=>{setFilters({...filters, state: e})}}
          isDisabled={loading}
          options={datasources.states}
          isClearable={true}
        />
      </div>
      <div className='d-inline-block mr-3' style={{width: '200px'}}>
        <Select
          styles={selectStyles}
          value={filters.user}
          placeholder={'Assignee'}
          onChange={(e)=>{setFilters({...filters, user: e})}}
          isDisabled={loading}
          options={datasources.users}
          isClearable={true}
        />
      </div>
      <div className='d-inline-block mr-3' style={{width: '160px'}}>
        <Select
          styles={selectStyles}
          value={sort}
          placeholder={'Sort by'}
          onChange={(e)=>setSort(e)}
          isDisabled={loading}
          options={sort_values}
          isClearable={true}
        />
      </div>

      <div className='d-inline-block mr-3 text-muted cursor-pointer' onClick={() => setFilters({...filters, show_closed: !filters.show_closed})}>
        <small style={show_closed_style}>
          Show Closed
        </small>
      </div>

      <button className='btn btn-primary pull-right' onClick={() => setSelectedTicket({id: 0})}>
        New Ticket
      </button>
    </div>
  )
}

export default TicketsHeader
