import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import _ from 'lodash'
import SubmissionsSidebar from './SubmissionsSidebar'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_children: false,
      son_aggregations: [],
    }

    this.getNewMap = this.getNewMap.bind(this)
  }

  getNewMap(parent) {
    if(this.state.open_children) {
      this.setState({
        son_aggregations: [],
        open_children: false,
      })
    } else {
      let new_map = _.cloneDeep(this.props.aggregations)
      new_map.forEach((bucket) => {
        let new_children = []
        bucket.children.forEach((son) => {
          if(son.key == parent.key) {
            new_children = _.cloneDeep(son.children)
            new_children.forEach((new_son) => {
              let parent_keys = (parent.parent_keys || [])
              let parent_headers = (parent.parent_headers || [])
              parent_keys.push(parent.key)
              parent_headers.push(parent.name)
              new_son.parent_keys = [...new Set(parent_keys)]
              new_son.parent_headers = [...new Set(parent_headers)]
            })
          }
        })
        bucket.children = new_children
      })

      this.setState({
        son_aggregations: new_map,
        open_children: true,
      })
    }
  }

  render() {
    let {son, children_with_sons, children_map, header_keys, header_headers, auth} = this.props
    let has_son = children_with_sons.includes(son.key)

    let chevron = this.state.open_children ? "fa fa-minus" : "fa fa-plus"

    let tr_style = {fontSize: `${this.props.font_size}px`, lineHeight: this.props.line_height}
    let td_style = {paddingLeft: `${this.props.padding_left}px`}
    let icon_style = {paddingRight: '10px'}

    return(
      <Fragment>
        <tr style={tr_style}>
          <th style={td_style}>
            { has_son &&
              <Fragment>
                <span style={icon_style} onClick={() => this.getNewMap(son)} className='pt-1 small cursor-pointer pull-left text-secondary'>
                  <i className={chevron}></i>
                </span>
                <a style={{cursor: 'pointer'}} onClick={(e) => this.getNewMap(son)}>{son.name}</a> 
              </Fragment>
            }

            { !has_son &&
              <span style={{fontWeight:'normal'}}>{son.name}</span>
            }

          </th>
          { header_keys.map((header_key, index) => {
            let data = children_map[header_key][son.key]

            if(!data) {
              return(
                <td key={header_key + son.key} className='text-center'>{'-'}</td>
              )
            } else {
              let all_filters = [header_key, son.key].concat(son.parent_keys || [])
              let td_style = {}
              if(all_filters.join() == this.props.open_filters.join()) {
                td_style = {backgroundColor: '#dad6d6'}
              }

              let has_percentage = false
              let value = data.value
              if((typeof data.value) == "string" && data.value.match(/%$/g)) {
                has_percentage = true
                value = value.replace("%", "")
              }

              return(
                <td key={header_key + son.key} className='text-center' style={td_style}>
                  <span
                    className='cursor-pointer'
                    onClick={() => this.props.handleSingleValueSelect(son, header_key, header_headers[index], data.value)}
                  >
                    {value}

                    { has_percentage &&
                      <span className='ml-1 small text-secondary'>
                        %
                      </span>
                    }

                  </span>
                </td>
              )
            }
          })}
        </tr>

        { this.state.open_children && this.state.son_aggregations.length > 0 &&
          <TableRows
            aggregations={this.state.son_aggregations}
            padding_left={this.props.padding_left + 30}
            padding_right={this.props.padding_right + 20}
            font_size={this.props.font_size - 1}
            line_height={this.props.line_height - 0.2}
            handleSingleValueSelect={this.props.handleSingleValueSelect}
            open_filters={this.props.open_filters}
          />
        }
      </Fragment>
    )
  } 
}

class TableRows extends Component {
  render() {
    let aggregations = _.cloneDeep(this.props.aggregations)

    let header_keys = []
    let header_headers = []
    let children_map = {}
    let used_children_keys = []
    let children_with_sons = []
    let full_used_children = []

    aggregations.forEach((bucket) => {
      header_keys.push(bucket.key)
      header_headers.push(bucket.name)
      children_map[bucket.key] = {}
      bucket.children.forEach((son) => {
        if(!used_children_keys.includes(son.key)) {
          used_children_keys.push(son.key)
          full_used_children.push({name: son.name, key: son.key, parent_keys: son.parent_keys, parent_headers: son.parent_headers})
          children_map[bucket.key][son.key] = son
        } else {
          children_map[bucket.key][son.key] = son
        }

        if(son.children.length > 0) {
          children_with_sons.push(son.key)
        }
      })
    })
    let padding_left = this.props.padding_left || 12
    let font_size = this.props.font_size || 16
    let padding_right = this.props.padding_right || 12
    let line_height = this.props.line_height || 1.5

    return(
      <Fragment>
        { full_used_children.map((son) => {
          return(
            <Row 
              open_filters={this.props.open_filters}
              handleSingleValueSelect={this.props.handleSingleValueSelect}
              key={son.key}
              padding_left={padding_left}
              padding_right={padding_right}
              font_size={font_size}
              line_height={line_height}
              son={son}
              aggregations={aggregations}
              children_with_sons={children_with_sons}
              header_keys={header_keys}
              header_headers={header_headers}
              children_map={children_map}
            />
          )
        })}
      </Fragment>
    ) 
  }
}

export default class DrillDownTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open_submissions: false,
      open_filters: [],
      all_headers: [],
      open_value: "",
    };

    this.handleSingleValueSelect = this.handleSingleValueSelect.bind(this);
  }

  handleSingleValueSelect(son, header_key, header_header, value) {
    if (
      !this.props.ar ||
      ![
        "submissions_count",
        "locations_reported",
        "compliance",
        "compliance_deficit",
        "compliance_deficit_location",
      ].includes(this.props.ar.query.report_type.value)
    ) {
      return;
    }

    let all_headers = [header_header].concat(son.parent_headers || []);
    all_headers.push(son.name);
    let all_filters = [header_key, son.key].concat(son.parent_keys || []);
    this.setState({
      open_submissions: true,
      open_filters: all_filters,
      all_headers: all_headers,
      open_value: value,
    });
  }

  render() {
    let { aggregations, filters, auth } = this.props;
    let headers = [""];
    let header_keys = [];

    aggregations.forEach((bucket) => {
      headers.push(bucket.name);
    });

    let column_class = this.state.open_submissions ? "col-md-7" : "col-md-12";
    if(auth.client_portal && !auth.has_submission_access) {
      column_class = "col-md-12"
    }

    return (
      <Fragment>
        <div className={column_class}>
          <div className="table-responsive mb-4">
            <table className="table mb-0 table-bordered">
              <thead>
                <tr>
                  {headers.map((header) => {
                    return (
                      <th className="text-center" key={header}>
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                <TableRows
                  aggregations={this.props.aggregations}
                  handleSingleValueSelect={this.handleSingleValueSelect}
                  open_filters={this.state.open_filters}
                />
              </tbody>
            </table>
          </div>
        </div>

        {this.state.open_submissions && (
          <Fragment>
            {(!auth.client_portal ||
              (auth.client_portal && auth.has_submission_access)) && (
              <div className="col-md-5 bg-white sticky-top ">
                <SubmissionsSidebar
                  {...this.props}
                  value={this.state.open_value}
                  filters={this.state.open_filters}
                  all_headers={this.state.all_headers}
                  close={() => {
                    this.setState({
                      open_submissions: false,
                      open_filters: [],
                      all_headers: [],
                    });
                  }}
                />
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
