import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import AnalyticsApi from '../../../utils/analytics_api'
import ContentLoader from 'react-content-loader'

const Loader = props => {
  return (
    <ContentLoader viewBox="0 0 778 116" width={778} height={116} {...props}>
      <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
      <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
      <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
      <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
      <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
    </ContentLoader>
  )
}

export default class SubmissionsSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current_submission: undefined,
      loading: true,
      submissions: [],
      loading_iframe: true,
    }

    this.loadSubmissions = this.loadSubmissions.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.hideSpinner = this.hideSpinner.bind(this)
  }

  handleBack() {
    if(this.state.current_submission) {
      this.setState({current_submission: undefined, loading_iframe: true})
    } else {
      this.props.close()
    }
  }

  hideSpinner() {
    this.setState({loading_iframe: false})
  }

  loadSubmissions() {
    let id = (this.props.ar || {}).id || 0

    this.setState({loading: true, loading_iframe: true, submissions: [], current_submission: undefined}, () => {
      AnalyticsApi.getSubmissions(id, this.props.query, this.props.filters).then((response) => {
        this.setState({submissions: response.submissions, loading: false})
      })
    })
  }

  componentWillMount() {
    this.loadSubmissions()
  }

  componentWillUpdate(nextProps, nextState) {
    if(nextProps.filters.join() != this.props.filters.join()) {
      this.loadSubmissions()
    }
  }

  render() {
    let location_style = {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }

    let label = this.state.current_submission ? "Back" : "Close"
    let icon = this.state.current_submission ? 'mr-2 pull-right text-secondary cursor-pointer fa fa-chevron-left' : 'fa fa-times text-secondary cursor-pointer pull-right mr-2'

    return(
      <Fragment>
        <div className=''>
          <div className="">
            <i style={{marginTop:'14px', fontSize: '18px'}} onClick={this.handleBack} className='' className={icon}></i>
            <div style={location_style}>
              <span className='pull-left' style={{fontWeight: 550}}>
                {this.props.all_headers.join(" / ")}
              </span>
            </div>
          </div>
          <div className="">
            {this.props.value}
          </div>
        </div>

        { this.state.loading ? (
          <div>
            <Loader />
            <Loader />
            <Loader />
            <Loader />
            <Loader />
            <Loader />
          </div>
        ) : (
          <div className='mt-2'>
            { this.state.current_submission ? (
              <div>
                { this.state.loading_iframe &&
                  <div className='text-center'>
                    <i className="fa fa-spinner fa-spin fa-1x fa-fw" aria-hidden="true"></i>
                  </div>
                }

                <iframe onLoad={this.hideSpinner} style={{borderWidth: '0px', height:'95vh'}} loading="lazy" width="100%" src={"/submissions/" + this.state.current_submission + "/webview?drilldown=true"}>
                </iframe>
              </div>
            ) : (
              <div className='overflow-auto' style={{maxHeight: '500px'}}>
                { this.state.submissions.map((submission) => {
                  return(
                    <div key={submission.id} className="card mb-1 cursor-pointer" onClick={() => {this.setState({current_submission: submission.id, loading_iframe: true})}}>
                      <div className="card-body p-3" >
                        <div className='d-inline-block' style={{width: '70%'}}>
                          <h6 className='mb-0' style={location_style}>{submission.location}</h6>
                          <small>{submission.submitted_at}</small>
                        </div>
                        <div className='d-inline-block' style={{width: '30%'}}>
                          <i className='text-secondary fa fa-chevron-right pull-right ml-2'></i>

                          { submission.compliance &&
                            <h5 className="mb-0 d-inline-block pull-right" style={{marginTop: '-4px', color: submission.color}}>
                              {submission.compliance}%
                            </h5>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )}
     </Fragment>
    )
  }
}

