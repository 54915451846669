import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token


//Datasources

function getFormsDataSource(entity_type, entity_id) {
  let url = '/form_data_sets/datasources.json';
  return axios.get(url).then((response) => {
      return(response.data.datasources);
    });
}

//Insights
function getDataSource(entity_type, entity_id) {
  let url;
  if(entity_type){
    url = `/insights/datasources.json?entity_type=${entity_type}&entity_id=${entity_id}`;
  }
  else {
    url = '/insights/datasources.json';
  }

  return axios.get(url).then((response) => {
    return(response.data)
  });
}

function getDataSourceTypes(datasource) {
  return axios.get(`/insights/dashboard_types.json?datasource=${datasource.model_value}&datatype=${datasource.type}`).then(response => response.data)
}

function createInsight(data, entity_type, entity_id) {
  let url;
  if (entity_type && entity_id ) {
    url = `/insights?entity_type=${entity_type}&entity_id=${entity_id}`;
  } else {
    url = '/insights';
  }

  return axios.post(url, { insight: data }).then((response) => {
    return response.data
  });
}

function getInsights(entity_type, entity_id, type){
  let url = ''

  if (entity_type && entity_id) {
    url = `/insights.json?entity_type=${entity_type}&entity_id=${entity_id}&type=${type}`;
  } else {
    url = `/insights.json?type=${type}`;
  }

  return axios.get(url).then((response)=>{
    return response.data;
  })
}

function getInsight(dashboardID){
  return axios.get(`/insights/${dashboardID}.json`).then((response)=>{
    return response.data
  })
}

function getS3File(url) {
  return axios.get(url).then(response => response.data)
}

function deleteInsight(id) {
  return axios.delete(`/insights/${id}.json`)
}

function updateInsight(data) {
  return axios.put(`/insights/${data.id}.json`, data).then((response) => {
    return response.data
  })
}


module.exports = {
  getFormsDataSource: getFormsDataSource,
  getDataSource: getDataSource,
  getDataSourceTypes: getDataSourceTypes,
  createInsight: createInsight,
  getInsights: getInsights,
  getInsight: getInsight,
  getS3File: getS3File,
  deleteInsight: deleteInsight,
  updateInsight: updateInsight,
}
