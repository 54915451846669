import React, { Component } from 'react'
import resourcesApi from '../../utils/resources_api'
import InsightCounter from '../Chart/InsightCounter'
import InsightTable from '../Chart/InsightTable'
import InsightColumnChart from '../Chart/InsightColumnChart'
import InsightLineChart from '../Chart/InsightLineChart'
import InsightPieChart from '../Chart/InsightPieChart'
import InsightDrillDown from '../Chart/InsightDrillDown'

import EditInsightButton from './EditInsightButton'

function InsightActions(props) {
  return(
    <div className='dashboard-options--wrapper'>
      <div className='dashboard-options'>
      { props.data.show_edit && 
        <EditInsightButton 
          dashboard_groups={props.dashboard_groups} 
          handleUpdate={props.handleUpdate} 
          empty_dashboard_group_label={props.empty_dashboard_group_label}
          entity_id={props.entity_id}
          insight={props.data}/>
      }

      { props.data.show_delete && 
        <button
          className='btn btn-link btn-delete'
          onClick={props.handleDelete}>
          {props.deleteText}
        </button>
      }
      </div>
    </div>
  )
}

InsightActions.defaultProps = {
  editText: i18n.t('insight.edit'),
  deleteText: i18n.t('insight.destroy')
}
 

class Insight extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.data, timeFetching: 0, showReload: false}
    this.shouldFetchData = this.shouldFetchData.bind(this);
    this.reloadInsight = this.reloadInsight.bind(this);
    this.getS3File = this.getS3File.bind(this);
    this.getChart = this.getChart.bind(this);
    this.getColumnSize = this.getColumnSize.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    this.handleReload = this.handleReload.bind(this)

    this.getPollingInteral = this.getPollingInterval(this);
  }

  handleReload() {
    this.setState({timeFetching: 0, showReload: false}, () => this.reloadInsight())
  }

  getPollingInterval() {
    let timeFetching = this.state.timeFetching
    if(timeFetching >= 0 && timeFetching < 20000) {
      return 5000
    } else if(timeFetching >= 20000 && timeFetching < 60000) {
      return 10000
    } else if(timeFetching >= 60000 && timeFetching < 120000) {
      return 20000
    } else if(timeFetching >= 120000 && timeFetching < 300000) {
      return 30000
    } else {
      return 30000
    }
  }

  handleUpdate(new_data) {
    this.props.updateInsight(new_data)
  }

  handleDelete() {
    let response = window.confirm(i18n.t('confirm_delete_message'))

    if (response) {
      resourcesApi.deleteInsight(this.state.id).then(() => {
        this.props.removeInsightFromList(this.state.id)
      })
    }
  }

  shouldFetchData(){
    return(this.state.processing || this.state.data_url == undefined)
  }

  reloadInsight() {
    if(this.state.dashboard_type != 'drill_down') {
      resourcesApi.getInsight(this.state.id).then((insight) => {
        clearInterval(this.state.reloadInterval)
        if (insight.processing) {
          let interval = this.getPollingInterval()
          let timeFetching = this.state.timeFetching + interval
          if(timeFetching > 300000) {
            this.setState(() => {
              return {
                showReload: true,
              }
            })
          } else {
            this.setState(() => {
              return {
                timeFetching: timeFetching,
                reloadInterval: setInterval(this.reloadInsight, interval)
              }
            })
          }
        } else {
          this.setState({...insight}, () => this.getS3File())
        }
      })
    }
  }

  getS3File() {
    resourcesApi.getS3File(this.state.data_url).then((response) => {
      this.setState(() => {
        return {data: response}
      })
    }).catch(() => {
      setTimeout(() => {
        this.getS3File();
      }, 2000)
    })
  }

  componentDidMount(){
    if(this.shouldFetchData()) {
      this.setState(() => {
        return {
          reloadInterval: setInterval(this.reloadInsight, 1000)
        }
      })
    } else {
      this.getS3File()
    }
  }

  getChart(data) {
    data = Object.assign(data, {handleReload: this.handleReload})
    if(data.dashboard_type == 'graph' && data.data && data.data.isStacked) {
      return(<InsightColumnChart {...data} />)
    }

    switch(data.dashboard_type) {
      case 'single_value':
      return <InsightCounter
      {...data}
      />;
      case 'drill_down':
      return <InsightDrillDown
      {...data}
      />;

      case 'table':
      return <InsightTable
      {...data}
      />;
      case 'graph':
      switch (data.chart_type) {
        case 'bar_chart':
        return <InsightColumnChart
        {...data}
        />;
        case 'line_chart':
        return <InsightLineChart
        {...data}
        />;
        case 'pie_chart':
        return <InsightPieChart
        {...data}
        />;
      }
      default:
        return (
          <div key={data.id}>
          </div>
        )
    }
  }

  getColumnSize(data) {
    switch(data.dashboard_type) {
      case 'single_value':
        return 'column is-quarter'
      case 'table':
        const colLength = (data.data) ? data.data.headers.length + 1 : 0;
        let size = 'column ';
       if (colLength <= 4) {
          size += 'is-half';
        } else {
          size += 'is-full';
        }
        return size
      case 'graph':
        return 'column is-half chart-wrap'
      case 'drill_down':
        return 'column is-full'
      default:
        return 'column is-half'
    }
  }

  render() {
    let chart = this.getChart(this.state)
    let size = this.getColumnSize(this.state)

    return(
      <div className={size}>
        <div className='card dashboard'>
          { !this.props.webview && (this.state.show_edit || this.state.show_delete) &&
            <InsightActions 
              data={this.state}
              entity_id={this.props.entity_id}
              empty_dashboard_group_label={this.props.empty_dashboard_group_label}
              dashboard_groups={this.props.dashboard_groups}
              handleUpdate={this.handleUpdate}
              handleDelete={this.handleDelete} />
          }
          <div>
            {chart}
          </div>
        </div>
      </div>
    )
  }
}

export default Insight;
