var app = window.app || {};
app.calendar = app.calendar || {};

$.extend(app.calendar, new function () {
    var module = {
        drawCalendar: drawCalendar,
        eventsByWeekId: eventsByWeekId,
        eventsByCalendarWeekId: eventsByCalendarWeekId,
        weekSelector: weekSelector
    };

    return module;

    function AutoSaveEventsCounter(){
        var _eventsCount = 0;
        var _eventsBeforeSave = 10;

        return { increment: increment };

        function increment(){
            _eventsCount++;
            return _shouldSave();
        }

        function _shouldSave(){
            return (_eventsCount % _eventsBeforeSave == 0)
        }

    }

    function eventsByCalendarWeekId(id){
        events = $(weekSelector(id)).fullCalendar('clientEvents');
        return events;
    }

    function eventsByWeekId(id){
        var weekId = '#week_{0}'.replace('{0}', id);
        var eventsEl = $(weekId);
        var eventsData = eventsEl.data('week-events') || [];
        var events = $.map(eventsData, function(item){
            return JSON.parse(item);
        });
        return events;
    }

    function weekSelector(id){
        var selector = '.calendar[data-week-number="{0}"]'.replace('{0}', id);
        return selector;
    }

    function saveCalendar(){
        app.calendarGoals.save(
            function(data){
                app.calendar.notifications.update(data.message);
            },
            function(data){
                app.calendar.notifications.update(data.errors);
            },
            function(data){
                app.calendar.notifications.update('No pudimos guardar la información en estos momentos. Por favor verificar conectividad de internet', 5000);
            }
        );
    }

    function drawCalendar(){
        _counter = new AutoSaveEventsCounter();

        $('.calendar').fullCalendar({
            header: {
                left: '',
                center: '',
                right: ''
            },
            firstDay: 1,
            defaultDate: '2015-01-05',
            titleFormat: '',
            contentHeight: 'auto',
            defaultView: 'visitGoal',
            views: {
                visitGoal: {
                    type: 'basicWeek',
                    columnFormat: 'ddd'
                }
            },
            editable: true,
            droppable: true,
            eventOrder: function(event1, event2){
                return event1.order - event2.order;
            },
            eventDrop: function(event, delta){
                if (_counter.increment()) {
                    saveCalendar();
                }
            },
            loading: function(isLoading, view){
                if(!isLoading){
                    $('.calendar-weeks-container').removeClass('invisible');
                    $('.calendar-weeks-box .calendar-loading').addClass('d-none');
                }
            },
            drop: function(date, allDay, ui, view) {

                var originalEventObject = $(this).data('eventObject');

                // we need to copy it, so that multiple events don't have a reference to the same object
                var copiedEventObject = $.extend({}, originalEventObject);

                copiedEventObject.start = date;
                copiedEventObject.allDay = allDay;

                var lastSibling = view.calendar.clientEvents(function(event){
                    return event.start.date() == date.date();
                }).pop();
                var sortOrder = (lastSibling && lastSibling.order + 1) || 1;

                copiedEventObject.order = sortOrder;
                view.calendar.renderEvent(copiedEventObject, true);
                if (_counter.increment()) {
                    saveCalendar();
                }
            },
            eventClick: function(calEvent, jsEvent, view) {
                view.calendar.removeEvents(calEvent._id);
                if (_counter.increment()) {
                    saveCalendar();
                }
            },
            events: function(start, end, timezone, callback) {
                var el = this.getView().el.parent().parent('.calendar');
                var weekNumber = el.data('week-number');
                var events = app.calendar.eventsByWeekId(weekNumber);
                callback(events);
            }
        });
    }
}());
