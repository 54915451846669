import React, { Component, useState, useEffect } from 'react';
import { render } from 'react-dom';
import Attachments from '../components/FormDesignDocumentFolders/Attachments.js'
import Select from 'react-select'
import axios from 'axios'

let selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const FieldDocumentView = ({field, form_id, document_key, parent_key, setDesign}) => {
  const [documents, setDocuments] = useState([])

  let params = {
    document_key,
    field_key: field.key,
    parent_key: parent_key,
  }

  const loadFiles = () => {
    axios.get(`/web_api/forms/${form_id}/documents`, {params: params}).then((response) => {
      let documents = JSON.parse(response.data.documents).data.map(file => file.attributes)
      setDesign(JSON.parse(response.data.design))
      setDocuments(documents)
    })
  }

  const removeFile = (file) => {
    let id = file.id || file.document_id

    axios.delete(`/web_api/forms/${form_id}/documents/${id}`, {params: params}).then((response) => {
      loadFiles()
    })
  }

  useEffect(() => {
    loadFiles()
  }, [])

  return(
    <Attachments
      can_create_attachment={true}
      documents={documents}
      setDocuments={setDocuments}
      form_id={form_id}
      document_key={document_key}
      removeFile={removeFile}
    />
  )
}

const Field = ({field, form_id, parent_document_key, parent_key, setDesign, dynamic_lists}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  let document_key = field.document_reference_filter1 || `forms::${form_id}::${field.key}`

  if(parent_document_key) {
    document_key = field.document_reference_filter1 || `${parent_document_key}::${field.key}`
  }

  let selected_dynamic_list = dynamic_lists.find(e => e.value == parseInt(field.document_reference_list_id))

  const handleSelect = (e) => {
    setLoading(true)

    let params = {
      document_key,
      field_key: field.key,
      parent_key: parent_key,
      dynamic_list: e
    }

    axios.post(`/web_api/forms/${form_id}/document_update_dynamic_list`, params).then((response) => {
      setDesign(JSON.parse(response.data.design))
      setLoading(false)
    })
  }

  return(
    <div className='my-2'>
      <div className=''>
        <h6 className='d-inline-block mr-2 mb-0'>{field.label}</h6>

        { (!open && !field.document_reference_filter1) &&
          <span onClick={() => setOpen(e => !e)} className='small cursor-pointer text-primary'>Add</span>
        }

        {/* { (open || field.document_reference_filter1) &&
          <div className='pull-right' style={{width: '200px'}}>
            <Select
              styles={selectStyles}
              value={selected_dynamic_list}
              placeholder={'Dynamic list'}
              onChange={handleSelect}
              isDisabled={loading}
              options={dynamic_lists}
            />
          </div>
        } */}

      </div>

      { (open || field.document_reference_filter1) &&
        <FieldDocumentView 
          field={field}
          form_id={form_id}
          document_key={document_key}
          parent_key={parent_key}
          setDesign={setDesign}
        />
      }

      { field.type == "subform" &&
        <div className='px-4'>
          { field.subfields.map((son_field) => {
            let parent_key = field.key

            return(
              <Field 
                key={son_field.key}
                field={son_field}
                form_id={form_id}
                parent_document_key={document_key}
                parent_key={parent_key}
                setDesign={setDesign}
                dynamic_lists={dynamic_lists}
              />
            )
          })}
        </div>
      }
    </div>
  )
}

const FormDesignDocumentFolders = (props)=> {
  const [design, setDesign] = useState(props.design)

  return(
    <div className=''>
      { design.map((field) => 
        <div className='row' key={field.key}>
          <div className='col-12' style={{borderBottom: '1px solid grey'}}>
            <Field 
              field={field}
              form_id={props.form_id}
              setDesign={setDesign}
              dynamic_lists={props.dynamic_lists}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FormDesignDocumentFolders
