import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

function ShowMore(props) {
  return(
    <div className="row">
      <div className="col-auto p-0">
        <label className="footer-campo__text">Show</label>
      </div>
      <div className="col-auto">
        <select value={props.selected}
          onChange={props.onSelect}
          className="form-control form-control-sm form-campo__select--sm">
          {props.options.map((value, i) => {
            return(<option value={value} key={i}>{value}</option>)
          })}
        </select>
      </div>
      <div className="col-auto p-0">
        <span className="footer-campo__text">Result per page</span>
      </div>
    </div>
  )
}
export default ShowMore
