import React, { Component, Fragment } from 'react';
import { render } from 'react-dom'
import axios from 'axios'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default class SubmissionShare extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_url: "",
      open: false,
    }

    this.shareClick = this.shareClick.bind(this)
  }

  shareClick() {
    if(this.state.open) {
      this.setState({share_url: "", open: false})
    } else {
      let url = `/submissions/${this.props.record.id}/public_url`

      axios.get(url).then((response) => {
        this.setState({share_url: response.data.public_url, open: true})
      })
    }
  }

  componentDidMount() {
    if(document.getElementById("share-link")) {
      document.getElementById("share-link").addEventListener("click", this.shareClick)
    }
  }

  componentWillUnmount() {
    if(document.getElementById("share-link")) {
      document.getElementById("share-link").removeEventListener("click", this.shareClick)
    }
  }

  render() {
    let btn_style = "btn btn-primary"
    let btn_text = "Copy"
    if(this.state.copied) {
      btn_style = "btn btn-success"
      btn_text = "Copied"
    }
    return(
      <Fragment>
        { this.state.open &&
          <div className="bg-white p-3 border rounded mb-2">
            <div className="input-group">
              <input type="text" className="form-control" style={{fontSize: '12px'}} value={this.state.share_url}/>
              <div className="input-group-append" style={{width: 'auto'}}>
                <CopyToClipboard text={this.state.share_url}
          onCopy={() => this.setState({copied: true})}>
                <button className={btn_style} style={{fontSize: '12px'}} type="button">{btn_text}</button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        }
      </Fragment>
    )
  }
}
 
