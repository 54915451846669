import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

function getSelectors(type) {
  let url =  '/forms/location_selectors?type=' + type
  return axios.get(url).then(response => response.data)
}

function updateAssignation(id, assignation_type, assignation_data) {
  let url = '/forms/' + id + '/update_assignation'

  return axios.post(url, {assignation_type: assignation_type, assignation_data: assignation_data}).then(response => response.data);
}

module.exports = {
  getSelectors: getSelectors,
  updateAssignation: updateAssignation,
}
