import React, { Component } from 'react'
import { render } from 'react-dom'

import ImagesApi from '../../utils/images_api'
import FilterModal from '../Images/FilterModal'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "../Images/translations/global.json"

import { toast } from 'react-toastify'
import moment from 'moment'

class GalleriesShowNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edit_modal_open: false,
      gallery_keys: [],
      name: props.gallery.name,
      public_show_only_favorites: props.gallery.public_show_only_favorites,
      public_hide_flagged: props.gallery.public_hide_flagged,
      filters: {
        search: props.search || '',
        locations: [],
        chains: [],
        chain_categories: [],
        location_types: [],
        zones: [],
        users: [],
        supervisors: [],
        ca: [],
        cb: [],
        cc: [],
        cd: [],
        form: {},
        start_date: null,
        end_date: null,
      },
      disabled: {
        locations: false,
        chain_categories: false,
        chains: false,
        users: false,
        supervisors: false,
        zones: false,
        location_types: false,
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    })

    this.openEditModal = this.openEditModal.bind(this)
    this.closeEditModal = this.closeEditModal.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleState = this.handleState.bind(this)
    this.handleName = this.handleName.bind(this)
    this.handlePublicFavorites = this.handlePublicFavorites.bind(this)
    this.handlePublicFlagged = this.handlePublicFlagged.bind(this)
  }

  handleName(event) {
    this.setState({name: event.target.value})
  }

  handlePublicFavorites(event) {
    this.setState({public_show_only_favorites: event.target.checked})
  }

  handlePublicFlagged(event) {
    this.setState({public_hide_flagged: event.target.checked})
  }

  handleDelete() {
    ImagesApi.deleteGallery(this.props.gallery.id).then((response) => {
      window.location = '/galleries'
    })
  }

  handleSubmit(filters) {
    ImagesApi.updateGallery(this.props.gallery.id, {
      name: this.state.name,
      public_show_only_favorites: this.state.public_show_only_favorites,
      public_hide_flagged: this.state.public_hide_flagged,
      filters: filters
    }).then((response) => {
      this.props.handleUpdatedGallery(response.data.attributes)
      this.closeEditModal()
    })
  }

  handleState(new_state) {
    this.setState(new_state)
  }

  openEditModal() {
    let gallery_form_id = this.props.gallery.filters.form
    if(gallery_form_id) {
      ImagesApi.getFormKeys(gallery_form_id).then((response) => {
        let keys = response.keys.map((record) => ({value: record.slug, label: record.full_name_count, form_id: record.form_id}))
        this.setState({edit_modal_open: true, gallery_keys: keys})
      })
    } else {
      this.setState({edit_modal_open: true, gallery_keys: []})
    }
  }

  closeEditModal() {
    this.setState({edit_modal_open: false, gallery_keys: []})
  }

  componentWillMount() {
    let gallery_form_id = this.props.gallery.filters.form
    if(gallery_form_id) {
      ImagesApi.getFormKeys(gallery_form_id).then((response) => {
        let keys = response.keys.map((record) => ({value: record.slug, label: record.full_name_count, form_id: record.form_id}))
        this.setState({gallery_keys: keys})
      })
    }
  }

  render() {
    let gallery = this.props.gallery

    return (
      <div>
        <section className="images-filters bg-white">
          <div className="container-fluid border-top">
            <div className="container p-0 pl-sm-3 pr-sm-3">
              <div className="row no-gutters">
                <nav className="w-100">
                  <div className="nav nav__sub-header py-2 justify-content-end">
                    <a className="gallery-name">{gallery.name}</a>
                    {this.props.auth.create && (
                      <a
                        onClick={this.openEditModal}
                        className="nav-item nav-link py-1"
                      >
                        Edit Gallery
                      </a>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>

        {this.state.edit_modal_open && (
          <FilterModal
            comesFromGallery={true}
            editingGallery={this.props.gallery}
            name={this.state.name}
            public_show_only_favorites={this.state.public_show_only_favorites}
            public_hide_flagged={this.state.public_hide_flagged}
            closeSearchModal={this.closeEditModal}
            isOpen={this.state.edit_modal_open}
            filters={this.state.filters}
            disabled={this.state.disabled}
            handleName={this.handleName}
            handlePublicFavorites={this.handlePublicFavorites}
            handlePublicFlagged={this.handlePublicFlagged}
            handleSubmit={this.handleSubmit}
            handleDelete={this.handleDelete}
            handleState={this.handleState}
            language={this.props.language}
            keys={this.state.gallery_keys}
          />
        )}
      </div>
    );
  }
}

export default withLocalize(GalleriesShowNav)
