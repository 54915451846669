var app = window.app || {};
app.calendar = app.calendar || {};
app.calendar.notifications = app.calendar.notifications || {};

$.extend(app.calendar.notifications, new function(){

    return {
        update: updateNotifcation
    };

    function updateNotifcation(message, delayTime){
        $('#status-panel .status-message')
        .html(message)
        .removeClass('inactive')
        .delay(delayTime || 2000)
        .queue(function(next) { $(this).addClass('inactive'); next(); })
    }
});
