import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectUsers extends Component {
  constructor(props) {
    super(props);
    this.renderUsers = this.renderUsers.bind(this);
  }

  renderUsers() {
    let selected_users = this.props.users.map((data) => data.id);
    let user_filters = this.props.user_filters

    let filter_values = user_filters.map(a => a.id);

    selected_users = selected_users.map((value) => {
      let new_value = filter_values.includes(value) ? value : ("user_" + value + " (" + i18n.t('deleted') + ")")
      return new_value
    })

    let userOptions = user_filters.map((options) => {
      return (
      <Option key={options.id} text={options.name} value={options.id}>
      {options.name}
      </Option>
      );
    })

    return (
      <Select
        placeholder={this.props.selectMultipleText}
        value={selected_users}
        multiple={true}
        optionLabelProp={'text'}
        onChange={this.props.handleSelect}
        dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
        style={{ width: '100%' }}
        dropdownStyle={{ zIndex: 101 }}
        >
        {userOptions}
      </Select>
    )
  }

  render() {
    return (
      <div>{this.renderUsers()}</div>
    );
  }
}

SelectUsers.defaultProps={
  selectMultipleText: i18n.t('insight.form.multiple_select'),
}
