import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import {_} from 'lodash'
import helpers from './helpers'

import Popover from 'react-tiny-popover'
import SubmissionPopover from './SubmissionPopover'

class SubmissionItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_popover_open: false
    }

    this.closePopover = this.closePopover.bind(this)
  }

  closePopover() {
    this.setState({is_popover_open: false})
  }

  render() {
    let { item, report_type, icons } = this.props
    let extra_class = 'good'
    let component = (<div></div>)
    let style = {}

    if(item.t == 'oe') {
      if(item.s_t == 'ofa') {
        component = (<img className='se_circle_icon' src={icons.se_circle} />)
      } else if(item.s_t == 'ex') {
        component = (<img className='se_dot_icon' src={icons.se_dot} />)
      } else if(item.s_t == 'ex_ofa') {
        component = (<img className='se_dot_circle_icon' src={icons.se_dot_circle} />)
      }
    }

    if(item.t == 'oes') {
      component = (<i className='fa fa-minus'></i>)
    }

    if(report_type == 'submissions' && item.c.h) {
      style = {
        color: '#fff',
        backgroundColor: item.c.c,
        borderColor: item.c.c,
      }

      if(item.t == 'oe') {
        component = (<i style={{color: 'white'}} className='fa fa-circle'></i>)
        if(item.s_t == 'ofa') {
          component = (<img className='se_circle_icon' src={icons.se_circle} />)
        } else if(item.s_t == 'ex') {
          component = (<img className='se_dot_icon' src={icons.se_dot} />)
        } else if(item.s_t == 'ex_ofa') {
          component = (<img className='se_dot_circle_icon' src={icons.se_dot_circle} />)
        }
      }
    }

    if(item.t == 'e') {
      extra_class='extra'
      if(item.s_t == 'ofa') {
        component = (<img className='se_circle_icon' src={icons.se_circle} />)
      } else if(item.s_t == 'ex') {
        component = (<img className='se_dot_icon' src={icons.se_dot} />)
      } else if(item.s_t == 'ex_ofa') {
        component = (<img className='se_dot_circle_icon' src={icons.se_dot_circle} />)
      }

    }

    if(item.t == 'm') {
      extra_class='none'
    }

    if(item.t == 'f') {
      extra_class='future'
    }

    let id = ''
    
    if(item.s == 's') {
      id = `subs${item.id}`
    } else {
      id = `item${item.id}`
    }

    return(
      <Popover
        isOpen={this.state.is_popover_open}
        position={['bottom','left','right','top']}
        padding={2}
        onClickOutside={() => this.setState({ is_popover_open: false })}
        disableReposition={false}
        content={(props) => {
          return (
            <SubmissionPopover {...props} selected_id={id} closePopover={this.closePopover}/>
          )
        }}
  >
        <div style={style} id={id} onClick={()=> this.setState({is_popover_open: !this.state.is_popover_open})} className={"submission-item " + extra_class}>
          {component}
        </div>
      </Popover>
    )
  }
}

const Day = (props) => {
  let items = props.values
  let report_type = props.report_type
  let is_current = props.is_current
  let column = "col"
  if(is_current) {
    column = "col current-day"
  }

  let ok = []
  let extra = []
  let missing = []
  if (report_type == 'submissions') {
    let sorted_values = _.sortBy(items, function(o) { return o.c.v }).reverse()
    sorted_values.forEach((item) => {
      if(item.c.v || item.c.v == 0) {
        ok.push(item)
      } else {
        missing.push(item)
      }
    })
    
  } else {
    items.forEach((item) => {
      if(item.t == 'oe' || item.t == 'o' || item.t == 'oes') {
        ok.push(item)
      } else if (item.t == 'e') {
        extra.push(item)
      } else {
        missing.push(item)
      }
    })
  }

  return(
    <div className={column}>
      <div className='campo__week-day-title text-center text-muted small mb-2 mt-3'>
        {i18n.t('explore.sday.' + props.day)}
      </div>

      <div className='campo__week-day-submissions'>
        { ok.map((item) => {
          return(
            <SubmissionItem 
              icons={props.icons}
              report_type={report_type}
              key={item.id}
              item={item}
            />
          )
        })}
        { extra.map((item) => {
          return(
            <SubmissionItem 
              icons={props.icons}
              report_type={report_type}
              key={item.id}
              item={item}
            />
          )
        })}
        { missing.map((item) => {
          return(
            <SubmissionItem 
              icons={props.icons}
              report_type={report_type}
              key={item.id}
              item={item}
            />
          )
        })}
      </div>
    </div>
  )
}

const Week = (props) => {
  let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'sat', 'sun']
  let {week, values} = props.week
  let {total_submissions, only_routes, completed, extra, percentage, groups} = helpers.statistics(values)

  return(
    <div className='campo__week mt-2 pb-4'>
      <div className='campo__week-title pb-1'>
        <div className='row'>
          <div className='col-md-3'>
            <h6 className='mt-4 text-secondary text-uppercase font-weight-bold'>{week[0]} - {week[1]}</h6>
          </div>

          { props.report_type == 'route_compliance' ? (
            <div className='col-md-9'>
              <div className='d-inline pull-right px-3 pt-2'>
                <h6 className='mb-0 text-center'>{extra}</h6>
                <span className='text-muted text-uppercase small'>{i18n.t('explore.extra')}</span>
              </div>


              <div className='d-inline pull-right px-3 pt-2'>
                <h6 className='mb-0 text-center'>{percentage}</h6>
                <span className='text-muted text-uppercase small'>{i18n.t('explore.completed')}</span>
              </div>

              <div className='d-inline pull-right px-3 pt-2'>
                <h6 className='mb-0 text-center'>{completed} / {only_routes}</h6>
                <span className='text-muted text-uppercase small'>{i18n.t('explore.total_routes')}</span>
              </div>

              <div className='d-inline pull-right px-3 pt-2'>
                <h6 className='mb-0 text-center'>{total_submissions}</h6>
                <span className='text-muted text-uppercase small'>{i18n.t('explore.total_reports')}</span>
              </div>
            </div>
          ) : (
            <div className='col-md-9'>
              <div className='d-inline pull-right px-3 pt-2'>
                <h6 className='mb-0 text-center'>{total_submissions}</h6>
                <span className='text-muted text-uppercase small'>{i18n.t('explore.total_reports')}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='campo__week-submissions'>
        <div className='campo__week-day'>
          <div className='row'>
            { days.map((day, index) => {
              let is_current = false
              if(week[2] && week[2].wday == index + 1) {
                is_current = true
              }
              return(
                <Day
                  icons={props.icons}
                  report_type={props.report_type}
                  key={props.week_key + day}
                  day={day}
                  values={groups[index]}
                  is_current={is_current}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Week
