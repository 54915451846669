import React, { Component } from 'react'
import { render } from 'react-dom'

import { renderToStaticMarkup } from "react-dom/server"
import { withLocalize, Translate } from "react-localize-redux"
import globalTranslations from "../Images/translations/global.json"

import GalleryShareModal from "./GalleryShareModal"
import ImagesApi from '../../utils/images_api'

class GallerySharesIndexNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_modal_open: false,
      name: '',
      gallery_share: {
        name: '',
        description: '',
        galleries: [],
      }
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Spanish", code: "es" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: props.language
      },
    })

    this.openSearchModal = this.openSearchModal.bind(this)
    this.closeSearchModal = this.closeSearchModal.bind(this)
    this.handleData = this.handleData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleData(value, type) {
    let gallery_share = this.state.gallery_share
    gallery_share[type] = value
    this.setState({gallery_share: gallery_share})
  }


  handleSubmit() {
    let gallery_share = this.state.gallery_share
    if(!gallery_share.name || !gallery_share.description || gallery_share.galleries.length == 0) {
      return
    }

    ImagesApi.createGalleryShare({
      gallery_share: gallery_share,
    }).then((response) => {
      this.setState({search_modal_open: false, gallery_share: {name: '', description: '', galleries: []}}, () => {
        this.props.handleCreatedGalleryShare()
      })
    })
  }

  openSearchModal() {
    this.setState({search_modal_open: true})
  }

  closeSearchModal() {
    this.setState({search_modal_open: false})
  }

  render() {
    return(
      <div>
        { this.props.activeLanguage &&
        <div>
          <section className='images-filters bg-white'>
            <div className="container-fluid border-top">
              <div className="container p-0 pl-sm-3 pr-sm-3">
                <div className="row no-gutters">
                  <nav className="w-100">
                    <div className="nav nav__sub-header py-2 justify-content-end">
                      <a onClick={this.openSearchModal} className="nav-item nav-link py-1">
                        <Translate id="gallery_shares.index.nav.new" />
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </section>
          <GalleryShareModal 
            search_modal_open={this.state.search_modal_open}
            closeSearchModal={this.closeSearchModal}
            galleries={this.props.galleries}
            gallery_share={this.state.gallery_share}
            handleData={this.handleData}
            handleSubmit={this.handleSubmit}
          />
        </div>
        }
      </div>
    )
  }
}

export default withLocalize(GallerySharesIndexNav)
