import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectDatasource extends Component {
  constructor(props) {
    super(props);
    this.renderFormTypes = this.renderFormTypes.bind(this);
  }

  renderFormTypes() {
    if (this.props.datasources.length > 0) {
      return (
        <Select
          dropdownClassName='simple-group-dropdown'
          value={this.props.selectedValue.label}
          onChange={this.props.handleSelectDatasource}
          placeholder={ this.props.selectDatasourcePlaceHolderText }
          style={{width: '100%'}}
          disabled={this.props.isFetching}
          optionFilterProp="label"
          dropdownStyle={{zIndex: 101}}
          filterOption={filterOptions}>
          {this.props.datasources.map((data) => {
            return (
            <OptGroup key={data.label} label={data.label} value="">
              {data.values.map((info) => <Option label={info.label} key={info.value} value={info.value}>{info.label}</Option>)}
            </OptGroup>
            )
          })}
        </Select>
      )
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.renderFormTypes()}
        </div>
      </div>
    );
  }
}

SelectDatasource.defaultProps={
  selectDatasourcePlaceHolderText: i18n.t('dashboard_form.placeholder_select_datasource')
}
