$(document).ready(function(){
  $('#post_publish_now_true').click(function(){
    $('.schedule-container').addClass('hidden')
  })

  $('#post_publish_now_false').click(function(){
    $('.schedule-container').removeClass('hidden')
  })

  $('#post_expires_true').click(function(){
    $('.expire-container').removeClass('hidden')
  })

  $('#post_expires_false').click(function(){
    $('.expire-container').addClass('hidden')
  })

  $('#post_start_at').daterangepicker({
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true,
    minDate: new Date(),
    locale: {
      format: 'MM-DD-YYYY H:mm'
    }
  });

  $('#post_expire_at').daterangepicker({
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true,
    minDate: new Date(),
    locale: {
      format: 'MM-DD-YYYY H:mm'
    }
  });

})
