import React, { Component } from 'react'
import BaseInsightComponent from './BaseInsightComponent'
import PropTypes from 'prop-types'

class InsightLineChart extends BaseInsightComponent {
  chartConfig(){
    return {
      chart: {
        type: 'line'
      },
      title: {
        text: this.props.name
      },
      subtitle: {
        text: this.props.description
      },
      xAxis: {
        categories: this.props.data.labels,
      },
      yAxis: {
        min: 0,
        title: {
          text: this.props.data.yText
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        useHTML: true,
        shared: true
      },
      series: this.props.data.series,
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ["printChart",
              "separator",
              "downloadXLS",
              "downloadCSV",
              "separator",
              "downloadPNG",
              "downloadPDF",
            ]
          }
        }
      }
    };
  }

}

InsightLineChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  yText: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number)
  }))
}
InsightLineChart.defaultProps = {
  title: '',
  subtitle: '',
  labels: [],
  yText: '',
  series: []
}

export default InsightLineChart;
