import React, { Component } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'

import ImagesApi from '../../utils/images_api'

import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment from 'moment'

import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async'

import { withLocalize, Translate } from "react-localize-redux"

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 1040
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '0px'
  }
}

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const keyMap = {
  search: 'search',
  form: 'form',
  users: 'user',
  supervisors: 'supervisor',
  locations: 'location',
  chain_categories: 'chain_category',
  chains: 'chain',
  location_types: 'location_type',
  zones: 'zone',
  ca: 'form_field_classifier_a',
  cb: 'form_field_classifier_b',
  cc: 'form_field_classifier_c',
  cd: 'form_field_classifier_d',
  start_date: 'start_date',
  end_date: 'end_date',
  keys: 'key',
  countries: "country"
}

const inverseKeyMap = {
  search: 'search',
  form: 'form',
  user: 'users',
  supervisor: 'supervisors',
  location: 'locations',
  chain_category: 'chain_categories',
  chain: 'chains',
  location_type: 'location_types',
  zone: 'zones',
  form_field_classifier_a: 'ca',
  form_field_classifier_b: 'cb',
  form_field_classifier_c: 'cc',
  form_field_classifier_d: 'cd',
  start_date: 'start_date',
  end_date: 'end_date',
  key: 'keys',
  country: 'countries',
}

class FilterModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      forms: [],
      datasources: {}, 
      loading_datasources: true,
    }

    this.selectSearch = this.selectSearch.bind(this)
    this.selectForm = this.selectForm.bind(this)
    this.selectDateRange = this.selectDateRange.bind(this)

    this.selectUsers = this.selectUsers.bind(this)
    this.selectSupervisors = this.selectSupervisors.bind(this)

    this.selectChainCategories = this.selectChainCategories.bind(this)
    this.selectChains = this.selectChains.bind(this)
    this.selectLocations = this.selectLocations.bind(this)
    this.selectCountries = this.selectCountries.bind(this)
    this.selectLocationTypes = this.selectLocationTypes.bind(this)
    this.selectZones = this.selectZones.bind(this)

    this.selectCa = this.selectCa.bind(this)
    this.selectCb = this.selectCb.bind(this)
    this.selectCc = this.selectCc.bind(this)
    this.selectCd = this.selectCd.bind(this)

    this.selectKeys = this.selectKeys.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.clearDates = this.clearDates.bind(this)

    this.loadLocationOptions = this.loadLocationOptions.bind(this)
  }

  loadLocationOptions(filter) {
    return ImagesApi.getLocationDatasources(filter).then((response) => {
      let locations =  response.locations.map((record) => ({value: record.id, label: record.name}))
      return locations
    })
  }

  handleDelete() {
    this.props.handleDelete()
  }

  handleSubmit() {
    let keys = Object.keys(this.props.filters)
    let filters = {}

    keys.forEach((key, index) => {
      let data = this.props.filters[key]
      if(key == 'form') {
        if(data.value) { filters[keyMap[key]] = data.value }
      } else if (key == 'search') {
        filters[keyMap[key]] = data
      } else if (key == 'start_date') {
        if(data) { filters[keyMap[key]] = data}
      } else if (key == 'end_date') {
        if(data) { filters[keyMap[key]] = data}
      } else {
        if(data.length > 0) {
          filters[keyMap[key]] = []
          data.forEach((datum) => {
            filters[keyMap[key]].push(datum.value)
          })
        }
      }
    })

    this.props.handleSubmit(filters)
  }

  clearDates() {
    let filters = this.props.filters
    filters.start_date = null
    filters.end_date = null
    this.props.handleState({filters: filters})
  }

  selectSearch(event) {
    let value = event.target.value
    let filters = this.props.filters
    filters.search = value
    this.props.handleState({filters: filters})
  }

  selectForm(data) {
    let filters = this.props.filters
    filters.form = data
    filters.ca = []
    filters.cb = []
    filters.cc = []
    filters.cd = []
    filters.keys = []

    this.setState({keys: []},() => {
      ImagesApi.getFormKeys(data.value).then((response) => {
        let keys = response.keys.map((record) => ({value: record.slug, label: record.full_name_count, form_id: record.form_id}))
        this.setState({keys: keys}, () => {
          this.props.handleState({filters: filters, gallery_keys: keys})
        })
      })
    })
  }

  selectDateRange(data) {
    let filters = this.props.filters
    filters.start_date = data.startDate
    filters.end_date = data.endDate
    this.props.handleState({filters: filters})
  }

  selectUsers(data) {
    let disabled_others = data.length == 0 ? false : true
    let filters = this.props.filters
    let disabled = this.props.disabled
    filters.users = data
    filters.supervisors = []
    disabled.supervisors = disabled_others

    this.props.handleState({filters: filters, disabled: disabled})
  }

  selectSupervisors(data) {
    let disabled_others = data.length == 0 ? false : true
    let filters = this.props.filters
    let disabled = this.props.disabled
    filters.supervisors = data
    filters.users = []
    disabled.users = disabled_others

    this.props.handleState({filters: filters, disabled: disabled})
  }
  
  selectChainCategories(data) {
    let disabled_others = data.length == 0 ? false : true
    let filters = this.props.filters
    let disabled = this.props.disabled
    filters.chain_categories = data
    filters.locations = []
    filters.chains = []
    disabled.chains = disabled_others
    disabled.locations = disabled_others

    this.props.handleState({filters: filters, disabled: disabled})
  }

  selectChains(data) {
    let disabled_others = data.length == 0 ? false : true
    let filters = this.props.filters
    let disabled = this.props.disabled
    filters.chains = data
    filters.chain_categories = []
    filters.locations = []
    disabled.chain_categories = disabled_others
    disabled.locations = disabled_others

    this.props.handleState({filters: filters, disabled: disabled})
  }

  selectCountries(data) {
    let filters = this.props.filters
    filters.countries = data
    this.props.handleState({filters: filters})
  }

  selectLocations(data) {
    let disabled_others = data.length == 0 ? false : true
    let filters = this.props.filters
    let disabled = this.props.disabled
    filters.locations = data
    filters.chain_categories = []
    filters.chains = []
    disabled.chains = disabled_others
    disabled.chain_categories = disabled_others

    this.props.handleState({filters: filters, disabled: disabled})
  }

  selectLocationTypes(data) {
    let filters = this.props.filters
    filters.location_types = data
    this.props.handleState({filters: filters})
  }

  selectZones(data) {
    let filters = this.props.filters
    filters.zones = data
    this.props.handleState({filters: filters})
  }

  selectCa(data) {
    let filters = this.props.filters
    filters.ca = data
    this.props.handleState({filters: filters})
  }

  selectCb(data) {
    let filters = this.props.filters
    filters.cb = data
    this.props.handleState({filters: filters})
  }

  selectCc(data) {
    let filters = this.props.filters
    filters.cc = data
    this.props.handleState({filters: filters})
  }

  selectCd(data) {
    let filters = this.props.filters
    filters.cd = data
    this.props.handleState({filters: filters})
  }

  selectKeys(data) {
    let filters = this.props.filters
    filters.keys = data
    this.props.handleState({filters: filters})
  }

  componentDidMount() {
    moment.locale(this.props.language)

    ImagesApi.getDatasources().then((response) => {
      let forms = response.forms.map((record) => ({value: record.id, label: record.full_name_count}))

      let chains = response.chains.map((record) => ({value: record.id, label: record.name}))
      let chain_categories = response.chain_categories.map((record) => ({value: record.id, label: record.name}))
      let zones = response.zones.map((record) => ({value: record.id, label: record.name}))
      let location_types = response.location_types.map((record) => ({value: record.id, label: record.name}))

      let users = response.users.map((record) => ({value: record.id, label: record.name}))
      let supervisors = response.supervisors.map((record) => ({value: record.id, label: record.name}))

      let ca = response.ca.map((record) => ({value: record.id, label: record.label, form_id: record.form_id}))
      let cb = response.cb.map((record) => ({value: record.id, label: record.label, form_id: record.form_id}))
      let cc = response.cc.map((record) => ({value: record.id, label: record.label, form_id: record.form_id}))
      let cd = response.cd.map((record) => ({value: record.id, label: record.label, form_id: record.form_id}))

      let datasources = {
        forms: forms,
        chain_categories: chain_categories,
        chains: chains,
        location_types: location_types,
        zones: zones,
        users: users,
        supervisors: supervisors,
        ca: ca,
        cb: cb,
        cc: cc,
        cd: cd,
        countries: response.countries,
      }

      if(this.props.editingGallery) {
        let filters = this.props.editingGallery.filters
        let new_filters = {}
        let disabled = {}
        let keys = Object.keys(inverseKeyMap)
        keys.forEach((key, index) => {
          if(key == 'form') {
            if(filters[key]) {
              new_filters[keyMap[key]] = forms.find((form) => form.value == filters[key])
            } else {
              new_filters[keyMap[key]] = {}
            }
          } else if (key == 'search') {
            new_filters[keyMap[key]] = filters[key] || ''
          } else if (key == 'start_date') {
            new_filters[keyMap[key]] = filters[key] ? moment(filters[key]) : null
          } else if (key == 'end_date') {
            new_filters[keyMap[key]] = filters[key] ? moment(filters[key]) : null
          } else {
            if(filters[key]) {
              if(key == 'location') {
                disabled.chains = true
                disabled.chain_categories = true
              } else if ( key == 'chain' ) {
                disabled.locations = true
                disabled.chain_categories = true
              } else if ( key == 'chain_category') {
                disabled.chains = true
                disabled.locations = true
              } else if ( key == 'user') {
                disabled.supervisors = true
              } else if ( key == 'supervisor') {
                disabled.users = true
              }
              if(inverseKeyMap[key] == 'locations') {
                new_filters[inverseKeyMap[key]] = this.props.editingGallery.locations
              } else {
                let sources = datasources[inverseKeyMap[key]]
                if(inverseKeyMap[key] == 'keys') {
                  sources = this.props.keys
                }

                new_filters[inverseKeyMap[key]] = sources.filter((source) => filters[key].includes(source.value))
              }
            } else {
              new_filters[inverseKeyMap[key]] = []
            }
          }
        })

        this.props.handleState({filters: new_filters, disabled: disabled})
      }

      this.setState({forms: response.forms, datasources: datasources, loading_datasources: false})
    })
  }

  render() {
    let {ca, cb, cc, cd} = this.state.datasources
    let keys = this.state.keys || this.props.keys
    let form_id = this.props.filters.form.value
    let ca_label = (<Translate id="advance_search.classifier_a" />)
    let cb_label = (<Translate id="advance_search.classifier_b" />)
    let cc_label = (<Translate id="advance_search.classifier_c" />)
    let cd_label = (<Translate id="advance_search.classifier_d" />)
    if(form_id && !this.state.loading_datasources) {
      let form = this.state.forms.find((form) => form.id == form_id)
      ca = ca.filter((item) => item.form_id == form_id )
      cb = cb.filter((item) => item.form_id == form_id )
      cc = cc.filter((item) => item.form_id == form_id )
      cd = cd.filter((item) => item.form_id == form_id )
      if (form.form_field_classifier_a_label) { ca_label = form.form_field_classifier_a_label}
      if (form.form_field_classifier_b_label) { cb_label = form.form_field_classifier_b_label}
      if (form.form_field_classifier_c_label) { cc_label = form.form_field_classifier_c_label}
      if (form.form_field_classifier_d_label) { cd_label = form.form_field_classifier_d_label}
    }

    let form_value = form_id ? this.props.filters.form : null

    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={this.props.closeSearchModal}
        isOpen={this.props.isOpen}>

        <div className="">
          <div onClick={this.props.closeSearchModal} className="close-button">
            <i style={{cursor: 'pointer', marginTop: '15px', marginRight: '20px'}} className="fa fa-times fa-2x clickable pull-right"></i>
          </div>
          { !this.state.loading_datasources &&
            <div className="p-5">
              <h2 className="text-dark h5 d-block campo_font-weight-semi-bold mb-4">
                {this.props.comesFromGallery ? (
                  <Translate id="galleries.modal.title" />
                ) : (
                  <Translate id="advance_search.title" />
                )}
              </h2>
              {this.props.comesFromGallery &&
                <div className="row mb-4">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        <Translate id="advance_search.form.name.label" />
                      </label>

                      <input value={this.props.name} onChange={this.props.handleName} className='form-control text-dark'/>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label className='form-group--campo_label text-uppercase ml-2'>
                        <input
                          type="checkbox"
                          className='campo-checkbox mr-2'
                          checked={this.props.public_show_only_favorites}
                          onChange={this.props.handlePublicFavorites} />
                        <Translate id="advance_search.form.public_show_only_favorites.label" /> <i className="flag-clicker-icon fa fa-star-o"></i>
                      </label>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="form-group">
                      <label className='form-group--campo_label text-uppercase'>
                        <input
                          type="checkbox"
                          className='campo-checkbox mr-2'
                          checked={this.props.public_hide_flagged}
                          onChange={this.props.handlePublicFlagged} />
                        <Translate id="advance_search.form.public_hide_flagged.label" /> <i className="flag-clicker-icon fa fa-flag-o"></i>
                      </label>
                    </div>
                  </div>
                </div>
              }

              <div className="row mb-4">
                { !this.props.comesFromGallery && this.props.fast_filter_type != 'predefined' &&
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        <Translate id="advance_search.form.search.label" />
                      </label>

                      <input value={this.props.filters.search} onChange={this.selectSearch} className='form-control text-dark'/>
                    </div>
                  </div>
                }

                <div className="col-12 col-lg-6">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.form.label" />
                    </label>

                    <Select
                      styles={selectStyles}
                      value={form_value}
                      placeholder={(<Translate id="advance_search.form.form.placeholder" />)}
                      onChange={this.selectForm}
                      options={this.state.datasources.forms}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="form-group--campo_label text-uppercase m-0">
                      <Translate id="advance_search.form.date_range.label" />
                      { (this.props.filters.start_date || this.props.filters.end_date) &&
                        <a className='ml-3' onClick={this.clearDates}>
                          <Translate id="advance_search.form.date_range.clear" />
                        </a>
                      }
                    </label>
                    
                    <div>
                      <DateRangePicker
                        orientation="horizontal"
                        verticalHeight={568}
                        hideKeyboardShortcutsPanel
                        startDate={this.props.filters.start_date}
                        startDateId="start_date_id"
                        startDatePlaceholderText={this.props.translate("advance_search.form.date_range.placeholder_start")}
                        endDate={this.props.filters.end_date}
                        endDateId="end_date_id"
                        endDatePlaceholderText={this.props.translate("advance_search.form.date_range.placeholder_end")}
                        onDatesChange={this.selectDateRange}
                        focusedInput={this.state.focusedInput}
                        isOutsideRange={day => (moment().diff(day) < 0)}
                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                      />
                    </div>
                  </div>
                </div>

                { (form_id) && keys && keys.length > 0 &&
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="form-group--campo_label text-uppercase m-0">
                        <Translate id="advance_search.form.keys.label" />
                      </label>

                      <Select
                        closeMenuOnSelect={false}
                        styles={selectStyles}
                        value={this.props.filters.keys}
                        placeholder={(<Translate id="advance_search.form.keys.placeholder" />)}
                        isMulti={true}
                        onChange={this.selectKeys}
                        options={keys}
                      />
                    </div>
                  </div>
                }
              </div>

              { form_id && (ca.length > 0 || cb.length > 0 || cc.length > 0 || cd.length > 0) &&
                <div className="row mb-4">
                  {ca.length > 0 &&
                    <div className="col-12 col-lg-3">
                      <div className="form-group form-group--campo">
                        <label className="text-uppercase m-0">
                          {ca_label}
                        </label>

                        <Select
                          styles={selectStyles}
                          value={this.props.filters.ca}
                          placeholder={(<Translate id="advance_search.form.classifier.placeholder" />)}
                          isMulti={true}
                          onChange={this.selectCa}
                          options={ca}
                        />
                      </div>
                    </div>
                  }
                  {cb.length > 0 &&
                    <div className="col-12 col-lg-3">
                      <div className="form-group form-group--campo">
                        <label className="text-uppercase m-0">
                          {cb_label}
                        </label>

                        <Select
                          styles={selectStyles}
                          value={this.props.filters.cb}
                          placeholder={(<Translate id="advance_search.form.classifier.placeholder" />)}
                          isMulti={true}
                          onChange={this.selectCb}
                          options={cb}
                        />
                      </div>
                    </div>
                  }
                  {cc.length > 0 &&
                    <div className="col-12 col-lg-3">
                      <div className="form-group form-group--campo">
                        <label className="text-uppercase m-0">
                          {cc_label}
                        </label>

                        <Select
                          styles={selectStyles}
                          value={this.props.filters.cc}
                          placeholder={(<Translate id="advance_search.form.classifier.placeholder" />)}
                          isMulti={true}
                          onChange={this.selectCc}
                          options={cc}
                        />
                      </div>
                    </div>
                  }
                  {cd.length > 0 &&
                    <div className="col-12 col-lg-3">
                      <div className="form-group form-group--campo">
                        <label className="text-uppercase m-0">
                          {cd_label}
                        </label>

                        <Select
                          styles={selectStyles}
                          value={this.props.filters.cd}
                          placeholder={(<Translate id="advance_search.form.classifier.placeholder" />)}
                          isMulti={true}
                          onChange={this.selectCd}
                          options={cd}
                        />
                      </div>
                    </div>
                  }
                </div>
              }

             
              <div className="row mb-4">
                <div className="col-12 col-lg-4">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.chain_category.label" />
                    </label>

                    <Select
                      styles={selectStyles}
                      value={this.props.filters.chain_categories}
                      placeholder={(<Translate id="advance_search.form.chain_category.placeholder" />)}
                      isMulti={true}
                      isDisabled={this.props.disabled.chain_categories}
                      onChange={this.selectChainCategories}
                      options={this.state.datasources.chain_categories}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.chain.label" />
                    </label>

                    <Select
                      styles={selectStyles}
                      value={this.props.filters.chains}
                      placeholder={(<Translate id="advance_search.form.chain.placeholder" />)}
                      isMulti={true}
                      isDisabled={this.props.disabled.chains}
                      onChange={this.selectChains}
                      options={this.state.datasources.chains}
                    />
                  </div>
                </div>

                { this.state.datasources.location_types.length > 0 && 
                  <div className="col-12 col-lg-4">
                    <div className="form-group form-group--campo">
                      <label className="text-uppercase m-0">
                        <Translate id="advance_search.form.location_type.label" />
                      </label>

                      <Select
                        styles={selectStyles}
                        value={this.props.filters.location_types}
                        placeholder={(<Translate id="advance_search.form.location_type.placeholder" />)}
                        isMulti={true}
                        isDisabled={this.props.disabled.location_types}
                        onChange={this.selectLocationTypes}
                        options={this.state.datasources.location_types}
                      />
                    </div>
                  </div>
                }

                { this.state.datasources.zones.length > 0 && 
                  <div className="col-12 col-lg-4">
                    <div className="form-group form-group--campo">
                      <label className="text-uppercase m-0">
                        <Translate id="advance_search.form.zone.label" />
                      </label>

                      <Select
                        styles={selectStyles}
                        value={this.props.filters.zones}
                        placeholder={(<Translate id="advance_search.form.zone.placeholder" />)}
                        isMulti={true}
                        isDisabled={this.props.disabled.zones}
                        onChange={this.selectZones}
                        options={this.state.datasources.zones}
                      />
                    </div>
                  </div>
                }

                <div className="col-12 col-lg-4">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.location.label" />
                    </label>

                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isMulti={true}
                      placeholder={(<Translate id="advance_search.form.location.placeholder" />)}
                      isDisabled={this.props.disabled.locations}
                      value={this.props.filters.locations}
                      onChange={this.selectLocations} 
                      loadOptions={this.loadLocationOptions} />
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.country.label" />
                    </label>


                    <Select
                      styles={selectStyles}
                      value={this.props.filters.countries}
                      placeholder={(<Translate id="advance_search.form.country.placeholder" />)}
                      isMulti={true}
                      isDisabled={this.props.disabled.countries}
                      onChange={this.selectCountries}
                      options={this.state.datasources.countries}
                    />
                  </div>
                </div>

              </div>
              
              <div className="row mb-4">
                <div className="col-12 col-lg-4">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.user.placeholder" />
                    </label>

                    <Select
                      styles={selectStyles}
                      value={this.props.filters.users}
                      placeholder={(<Translate id="advance_search.form.user.placeholder" />)}
                      isMulti={true}
                      isDisabled={this.props.disabled.users}
                      onChange={this.selectUsers}
                      options={this.state.datasources.users}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="form-group form-group--campo">
                    <label className="text-uppercase m-0">
                      <Translate id="advance_search.form.supervisor.label" />
                    </label>

                    <Select
                      styles={selectStyles}
                      value={this.props.filters.supervisors}
                      placeholder={(<Translate id="advance_search.form.supervisor.placeholder" />)}
                      isMulti={true}
                      isDisabled={this.props.disabled.supervisors}
                      onChange={this.selectSupervisors}
                      options={this.state.datasources.supervisors}
                    />
                  </div>
                </div>
              </div> 
              <div className="row justify-content-end">
                <div className="col-auto">
                  { this.props.comesFromGallery && this.props.editingGallery &&
                    <button 
                      onClick={this.handleDelete}
                      className="text-white mr-2 text-uppercase form-campo__button_danger border-0 py-2 px-4 rounded">
                      <Translate id="galleries.delete" />
                    </button>
                  }
                  <button 
                    onClick={this.handleSubmit}
                    className="text-white text-uppercase form-campo__button border-0 py-2 px-4 rounded">

                    {this.props.comesFromGallery ? (
                      <div>
                        { this.props.editingGallery ? (
                          <Translate id="galleries.save" />
                        ) : (
                          <Translate id="galleries.create" />
                        )}
                      </div>
                    ) : (
                      <Translate id="advance_search.filter" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>
    )
  }
}

export default withLocalize(FilterModal)
