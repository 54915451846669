import React, { Component } from 'react';
import uuid from 'node-uuid';
import { NumberFormatter as NF } from '../../utils/index';
import BaseInsightComponent from './BaseInsightComponent';

export default class InsightTable extends BaseInsightComponent {
  constructor(props) {
    super(props);
  }

  renderGroupedRows(rows) {
    const simpleGroupedRows = rows.headers.map((header, index) => {
      return [header].concat(rows.values[index]);
    });

    return (
      <tbody key={uuid.v4()}>
        <tr className='group-title-row'>
          <th className='text-left'>{rows.title}</th>
          {rows.totals.map((total) => {
            return <th key={uuid.v4()} className='text-right'>{total}</th>
          })}
        </tr>
        {simpleGroupedRows.map((row) => {
          const [header, ...rest] = row;
          return (
            <tr key={uuid.v4()}>
              <th className='text-right'>{header}</th>
              {rest.map((v) => <td key={uuid.v4()} className='text-right'>{NF.withSeparator(v)}</td>)}
            </tr>
          );
        })}
      </tbody>
    );
  }

  renderRow(row) {
    return (
      <tr key={uuid.v4()}>
        <th className='text-right'>{row.header}</th>
        {row.values.map((v) => <td key={uuid.v4()} className='text-right'>{NF.withSeparator(v)}</td>)}
      </tr>
    );
  }

  renderContent() {
    if (this.isLoading() && !this.props.showReload) {
      return (
        <div className='spinner-wrap'>
          Loading ...
        </div>
      );
    } else if (this.props.showReload) {
      return(this.renderReload())
    } else{
      let body;
      if (this.props.data.grouped) {
        body = this.props.data.rows.map((row) => this.renderGroupedRows(row));
      } else {
        body = (
          <tbody>
            {this.props.data.rows.map((row) => this.renderRow(row))}
          </tbody>
        );
      }

      let totals;

      if(this.props.data.totals){
        totals = (
          <tbody>
            <tr>
              <th className='text-right'>{i18n.t('table_component.table_result_total')}</th>
              {this.props.data.totals.map(t => <th key={uuid.v4()} className='text-right'>{NF.withSeparator(t)}</th>)}
            </tr>
          </tbody>
        )
      }


      return (
        <table className='table'>
          <thead>
            <tr>
              <th></th>
              {this.props.data.headers.map((header) => <th key={uuid.v4()} className='text-right'>{header}</th>)}
            </tr>
          </thead>
          {body}
          {totals}
        </table>
      );
    }
  }

  render() {
    return (
      <div>
        <p className='dashboard-label'>{this.props.name}</p>
        <p className='dashboard-description'>{this.props.description}</p>
        {this.renderContent()}
      </div>
    );
  }
}
