import React, { useState, useEffect, Fragment } from 'react'
import { render } from 'react-dom'

import AnalyticsApi from '../../utils/analytics_api'
import AnalyticCopyModal from './AnalyticCopyModal'
import AnalyticSaveModal from './AnalyticSaveModal'

const ReportBox = ({
  setActiveReport,
  setChanged,
  loadIndex,
  analytic_report,
  is_active,
  changed
}) => {
  const [open_copy_modal, setOpenCopyModal] = useState(false)
  const [processing_copy, setProcessingCopy] = useState(false)
  const [edit_report, setEditReport] = useState({})

  let full_class = "cursor-pointer list-group-item list-group-item-action flex-column align-items-start" 
  let ellipsis_style = {
    fontSize: '15px',
  }

  if(is_active) {
    full_class = `${full_class} active`
    ellipsis_style.color = "white"
  }

  const handleEdit = () => {
    let report = Object.assign({}, analytic_report)
    if(report.has_mobile_dashboard) {
      report.has_mobile_dashboard = {value: true, label: 'Yes'}
    } else {
      report.has_mobile_dashboard = {value: false, label: 'No'}
    }
    if(report.show_on == 'organization_reports') {
      report.show_on = {value: 'organization_reports', label: 'Organization reports'}
    } else {
      report.show_on = {value: 'my_reports', label: 'My reports'}
    }
    setEditReport(report)
  }

  const handleEditSubmit = () => {
    let data = {analytic_report: edit_report}
    AnalyticsApi.editAnalyticReport(data).then((response) => {
      setEditReport({})
      loadIndex()
    })
  }

  const handleCopySave = (new_title) => {
    if(processing_copy) {
      return
    }

    setProcessingCopy(true)

    AnalyticsApi.copy(analytic_report.id, new_title).then((response) => {
      setOpenCopyModal(false)
      setProcessingCopy(false)
      setChanged(!changed)
    })
  }

  const handleRemove = () => {
    AnalyticsApi.remove(analytic_report.id).then((response) => {
      setChanged(!changed)
    })
  }

  const handleView = (e) => {
    if(!$(e.target).attr('class').match("fa-ellipsis-v")) {
      setActiveReport(analytic_report)
    }
  }

  return(
    <div
      onClick={handleView}
      style={{zIndex: 'auto'}} 
      className={full_class}>
      <div className="d-flex w-100 justify-content-between">
        <div
          className="mb-1">
          {analytic_report.name}

          { (analytic_report.analytic_report_folder || {}).label &&
            <div className='mt-1 mb-2' style={{fontSize: '12px'}}>
              <i className='fa fa-folder-o mr-1'></i>
              {(analytic_report.analytic_report_folder || {}).label}
            </div>
          }
        </div>

        <div className="btn-group">
          <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="px-2">  
            <i
              className='fa fa-ellipsis-v px-1 cursor-pointer'
              style={ellipsis_style}>
            </i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            { analytic_report.can_edit &&
                <a href="#" onClick={handleEdit} className='dropdown-item'>Edit</a>
            }
            { analytic_report.can_copy &&
              <a href="#" onClick={() => setOpenCopyModal(true)} className='dropdown-item'>Copy</a>
            }
            <div className="dropdown-divider"></div>
            { analytic_report.can_destroy &&
              <a href="#" onClick={handleRemove} className='dropdown-item text-danger'>Delete</a>
            }
          </div>  
        </div>
      </div>
      <small className="mb-1">
        {analytic_report.description}
      </small>

      { open_copy_modal && 
        <AnalyticCopyModal 
          closeModal={() => setOpenCopyModal(false)}
          analytic_report={analytic_report}
          handleSubmit={handleCopySave}
        />
      }

      { edit_report.id &&
        <AnalyticSaveModal 
          save_modal_open={true}
          closeSaveModal={() => setEditReport({})}
          analytic_report={edit_report}
          handleReportData={(data) => setEditReport({...edit_report, ...data})}
          handleSubmit={handleEditSubmit}
          isEdit={true}
        />
      }


    </div>
  )
}

export default ReportBox
