import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

const getComments = (model_data) => {
  let url = '/web_api/projects_management/comments'
  return axios.get(url, {params: model_data}).then(response => response.data)
}

const createComment = (model_data) => {
  let url = '/web_api/projects_management/comments'
  return axios.post(url, model_data).then(response => response.data)
}

const updateComment = (model_data, id) => {
  let url = `/web_api/projects_management/comments/${id}`
  return axios.put(url, model_data).then(response => response.data)
}

const destroyComment = (model_data, id) => {
  let url = `/web_api/projects_management/comments/${id}`
  return axios.delete(url, {params: model_data}).then(response => response.data)
}

module.exports = {
  getComments: getComments,
  createComment: createComment,
  updateComment: updateComment,
  destroyComment: destroyComment,
}
