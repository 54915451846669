import React, { Component } from 'react'
import { render } from 'react-dom'

import { LocalizeProvider } from "react-localize-redux"

import { ToastContainer } from 'react-toastify'

import GallerySharesIndexNav from '../components/GalleryShares/GallerySharesIndexNav'
import GallerySharesIndex from '../components/GalleryShares/GallerySharesIndex'
import GalleryShareModal from "../components/GalleryShares/GalleryShareModal"
import ImagesApi from '../utils/images_api'

export default class GallerySharesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      is_editing: false,
      gallery_share: {name: '', description: '', galleries: []},
      gallery_shares: [],
    }

    this.handleCreatedGalleryShare = this.handleCreatedGalleryShare.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.getGalleryShares = this.getGalleryShares.bind(this)

    this.handleData = this.handleData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeSearchModal = this.closeSearchModal.bind(this)
  }

  handleData(value, type) {
    let gallery_share = this.state.gallery_share
    gallery_share[type] = value
    this.setState({gallery_share: gallery_share})
  }

  handleSubmit() {
    let gallery_share = this.state.gallery_share
    if(!gallery_share.name || !gallery_share.description || gallery_share.galleries.length == 0) {
      return
    }

    ImagesApi.updateGalleryShare({
      gallery_share: gallery_share,
    }).then((response) => {
      this.setState({is_editing: false, gallery_share: {name: '', description: '', galleries: []}}, () => {
        this.getGalleryShares()
      })
    })

  }

  closeSearchModal() {
    this.setState({is_editing: false, gallery_share: {name: '', description: '', galleries: []}})
  }

  getGalleryShares() {
    ImagesApi.getGalleryShares().then((gallery_shares) => {
      this.setState({gallery_shares})
    })
  }

  handleCreatedGalleryShare() {
    this.getGalleryShares()
  }

  handleRemove() {
    this.getGalleryShares()
  }

  handleReset() {
    this.getGalleryShares()
  }

  handleEdit(gs) {
    this.setState({gallery_share: gs, is_editing: true})
  }
  
  componentDidMount() {
    this.getGalleryShares()
  }

  render() {
    return(
      <LocalizeProvider>
        <GallerySharesIndexNav
          handleCreatedGalleryShare={this.handleCreatedGalleryShare}
          {...this.props}
        />
      
        <GallerySharesIndex 
          gallery_shares={this.state.gallery_shares}
          handleRemove={this.handleRemove}
          handleEdit={this.handleEdit}
          handleReset={this.handleReset}
        />

        <GalleryShareModal 
          search_modal_open={this.state.is_editing}
          is_editing={this.state.is_editing}
          closeSearchModal={this.closeSearchModal}
          galleries={this.props.galleries}
          gallery_share={this.state.gallery_share}
          handleData={this.handleData}
          handleSubmit={this.handleSubmit}
        />

      </LocalizeProvider>
    )
  }
}
