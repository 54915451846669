import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Dropzone from 'react-dropzone'

import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

import AttachmentFiles from './AttachmentFiles'

export default class Attachments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      max_files: props.max_files || 1000,
      multiple: props.multiple || true,
      loading: true,
    }

    this.onDrop = this.onDrop.bind(this)
  }

  onDrop(files) {
    let current_files = this.props.documents

    if((files.length + current_files.length) <= this.state.max_files) {
      let files_map = files.map((file) => {
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            uploaded: false,
            uuid: Math.random().toString(36).substring(9),
            extension: file.name.split('.').pop()
          }
        )
      })

      let files_data = files_map.map(file => {
        return {name: file.name, uuid: file.uuid, extension: file.extension, type: file.type }
      })

      let params = {
        form_id: this.props.form_id,
        document_key: this.props.document_key,
        files: files_data || []
      }

      axios.get(`/web_api/forms/${this.props.form_id}/document_signed_urls`, {params: params}).then((response) => {
        let data = response.data.signed_urls

        files_map = files_map.map((file) => {
          let upload_data = data.find(e => e.uuid == file.uuid)
          return Object.assign(file, {...upload_data})
        })

        this.props.setDocuments(files_map.concat(this.props.documents))
      })
    }
  }

  render() {
    let { max_files, multiple } = this.state
    let { can_create_attachment, small_view } = this.props

    let documents = this.props.documents

    return(
      <div className='py-4'>
        { can_create_attachment && documents.length < max_files &&
          <div className='row'> 
            <div className='col-md-12'> 
              <Dropzone
                className="dropzone py-3 mb-2"
                multiple={multiple}
                onDrop={this.onDrop}>
                <center className="text-secondary"><u><span className="font-weight-bold">Choose files</span></u> or drag them here</center>
              </Dropzone>
            </div>
          </div>
        }

        <div className='row'> 
          <div className='col-md-12'> 
            <AttachmentFiles 
              files={documents}
              small_view={small_view}
              form_id={this.props.form_id}
              document_key={this.props.document_key}
              removeFile={this.props.removeFile}
            />
          </div>
        </div>
      </div>
    )
  }
}
