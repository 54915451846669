import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

function Empty(props) {
  return(
    <div className='row'>
      <div className='col-md-12'>
        <div className='empty_list pt-5'>
          <center>
            <span>{props.text || 'Empty list'}</span>
          </center>
        </div>
      </div>
    </div>
  )
}
export default Empty
