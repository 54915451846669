import React from 'react';
import Lightbox from 'react-images';

class ProfilePageImages extends React.Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.OnImageClicked = this.OnImageClicked.bind(this);

    this.state = { currentPageIndex: 0, isOpen: false };
  }

  onClose(){
    this.setState({ isOpen: false });
  }

  onClickPrev(){
    let currentPageIndex = this.state.currentPageIndex - 1;
    let totalPages = this.props.photos.length;
    currentPageIndex = currentPageIndex < 0 ? totalPages : currentPageIndex;

    this.setState({ currentPageIndex })
  }

  onClickNext(){
    let currentPageIndex = this.state.currentPageIndex + 1;
    let totalPages = this.props.photos.length;
    currentPageIndex = currentPageIndex > totalPages ? 0 : currentPageIndex;

    this.setState({ currentPageIndex })
  }

  OnImageClicked(event){
    let currentPageIndex = parseInt(event.target.dataset.index);
    this.setState({ currentPageIndex, isOpen: true });
  }

  mappedImages(){
    return this.props.photos.map(this.imageMap);
  }

  imageMap(photo){
    return { src: photo.url, caption: photo.caption, srcset: [photo.url] }
  }

  renderPhotos(images) {
    let imageElements = images.map((image, index) => {
      return (
        <div key={index} className="gallery-image" data-toggle="tooltip" data-placement="bottom" title={image.caption}>
          <img className="img-thumbnail small-thumbnail clickable" src={image.src} data-index={index} onClick={this.OnImageClicked} />
        </div>
        )
    });

    return (
      <div>
        {imageElements}
      </div>
    );
  }

  render() {
    let images = this.mappedImages();
    let {isOpen, currentPageIndex} = this.state
    let lightbox = (<Lightbox
      images={images}
      isOpen={isOpen}
      currentImage={currentPageIndex}
      onClickImage={this.OnImageClicked}
      onClose={this.onClose}
      onClickPrev={this.onClickPrev}
      onClickNext={this.onClickNext} />)

      return (
      <div>
        {lightbox}
        {this.renderPhotos(images)}
      </div>
      );
  }
}

ProfilePageImages.defaultProps = { photos: [] };

export default ProfilePageImages;
