import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import SelectDatasource from './SelectDatasource';
import SelectGraph from './SelectGraph';
import SelectFieldgroup from './SelectFieldgroup';
import SelectFieldSubgroups from './SelectFieldSubgroups';
import SelectOperations from './SelectOperations';
import SelectPeriod from './SelectPeriod';
import SelectLocations from './SelectLocations';
import SelectGrouping from './SelectGrouping';
import SelectSummarize from './SelectSummarize';
import SelectUsers from './SelectUsers';
import SelectDashboardGroup from './SelectDashboardGroup';
import resourcesApi from '../../utils/resources_api'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '2px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
};

function StepHeader(props) {
  return(
    <div className='form-step-header'>
      <div className='step-number'>
        {props.number}
      </div>
      <div className='step-header'>
        <span className='step-title'>
          {props.title}
        </span>
        <span className='step-description'>
          {props.description}
        </span>
      </div>
    </div>
  )
}

function Step1 (props) {
  return(
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-step'>
          <StepHeader number={1} title={props.title} description={props.description} />
          <div className='form-step-content'>
            <div className='row'>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className='form-part'>
                  <label>{props.dashboardName}</label>
                  <input
                    id='dashboardName'
                    className='form-control'
                    type='text'
                    defaultValue={props.data.name}
                    onChange={props.handleInputName} />
                </div>
              </div>

              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className='form-part'>
                  <label>{props.dashboardDescription}</label>
                  <input
                    id='dashboardDescription'
                    className='form-control'
                    type='text'
                    defaultValue={props.data.description}
                    onChange={props.handleInputDescription} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Step1.defaultProps = {
  dashboardName: i18n.t('insight.form.step1.input_name'),
  dashboardDescription: i18n.t('insight.form.step1.input_description'),
  title: i18n.t('insight.form.step1.title'),
  description: i18n.t('insight.form.step1.description'),
}

function Step2 (props) {
  return (
    <div className='row'>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className='form-step'>
          <StepHeader number={2} title={props.title} description={props.description} />

          <div className='form-step-content'>
            <div className='row'>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className='form-part'>
                  <label>{props.dashboardDatasource}</label>
                  <SelectDatasource 
                    handleSelectDatasource={props.handleSelectDatasource}
                    selectedValue={props.data.datasource}
                    datasources={props.datasources}
                    isFetching={props.isFetching}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Step2.defaultProps = {
  dashboardDatasource: i18n.t('insight.form.step2.input_select_datasource'),
  title: i18n.t('insight.form.step2.title'),
  description: i18n.t('insight.form.step2.description'),
}

class FormDataSetStep extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let baseStep = 3
    let showPeriod = true
    if(this.props.hideDatasourceSelect) {
      baseStep = 2
    }

    if(this.props.filters.date && this.props.filters.date.type == 'absolute' && (this.props.data.fieldGroup.value == "_campodata_location_presence" || this.props.data.fieldGroup.value == "_campodata_location_presence_percentage" )) {
      showPeriod = false
    }

    return(
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-step'>
              <StepHeader number={baseStep} title={this.props.title} description={this.props.description} />
              <div className='form-step-content'>
                <SelectGraph 
                  handleChartSelect={this.props.handleChartSelect}
                  dashboardTypes={this.props.dashboardTypes}
                  dashboard_type={this.props.data.dashboard_type}/>
              </div>
            </div>
          </div>
        </div>
        { this.props.selected_chart &&
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-step'>
                <StepHeader number={baseStep + 1} title={this.props.title2} description={this.props.description2} />
                <div className='form-step-content'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='operation-section'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-part'>
                              <label>{this.props.selectFieldGroupText}</label>
                              <SelectFieldgroup 
                                handleSelectFieldGroup={this.props.handleSelectFieldGroup}
                                fieldGroup={this.props.data.fieldGroup}
                                dashboard_type={this.props.data.dashboard_type}
                                field_groups={this.props.field_groups} />
                            </div>
                          </div>
                          {!this.props.hideOperations &&
                          <div className='col-md-6'>
                            <div className='form-part'>
                              <label>{this.props.selectOperationText}</label>
                              <SelectOperations 
                                handleSelectOperation={this.props.handleSelectOperation}
                                operation={this.props.data.operation}
                                operations={this.props.operations} />
                            </div>
                          </div>
                          }
                        </div>
                        <div className='row'>
                          <div className='col-md-12'>
                            <SelectFieldSubgroups
                              fieldGroup={this.props.data.fieldGroup}
                              field_subgroups={this.props.field_subgroups}
                              selectedFieldSubGroups={this.props.data.selectedFieldSubGroups}
                              handleSelectFieldSubgroups={this.props.handleSelectFieldSubgroups}

                              dashboard_type={this.props.data.dashboard_type}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  
                  { showPeriod &&
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='operation-section'>
                        { this.props.filters.date && 
                          <div className='row'>
                            { this.props.filters.date.start_date &&
                              <div className='col-md-6'>
                                <div className='form-part'>
                                  <label>{this.props.startDateText}</label>
                                  <h5>{this.props.filters.date.start_date}</h5>
                                </div>
                              </div>
                            }

                            { this.props.filters.date.end_date &&
                              <div className='col-md-6'>
                                <div className='form-part'>
                                  <label>{this.props.endDateText}</label>
                                  <h5>{this.props.filters.date.end_date}</h5>
                                </div>
                              </div>
                            }
                          </div>
                        }

                        <SelectPeriod
                          datasource={this.props.data.datasource}
                          fieldGroup={this.props.data.fieldGroup}
                          periods={this.props.periods}
                          period={this.props.data.period}
                          period_in_past={this.props.data.period_in_past}
                          dashboard_type={this.props.data.dashboard_type}

                          dashboardTypes={this.props.dashboardTypes}
                          handleSelectPeriodInPast={this.props.handleSelectPeriodInPast}
                          handleSelectPeriod={this.props.handleSelectPeriod}/>
                      </div>
                    </div>
                  </div>
                  }

                  { this.props.data.datasource.capture_location &&
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='operation-section'>
                          <SelectLocations
                            locations={this.props.data.locations}
                            location_filters={this.props.filters.locations}
                            handleSelectLocations={this.props.handleSelectLocations}/>
                        </div>
                      </div>
                    </div>
                  }

                  { (this.props.filters.supervisors || this.props.filters.users) &&
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='operation-section'>
                        <div className='row'>
                          { this.props.filters.supervisors &&
                            <div className='col-md-6'>
                              <div className='form-part'>
                                <label>{this.props.selectSupervisorsText}</label>
                                <SelectUsers
                                users={this.props.data.supervisors}
                                user_filters={this.props.filters.supervisors}
                                handleSelect={this.props.handleSelectSupervisors}/>
                              </div>
                            </div>
                          }

                          { this.props.filters.users &&
                            <div className='col-md-6'>
                              <div className='form-part'>
                                <label>{this.props.selectUsersText}</label>
                                <SelectUsers
                                users={this.props.data.users}
                                user_filters={this.props.filters.users}
                                handleSelect={this.props.handleSelectUsers}/>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  }
             
                  <div className='row'>
                    <div className='col-md-12'>
                      { this.props.selected_chart.value != 'single_value' &&
                        <div className='operation-section'>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-part'>
                                <label>{this.props.selectGroupingText}</label>
                                <SelectGrouping
                                  group_by={this.props.data.group_by}
                                  selected_chart={this.props.selected_chart}
                                  fieldGroup={this.props.data.fieldGroup}
                                  handleSelectGrouping={this.props.handleSelectGrouping}/>
                              </div>
                            </div>

                            { (this.props.data.fieldGroup.value == '_campodata_location_presence_percentage' || this.props.data.fieldGroup.value == '_campodata_location_presence') &&
                              <div className='col-md-6'>
                                <div className='form-part'>
                                  <label>{this.props.selectSummarizeText}</label>
                                  <SelectSummarize
                                    summarize_by={this.props.data.summarize_by}
                                    summarize_data={this.props.summarize_data}
                                    handleSelectSummarize={this.props.handleSelectSummarize}/>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

FormDataSetStep.defaultProps = {
  title: i18n.t('insight.form_data_set.step3.title'),
  description: i18n.t('insight.form_data_set.step3.description'),
  title2: i18n.t('insight.form_data_set.step4.title'),
  description2: i18n.t('insight.form_data_set.step4.description'),

  startDateText: i18n.t('insight.form_data_set.step4.start_date'),
  endDateText: i18n.t('insight.form_data_set.step4.end_date'),
  selectFieldGroupText: i18n.t('insight.form.step4.input_field_group'),
  selectOperationText: i18n.t('insight.form.step4.input_operation'),
  selectGroupingText: i18n.t('insight.form.step4.input_grouping'),
  selectSupervisorsText: i18n.t('insight.form.step4.input_supervisors'),
  selectUsersText: i18n.t('insight.form.step4.input_users'),
  selectSummarizeText: i18n.t('insight.form.step4.input_summarize'),

  reportTitle: i18n.t('insight.form.step4.report_title'),
  locationTitle: i18n.t('insight.form.step4.location_title'),
  dateTitle: i18n.t('insight.form.step4.date_title'),
  groupTitle: i18n.t('insight.form.step4.group_title'),
  usersTitle: i18n.t('insight.form.step4.users_title'),
}

class FormStep extends React.Component {
  constructor(props) {
    super(props)
  }


  render () {
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-step'>
              <StepHeader number={3} title={this.props.title} description={this.props.description} />
              <div className='form-step-content'>
                <SelectGraph 
                  handleChartSelect={this.props.handleChartSelect}
                  dashboardTypes={this.props.dashboardTypes}
                  dashboard_type={this.props.data.dashboard_type}/>
              </div>
            </div>
          </div>
        </div>
        
        { this.props.selected_chart &&this.props.selected_chart.value == 'drill_down' &&
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-step'>
                <StepHeader number={4} title={this.props.title2} description={this.props.description2} />
                <div className='form-step-content'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='col-md-6'>
                        <div className='form-part'>
                          <label>{this.props.selectFieldGroupText}</label>
                          <SelectFieldgroup 
                            handleSelectFieldGroup={this.props.handleSelectFieldGroup}
                            dashboard_type={this.props.data.dashboard_type}
                            fieldGroup={this.props.data.fieldGroup}
                            field_groups={this.props.field_groups} />
                        </div>
                      </div>
                    </div>
                  </div>
                  { !this.props.entity_id && this.props.dashboard_groups.length > 0 && 
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='col-md-6'>
                          <div className='form-part'>
                            <label>{this.props.selectDashboardGroupText}</label>
                            <SelectDashboardGroup 
                              empty_dashboard_group_label={this.props.empty_dashboard_group_label}
                              handleSelectDashboardGroup={this.props.handleSelectDashboardGroup}
                              dashboard_group={this.props.data.dashboard_group}
                              dashboard_groups={this.props.dashboard_groups} />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        { this.props.selected_chart && this.props.selected_chart.value != 'drill_down' &&
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-step'>
                <StepHeader number={4} title={this.props.title2} description={this.props.description2} />
                <div className='form-step-content'>
                  
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='operation-section'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-part'>
                              <label>{this.props.selectFieldGroupText}</label>
                              <SelectFieldgroup 
                                handleSelectFieldGroup={this.props.handleSelectFieldGroup}
                                dashboard_type={this.props.data.dashboard_type}
                                fieldGroup={this.props.data.fieldGroup}
                                field_groups={this.props.field_groups} />
                            </div>
                          </div>
                          {!this.props.hideOperations &&
                          <div className='col-md-6'>
                            <div className='form-part'>
                              <label>{this.props.selectOperationText}</label>
                              <SelectOperations 
                                handleSelectOperation={this.props.handleSelectOperation}
                                operation={this.props.data.operation}
                                operations={this.props.operations} />
                            </div>
                          </div>
                          }
                        </div>
                        <div className='row'>
                          <div className='col-md-12'>
                            <SelectFieldSubgroups
                              fieldGroup={this.props.data.fieldGroup}
                              field_subgroups={this.props.field_subgroups}
                              selectedFieldSubGroups={this.props.data.selectedFieldSubGroups}
                              dashboard_type={this.props.data.dashboard_type}
                              handleSelectFieldSubgroups={this.props.handleSelectFieldSubgroups} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='operation-section'>
                        <SelectPeriod
                          datasource={this.props.data.datasource}
                          fieldGroup={this.props.data.fieldGroup}
                          periods={this.props.periods}
                          period={this.props.data.period}
                          period_in_past={this.props.data.period_in_past}
                          dashboard_type={this.props.data.dashboard_type}
                          dashboardTypes={this.props.dashboardTypes}
                          handleSelectPeriodInPast={this.props.handleSelectPeriodInPast}
                          handleSelectPeriod={this.props.handleSelectPeriod}/>

                      </div>
                    </div>
                  </div>

                  { this.props.data.datasource.capture_location &&
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='operation-section'>
                          <SelectLocations
                            locations={this.props.data.locations}
                            location_filters={this.props.location_filters}
                            handleSelectLocations={this.props.handleSelectLocations}/>
                        </div>
                      </div>
                    </div>
                  }

                  <div className='row'>
                    <div className='col-md-12'>
                      { this.props.selected_chart.value != 'single_value' &&
                        <div className='operation-section'>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-part'>
                                <label>{this.props.selectGroupingText}</label>
                                <SelectGrouping
                                  group_by={this.props.data.group_by}
                                  selected_chart={this.props.selected_chart}
                                  fieldGroup={this.props.data.fieldGroup}
                                  handleSelectGrouping={this.props.handleSelectGrouping}/>
                              </div>
                            </div>

                            { (this.props.data.fieldGroup.value == '_campodata_location_presence_percentage' || this.props.data.fieldGroup.value == '_campodata_location_presence') &&
                            <div className='col-md-6'>
                              <div className='form-part'>
                                <label>{this.props.selectSummarizeText}</label>
                                <SelectSummarize
                                  summarize_by={this.props.data.summarize_by}
                                  summarize_data={this.props.summarize_data}
                                  handleSelectSummarize={this.props.handleSelectSummarize}/>
                              </div>
                            </div>
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  { !this.props.entity_id && this.props.dashboard_groups.length > 0 && 
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='operation-section'>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-part'>
                                <label>{this.props.selectDashboardGroupText}</label>
                                <SelectDashboardGroup 
                                  empty_dashboard_group_label={this.props.empty_dashboard_group_label}
                                  handleSelectDashboardGroup={this.props.handleSelectDashboardGroup}
                                  dashboard_group={this.props.data.dashboard_group}
                                  dashboard_groups={this.props.dashboard_groups} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

FormStep.defaultProps = {
  title: i18n.t('insight.form.step3.title'),
  description: i18n.t('insight.form.step3.description'),
  title2: i18n.t('insight.form.step4.title'),
  description2: i18n.t('insight.form.step4.description'),
  selectDashboardGroupText: i18n.t('insight.form.step4.input_dashboard_group'),
  selectFieldGroupText: i18n.t('insight.form.step4.input_field_group'),
  selectOperationText: i18n.t('insight.form.step4.input_operation'),
  selectLocationText: i18n.t('insight.form.step4.input_location'),
  selectGroupingText: i18n.t('insight.form.step4.input_grouping'),
  selectSummarizeText: i18n.t('insight.form.step4.input_summarize'),

  reportTitle: i18n.t('insight.form.step4.report_title'),
  locationTitle: i18n.t('insight.form.step4.location_title'),
  dateTitle: i18n.t('insight.form.step4.date_title'),
  groupTitle: i18n.t('insight.form.step4.group_title'),
  usersTitle: i18n.t('insight.form.step4.users_title'),
}

export default class InsightForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      data: props.form.data,
      isFetching: false,
      hideOperations: true,
      loadedFormData: false,
    };

    this.handleInputName = this.handleInputName.bind(this)
    this.handleInputDescription = this.handleInputDescription.bind(this)
    this.handleSelectDatasource = this.handleSelectDatasource.bind(this)
    this.handleSelectFieldGroup = this.handleSelectFieldGroup.bind(this)
    this.handleSelectFieldSubgroups = this.handleSelectFieldSubgroups.bind(this)
    this.handleChartSelect = this.handleChartSelect.bind(this)
    this.handleSelectOperation = this.handleSelectOperation.bind(this)
    this.handleSelectPeriod = this.handleSelectPeriod.bind(this)
    this.handleSelectPeriodInPast = this.handleSelectPeriodInPast.bind(this)
    this.handleSelectLocations = this.handleSelectLocations.bind(this)
    this.handleSelectGrouping = this.handleSelectGrouping.bind(this)
    this.handleSelectSummarize = this.handleSelectSummarize.bind(this)
    this.handleSelectUsers = this.handleSelectUsers.bind(this)
    this.handleSelectSupervisors = this.handleSelectSupervisors.bind(this)
    this.handleSelectDashboardGroup = this.handleSelectDashboardGroup.bind(this)
    this.closeForm = this.closeForm.bind(this)
    this.renderButton = this.renderButton.bind(this)
  }

  

  handleSelectUsers(values) {
    let users = []
    values.forEach((value) => {
      let selected_user = this.state.fds_filters.users.find((data) => data.id == value)
      users.push(selected_user)
    })

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {users: users}),
      }                                                                         
    })
  }

  handleSelectSupervisors(values) {
    let supervisors = []
    values.forEach((value) => {
      let selected_supervisor = this.state.fds_filters.supervisors.find((data) => data.id == value)
      supervisors.push(selected_supervisor)
    })

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {supervisors: supervisors}),
      }                                                                         
    })
  }

  handleSelectLocations(values, labels, filter_type) {
    let new_locations = []
    let type
    
    new_locations = this.state.data.locations.filter((obj) => {
      if(filter_type == 'chain') {
        return obj.type != 'chain' && obj.type != 'chain_category'

      } else {
        return obj.type != filter_type
      }
    })

    values.forEach((value, index) => {
      type = value.split("_").slice(0,-1).join("_")
      new_locations.push({value: value, type: type })
    })

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {locations: new_locations}),
      }                                                                         
    })
  }

  handleSelectGrouping(value) {
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {group_by: {value: value}}),
      }                                                                         
    })
  }

  handleSelectDashboardGroup(value) {
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {dashboard_group: {value: value}}),
      }                                                                         
    })
  }

  handleSelectSummarize(value) {
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {summarize_by: {value: value}}),
      }                                                                         
    })
  }

  handleSelectPeriodInPast(value) {
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {period_in_past: {value: value}}),
      }                                                                         
    })
  }

  handleSelectPeriod(value) {
    let field_group_value = this.state.data.fieldGroup.value
    let selected_period = this.state.periods.find((data) => data.value == value) || {}

    if(field_group_value == '_campodata_location_presence_percentage' || 
      field_group_value == '_campodata_location_presence') {
      let filtered = this.state.dashboardTypes.filter((obj) => obj.value == 'single_value')
      if(filtered[0]) {
        selected_period = filtered[0].periods.find((data) => data.value == value) || {}
      }
    }

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {period: selected_period}),
      }                                                                         
    })
  }

  handleSelectOperation(value) {
    let selected_operation
    selected_operation = this.state.operations.find((data) => data.value == value)

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {operation: selected_operation}),
      }                                                                         
    })
  }

  handleSelectFieldSubgroups(values) {
    let new_subgroups = []
    let type

    if(!Array.isArray(values)) {
      values = [values]
    }

    values.forEach((value, index) => {
      type = value.split("_").slice(0,2).join("_")
      new_subgroups.push({value: value, type: type })
    })

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {selectedFieldSubGroups: new_subgroups}),
      }                                                                         
    })
  }

  handleSelectFieldGroup(value) {
    let forOperations =  ["_campodata_compliance", "_campodata_detailed_compliance", "_campodata_reports", "_campodata_location_presence", "_campodata_location_presence_percentage", "_campodata_exceptions"]
    let hideOperations = forOperations.indexOf(value) > -1

    this.setState(() =>{
      return {
        data: Object.assign(this.state.data, {fieldGroup: {value: value}}),
        hideOperations: hideOperations,
      }
    })
  }

  handleChartSelect(value) {
    let search_for_periods
    let selected_chart
    let periods
    let period
    let period_in_past

    let selectedFieldSubGroups = this.state.data.selectedFieldSubGroups

    if (value.value == 'bar_chart' || value.value == 'line_chart') {
      search_for_periods = 'graph'
    } else{
      search_for_periods = value.value
    }


    selected_chart = this.state.dashboardTypes.find((data) => data.value == search_for_periods)
    periods = selected_chart.periods

    if(this.state.data.period.value &&
      this.state.data.dashboard_type &&
      (this.state.data.dashboard_type.value == value.value)) {
      //Edit case
      period = this.state.data.period
      period_in_past = this.state.data.period_in_past
    } else {
      if (selected_chart.value != 'single_value' && selected_chart.value != 'drill_down' ) {
        period = periods[0]
        period_in_past = {value: period.periods_in_past.default}

        if (this.state.data.period.value && this.state.data.dashboard_type.value != 'single_value') {
          period = this.state.data.period
        }

        if (this.state.data.period_in_past.value && this.state.data.dashboard_type.value != 'single_value') {
          period_in_past = this.state.data.period_in_past
        }

      } else {
        period = periods[0]
        period_in_past = {}
        selectedFieldSubGroups = []
      }
    }

    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {selectedFieldSubGroups: selectedFieldSubGroups, dashboard_type: value, period: period, period_in_past: period_in_past}),
        periods: periods,
        selected_chart: selected_chart,
      }                                                                         
    })
  }

  handleSelectDatasource(value) {
    let selected_datasource
    let locations = []
    let selectedFieldSubGroups = []
    let operation = {}
    let values = this.props.form.datasources.map((obj) => {
      return obj.values
    })

    values = [].concat.apply([], values);

    selected_datasource = values.find((data) => data.value == value)
    if(!selected_datasource){
      return
    }

    selected_datasource.model_value = value.split('_').slice(-1)[0]

    this.setState(() =>{                                                        
      return {                                                                  
        isFetching: true,
      }                                                                         
    })

    if(selected_datasource.type == 'form') {
      if(this.state.data.datasource) {
        if(this.state.data.datasource.value == selected_datasource.value) {
          //Edit case
          locations = this.state.data.locations || []
          selectedFieldSubGroups = this.state.data.selectedFieldSubGroups || []
          operation = this.state.data.operation
        }
      }

      resourcesApi.getDataSourceTypes(selected_datasource).then((response) => {
        if(!operation.value) {
          operation = response.operations[0]
        }

        this.setState(() =>{                                                        
          return {                                                                  
            isFetching: false,
            loadedFormData: true,
            dashboardTypes: response.dashboardTypes,
            operations: response.operations,
            field_subgroups: response.field_subgroups,
            field_groups: response.field_groups,
            filters: response.filters,
            summarize_data: response.summarize_data,
            data: Object.assign(this.state.data, 
              {
                datasource: selected_datasource,
                operation: operation,
                locations: locations,
                selectedFieldSubGroups: selectedFieldSubGroups
              }
            ),
          }                                                                         
        })
      })
    } else {
      resourcesApi.getDataSourceTypes(selected_datasource).then((response) => {
        if(!operation.value) {
          operation = response.operations[0]
        }

        let new_data = {datasource: selected_datasource}

        if( !this.state.data.id) {
          new_data = {
            datasource: selected_datasource,
            locations: response.fds.selected_filters.locations || [],
            supervisors: response.fds.selected_filters.supervisors || [],
            users: response.fds.selected_filters.users || [],
            selectedFieldSubGroups: response.fds.selected_filters.field_subgroups || [],
          }
        }

        this.setState(() =>{
          return {
            isFetching: false,
            loadedFormData: true,
            fds_filters: response.filters,
            dashboardTypes: response.dashboardTypes,
            operations: response.operations,
            field_subgroups: response.field_subgroups,
            field_groups: response.field_groups,
            summarize_data: response.summarize_data,
            data: Object.assign(this.state.data, new_data)
          }                                                                         
        })
      })
    }
  }

  handleInputName(event) {
    var value = event.target.value                                              
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {name: value})
      }                                                                         
    })
  }

  handleInputDescription(event) {
    var value = event.target.value
    this.setState(() =>{                                                        
      return {                                                                  
        data: Object.assign(this.state.data, {description: value})
      }                                                                         
    })
  }

  closeForm() {
    this.props.closeModal()
  }

  renderButton() {
    let showButton
    if (this.state.data.datasource.type == 'form')  {
      showButton = this.state.data.fieldGroup.value
    } else{
      showButton = this.state.data.fieldGroup.value
    }

    return(
      <div className='row'>
        <div className='col-md-12 form-button-section'>
          <hr/>
          { showButton &&
            <button className='btn btn-success pull-right btn-lg'
              onClick={this.props.handleSave.bind(null, this.state.data)}>
              {this.props.submitText}
            </button>
          }
        </div>
      </div>
    )
  }

  componentDidMount() {
    let selected_value = this.props.form.data.datasource.value
    if (selected_value) {
      this.handleSelectDatasource(selected_value)
    }
  }

  render() {
    let {props} = this;

    return (
      <section>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={props.form.display}>

          <div className='container-fluid campo__dashboard'> 
            <div className='row modal-header--wrapper'>
              <div className='col-md-10 col-sm-10 col-xs-10 title'>
                {this.state.data.id ? props.editDashboardTitle : props.newDashboardTitle}
              </div>
              <div className="col-md-2 col-sm-2 col-xs-2 close-button">
                <i className="fa fa-times fa-2x clickable pull-right" onClick={this.closeForm}></i>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <hr className='header-horizontal-rule' />
              </div>
            </div>

            <div className='row modal--content--wrapper'>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                <div className='modal--content'>
      
                  <Step1 data={this.state.data} 
                    handleInputName={this.handleInputName}
                    handleInputDescription={this.handleInputDescription}/>
          
                  { !props.hideDatasourceSelect &&
                    <Step2 data={this.state.data} 
                      handleSelectDatasource={this.handleSelectDatasource}
                      datasources={this.props.form.datasources}
                      isFetching={this.state.isFetching}/>
                  }

                  { this.state.loadedFormData && this.state.data.datasource.type == 'form' &&
                    <FormStep 
                      data={this.state.data} 
                      dashboardTypes={this.state.dashboardTypes}
                      field_groups={this.state.field_groups}
                      field_subgroups={this.state.field_subgroups}
                      operations={this.state.operations}
                      periods={this.state.periods}
                      location_filters={this.state.filters.locations}
                      selected_chart={this.state.selected_chart}
                      summarize_data={this.state.summarize_data}
                      dashboard_groups={this.props.dashboard_groups}
                      empty_dashboard_group_label={this.props.empty_dashboard_group_label}

                      hideOperations={this.state.hideOperations}
                      handleSelectOperation={this.handleSelectOperation}
                      handleSelectFieldGroup={this.handleSelectFieldGroup}
                      handleSelectFieldSubgroups={this.handleSelectFieldSubgroups}
                      handleSelectPeriod={this.handleSelectPeriod}
                      handleSelectPeriodInPast={this.handleSelectPeriodInPast}
                      handleSelectLocations={this.handleSelectLocations}
                      handleSelectGrouping={this.handleSelectGrouping}
                      handleSelectSummarize={this.handleSelectSummarize}
                      handleSelectDashboardGroup={this.handleSelectDashboardGroup}
                      entity_id={this.props.entity_id}
                      handleChartSelect={this.handleChartSelect}/>
                  }
      
                  { this.state.loadedFormData && this.state.data.datasource.type == 'form_data_set' &&
                    <FormDataSetStep 
                      hideDatasourceSelect={props.hideDatasourceSelect}
                      data={this.state.data}
                      dashboardTypes={this.state.dashboardTypes}
                      field_groups={this.state.field_groups}
                      field_subgroups={this.state.field_subgroups}
                      operations={this.state.operations}
                      periods={this.state.periods}
                      selected_chart={this.state.selected_chart}
                      filters={this.state.fds_filters}
                      summarize_data={this.state.summarize_data}
                      dashboard_groups={this.props.dashboard_groups}
                      empty_dashboard_group_label={this.props.empty_dashboard_group_label}

                      hideOperations={this.state.hideOperations}
                      handleSelectOperation={this.handleSelectOperation}
                      handleSelectFieldGroup={this.handleSelectFieldGroup}
                      handleSelectFieldSubgroups={this.handleSelectFieldSubgroups}
                      handleSelectPeriod={this.handleSelectPeriod}
                      handleSelectPeriodInPast={this.handleSelectPeriodInPast}
                      handleSelectLocations={this.handleSelectLocations}
                      handleSelectGrouping={this.handleSelectGrouping}
                      handleSelectSummarize={this.handleSelectSummarize}
                      handleSelectUsers={this.handleSelectUsers}
                      handleSelectSupervisors={this.handleSelectSupervisors}
                      handleSelectDashboardGroup={this.handleSelectDashboardGroup}
                      entity_id={this.props.entity_id}
                      handleChartSelect={this.handleChartSelect}/>
                  }

                  {this.renderButton()}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

InsightForm.defaultProps = {
  processingText: i18n.t('insight.processing'),
  newDashboardTitle: i18n.t('insight.form.new_title'),
  editDashboardTitle: i18n.t('insight.form.edit_title'),
  submitText: i18n.t('insight.form.submit'),
}
