var app = window.app || {};
app.calendarGoals = app.calendarGoals || {};

$.extend(app.calendarGoals, new function () {

    var module = {
        save: saveCalendarsGoals
    };

    return module;

    function saveCalendarsGoals(success, failure, error){
        const calendarsNodeName = '.calendar';

        $.each($(calendarsNodeName), function(idx, calendar){
            var el = $(calendar);

            var days = {};
            for(var i = 0; i < 7; i++) {
                days[i] = [];
            }

            $.each(el.fullCalendar('clientEvents'), function(index, event){
                var day = event.start.day();
                days[day].push({
                    location: event.location,
                    order: event.order
                });
            });

            el.find('input[name*="[days]"]').val(JSON.stringify(days));
        });

        var form = $(calendarsNodeName).parent('form');

        $.ajax({
            data: form.serialize(),
            url: form.attr('action'),
            type: 'PUT',
            dataType: 'json',
            success: success,
            failure: failure,
            error: error
        });


    }

});




$('#save-calendar').on('click', function(evt){
    app.calendarGoals.save(
        function(data){
            app.calendar.notifications.update(data.message);
        },
        function(data){
            app.calendar.notifications.update(data.errors);
        },
        function(data){
            app.calendar.notifications.update('No pudimos guardar la información en estos momentos. Por favor verificar conectividad de internet', 5000);
        }
    );

    evt.preventDefault();

});
