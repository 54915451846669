import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { CSVLink, CSVDownload } from "react-csv"

import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default class ExportTableButton extends Component {
  constructor(props) {
    super(props)

    this.exportPdf = this.exportPdf.bind(this)
    this.exportRows = this.props.exportRows
  }

  exportPdf() {
    let  { headers, export_rows, filename } = this.props

    let doc = new jsPDF()

    doc.autoTable({
      head: [headers],
      body: export_rows,
    })

    let pdf_filename = (filename || 'pdf_export') +' '+ new Date().toLocaleDateString() + '.pdf'

    doc.save(pdf_filename)
  }

  escapedRows(export_rows) {
    let new_map = export_rows.map((row) => {
      return row.map((element) => {
        if(typeof element == 'string') {
          return (element || "").replace(/\"/g, '\"\"')
        } else {
          return element
        }
      })
    })

    return new_map
  }

  render() {
    let  { headers, export_rows, filename, loading } = this.props
    let csv_data = [headers, ...this.escapedRows(export_rows)] 
    let csv_filename = (filename || 'csv_export') + ' ' + new Date().toLocaleDateString() + '.csv'

    return(
      <div className='d-inline'>
        <div className="dropdown d-inline">
          <button className="btn btn-sm px-4 btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={!!loading}>
            Export
          </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <CSVLink
                data={csv_data}
                className='dropdown-item'
                filename={csv_filename}
              >
                CSV
              </CSVLink>
              <a className="dropdown-item" href="#" onClick={this.exportPdf}>
                PDF
              </a>
            </div>
          </div>
      </div>
    )
  }
}
 
