import React, { Component } from 'react'
import { render } from 'react-dom'

import ImageModal from './ImageModal'
import ImagesApi from '../../utils/images_api'
import Empty from '../../utils/Empty'
import ProgressiveImage from 'react-progressive-image'

import ShowMore from '../../utils/ShowMore'
import ReactPaginate from 'react-paginate'

import { Translate } from "react-localize-redux"
import { ToastContainer, toast } from 'react-toastify'

import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'

class ImageBox extends Component {
  constructor(props) {
    super(props)
    this.changeBanned = this.changeBanned.bind(this)
    this.changeFavorite = this.changeFavorite.bind(this)
    this.downloadSingleImage = this.downloadSingleImage.bind(this)
    this.state = {
      image: null,
      over: false
    }

    this.mouseOut = this.mouseOut.bind(this)
    this.mouseOver = this.mouseOver.bind(this)
  }

  mouseOut(e) {
    this.setState({over: false})
  }

  mouseOver() {
    this.setState({over: true})
  }

  changeBanned() {
    let image = this.state.image || this.props.image
    image.banned = !image.banned

    this.setState({image: image}, () => {
      ImagesApi.changeBanned(this.props.image.id).then((response) => {
        image.banned = response.data.attributes.banned
        this.setState({image: image})
      }).catch((error) => {
        toast.error("Error!");
        image.banned = !image.banned
        this.setState({image: image})
      })

    })
  }

  changeFavorite() {
    let image = this.state.image || this.props.image
    image.favorite = !image.favorite

    this.setState({image: image}, () => {
      ImagesApi.changeFavorite(this.props.image.id).then((response) => {
        image.favorite = response.data.attributes.favorite
        this.setState({image: image})
      }).catch((error) => {
        toast.error("Error!");
        image.favorite = !image.favorite
        this.setState({image: image})
      })
    })
  }

  downloadSingleImage() {
    let image = this.state.image || this.props.image
    window.open(image.image_url, '_blank')
  }

  render() {
    let image = this.state.image || this.props.image
    let bannedIcon = image.banned ? 'fa-flag' : 'fa-flag-o'
    let favoriteIcon = image.favorite ? 'fa-star' : 'fa-star-o'
    let image_url = image.thumbnails_generated ? image.thumb_url : image.image_url
    let style = {}
    let has_size = false

    if(image.thumbnails_generated && image.height) {
      let window_width = $(window).width()
      let image_width = 214

      let new_height = Math.round(image_width * image.height / image.width)
      style = {height: `${new_height}px`, backgroundColor: image.image_dominant_color}
    
      has_size = true
    }

    return(
      <figure href="#"
        style={style}
        onMouseLeave={this.mouseOut}
        onMouseEnter={this.mouseOver}
        onClick={(e) => this.props.selectImage(e, image, this.props.index) }>
        <ProgressiveImage src={image_url} placeholder={this.props.image_placeholder_url}>
          {(src, loading) => {
            if(has_size) {
              if(loading) {
                return (<div style={style}></div>)
              }
              else {
                return  (<img style={style} src={src} alt='an image'/>)
              }
            } else {
              return (<img style={{ opacity: loading ? 0.7 : 1 }} src={src} alt='an image'/>)
            }
          }}
        </ProgressiveImage>
        <figcaption className="font-weight-normal">
          { image.location.name ? (
            <span style={{lineHeight: '1'}}>{image.location.name} - {image.user.name}</span>
          ): (
            <span style={{lineHeight: '1'}}>{image.user.name}</span>
          )}
          <div>
            <span className="d-inline-block">{image.submitted_at}</span>
            { !this.props.comes_from_gallery_share &&
              <nav className="d-inline-block float-right">
                { this.state.over &&
                  <a className='flag-clicker-link' title='Download Image' onClick={this.downloadSingleImage}>
                    <i className={'flag-clicker-icon fa fa-download'}></i>
                  </a>
                }

                { (this.state.over || image.banned) &&
                <a className='flag-clicker-link' title='Toggle Flag Image' onClick={this.changeBanned}>
                  <i className={'flag-clicker-icon fa ' + bannedIcon}></i>
                </a>
                }

                { (this.state.over || image.favorite) &&
                <a className='flag-clicker-link' title='Toggle Favorite Image' onClick={this.changeFavorite}>
                  <i className={'flag-clicker-icon fa ' + favoriteIcon}></i>
                </a>
                }
              </nav>
            }
          </div>
        </figcaption>
      </figure>
    )
  }
}

export default class ImagesIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_modal: false,
      selected_image: {},
      full_image: {},
      loading_image: true,
      selected_image_index: null,
    }

    this.selectImage = this.selectImage.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleSlider = this.handleSlider.bind(this)
    this.downloadImages = this.downloadImages.bind(this)
  }

  downloadImages() {
    let zip = new JSZip()
    let zipFilename = "images.zip"
    let count = 0
    this.props.images.forEach((image) => {
      let lname = image.location.name + "-" + image.user.name + "-" 
      let filename = lname.replace(/ /g,"_") + image.id + "-" + image.key + '.' + image.extension
      JSZipUtils.getBinaryContent(image.image_url, (err, data) => {
        if (err) {
          count++;
        } else {
          zip.file(filename, data, { binary: true })
          count++;
        }

        if(count == this.props.images.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename)
          })
        }
      })
    })
  }

  handleSlider(new_index) {
    let image = this.props.images[new_index]
    this.selectImage({target: {className: ''}}, image, new_index)
  }

  selectImage(event, image, index) {
    if(event.target.className.match('flag-clicker-icon') || event.target.className.match('flag-clicker-icon')) {
      return
    }

    this.setState({
      selected_image: image,
      full_image: {},
      loading_image: true,
      selected_image_index: index,
      open_modal: true,
    }, () => {
      if(this.props.comes_from_gallery_share) {
        this.setState({
          full_image: {},
          loading_image: false,
        })
      } else {
        ImagesApi.getImage(image.id).then((response) => {
          this.setState({
            full_image: response.data.attributes,
            loading_image: false,
          })
        })
      }
    })
  }

  closeModal() {
    this.setState({
      selected_image: {},
      full_image: {},
      selected_image_index: null,
      loading_image: true,
      open_modal: false,
    })
  }

  render() {
    let fixed_class = this.props.onProfilePage ? '' : 'footer-campo fixed-bottom' 
    return(
      <div>
        <ToastContainer/>

        <div className="app p-3">
          <div className="container pb-5">
            {this.props.images.length > 0 ? (
              <section className="gallery">
                {this.props.images.map((image, index) => {
                  return <ImageBox comes_from_gallery_share={this.props.comes_from_gallery_share} image_placeholder_url={this.props.image_placeholder_url} selectImage={this.selectImage} image={image} key={image.id} index={index}/>;
                })}
              </section>
            ) : (
              <Empty text={(<Translate id="nav.no_images_found" />)} />
            )}
          </div>

          {this.state.selected_image.id &&
          <ImageModal
            image_placeholder_url={this.props.image_placeholder_url}
            loading_url={this.props.loading_url}
            image={this.state.selected_image}
            full_image={this.state.full_image}
            loading_image={this.state.loading_image}
            index={this.state.selected_image_index}
            total_images={this.props.images.length}
            is_open={this.state.open_modal}
            closeModal={this.closeModal}
            handleSlider={this.handleSlider}
            comes_from_gallery_share={this.props.comes_from_gallery_share}
          />
          }
        </div>
        { this.props.images.length > 0 &&
          <footer className={fixed_class + " py-3"}>
            <div className="container-fluid">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-auto">
                    <ShowMore
                      selected={this.props.per_page}
                      options={[10,25,50,100]}
                      onSelect={this.props.handlePerPage}
                    />
                  </div>

                  <div className="col-auto">
                    <ReactPaginate previousLabel={"‹"}
                      nextLabel={"›"}
                      breakLabel={<a href="">...</a>}
                      breakClassName={"break-me"}
                      pageCount={this.props.total_pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.props.handlePagination}
                      containerClassName={"pagination pagination-sm pagination-campo m-0"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link cursor-pointer"}
                      activeClassName={"active"} />
                  </div>

                  <div className="col-auto">
                    <a href='#' onClick={this.downloadImages} className="py-1">
                      <small><i className="fa fa-download"></i>&nbsp;<Translate id="nav.download" /></small>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </footer>
        }
      </div>
    )
  }
}
