import React, { Component } from 'react'
import { render } from 'react-dom'
import Select from 'react-select'
import {selectStyles} from '../../../support/helpers'

export default class Group extends Component {
  constructor(props) {
    super(props)

    this.updateGroup = this.updateGroup.bind(this)
  }

  updateGroup(data) {
    let group = this.props.group
    group.group = data
    this.props.updateGroup(
      group.uuid, group
    )
  }

  render() {
    let {group, form, datasources, show_validations} = this.props
    let options = [
      {value: 'chain', label: i18n.t('analytics.form.filter_type.chains')},
      {value: 'location_type', label: i18n.t('analytics.form.filter_type.location_type')},
      {value: 'chain_category', label: i18n.t('analytics.form.filter_type.chain_category')},
      {value: 'zone', label: i18n.t('analytics.form.filter_type.zone')},
      {value: 'location', label: i18n.t('analytics.form.filter_type.location')},
      {value: 'supervisor', label: i18n.t('analytics.form.filter_type.supervisor')},
      {value: 'user', label: i18n.t('analytics.form.filter_type.user')},
      {value: 'city', label: i18n.t('analytics.form.filter_type.city')},
      {value: 'country', label: i18n.t('analytics.form.filter_type.country')},
      {value: 'key', label: i18n.t('analytics.form.filter_type.question')},
      {value: 'answer', label: i18n.t('analytics.form.filter_type.answer')},
      {value: 'exception_message', label: i18n.t('analytics.form.filter_type.exception_message')},
    ]

    let show_errors = show_validations && !group.valid
    let border_class = show_errors ? 'border-danger' : ''
    let new_select_styles = selectStyles
    if(show_errors) {
      new_select_styles =  {
        control: (styles, {isDisabled, isFocused}) => {
          let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
          return { 
            ...styles,
            backgroundColor: backgroundColor,
            borderColor: '#dc3545',
            '&:hover': { borderColor: '#dc3545' },
            boxShadow: `0 0 0 1px 'orange'`
          }
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: 'hsl(0,0%,95%)',
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          fontSize: '16px',
          fontWeight: '600',
        })
      }

    }

    if(form) {
      if(datasources.ca.length > 0) {options.push({label: `Question - ${form.ca_label}`, value: 'ca', type: 'single_select'})}
      if(datasources.cb.length > 0) {options.push({label: `Question - ${form.cb_label}`, value: 'cb', type: 'single_select'})}
      if(datasources.cc.length > 0) {options.push({label: `Question - ${form.cc_label}`, value: 'cc', type: 'single_select'})}
      if(datasources.cd.length > 0) {options.push({label: `Question - ${form.cd_label}`, value: 'cd', type: 'single_select'})}
    }

    options = options.concat(datasources.classifiers)
 
    return(
      <div className="col-auto mr-2 mb-3">
        <div style={{width: '200px', display: 'inline-block'}}>
          <Select
            styles={new_select_styles}
            isMulti={false}
            value={this.props.group.group}
            placeholder={i18n.t('analytics.form.ph.select_group')}
            onChange={this.updateGroup}
            options={options} />
        </div>

        <button
          type="button"
          style={{marginTop: '-3px'}}
          onClick={() => this.props.removeGroup(group.uuid)}
          className={"btn btn-light " + border_class}>
          <i className="fa fa-times"></i>
        </button>
      </div>
    )
  }
}
