import React, { Component } from 'react'
import { render } from 'react-dom'

import Loading from '../utils/Loading'
import ImagesFilters from '../components/Images/ImagesFilters'
import ImagesIndex from '../components/Images/ImagesIndex'

import ImagesApi from '../utils/images_api'

import { LocalizeProvider } from "react-localize-redux"


import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'

export default class ImagesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      images: [],
      total_pages: 0,
      filters: {
        advance_filters: {search: props.search || ''},
        per_page: 25,
        only_favorites: false,
        hide_banned: false,
      }
    }

    this.getImages = this.getImages.bind(this)
    this.handlePagination = this.handlePagination.bind(this)
    this.handlePerPage = this.handlePerPage.bind(this)
    this.handleOnlyFavorites = this.handleOnlyFavorites.bind(this)
    this.handleHideBanned = this.handleHideBanned.bind(this)
    this.handleAdvanceFilters = this.handleAdvanceFilters.bind(this)
    this.downloadImages = this.downloadImages.bind(this)
  }

  downloadImages() {
    let zip = new JSZip()
    let zipFilename = "images.zip"
    let count = 0
    this.state.images.forEach((image) => {
      let lname = image.location.name + "-" + image.user.name + "-" 
      let filename = lname.replace(/ /g,"_") + image.id + "-" + image.key + '.' + image.extension
      JSZipUtils.getBinaryContent(image.image_url, (err, data) => {
        if (err) {
          count++;
        } else {
          zip.file(filename, data, { binary: true })
          count++;
        }

        if(count == this.state.images.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename)
          })
        }
      })
    })
  }

  
  handleAdvanceFilters(advance_filters) {
    let filters = this.state.filters
    filters.advance_filters = advance_filters
    filters.page = 1
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  handleOnlyFavorites() {
    let filters = this.state.filters
    filters.only_favorites = !filters.only_favorites
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  handleHideBanned() {
    let filters = this.state.filters
    filters.hide_banned = !filters.hide_banned
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  getImages(filters) {
    ImagesApi.getImages(filters).then((response) => {
      let images = response.data.map(image => image.attributes)
      let total_pages = response.meta.total_pages
      this.setState({total_pages: total_pages, loading: false, images:images})
    })
  }

  handlePagination(data) {
    let page = (data.selected + 1)
    let filters = this.state.filters
    filters.page = page
    this.getImages(filters)
  }

  handlePerPage(event) {
    let per_page = event.target.value
    let filters = this.state.filters
    filters.per_page = per_page
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  componentWillMount() {
    this.getImages(this.state.filters)
  }

  render() {
    return(
      <LocalizeProvider>
        <ImagesFilters
          only_favorites={this.state.filters.only_favorites}
          hide_banned={this.state.filters.hide_banned}
          language={this.props.language}
          search={this.props.search}
          handleOnlyFavorites={this.handleOnlyFavorites}
          handleHideBanned={this.handleHideBanned}
          handleAdvanceFilters={this.handleAdvanceFilters}
          downloadImages={this.downloadImages}
        />
        { this.state.loading ? (
          <div></div>
        ) : (
          <ImagesIndex 
            image_placeholder_url={this.props.image_placeholder_url}
            loading_url={this.props.loading_url}
            images={this.state.images}
            per_page={this.state.filters.per_page}
            handlePerPage={this.handlePerPage}
            total_pages={this.state.total_pages}
            handlePagination={this.handlePagination}
            downloadImages={this.downloadImages}
          />
        )}
      </LocalizeProvider>
    )
  }
}
