import React, { Component } from 'react'
import { render } from 'react-dom'

import Loading from '../../utils/Loading'
import ImagesApi from '../../utils/images_api'
import GallerySharesShowGalleryNav from './GallerySharesShowGalleryNav'
import ImagesIndex from '../Images/ImagesIndex'

export default class GallerySharesShowGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gallery: null,
      loading: true,
      images: [],
      total_pages: 0,
      filters: {
      }
    }

    this.getImages = this.getImages.bind(this)
    this.handlePagination = this.handlePagination.bind(this)
    this.handlePerPage = this.handlePerPage.bind(this)

    this.handleFilter = this.handleFilter.bind(this)
  }

  handleFilter(filters) {
    let new_filters = Object.assign(filters, this.state.filters)
    new_filters.page = null
    this.setState({filters: new_filters}, () => {
      this.getImages(filters)
    })
  }

  getImages(filters) {
    ImagesApi.getGalleryShareImages(this.state.gallery_share_id, this.state.gallery_id, filters).then((response) => {
      let images = response.data.map(image => image.attributes)
      let total_pages = response.meta.total_pages
      this.setState({total_pages: total_pages, loading: false, images:images})
    })
  }

  handlePagination(data) {
    let page = (data.selected + 1)
    let filters = this.state.filters
    filters.page = page
    this.getImages(filters)
  }

  handlePerPage(event) {
    let per_page = event.target.value
    let filters = this.state.filters
    filters.per_page = per_page
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  componentDidMount() {
    let gallery_id = this.props.match.params.galleryId
    let gallery_share_id = this.props.match.params.galleryShareId

    ImagesApi.getGalleryShareGallery(gallery_share_id, gallery_id, this.props.auth).then((response) => {
      let gallery = response.data.attributes
      let filters = {
        per_page: 25,
        auth: this.props.auth,
      }
      this.setState({
        gallery: gallery,
        gallery_id: gallery_id,
        gallery_share_id: gallery_share_id,
        filters: filters
      }, () => {
        this.getImages(filters)
      })
    })
  }

  render() {
    return(
      <div>
        { this.state.gallery &&
          <GallerySharesShowGalleryNav
            gallery={this.state.gallery}
            language={this.props.language}
            handleFilter={this.handleFilter}
          />
        }

        { this.state.loading ? (
          <div></div>
        ) : (
          <ImagesIndex 
            image_placeholder_url={this.props.image_placeholder_url}
            loading_url={this.props.loading_url}
            images={this.state.images}
            per_page={this.state.filters.per_page}
            handlePerPage={this.handlePerPage}
            total_pages={this.state.total_pages}
            handlePagination={this.handlePagination}
            comes_from_gallery_share={true}
          />
        )}
      </div>
    )
  }
}
