import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectSummarize extends Component {
  constructor(props) {
    super(props);
    this.renderSummarize = this.renderSummarize.bind(this);
  }

  renderSummarize() {
    let summarize_data = this.props.summarize_data
    if (summarize_data.length > 0) {
      let summarizeOptions = summarize_data.map((options) => {
        return (
        <Option key={options.value} text={options.label} value={options.value}>
        {options.label}
        </Option>
        );
      })

      return (
        <Select
          placeholder={this.props.placeholderText}
          value={this.props.summarize_by.value}
          optionLabelProp={'text'}
          onChange={this.props.handleSelectSummarize}
          dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
          style={{ width: '100%' }}
          dropdownStyle={{ zIndex: 101 }}
          >
          {summarizeOptions}
        </Select>
      )
    }
  }

  render() {
    return (
      <div>{this.renderSummarize()}</div>
    );
  }
}

SelectSummarize.defaultProps={
  placeholderText: i18n.t('dashboard_form.placeholder_summarize'),
}
