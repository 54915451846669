module.exports = {
  small_modal_style: {
    overlay : {
      position          : 'fixed',
      top               : 0,
      left              : 0,
      right             : 0,
      bottom            : 0,
      backgroundColor   : 'rgba(0, 0, 0, 0.60)',
      zIndex            : 101,
      display           : 'block'
    },
    content : {
      position                   : 'relative',
      overflow                   : 'auto',
      WebkitOverflowScrolling    : 'touch',
      borderRadius               : 'none',
      outline                    : 'none',
      background                 : 'none',
      border                     : 'none',
      width                      : 'auto',
      maxWidth                   : '550px',
      margin                     : '0 auto',
      left                       : '0px',
      right                      : '0px',
    }
  }
}
