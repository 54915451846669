import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

import SubmissionExplorerFilter from '../components/submission_explorer/SubmissionExplorerFilter'
import Week from '../components/submission_explorer/Week'
import WeekLoader from '../components/submission_explorer/WeekLoader'
import GlobalSummary from '../components/submission_explorer/GlobalSummary'
import Legend from '../components/submission_explorer/Legend'
import submissionExplorerApi from '../utils/submission_explorer_api'

import helpers from '../components/submission_explorer/helpers'
import { ToastContainer, toast } from 'react-toastify';


export default class SubmissionExplorer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_explore: false,
      data: [],
    }

    this.onFilter = this.onFilter.bind(this)
    this.onDownload = this.onDownload.bind(this)
  }

  onFilter(data) {
    let report_type = data.report_type.value
    this.setState({selected_id: null, loading_explore: true, data: []}, () => {
      submissionExplorerApi.explore(data).then((response) => {
        let weeks = response.meta.weeks
        let data = response.data.map((el) => el.attributes)
        let map = []
        let summary = helpers.statistics(data)
        weeks.forEach((week) => map.push({week: week, values: []}))
        data.forEach((value) => map[value.w].values.push(value))
        this.setState({summary: summary, report_type: report_type, loading_explore: false, data: map})
      }).catch(error => {
        toast.error(i18n.t('errors.backend'), {
          position: toast.POSITION.TOP_RIGHT
        })
        this.setState({loading_explore: false, data: []})
      })
    })
  }

  onDownload(data) {
    let json_data = encodeURI(JSON.stringify(data))
    window.open(`/web_api/submission_explorer/download?filters=${json_data}`, '_blank');
  }

  componentWillMount() {
    submissionExplorerApi.getDatasources().then((response) => {
      this.setState({datasources: response})
    })
  }

  render() {
    return(
      <div className='px-3 pt-3' style={{paddingBottom: '150px'}}>
        { this.state.datasources &&
          <div className='row bg-white py-3'>
            <div className='col-md-12'>
              <SubmissionExplorerFilter loading={this.state.loading_explore} datasources={this.state.datasources} onDownload={this.onDownload} onFilter={this.onFilter} />
            </div>
          </div>
        }
        
        { this.state.loading_explore ? (
          <WeekLoader />
        ) : (
          <Fragment>
            { this.state.data.length > 0 &&
              <GlobalSummary summary={this.state.summary} report_type={this.state.report_type}/>
              &&
                  <div>
                    <GlobalSummary summary={this.state.summary} report_type={this.state.report_type}/>
                    <Legend report_type={this.state.report_type}/>
                  </div>
            }
            { this.state.data.map((week) => {
              let key = week.week[0].replace(/\s/g, '')
              return (
                <div className='row bg-white my-4 border' key={key}>
                  <div className='col-md-12'>
                    <Week icons={this.props.icons} week_key={key} report_type={this.state.report_type} week={week}/>
                  </div>
                </div>
              )
            })}
          </Fragment>
        )}

        <ToastContainer autoClose={5000} />
      </div>
    )
  }
}
