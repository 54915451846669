import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Dropzone from 'react-dropzone'

import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

import AttachmentFiles from './AttachmentFiles'

export default class Attachments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      max_files: props.max_files || 1000,
      multiple: props.multiple || true,
      loading: true,
    }

    this.onDrop = this.onDrop.bind(this)
    this.loadFiles = this.loadFiles.bind(this)
  }

  loadFiles() {
    let params = {
      resource_type: this.props.resource_type,
      resource_id: this.props.resource_id,
    }

    axios.get(`/web_api/projects_management/attachments`, {params: params}).then((response) => {
      let files = response.data.data.map(file => file.attributes)
      this.setState({files: files, loading: false})
    })
  }

  componentWillMount() {
    this.loadFiles()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.setState({loading: true}, () => {
        this.loadFiles()
      })
    }
  }

  onDrop(files) {
    let current_files = this.state.files

    if((files.length + current_files.length) <= this.state.max_files) {
      let files_map = files.map((file) => {
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            uploaded: false,
            uuid: Math.random().toString(36).substring(9),
            extension: file.name.split('.').pop()
          }
        )
      })

      let files_data = files_map.map(file => {
        return {name: file.name, uuid: file.uuid, extension: file.extension, type: file.type }
      })

      let params = {
        resource_type: this.props.resource_type,
        resource_id: this.props.resource_id,
        files: files_data || []
      }

      axios.get(`/web_api/projects_management/attachments/signed_urls`, {params: params}).then((response) => {
        let data = response.data.signed_urls

        files_map = files_map.map((file) => {
          let upload_data = data[file.uuid]
          return Object.assign(file, {...upload_data})
        })

        this.setState({files: files_map.concat(current_files)})
      })
    }
  }

  render() {
    let { files, max_files, multiple } = this.state
    let { can_create_attachment, small_view } = this.props

    return(
      <div>
        { can_create_attachment && files.length < max_files &&
          <div className='row'> 
            <div className='col-md-12'> 
              <Dropzone
                className="dropzone py-2 mb-2"
                multiple={multiple}
                onDrop={this.onDrop}>
                <center className="text-secondary"><u><span className="font-weight-bold">Choose files</span></u> or drag them here</center>
              </Dropzone>
            </div>
          </div>
        }

        <div className='row'> 
          <div className='col-md-12'> 
            <AttachmentFiles 
              files={this.state.files}
              resource_type={this.props.resource_type}
              resource_id={this.props.resource_id}
              small_view={small_view}
            />
          </div>
        </div>
      </div>
    )
  }
}
