import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

import Select from 'react-select'
import MonthFilter from '../../utils/MonthFilter'
import moment from 'moment'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class CalendarFilters extends Component {
  constructor(props) {
    super(props)
    let current = moment()
    let start_date = current.startOf('month')
    let end_date = current.endOf('month')

    this.state = {
      filters: {
        year: current.year(),
        month: current.month() + 1,
        day: current.date()
      }
    }

    this.selectMonthYear = this.selectMonthYear.bind(this)
    this.selectUser = this.selectUser.bind(this)
    this.selectReportType = this.selectReportType.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showSubmit = this.showSubmit.bind(this)
  }

  showSubmit() {
    let ss = this.state.filters
    return(ss.user && ss.year && ss.month)
  }

  selectMonthYear(year, month) {
    let filters = this.state.filters
    filters.year = year
    filters.month = month
    filters.day = moment().day()
    this.setState({filters: filters})
  }

  selectUser(data) {
    let filters = this.state.filters
    filters.user = data
    this.setState({filters: filters})
  }

  selectReportType(data) {
    let filters = this.state.filters
    filters.report_type = data
    this.setState({filters: filters})
  }

  handleSubmit() {
    this.props.onFilter(this.state.filters)
  }

  componentWillMount() {
    let se_report_type = cookies.get('se_report_type')
    if(se_report_type) {
      let report_type = this.props.datasources.report_types.find((report_type) => {
        return report_type.value == se_report_type
      })
      let filters = this.state.filters
      filters.report_type = report_type

      this.setState({filters})
    } else {
      let report_type = this.props.datasources.report_types[1]

      let filters = this.state.filters
      filters.report_type = report_type
      this.setState({filters})
    }
  }

  render() {
    return(
      <div className='campo__filters mb-4'>
        <div className='campo__filters-title'>
          <h4>
            {i18n.t('explore.title')}
          </h4>
        </div>
        <div className='campo__filters-inputs'>
          <div className='form-row'>
            <div className="col-lg-3 col-md-3 form-group form-group--campo">
              <label className="text-uppercase m-0">
                {i18n.t('explore.filters.user')}
              </label>

              <Select
                styles={selectStyles}
                value={this.state.filters.user}
                placeholder={i18n.t('explore.filters.calendar_user_placeholder')}
                onChange={this.selectUser}
                options={this.props.datasources.users}
              />
            </div>

            <div className="col-lg-3 col-md-3 form-group form-group--campo">
              <label className="text-uppercase m-0">
                {i18n.t('explore.filters.report_type')}
              </label>

              <Select
                styles={selectStyles}
                value={this.state.filters.report_type}
                placeholder={i18n.t('explore.filters.report_type_placeholder')}
                onChange={this.selectReportType}
                options={this.props.datasources.report_types}
              />
            </div>

            <div className="form-group col-lg-3 col-md-3">
              <label className="form-group--campo_label text-uppercase m-0">
                {i18n.t('explore.filters.month_picker')}
              </label>
              <div>
                <MonthFilter 
                  setFilter={this.selectMonthYear}
                  current_month={this.state.filters.month} 
                  current_year={this.state.filters.year}
                />
              </div>
            </div>
            { this.showSubmit() &&
              <div className="form-group col-lg-3 col-md-3">
                <button 
                  onClick={this.handleSubmit}
                  className="mt-4 pull-right text-white text-uppercase form-campo__button border-0 py-2 px-3 ml-2 rounded">
                  {i18n.t('explore.filters.button')}
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
