import React, { useState, useEffect, Fragment } from 'react'
import { render } from 'react-dom'

import QueryContainer from './QueryContainer'
import MapContainer from './MapContainer'

const ReportView = ({
  analytic_report,
  google_api_key,
  auth
}) => {
  const [tab, setTab] = useState('data')
  const [change, setChange] = useState(false)
  const [show_query, setShowQuery] = useState(false)

  let show_query_label = show_query ? 'Hide query' : 'Show query'

  useEffect(() => {
    if((analytic_report || {}).id) {
      setChange(true)
      const timeoutID = window.setTimeout(() => setChange(false), 300)
      return () => window.clearTimeout(timeoutID )
    }
  }, [analytic_report])

  return (
    <div className="bg-white p-4 vh-100 overflow-auto">
      {change ? (
        <div></div>
      ) : (
        <Fragment>
          <div className="row d-print-none">
            <div className="col-md-12">
              <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                  <a
                    className={"nav-link " + (tab == "data" ? "active" : "")}
                    onClick={() => setTab("data")}
                    href="javascript:void(0)"
                  >
                    Data
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (tab == "map" ? "active" : "")}
                    href="javascript:void(0)"
                    onClick={() => setTab("map")}
                  >
                    Map
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {tab == "map" && (
            <div className="row">
              <div className="col-md-12">
                <MapContainer
                  analytic_report={analytic_report}
                  google_api_key={google_api_key}
                />
              </div>
            </div>
          )}

          {tab == "data" && (
            <div className="row">
              {!auth.client_portal && (
                <div className="col-md-12 d-print-none">
                  <div className="pull-left pb-2" style={{ fontSize: "14px" }}>
                    <a
                      href="#"
                      onClick={() => setShowQuery((b) => !b)}
                      className=" text-primary"
                    >
                      {show_query_label}
                    </a>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <QueryContainer
                  comes_from_report={true}
                  query={analytic_report.query}
                  ar={analytic_report}
                  show_query={show_query}
                  showQuery={() => setShowQuery((b) => !b)}
                  auth={auth}
                />
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}


export default ReportView
