import React, { Component } from 'react';
import resourcesApi from '../../utils/resources_api'
import DataSetForm from './DataSetForm.js';

class CreateDataSetButton extends Component {
  constructor(props) {
    super(props)

    this.handleSave = this.handleSave.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.showInsightCreationModal = this.showInsightCreationModal.bind(this)
    this.state = {
      showModal: false
    }

  }

  showInsightCreationModal() {
    resourcesApi.getFormsDataSource().then((datasources) => {
      this.setState(() => {
        return {
          dataSources: datasources,
          showModal: true,
        }
      })
    })

  }

  closeModal() {
    this.setState(() => {
      return {
        showModal: false,
      }
    })
  }

  handleSave(data) {
    window.location.href = `/forms/${data.datasource.model_value}/form_data_sets/new?name=${data.name}&description=${data.description}`;
  }

  initialForm() {
    return({
      display: this.state.showModal,
      datasources: this.state.dataSources,
      data: {
        name: '',
        description: '',
        datasource: '',
      }
    })
  }

  render() {
    return (
      <div> 
        <a  className="nav-item nav-link py-1" onClick={this.showInsightCreationModal}>
          {i18n.t('data_set.create')}
        </a>

        { this.state.showModal &&
          <DataSetForm
            handleSave={this.handleSave}
            form={this.initialForm()}
            closeModal={this.closeModal}
          />
        }
      </div>
    )
  }
}

export default CreateDataSetButton
