import React, { Component } from 'react'
import { render } from 'react-dom'

import Loading from '../utils/Loading'
import ProfileImagesFilters from '../components/Images/ProfileImagesFilters'
import ImagesIndex from '../components/Images/ImagesIndex'

import ImagesApi from '../utils/images_api'

import { LocalizeProvider } from "react-localize-redux"

export default class ProfileImagesContainer extends Component {
  constructor(props) {
    super(props)
    let filters = props.filters
    filters.search = ''

    this.state = {
      loading: true,
      images: [],
      total_pages: 0,
      filters: {
        advance_filters: filters,
        per_page: 25,
        only_favorites: false,
        only_flagged: false,
      }
    }

    this.getImages = this.getImages.bind(this)
    this.handlePagination = this.handlePagination.bind(this)
    this.handlePerPage = this.handlePerPage.bind(this)
    this.handleAdvanceFilters = this.handleAdvanceFilters.bind(this)
  }

  handleAdvanceFilters(advance_filters) {
    let filters = this.state.filters
    filters.advance_filters = {...advance_filters, ...this.props.filters}
    filters.page = 1
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  getImages(filters) {
    ImagesApi.getImages(filters).then((response) => {
      let images = response.data.map(image => image.attributes)
      let total_pages = response.meta.total_pages
      this.setState({total_pages: total_pages, loading: false, images:images})
    })
  }

  handlePagination(data) {
    let page = (data.selected + 1)
    let filters = this.state.filters
    filters.page = page
    this.getImages(filters)
  }

  handlePerPage(event) {
    let per_page = event.target.value
    let filters = this.state.filters
    filters.per_page = per_page
    this.setState({filters: filters, loading: true} , ()=> {
      this.getImages(filters)
    })
  }

  componentDidMount() {
    this.getImages(this.state.filters)
  }

  render() {
    return(
      <LocalizeProvider>
        <ProfileImagesFilters
          language={this.props.language}
          baseFilters={this.props.filters}
          handleAdvanceFilters={this.handleAdvanceFilters}
        />
        { this.state.loading ? (
          <div></div>
        ) : (
          <ImagesIndex 
            image_placeholder_url={this.props.image_placeholder_url}
            loading_url={this.props.loading_url}
            images={this.state.images}
            per_page={this.state.filters.per_page}
            handlePerPage={this.handlePerPage}
            total_pages={this.state.total_pages}
            handlePagination={this.handlePagination}
            onProfilePage={true}
          />
        )}
      </LocalizeProvider>
    )
  }
}
