import React, { Component } from 'react';
import Select, {Option, OptGroup} from 'rc-select';
import filterOptions from '../../utils/filterOptions';

export default class SelectLocations extends Component {
  constructor(props) {
    super(props);
    this.renderChains = this.renderChains.bind(this);
    this.renderZones = this.renderZones.bind(this);
    this.renderLocationTypes = this.renderLocationTypes.bind(this);

    this.getFilters = this.getFilters.bind(this);
    this.getSelectedFilters = this.getSelectedFilters.bind(this);

    this.handleSelectChains = this.handleSelectChains.bind(this)
    this.handleSelectZones = this.handleSelectZones.bind(this)
    this.handleSelectLocationTypes = this.handleSelectLocationTypes.bind(this)
  }

  handleSelectChains(values, labels) {
    let chain_category_values = []
    let chain_category_labels = []

    values.forEach((value, index) => {
      let type = value.split("_").slice(0,-1).join("_")
      if(type == 'chain_category') {
        chain_category_values.push(value)
        chain_category_labels.push(labels[index])
      }
    })

    if(chain_category_values.length > 0) {
      values = chain_category_values
      labels = chain_category_labels
    }

    this.props.handleSelectLocations(values, labels, 'chain')
  }

  handleSelectZones(values, labels) {
    this.props.handleSelectLocations(values, labels, 'zone')
  }

  handleSelectLocationTypes(values, labels) {
    this.props.handleSelectLocations(values, labels, 'location_type')
  }

  getFilters(type){
    let filters = this.props.location_filters
    let case_filters = []

    case_filters = filters.filter((obj) => {
      return obj.type_filter == type
    })

    return case_filters
  }

  getSelectedFilters(type) {
    let locations = this.props.locations
    let case_locations = []
    if (type == 'chain') {
      case_locations = locations.filter((obj) => {
        return obj.type == 'chain' || obj.type == 'chain_category'
      })
    } else {
      case_locations = locations.filter((obj) => {
        return obj.type == type
      })
    }

    return case_locations.map((data) => data.value)
  }

  renderZones() {
    let selected_locations = this.getSelectedFilters('zone')
    let filter = this.getFilters('zone')[0]
    let filter_values = filter.values.map(a => a.value);

    selected_locations = selected_locations.map((value) => {
      let new_value = filter_values.includes(value) ? value : (value + " (" + i18n.t('deleted') + ")")
      return new_value
    })

    return(
      <Select
        value={selected_locations}
        optionLabelProp={'text'}
        multiple={true}
        placeholder={ this.props.selectMultiple }
        onChange={this.handleSelectZones}
        dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
        style={{width: '100%'}}
        dropdownStyle={{zIndex: 101}}
        >
        {filter.values.map((data) => {
          return (
          <Option key={data.value} text={data.label} value={data.value} > 
            {data.label}
          </Option>
          )
        })}
      </Select>
    )
  }

  renderLocationTypes(){
    let selected_locations = this.getSelectedFilters('location_type')
    let filter = this.getFilters('location_type')[0]

    let filter_values = filter.values.map(a => a.value);

    selected_locations = selected_locations.map((value) => {
      let new_value = filter_values.includes(value) ? value : (value + " (" + i18n.t('deleted') + ")")
      return new_value
    })


    return(
      <Select
        value={selected_locations}
        optionLabelProp={'text'}
        multiple={true}
        placeholder={ this.props.selectMultiple }
        onChange={this.handleSelectLocationTypes}
        dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
        style={{width: '100%'}}
        dropdownStyle={{zIndex: 101}}
        >
        {filter.values.map((data) => {
          return (
          <Option key={data.value} text={data.label} value={data.value} disabled={false}>
          {data.label}
          </Option>
          )
        })}
      </Select>

    )
  }

  renderChains() {
    let selected_locations = this.getSelectedFilters('chain')
    let filters = this.getFilters('chain')

    let filter_values = filters.map(a => a.values.map(b => b.value))
    filter_values = [].concat.apply([], filter_values)

    selected_locations = selected_locations.map((value) => {
      let new_value = filter_values.includes(value) ? value : (value + " (" + i18n.t('deleted') + ")")
      return new_value
    })

    if (filters.length > 0) {
      return (
        <Select
          dropdownClassName='simple-group-dropdown'
          onChange={this.handleSelectChains}
          multiple={true}
          value={selected_locations}
          placeholder={ this.props.selectMultiple }
          optionLabelProp="label"
          dropdownMenuStyle={{maxHeight: 200, overflow: 'auto'}}
          style={{width: '100%'}}
          dropdownStyle={{zIndex: 101}}
          optionFilterProp="label"
          filterOption={filterOptions}>
          {filters.map((data) => {
            return (
            <OptGroup key={data.label} label={data.label} value="">
            {data.values.map((info) => <Option label={info.label} key={info.value} value={info.value}>{info.label}</Option>)}
            </OptGroup>
            )
          })}
        </Select>
      )
    }
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-part'>
              <label>{this.props.selectChainText}</label>
              {this.renderChains()}
            </div>
          </div>
          { this.getFilters('location_type').length > 0 &&
            <div className='col-md-6'>
              <div className='form-part'>
                <label>{this.props.selectLocationTypeText}</label>
                {this.renderLocationTypes()}
              </div>
            </div>
          }
        </div>

        <div className='row'>
          { this.getFilters('zone').length > 0 &&
            <div className='col-md-6'>
              <div className='form-part'>
                <label>{this.props.selectZoneText}</label>
                {this.renderZones()}
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

SelectLocations.defaultProps={
  selectMultiple: i18n.t('insight.form.multiple_select'),
  selectLocationTypeText: i18n.t('insight.form.step4.input_location_type'),
  selectZoneText: i18n.t('insight.form.step4.input_zone'),
  selectChainText: i18n.t('insight.form.step4.input_chain'),
}
