import React from 'react'
import ReactDOM from 'react-dom'

function AndOrRadio(props) {
  return(
    <div className="custom-control custom-radio">
      <div className='filter-type d-inline mr-5'>
        <input type="radio"
          checked={props.selected === 'and'}
          onChange={() => props.onChange('and')}
          id={"andType" + props.uuid}
          className="custom-control-input" />
        <label className="custom-control-label" htmlFor={"andType" + props.uuid}>
          {i18n.t('analytics.form.and')}
        </label>
      </div>
      <div className='filter-type d-inline'>
        <input type="radio"
          onChange={() => props.onChange('or')}
          checked={props.selected === 'or'}
          id={"orType" + props.uuid}
          className="custom-control-input" />
        <label className="custom-control-label" htmlFor={"orType" + props.uuid}>
          {i18n.t('analytics.form.or')}
        </label>
      </div>
    </div>
  )
}

function ConditionLabel(props) {
  return(
    <div className='text-uppercase my-2'>
      <span className="badge badge-pill badge-light">{props.type}</span>
    </div>
  )
}


export { AndOrRadio, ConditionLabel }
