import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Picker from 'react-month-picker'

class MonthBox extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      value: this.props.value || 'N/A',
    }

    this._handleClick = this._handleClick.bind(this)
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.value || 'N/A',
    })
  }

  render() {
    return (
      <div onClick={this._handleClick}>
        <div className="form-group">
          <div className="input-group">
            <input type="text"
              style={{backgroundColor: 'white'}}
              readOnly
              value={this.state.value}
              className="form-control"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="fa fa-calendar"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _handleClick(e) {
    this.props.onClick && this.props.onClick(e)
  }
}

class MonthFilter extends React.Component {
 constructor(props) {
    super(props);

    this.state = {
      mvalue: {
        year: props.current_year,
        month: props.current_month,
      },
    }

    this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
    this.handleAMonthChange = this.handleAMonthChange.bind(this)
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)
    this.yearRange = this.yearRange.bind(this)
  }

  handleClickMonthBox(e) {
    this.refs.pickAMonth.show()
  }

  handleAMonthChange(year, month) {
    this.setState({mvalue: {year: year, month: month}})
    this.props.setFilter(year, month)
  }

  handleAMonthDissmis(value) {
    //
  }

  yearRange() {
    let end_year = this.props.current_year + 1
    let year_range = []
    for (var i = 1; end_year >= 2014; end_year--) {
      year_range.push(end_year);
    }
    return year_range.reverse()
  }

  render() {
    let spanish = ['Ene', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic']
    let english = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const pickerLang = {
      months: english
    }
    const mvalue = this.state.mvalue

    const makeText = m => {
      if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
      return '?'
    }

    return(
      <Picker
        ref="pickAMonth"
        years={this.yearRange()}
        value={mvalue}
        lang={pickerLang.months}
        onChange={this.handleAMonthChange}
        onDismiss={this.handleAMonthDissmis}>
        <MonthBox value={makeText(mvalue)} onClick={this.handleClickMonthBox} />
      </Picker>
    )
  }
}

export default MonthFilter;
