import Rails from '@rails/ujs';

Rails.start();
//From node_modules
import './node_modules'
//Direct inserted vendor libraries
import './vendor_libraries'
//Campodata code, code from jquery and others
import './campodata_js'

//Global setup and global react componets
import './global_components'
//All react related code, mostly containers
import './react_entry_point'

