import vis from './vendor_libraries_files/vis/vis.min'
import Trix from './vendor_libraries_files/trix'
global.Trix = Trix
global.vis = vis

import './vendor_libraries_files/sentir/plugins/chosen/chosen.jquery'
import './vendor_libraries_files/sentir/plugins/magnific-popup/jquery.magnific-popup'
//import './vendor_libraries_files/datatables/pdfmake'
//import './vendor_libraries_files/datatables/vfs_fonts'
//import './vendor_libraries_files/datatables/datatables.min'
//import './vendor_libraries_files/datatables/dataTables.editor.min'
//import './vendor_libraries_files/datatables/dataTables.select.min'
//import './vendor_libraries_files/datatables/dataTables.colReorder.min'
//import './vendor_libraries_files/datatables/buttons.html5.min'
//import './vendor_libraries_files/datatables/buttons.print.min'
//import './vendor_libraries_files/datatables/dataTables.buttons.min'
//import './vendor_libraries_files/datatables/natural'
import './vendor_libraries_files/intlTelInput'
import './vendor_libraries_files/intlUtils'
import './vendor_libraries_files/jquery.ba-throttle-debounce'
//import './vendor_libraries_files/jquery.upload/jquery.ui.widget'
//import './vendor_libraries_files/jquery.upload/jquery.iframe-transport'
//import './vendor_libraries_files/jquery.upload/jquery.fileupload.js'
import './vendor_libraries_files/FileSaver'
