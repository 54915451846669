import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import moment from 'moment'

let default_hour = {hour:12,minute:0,second:0,millisecond:0}

const ranges = {
  today: {
    start_date: moment().set(default_hour),
    end_date: moment().set(default_hour),
  },
  yesterday: {
    start_date: moment().subtract(1, 'day').set(default_hour),
    end_date: moment().subtract(1, 'day').set(default_hour)
  },
  this_week: {
    start_date: moment().startOf('isoWeek').set(default_hour),
    end_date: moment().endOf('isoWeek').set(default_hour),
  },
  this_month: {
    start_date: moment().startOf('month').set(default_hour),
    end_date: moment().endOf('month').set(default_hour),
  },
  past_week: {
    start_date: moment().subtract(1, 'week').startOf('isoWeek').set(default_hour),
    end_date: moment().subtract(1, 'week').endOf('isoWeek').set(default_hour),
  },
  past_month: {
    start_date: moment().subtract(1, 'month').startOf('month').set(default_hour),
    end_date: moment().subtract(1, 'month').endOf('month').set(default_hour),
  }
}

export default class PredefinedPicker extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(type) {
    this.props.handlePredefined(ranges[type])
  }

  render() {
    let style= {
      textTransform: 'uppercase',
      cursor: 'pointer',
      color: '#007bff',
      padding: '3px 0px 3px 0px'
    }

    return(
      <div style={{fontSize: '11px', padding: '3px 0px', marginTop: '-5px'}}>
        <span style={style} onClick={() => {this.handleClick('this_week')}}>{i18n.t('explore.filters.this_week')}</span>{" "}|{" "}
        <span style={style} onClick={() => {this.handleClick('past_week')}}>{i18n.t('explore.filters.past_week')}</span>{" "}|{" "}
        <span style={style} onClick={() => {this.handleClick('this_month')}}>{i18n.t('explore.filters.this_month')}</span>{" "}|{" "}
        <span style={style} onClick={() => {this.handleClick('past_month')}}>{i18n.t('explore.filters.past_month')}</span>
      </div>
    )
  }
}


